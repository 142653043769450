<template>
    <div class="online-dossier" v-if="true">
        <div class="dossier-dir-list">
            <DirList v-if="dirListVisible" />
            <LineStretch></LineStretch>
        </div>
        <div class="dossier-file-list">
            <FileList />
        </div>
        <div ref="dossierFileDetail" class="dossier-file-detail">
            <Shrink ref="shrink" :isShrink.sync="isShrink" />
            <FileDetail v-if="!isShrink" />
        </div>
    </div>
</template>

<script>
import DirList from './dirList';
import FileList from './fileList';
import FileDetail from './fileDetail';
import Shrink from '@/components/shrink';
import LineStretch from '@/common/utils/lineStretch';
import { state , mutations } from '@/utils/store/caseReading.js';

export default {
    components: {
        DirList,FileList,FileDetail,Shrink,LineStretch
    },
    computed: {
        permitList(){
            return this.$store.getters.permitList;
        }
    },
    data(){
        return {
            dirListVisible: true,
            isShrink: true,
            visible: false,
        }
    },
    created(){       
        mutations.dictInit().then(res => {
            this.visible = true;
        })
    },
    watch: {  
        'state.currentFile': {    
            handler(file){
                mutations.selectFile(file,state.dataList);
            }
        }
    },
    methods: {
        reloadDirList(){
            this.dirListVisible = false;
            this.$nextTick(() => {
                this.dirListVisible = true;
            })
        },
        // pageInit(){
        //     let dirCategory = this.$route.query.dirCategory;
        //     if(dirCategory){
        //         mutations.setDirCategroy(dirCategory);
        //     }
        //     let params = {
        //         caseId: state.caseInfo.caseId,
        //         dirCategory: dirCategory || state.dirCategory
        //     }
        //     mutations.getDirList(params).then(() => {
        //         this.visible = true;
        //         mutations.setCoverData();    //  初始化封皮数据
        //     })
        // }
    }
}
</script>

<style scoped lang='less'>
.online-dossier{
    display: flex;
    height: 100%;
    padding: 20px 16px 0 16px;
    box-sizing: border-box;
    background-color: #F7F7F7;
}

.dossier-file-detail{
    flex-shrink: 0;
    display: flex;
}
.dossier-file-list{
    flex-grow: 1;
    width: 0;
    height: 100%;
}
.dossier-dir-list{
    width: 320px;
    flex-shrink: 0;     //  不会等比缩小
    position: relative;
    // .col-line{
    //     width: 1px;
    //     border-left: 4px solid #fff;
    //     height: 100%;
    //     position: absolute;
    //     top: 0;
    //     right: -2px;
    //     background-color: #ccc;
    // }
    // .col-line:hover{
    //     cursor: w-resize;
    // }
}
.v-enter{
    width: 0;
}
.v-enter-active,
.v-leave-active{
    transition: width 12s linear;
    overflow: hidden;
}
.v-leave-to{
    width: 0;
}
</style>