<template>
    <div class="btn-group">
        <div @click="labelClick">
            <slot></slot>
        </div>
        <ul ref="btnList" class="btn-list" v-show="visible" tabindex="1" @blur="blur" v-if="data.length">
            <li class="btn-item" v-show="!item.hidden" v-for="item in data" :key="item.value" @click.stop="itemClick(item)" :url="item.url" v-hasPermit>
                {{item.label}}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default(){
                return [];
            }
        },
    },
    data(){
        return {
            visible: false
        }
    },
    methods: {
        itemClick(data){
            this.visible = false;
            this.$emit('btnClick',data.value);
        },
        labelClick(){
            this.visible = true;
            this.$nextTick(() => {
                this.$refs.btnList.focus();
            })
        },
        blur(){
            this.visible = false;
        },
    }
}
</script>

<style scoped lang='less'>
.btn-group{
    position: relative;
    white-space: nowrap;
    .btn-list{
        min-width: 100%;
        position: absolute;
        z-index: 2021;
        border: 1px solid #1976D2;
        background-color: #F7F7F7;
        color: #1976D2;
        border-radius: 5px;
        margin-top: 1px;
        overflow: hidden;
        .btn-item{
            padding: 0 10px;
            height: 30px;
            line-height: 30px;
            cursor: pointer;
            text-align: left;
        }
        .btn-item:hover{
            background-color: #E0EAF3;
        }
    }
    .btn-list:focus{
        outline: none;
    }
}
</style>