<template>
	<el-dialog :title="`文件上传至(${currentDir && currentDir.name})`" :visible.sync="pageShow" width="80%">
		<div class="upload-btn">
			<div class="left">
                <button class="btn btn-default iconfont iconshangchuan" @click="input.click()">上传本地文件</button>
                <button class="btn btn-default iconfont iconsaomiao" @click="scanClick">扫描图片</button>
                <div class="rname">
                    <el-input size="small" v-model="fileName"></el-input>
                    <button class="btn btn-default el-icon-edit-outline" @click="rname">文件批量重命名</button>
                </div>
            </div>
            <div class="edit-name" v-if="this.currentDir.type != 8 && isRenameFile">
                <div><el-switch v-model="finishUploadParams.rename" active-text="智能编目"></el-switch></div>
                <div class="prompt">此次上传的材料会自动编目（重命名）</div>
            </div>
		</div>
        <div class="set-tag" v-if="(dictMap['MEDIA'] && dictMap['MEDIA'].length) || (dictMap['OWNER'] && dictMap['OWNER'].length)">
            <div class="tag-row" v-if="dictMap['MEDIA'] && dictMap['MEDIA'].length">
                <p>材料介质：</p>
                <el-radio-group v-model="fileMedia">
                    <el-radio :label="item.value" :key="item.value" v-for="item in dictMap['MEDIA']">{{item.label}}</el-radio>
                </el-radio-group>
            </div>
            <div class="tag-row" v-if="dictMap['OWNER'] && dictMap['OWNER'].length">
                <p>材料归属：</p>
                <el-radio-group v-model="fileBelong">
                    <el-radio :label="item.value" :key="item.value" v-for="item in dictMap['OWNER']">{{item.label}}</el-radio>
                </el-radio-group>
            </div>
        </div>
		<div class="upload-area" @dragover.prevent @drop="fileDrop">
			<ul class="upload-list">
				<li v-for="(item,index) in files" :key="index" class="upload-item">
					<div class="operate">
						<button class="btn iconfont iconamplification" @click="toFileView(index)"></button>
						<button class="btn iconfont iconshanchu1" @click="deleteFile(index)"></button>
					</div>
					<div class="img">
                        <img :src="item.dataUrl" alt="">
                        <Progress :value="item.progress" :status="item.status"></Progress>
                    </div>
					<div class="name">{{item.name}}</div>
				</li>
			</ul>
			<div class="point" v-if="files.length === 0 && state.configs.uploadFileRules">
				<p>温馨提示： 批量上传材料控制在1G以内，100份以内</p>
				<p v-for="item in state.configs.uploadFileRules" :key="item.type">
                    {{`${item.type}支持格式：${item.suffix.join('、')} `}}
                    <span class="ml10">{{`大小限制：${item.size / 1024}M`}}</span>
                </p>
			</div>
		</div>
		<div slot="footer" class="dialog-footer">
			<button class="btn btn-default form-btn" @click="backSubmit">取消</button>
			<button class="btn btn-default form-btn" type="primary" @click="submit"
				url="/caseinfo/file/upload">上传</button>
		</div>
		<input ref="input" style="display: none;" type="file" @input="getFiles" multiple>
		<Scan ref="scan" @scanItem="scanItem" />

	</el-dialog>
</template>

<script>
import { fileUpload , finishUpload } from '@/api/fileAPI.js';
import { fileToBase64 , urlToFile } from '@/utils/file.js';
import { getConfigs } from '@/api/commonAPI.js';

import Scan from './Scan.vue';
import Progress from './Progress';

export default {
    components: {
        Scan,
        Progress,
    },
    data() {
        return {
            pageShow: false,
            files: [],
            fileName: '',
            isIE: false,
            finishUploadParams: {
                caseId: this.state.caseInfo.caseId,
                fileIds: [],
                rename: false,
                useLocal: false,
            },
            fileMedia: '',
            fileBelong: '',
            isRenameFile: false,
        };
    },
    inject: ['state', 'mutations'],
    computed: {
        currentDir() {
            return this.state.currentDir || {};
        },
        dirCategory() {
            return this.state.dirCategory;
        },
        input() {
            return this.$refs.input;
        },
        caseId(){
            return this.state.caseInfo.caseId;
        },
        dictMap(){
            return this.state.dictMap;
        },
        configs(){
            return this.$store.getters.configs;
        }
    },
    mounted(){
        if('msSaveOrOpenBlob' in navigator){
            this.isIE = true;
        }
    },
    created(){
        
    },
    methods: {
        init() {
            getConfigs({ key: `renameFile:${this.caseId}` }).then(res => {      //  是否有自动编目
                this.isRenameFile = res;
                this.pageShow = true;
                this.files = [];
                this.fileName = '';
                this.fileMedia = '';
                this.fileBelong = '';
                if(this.currentDir.type === 8){     //  待分类区目录
                    this.finishUploadParams.useLocal = false;           //  使用后端自己的配置
                }else{
                    this.finishUploadParams.rename = this.isRenameFile;
                    this.finishUploadParams.useLocal = true;            //  使用传参的配置
                }
            })
        },
        async getFiles(ev) {
            let files = ev.target.files;
            for (const file of files) {
                let dataUrl;
                if (/image\/*/.test(file.type)) {
                    dataUrl = await fileToBase64(file);
                }
                if(file.type === 'application/pdf'){
                    dataUrl = require('@/assets/imgs/default-pdf.png');
                }
                if(file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
                    dataUrl = require('@/assets/imgs/default-word.png');
                }
                if(file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
                    dataUrl = require('@/assets/imgs/default-excel.png');
                }
                if(/video\/*/.test(file.type)){
                    dataUrl = require('@/assets/imgs/default-video.png');
                }
                if(/audio\/*/.test(file.type)){
                    dataUrl = require('@/assets/imgs/default-audio.png');
                }
                if(file.type === ''){       //..file的type可能检测不出来

                }
                let fileObj = {
                    name: file.name,
                    dataUrl: dataUrl,
                    originFile: file,
                    fileType: file.type,
                    progress: 0,
                    status: 'no',
                }
                this.files.push(fileObj);
            }
            this.input.value = '';
        },
        fileDrop(event) {
            event.preventDefault();
            let files = event.dataTransfer.files;
            this.getFiles({
                target: {
                    files
                }
            })
        },
        toFileView(index) {
            this.mutations.toImgView(this.files[index],this.files,{urlKey: 'dataUrl'});
        },
        scanClick() {
            this.$refs.scan.readyLoadScan();
        },
        async scanItem(src) {
            const file = await urlToFile(src,(new Date()).valueOf() + '.png');
            const dataurl = await fileToBase64(file);
            let fileObj = {
                name: (new Date()).valueOf() + '.png',
                dataUrl: dataurl,
                fileType: 'image/png',
                originFile: file,
                progress: 0,
                status: 'no',
            }
            this.files.push(fileObj);
        },
        deleteFile(index) {
            this.files.splice(index, 1);
        },
        rname() {
            this.files.forEach((item, index) => {
                let strIndex = item.name.lastIndexOf(".");
                item.name = this.fileName + `-${index + 1}` + item.name.substring(strIndex, item.name.length);
            })
        },
        backSubmit(){
            this.pageShow = false;
        },
        async submit() {
            if(!this.files.length){
                this.$message.warning('请选择上传文件!');
                return;
            }
            this.files.forEach(file => file.status = 'wait');   //..等待上传
            await this.uploadStart();
            
            if(this.files.find(file => file.status === 'error')){
                
            }else{
                this.fileName = '';
                this.files = [];
                this.pageShow = false;
            }
            
        },
        async uploadStart() {
            let subCaseId = '';
            let fileIds = [];
            const currentDir = this.currentDir;
            let hasPermit = true;
            for (let i = 0; i < this.files.length; i++) {
                if(!hasPermit){
                    return;
                }
                const file = this.files[i];
                let form = new FormData();
                form.append('caseId', this.caseId);
                form.append('directoryId', this.currentDir.id);
                form.append('category', this.dirCategory);
                form.append('batch', true);
                form.append('subCaseId', subCaseId);
                let tags = [];
                tags.push({
                    type: 'SOURCE',
                    values: ['随']
                })
                if(this.fileMedia){
                    tags.push({
                        type: 'MEDIA',
                        values: [this.fileMedia]
                    })
                }
                if(this.fileBelong){
                    tags.push({
                        type: 'OWNER',
                        values: [this.fileBelong]
                    })
                }
                form.append('tags', JSON.stringify(tags));
                
                if(file.name !== file.originFile.name){
                    if('msSaveOrOpenBlob' in navigator){    //..IE浏览器

                    }else{
                        file.originFile = new File([file.originFile],file.name,{type:file.fileType});
                    }
                }
                //  IE要在后面加上fileName
                form.append('files', file.originFile,file.name);
                
                file.status = 'ing';    //..正在上传

                this.$store.commit('common/setRecord',{
                    key: '/caseinfo/file/upload',
                    value :{
                        _log_notes: `<span style="color: #1976D2">《${file.name}》</span>至${this.currentDir.name}目录`,
                        _log_params: {
                            caseId: this.state.caseInfo.caseId
                        }
                    }
                })
                let result;
                try{
                    result = await fileUpload(form,event => { this.uploadFunc(event,file) });
                    fileIds.push(...result.map(item => item.fileId));
                    subCaseId = result[0].subCaseId;
                    result.forEach(item => {
                        this.addFileToDir(currentDir,item);
                        file.status = 'success';    //..上传成功
                    })
                }catch(err) {
                    if(err.code === 13019 || err.code === 13020){
                        hasPermit = false;
                    }
                    file.status = 'error';      //..上传失败
                }
                
            }
            this.finishUploadParams.fileIds = fileIds;
            this.finishUploadParams.dirId = this.state.currentDir.id;
            this.finishUploadParams.subCaseId = subCaseId;

            // getSubCase({ caseId: this.state.caseInfo.caseId }).then(list => {        暂时隐藏
            //     this.mutations.setSubCaseList(list);
            // })

            if(hasPermit){
                finishUpload(this.finishUploadParams).then(res => {
                    this.$message.success('文件上传完成！');
                })
            }
            this.mutations.setDataList();
        },
        uploadFunc(event,file){
            file.progress = Math.round(event.loaded / event.total * 100);
        },
        addFileToDir(dir,file){
            let target = this.mutations.fileInit(file,dir);
            // console.log(JSON.parse(JSON.stringify(dir.children)));
            dir.children.push(target);
            this.state.dirObj[target.id] = target;
            this.mutations.fileGlobalOrder();
        },
    }
}
</script>

<style scoped lang='less'>
.upload-btn {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    .left{
        display: flex;
        align-items: center;
    }
    .edit-name{
        // display: flex;
        // flex-direction: column;
        text-align: right;
        .prompt{
            margin-top: 5px;
        }
    }
    .rname {
        margin-left: 10px;
        display: flex;
        align-items: center;
        /deep/ .el-input{
            width: 150px;
        }
    }
}
.set-tag{
    border: 1px solid #bbb;
    height: 80px;
    box-sizing: border-box;
    padding: 10px 20px;
    .tag-row{
        display: flex;
        align-items: center;
        height: 30px;
        line-height: 30px;
    }
}
.upload-area {
    height: 450px;
    background-color: rgb(245, 245, 245);
    overflow: auto;
    overflow-x: hidden;
    position: relative;
    .point {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        p {
            white-space: nowrap;
        }
    }
}
.upload-list {
    display: flex;
    flex-wrap: wrap;
    .upload-item {
        padding: 0 24px 12px;
        width: 128px;
        .operate {
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            visibility: hidden;
            .btn {
                width: 36px;
                height: 36px;
                font-size: 20px;
            }
            .btn:hover {
                background-color: rgb(224, 224, 224);
            }
        }
        .img {
            width: 128px;
            height: 180px;
            border: 1px solid #e0e0e0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            overflow: hidden;
            img {
                width: 100%;
            }
        }
        .name {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-top: 12px;
            text-align: center;
        }
    }
    .upload-item:hover {
        background-color: rgb(238, 238, 238);
        .operate {
            visibility: visible;
        }
    }
    .upload-item.wait{
        .img{
            background-color: rgba(0,0,0,.5);
        }
    }
}
</style>