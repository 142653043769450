<template>
    <div class="edit">
        <div class="dsr-form" v-for="(item,index) in copyForm['当事人']" :key="index">
            <div class="delete" title="删除">
                <i class="el-icon-delete" @click="deleteDSR(item)"></i>
            </div>
            <el-form label-position="top" label-width="80px" :model="item" size="small">
                <div class="dsr-item" :style="{width: `${cols[index]}%`}" v-for="(key,index) in dsrKeys" :key="index">
                    <el-form-item :label="key">
                        <el-input v-model="item[key]"></el-input>
                    </el-form-item>
                </div>
            </el-form>
            
        </div>
        <div>
            <button class="btn btn-default el-icon-plus" @click="addDSR">添加当事人</button>
        </div>
        <div class="other-form-container">
            <div v-if="otherTitle === '诉讼信息'">
                <el-form label-position="top" label-width="80px" :model="copyForm" size="small">
                    <el-form-item label="案由">
                        <el-input v-model="copyForm['案由']"></el-input>
                    </el-form-item>
                    <el-form-item label="诉讼请求">
                        <el-input v-model="copyForm['诉讼请求']" type="textarea"></el-input>
                    </el-form-item>
                    <el-form-item label="事实与理由">
                        <el-input v-model="copyForm['事实与理由']" type="textarea"></el-input>
                    </el-form-item>
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item label="受理法院">
                                <el-input v-model="copyForm['受理法院']"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="编写日期">
                                <el-input v-model="copyForm['编写日期']"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div v-if="otherTitle === '上诉信息'">
                <el-form label-position="top" label-width="80px" :model="copyForm" size="small">
                    <el-form-item label="原审案号">
                        <el-input v-model="copyForm['原审案号']"></el-input>
                    </el-form-item>
                    <el-form-item label="案由">
                        <el-input v-model="copyForm['案由']"></el-input>
                    </el-form-item>
                    <el-form-item label="上诉请求">
                        <el-input v-model="copyForm['上诉请求']" type="textarea"></el-input>
                    </el-form-item>
                    <el-form-item label="上诉理由">
                        <el-input v-model="copyForm['上诉理由']" type="textarea"></el-input>
                    </el-form-item>
                    <el-form-item label="事实与理由">
                        <el-input v-model="copyForm['事实与理由']" type="textarea"></el-input>
                    </el-form-item>
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item label="受理法院">
                                <el-input v-model="copyForm['受理法院']"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="编写日期">
                                <el-input v-model="copyForm['编写日期']"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div v-if="otherTitle === '答辩信息'">
                <el-form label-position="top" label-width="80px" :model="copyForm" size="small">
                    <el-form-item label="答辩请求">
                        <el-input v-model="copyForm['答辩请求']" type="textarea"></el-input>
                    </el-form-item>
                    <el-form-item label="事实与理由">
                        <el-input v-model="copyForm['事实与理由']" type="textarea"></el-input>
                    </el-form-item>
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item label="受理法院">
                                <el-input v-model="copyForm['受理法院']"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="编写日期">
                                <el-input v-model="copyForm['编写日期']"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div v-if="otherTitle === '判决信息'">
                <el-form label-position="top" label-width="80px" :model="copyForm" size="small">
                    <el-form-item label="案号">
                        <el-input v-model="copyForm['案号']"></el-input>
                    </el-form-item>
                    <el-form-item label="诉讼请求">
                        <el-input v-model="copyForm['诉讼请求']" type="textarea"></el-input>
                    </el-form-item>
                    <el-form-item label="事实结论">
                        <el-input v-model="copyForm['事实结论']" type="textarea"></el-input>
                    </el-form-item>
                    <el-form-item label="判决结果">
                        <el-input v-model="copyForm['判决结果']" type="textarea"></el-input>
                    </el-form-item>
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <el-form-item label="审判长">
                                <el-input v-model="copyForm['审判长']"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="审判员">
                                <el-input v-model="copyForm['审判员']"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="书记员">
                                <el-input v-model="copyForm['书记员']"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item label="审判法院">
                                <el-input v-model="copyForm['审判法院']"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="签发日期">
                                <el-input v-model="copyForm['签发日期']"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        form: {
            type: Object,
            default(){
                return {};
            }
        },
        formTemplate: {
            type: Object,
            default(){
                return {};
            }
        }
    },
    data(){
        return {
            cols: [],
            copyForm: null,
        }
    },
    computed: {
        dsrKeys(){
            return this.formTemplate['当事人'];
        },
        otherTitle(){
            return Object.keys(this.formTemplate)[1];
        },
        otherKeys(){
            return this.formTemplate[this.otherTitle];
        }
    },
    created(){
        // (170 + 340 + 340) = 850      //  每个input的宽比例
        let w1 = 170 / 850 * 100;
        let w2 = 340 / 850 * 100;
        this.cols = [w1,w2,w2,w1,w1,w1,w2,w1,w1,w1,w2];
        this.copyForm = JSON.parse(JSON.stringify(this.form));
        if(Object.keys(this.copyForm).length === 0){
            this.copyForm = {
                '当事人': [{}]
            }
        }
    },
    methods: {
        addDSR(){
            if(this.copyForm['当事人']){
                this.copyForm['当事人'].push({});
            }else{
                this.$set(this.copyForm,'当事人',[{}]);
            }
        },
        deleteDSR(data){
            this.copyForm['当事人'].splice(this.copyForm['当事人'].indexOf(data),1);
        }
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
.edit{
    width: 100%;
}
.dsr-form{
    width: 100%;
    height: auto;
    background-color: #f7f7f7;
    border: 1px solid #bbb;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    padding: 10px;
    position: relative;
    /deep/ .el-form{
        display: flex;
        flex-wrap: wrap;
        .el-form-item__label{
            padding-bottom: 0;
        }
    }
    .dsr-item{
        padding: 0 10px;
        box-sizing: border-box;
        span{
            display: inline-block;
            margin-bottom: 8px;
        }
    }
    .delete{
        position: absolute;
        top: 10px;
        right: 10px;
        color: #FF2525;
        cursor: pointer;
    }
}
.btn{
    width: 100%;
    border-color: #1990FD;
    color: #1990FD;
}
.btn:hover{
    background-color: #1990FD;
    color: #fff;
}
.other-form-container{
    padding-top: 20px;
    /deep/ .el-form{
        .el-form-item__label{
            padding-bottom: 0;
        }
        .el-textarea__inner{
            height: 80px;
        }
        .el-textarea__inner{
            font-family: '';
        }
    }
}
</style>