<template>
    <div class="file" :class="{'selected': file.selected}"  v-read="{file,scale: 0.75}">
        <!-- <img :src="dataurl()" alt="" /> -->
        <div class="img-container">
            <FileViewer :url="dataurl()" :type="file.fileType"></FileViewer>

        </div>
        <div class="point">{{file.globalIndex}}</div>
        <div v-if="file.pdfParams" :style="LayerStyle" class="layer-container">
            <Selection @textMark="textMark">
                <TextLayer :file="file" :configParams="configParams"></TextLayer>
            </Selection>
            <!-- 批注文本 -->
            <MarkLayer :file="file" v-on="$listeners" :commentList="commentList" @popover="popover" @popoverLeave="popoverLeave"></MarkLayer>
        </div>
        <div class="checkbox" @click.stop @mousedown.prevent>
            <el-checkbox :value="file.selected" @input="checkboxInput"></el-checkbox>
        </div>
        <div class="read" v-if="this.file.extra && this.file.extra.readFlag === 0 && state.hasReadPermit">未读</div>
        <div class="tag" url="/caseinfo/file/tags" v-hasPermit>
            <span v-if="file.tags && file.tags['SOURCE']" class="source" :title="tagLabel('SOURCE')">
                <img :src="state.tagImg[file.tags['SOURCE']]" alt="">
            </span>
            <span v-if="file.tags && file.tags['MEDIA']" class="medium" :title="tagLabel('MEDIA')">
                <img :src="state.tagImg[file.tags['MEDIA']]" alt="">
            </span>
            <span v-if="file.tags && file.tags['OWNER']" class="belong" :title="tagLabel('OWNER')">
                <img src="@/assets/fileTypeIcon/tag.png" alt="">
            </span>
        </div>

        <div ref="popover" class="popover" :style="popoverStyle" v-if="visible">
            <div class="title">批注内容</div>
            <div>
                {{commentText}}
            </div>
        </div>
    </div>
</template>

<script>
import TextLayer from './TextLayer.vue';
import Selection from './Selection.vue';
import MarkLayer from './MarkLayer.vue';
import FileViewer from '@/common/fileViewer';

export default {
    components: {
        TextLayer,
        Selection,
        MarkLayer,
        FileViewer
    },
    props: {
        file: {
            type: Object,
            default() {
                return {};
            }
        },
        configParams: {
            type: Object,
            default(){
                return {};
            }
        },
        commentList: {
            type: Array,
            default(){
                return [];
            }
        }
    },
    data(){
        return {
            visible: false,
            popoverStyle: {
                position: 'absolute',
                left: 0,
                top: 0
            },
            commentText: ''
        }
    },
    inject: ['state'],
    computed: {
        LayerStyle() {
            return {
                width: this.file.pdfParams.viewport.width + 'px',
                height: this.file.pdfParams.viewport.height + 'px',
                transformOrigin: '0 0',
                transform: `scale(${ this.nodeScale }) translate(-50%,-50%)`
            };
        },
        nodeScale(){
            return 794 * this.configParams.size / 100 / this.file.pdfParams.viewport.width;
        },
    },
    methods: {
        tagLabel(type){

            let key = this.file.tags[type];     //  标签的value
            let tag = this.state.dictMap[type] && this.state.dictMap[type].find(item => item.value === key);
            return tag && tag.label;
        },
        dataurl(){
            if(this.file.pdfParams){
                return this.file.pdfParams.imgUrl;
            }else{
                return this.file.filePath;
            }
        },
        textMark(event,obj){
            this.$emit('selectText',event,obj);
        },
        checkboxInput(val){
            this.$emit('selectFile',this.file);
        },
        popover(commentText,item){
            this.commentText = commentText;
            this.visible = true;
            let node = this.$el.querySelector(`[data-id="${item.dataId}"]`);
            this.$nextTick(() => {
                let top = node.offsetTop * this.nodeScale - this.$refs.popover.offsetHeight - 10;
                if(top < 0){
                    let nodes = this.$el.querySelectorAll(`[data-id="${item.dataId}"]`);
                    node = nodes[nodes.length - 1];
                    top = (node.offsetTop + node.offsetHeight) * this.nodeScale + 10;
                }
                this.popoverStyle.left = `${node.offsetLeft * this.nodeScale}px`;
                this.popoverStyle.top = `${top}px`;
            })
        },
        popoverLeave(){
            this.visible = false;
        }
    }
};
</script>

<style scoped lang="less">
.file {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .img-container{
        width: 100%;
        height: 100%;
    }
    .tag{
        position: absolute;
        right: 5px;
        bottom: 5px;
        display: flex;
        > span{
            margin-left: 5px;
            width: 20px;
            height: 16px;
            img{
                width: 100%;
            }
        }
        // .source{
            
        // }
    }
    .read{
        width: 30px;
        height: 18px;
        text-align: center;
        line-height: 18px;
        font-size: 12px;
        color: #fff;
        background-color: #FF6262;
        position: absolute;
        left: 0;
        bottom: 5px;
    }
    img {
        width: 100%;
        height: 100%;
        user-select: none;
    }
    .layer-container{
        position: absolute;
        left: 50%;
        top: 50%;
        // transform: translate(-50%,-50%);
        
        background: 1px solid #ccc;

    }
    .point{
        position: absolute;
        top: 10px;
        right: 10px;
    }
    .checkbox{
        position: absolute;
        left: 10px;
        top: 5px;
        // z-index: 2022;
    }
    .popover{
        width: 200px;
        background: #fff;
        min-width: 150px;
        border-radius: 4px;
        border: 1px solid #ebeef5;
        padding: 12px;
        z-index: 2000;
        color: #606266;
        line-height: 1.4;
        text-align: justify;
        font-size: 14px;
        box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
        word-break: break-all;
        .title{
            color: #303133;
            font-size: 16px;
            line-height: 1;
            margin-bottom: 12px;
        }
    }
}
.file.selected{
    outline: 4px solid #3874CC;;
}
</style>