<template>
    <div class="file-overview">
        <div class="header">
            <i class="iconfont iconprev" @click="prevFile" title="上一页"></i>
            <span>
                <i></i>
                <i></i>
                <i class="iconfont iconamplification" @click="screenView">全屏预览</i>
                <!-- <i class="iconfont iconshuaxin"></i> -->
                <i></i>
                <i></i>
            </span>
            <i class="iconfont iconnext" @click="nextFile" title="下一页"></i>
        </div>
        <div class="main" ref="main">
            <div v-if="activeFile && activeFile.id" style="width: 100%;height: 100%;">
                <!-- <img v-if="/image\/*/.test(activeFile.fileType)" :src="activeFile.filePath" alt=""> -->
                <FileViewer v-if="/image\/*/.test(activeFile.fileType) || currentType === 'text/html'" :data-id="activeFile.id" :type="activeFile.fileType" 
                :url="activeFile.thumbnailPath || activeFile.filePath"></FileViewer>
                <iframe v-else-if="/application\/pdf/.test(activeFile.fileType)" :src="`/pdfjs-2.2.228-dist/web/viewer.html?file=${activeFile.filePath}`" width="100%" height="100%" frameborder="0"></iframe>
                <iframe  v-else-if="currentType === 'audio/mpeg' || currentType === 'video/mp4'" :src="activeFile.filePath.replace(/(\w+):\/\/[^/:]+(:\d*)?/,'/api_v2')" frameborder="0" width="100%" height="100%" ></iframe>
            </div>
            <div class="blank" v-else>
                <img src="@/assets/imgs/blank.png" alt="">
                <p>未选择任何文件</p>
            </div>
        </div>
        <div class="footer">
            <i class="iconfont iconprev" @click="prevFile" title="上一页"></i>
            <i class="iconfont iconnext" @click="nextFile" title="下一页"></i>
        </div>
    </div>
</template>

<script>
import FileViewer from '@/common/fileViewer';

export default {
    components: {
        FileViewer
    },
    inject: ['state','mutations'],
    computed: {
        activeFile(){
            return this.state.activeFile;
        },
        fileList(){ 
            if(this.state.viewModel === 'AxisView' || this.state.viewModel === 'BatchView'){
                let files = this.mutations.getAllFiles(true);
                return files;
            }else{
                return this.currentDir.children.filter(item => item.dataType === 'file');
            }
            
        },
        currentDir(){
            return this.state.currentDir;
        },
        currentType(){
            return this.activeFile.fileType;
        }
    },
    watch: {
        'state.protectionEye': {
            handler(val){
                if(val){
                    this.$refs.main.style.backgroundColor = 'rgb(235, 243, 233)';
                }else{
                    this.$refs.main.style.backgroundColor = '';
                }
            }
        },
    },
    methods: {
        prevFile(){
            let index = this.fileList.indexOf(this.activeFile) - 1;
            if(index < 0){
                index = this.fileList.length - 1;
            }
            this.fileList.map(item => item.selected = false);
            this.fileList[index].selected = true;
            this.mutations.setActiveFile(this.fileList[index]);
        },
        nextFile(){
            let index = this.fileList.indexOf(this.activeFile) + 1;
            if(index >= this.fileList.length){
                index = 0;
            }
            this.fileList.map(item => item.selected = false);
            this.currentDir.children && this.currentDir.children.forEach(item => item.selected = false);
            this.fileList[index].selected = true;
            this.mutations.setActiveFile(this.fileList[index]);
        },
        screenView(){
            this.mutations.toImgView(this.activeFile,this.fileList);
        }
    }
}
</script>

<style scoped lang='less'>
.file-overview{
    height: 100%;
    display: flex;
    flex-direction: column;
    i:hover{
        cursor: pointer;
        color: #1976D2;
    }
}
.header{
    height: 40px;
    padding: 0 40px;
    background-color: #EDEDED;
    border: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span{
        i { margin: 0 10px; }
    }
    i:before{
        margin-right: 5px;
    }
}
.main{
    flex-grow: 1;
    height: 0;
    background-color: #fff;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    > div{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    img{
        // width: 70%;
        max-width: 100%;
        max-height: 100%;
    }
    .blank{
        text-align: center;
    }
}
.footer{
    border: 1px solid #ccc;
    height: 40px;
    line-height: 40px;
    background-color: #EDEDED;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
}
</style>