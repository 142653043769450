<template>
    <el-dialog
        title="回收站"
        :visible.sync="pageShow"
        width="80%"
    >
        <div class="upload-btn">
            <button class="btn btn-default iconfont iconhuanyuan" @click="restore">还原</button>
            <button class="btn btn-default iconfont iconqingkongshanchu" @click="cleanUp">彻底删除</button>
        </div>
        <div class="upload-area">
            <ul class="upload-list">
                <FileItem v-for="(item,index) in files" :file="item" :key="index" @dblclick.native="toImgView(index)" @selectFile="selectFile"></FileItem>
            </ul>
        </div>
        
    </el-dialog>
</template>

<script>
import { queryDeletedFile , restoreFile , hardRemove} from '@/api/fileAPI.js';
import FileItem from './FileItem.vue';
import { batchDel } from '@/utils/f.js';

export default {
    components: {
        FileItem,
    },
    data() {
        return {
            pageShow: false,
            files: [],
        };
    },
    inject: ['state','mutations'],
    computed: {
        currentDir(){
            return this.state.currentDir;
        },
        ctrlKey(){
            return this.state.keyCode.ctrl;
        },
        shiftKey(){
            return this.state.keyCode.shift;
        },
    },
    methods: {
        init(){
            this.files = [];
            this.pageShow = true;
            queryDeletedFile({caseId: this.state.caseInfo.caseId}).then(res => {
                let files = [];
                res.forEach(file => {
                    files.push(this.mutations.fileInit(file,{id: file.dirId}));
                })
                
                this.files = files;
            })
        },
        restore(){
            let ids = this.files.filter(file => file.selected).map(file => file.id);
            if(!ids.length){
                this.$message.warning('请选择需要操作的文件!');
                return;
            }
            this.$confirm('确认还原所选文件?', '还原', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(res => {
                restoreFile({ caseId: this.state.caseInfo.caseId, fileIds: ids.join(',') }).then(res => {
                    this.$message.success('文件恢复成功!');
                    
                    batchDel(this.files,item => {
                        return ids.includes(item.id);
                    })
                    //  还原
                    this.mutations.getDirList().then(() => {
                        this.$nextTick(() => {
                            this.state.vueComData.leftTree.$refs.dirTree.filter();
                        })

                    })
                    
                })
            }).catch(err => {})
        },
        cleanUp(){
            let ids = this.files.filter(file => file.selected).map(file => file.id);
            if(!ids.length){
                this.$message.warning('请选择需要操作的文件!');
                return;
            }
            this.$confirm('确定永久删除所选项目吗？无法撤销此操作！', '清空', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(res => {
                hardRemove({ caseId: this.state.caseInfo.caseId, fileIds: ids.join(',') }).then(res => {
                    this.$message.success('文件删除成功！');
                    batchDel(this.files,item => {
                        return ids.includes(item.id);
                    })
                })
            }).catch(err => {})
        },
        toImgView(index){
            this.mutations.toImgView(this.files[index],this.files);
        },
        selectFile(data){
            this.mutations.selectFile(data,this.files);
        }
    }
}
</script>

<style scoped lang='less'>
.upload-btn{
    margin-bottom: 15px;
    display: flex;
}
.upload-area{
    height: 500px;
    background-color: rgb(245, 245, 245);
    overflow: auto;
    overflow-x: hidden;
    position: relative;
    .point{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}
.upload-list{
    display: flex;
    flex-wrap: wrap;
       
}
</style>