<template>
    <el-dialog :visible.sync="open" title="编辑封皮" width="900px">
        <div class="cover-container" ref="container">
            
        </div>
        <div slot="footer" class="dialog-footer">
            <button class="btn btn-default form-btn" @click="open = false">取消</button>
            <button class="btn btn-default form-btn" @click="saveCover" url="/caseinfo/save-extra">保存</button >
        </div>
    </el-dialog>
</template>

<script>
import { saveExtra } from '@/api/commonAPI.js';

export default {
    name: 'EditCover',
    data(){
        return {
            map: {},
            open: false
        }
    },
    inject: ['state','mutations'],
    computed: {
        caseInfo(){
            return this.state.caseInfo;
        },
        dirList(){
            return this.state.dirList;
        }
    },
    methods: {
        init(){
            this.open = true;
            this.$nextTick(() => {
                let template = this.state.htmlTemplate.cover;
                this.$refs.container.innerHTML = template;
                this.dataInit();
                this.editTemplate();
            })
        },
        dataInit(){
            this.map = this.caseInfo.extra.BASE || {};
            const nodes = this.$el.querySelectorAll('[data-id]');
            const BASE = this.caseInfo.extra.BASE;
            if(BASE){
                nodes.forEach(node => {
                    let key = node.getAttribute('data-id');
                    let value = BASE[key];
                    const dataRender = node.getAttribute('data-render');
                    // console.log(dataRender);
                    if(value !== undefined && dataRender != 'none'){
                        // console.log(value);
                        this.map[key] = value;
                        node.textContent = value;
                    }
                })
            }
                    console.log(this.map);
        },
        blur(event){    //  失去焦点修改map值
            const input = event.target;
            const key = input.getAttribute('data-id');
            
            let value = input.value;
                // console.log(value.replace('\n','\\n'));
            this.map[key] = value;
        },
        editTemplate(){
            const nodes = this.$el.querySelectorAll('[data-id]');
            // let whiteList = ['FYMC','AJLX','JM'];
            nodes.forEach(node => {
                let input;
                let dataId = node.getAttribute('data-id');
                const dataRender = node.getAttribute('data-render');
                if(dataRender){                     //  span替换为input输入框
                    if(dataRender === 'none'){
                        console.log(123);
                        return;
                    }else{
                        input = document.createElement(dataRender);
                    }
                }else{
                    input = document.createElement('input');  
                }
                if(dataId === 'JM' || dataId === 'AJLX'){   //  案件类型和卷名不允许编辑
                    return;
                }
                
                input.setAttribute('data-id',dataId);
                input.value = node.innerHTML;
                
                node.parentNode.replaceChild(input,node);
                input.onblur = this.blur;
            })
        },
        saveCover(){
            let params = JSON.parse(JSON.stringify(this.map));
            // console.log(params);
            Object.keys(params).forEach(key => {
                if(params[key]){
                    params[key] = String(params[key]).replace(/\n/g,'\\n');

                }
            })
            saveExtra(params,{ caseId: this.caseInfo.caseId }).then(res => {
                this.caseInfo.extra.BASE = this.map;
                this.$message.success('封皮模板修改成功!');
                this.open = false;
                this.mutations.recursion(this.dirList,dir => {
                    if(dir.type === 11){
                        // console.log(123);
                        this.mutations.setCover(dir.fileList[0]);
                    }
                })
            })
        }
    }
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body{
    padding: 0;
}

</style>
<style lang="less">
.cover-container{
    // display: flex;
    // justify-content: center;
    // height: 600px;
    // overflow: auto;
    > div{
        margin: 0 auto;
    }
}
</style>