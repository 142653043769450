<template>
    <div class="site-data">
        <div class="dir-tree">
            <Tree
                ref="dirTree"
                @getSelectData="getSelectData"
                :data="dirList"
                :props="{label: 'name',value: 'id'}"
                :filter-node="filterNode"
                draggable
                :allow-drop="allowDrop"
                @treeDrop="treeDrop"
            >
                <template #text="scope" @mouseover.stop>
                    <span class="tree-slot" :title="scope.row.name" :data-id="scope.row.id">
                        <span class="tree-icon">
                            <img :src="treeIcon(scope.row)" alt="">
                        </span>
                        <span class="tree-text" ref="treeText">{{scope.row.name}}</span>
                        <span class="count" ref="treeText">{{fileCount(scope.row)}}</span>
                    </span>
                </template>
            </Tree>
        </div>
    </div>
</template>

<script>
import Tree from '@/common/tree/Tree.vue';

export default {
    components: {
        Tree
    },
    data(){
        return {
            dirList: [],
            dirMap: {},
            id: 1,
            moveStatus: {}
        }
    },
    inject: ['state','mutations'],
    created(){
        this.dirList = this.state.dirList;
        this.dirInit();
    },
    methods: {
        dirInit(){
            // 1.获取卷下所有目录
            let list = [];
            this.dirList.forEach(volume => {
                const children = this.filterDir(volume);
                if(children.length){
                    let bookList = this.dirToBook(children,volume);
                    let newVolume = this.copyDir(volume);
                    newVolume.children = bookList;
                    list.push(newVolume);
                }
            })
            this.dirList = list;

            this.dirMap = {};
            this.mutations.recursion(this.dirList,dir => {
                dir.value = this.id ++;
                dir.children && dir.children.forEach(child => child.pValue = dir.value);
                this.dirMap[dir.value] = dir;
            })
            console.log(this.dirList);
        },
        fileOrder(list){    //  分册内文件排序
            let globalIndex = 1;
            list.forEach(dir => {
                dir.children.forEach(file => {
                    file.globalIndex = globalIndex;
                    globalIndex += file.pageNum;
                })
            })
        },
        copyDir(dir,i = 0){
            let newDir = {
                name: dir.name,
                id: dir.id,
                type: dir.type,
                dataType: dir.dataType,
                children: dir.children.filter(item => item.dataType === 'file').slice(i * 200,(i + 1) * 200).map(file => {
                    return {
                        id: file.id,
                        name: file.name,
                        // globalIndex: file.globalIndex,
                        pageNum: file.pageNum,
                        dataType: file.dataType,
                        fileType: file.fileType
                    }
                })
            }
            return newDir;
        },
        
        filterDir(volume){        //  转化为不超过200个文件的目录
            const list = [];
            // 1.已有册的目录忽略
            const children = this.mutations.getDirChildren(volume);

            children.forEach(dir => {
                const length = dir.children.filter(item => item.dataType === 'file').length;
                if(length > 200){
                    for (let i = 0; i < length / 200; i++) {
                        let copyDir = this.copyDir(dir,i);
                        if(i != 0){
                            copyDir.id = '';
                        }
                        list.push(copyDir);
                    }
                }else if(length > 0){
                    list.push(this.copyDir(dir));
                }
            })
            return list;
        },
        dirToBook(children,volume){    //  目录转册
            // 1.已有册的目录忽略
            let index = 1;
            let bookLength = 0;     //  分册内文件数量
            let bookList = [];
            
            function createBook(){
                let book = {
                    name: `${volume.name}分册${index}`,
                    dataType: 'dir',
                    type: 2,
                    children: []
                }
                index ++;
                return book;
            }
            let book = createBook();
            children.forEach(item => {
                let length = item.children.length; //  目录内文件数量
                bookLength += length;               //  当前册内文件数量
                if(bookLength > 200){
                    bookList.push(JSON.parse(JSON.stringify(book)));
                    book = createBook();
                    bookLength = length;
                    // console.log(JSON.parse(JSON.stringify(book)));
                }
                
                book.children.push(item);
                this.fileOrder(book.children);
            })
            bookList.push(JSON.parse(JSON.stringify(book)));
            return bookList;
        },
        filterNode(params,data) {
            let flag1 = data.name.indexOf(this.keyword) !== -1;     //  关键字检索
            let flag2;
            let flag3 = data.type === 8;
            if(this.showEmptyDir){
                flag2 = true;
            }else{
                flag2 = data.dataType === 'file' || data.isLocal;
            }
            return flag1 && flag2 || flag3;
        },
        getSelectData(){
            
        },
        treeIcon(data){
            return this.mutations.treeIcon(data);
        },
        fileCount(data){
            let text = '';
            if(data.isLocal){
                return;
            }else{
                if(data.dataType === 'dir'){
                    let num;
                    switch(data.type){
                        case 1:
                            num = data.children.filter(item => item.type === 2).length;
                            text = num ? `${num}册` : ``;
                        break;
                        case 2:
                            num = this.mutations.getVolumeAllFiles(data.children).length;
                            text = num ? `${num}页` : ``;
                        break;
                        case 4:
                            num = this.mutations.getVolumeAllFiles(data.children).length;
                            text = num ? `${num}页` : ``;
                        case 8:
                            num = this.mutations.getVolumeAllFiles(data.children).length;
                            text = num ? `${num}页` : ``;
                        break;
                    }
                }else{
                    if(data.pageNum === 1){
                        text = `${data.globalIndex}`;
                    }else{
                        text = `${data.globalIndex}~${data.globalIndex + data.pageNum - 1}`;
                    }
                }
                
            }
            return text;
        },
        treeDrop(options){
            const { dragData , dropData , dropType , dragParentData , dropParentData } = options;
            let dragIndex = dragParentData && dragParentData.children.findIndex(item => item === dragData);
            let dropIndex = dropParentData && dropParentData.children.findIndex(item => item === dropData);
            if(dragData.type === 1){
                dragIndex = this.dirList.findIndex(item => item === dragData);
            }
            if(dropData.type === 1){
                dropIndex = this.dirList.findIndex(item => item === dragData);
            }
            // console.log(dragIndex,dropIndex);
            if(dropType === 'inner'){
                // console.log(dragParentData);
                dragParentData.children.splice(dragIndex,1);
                dropData.children.push(dragData);
            }else{
                if(dragParentData === dropParentData){                  //  同目录移动
                    if(dropType === 'before' && dragIndex < dropIndex){
                        dropIndex --;
                    }
                    if(dropType === 'after' && dragIndex > dropIndex){
                        dropIndex ++;
                    }
                }else{
                    if(dropType === 'after'){
                        dropIndex ++;
                    }
                }

                dragParentData.children.splice(dragIndex,1);
                dropParentData.children.splice(dropIndex,0,dragData);
            }
        },
        allowDrop(dragData,dropData,type){
            if(dragData.dataType === 'file' && dropData.dataType === 'file'){
                return type != 'inner';
            }
            if(dragData.dataType === 'file' && dropData.dataType === 'dir'){
                return type != 'before' && type !='after';
            }
            if(dragData.dataType === 'dir' && dropData.dataType === 'file'){
                return false;
            }
            // console.log(dropData.type);
            if(dropData.type === 8){
                return type != 'before';
            }
            
            let dragRootData = dragData;
            while(dragRootData.type != 1){
                dragRootData = this.dirMap[dragRootData.pValue];
            }
            let dropRootData = dropData;
            while(dropRootData.type != 1){
                dropRootData = this.dirMap[dropRootData.pValue];
            }
            if(dragRootData != dropRootData){
                return false;
            }

            return true;
        }
    },
    mounted(){
        
    },
}

</script>

<style lang="less" scoped>
.site-data{
    padding-left: 200px;
}
.dir-tree{
    width: 390px;
    overflow: auto;
}
.tree-slot{
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tree-icon{
        margin-right: 5px;
        img{
            width: 14px;
            height: 14px;
            vertical-align: middle;
        }
    }
    .tree-text{
        flex-grow: 1;
        width: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow:ellipsis;
    }
    .tree-suffix-icon{
        height: 18px;
        margin-right: 5px;
        img{
            height: 100%;
        }
    }
    .count{
        color: #ccc;
    }
}
</style>