<template>
    <div class="cover">
        <div class="img-prev img-btn" @click="prev">
            <i class="el-icon-arrow-left"></i>
        </div>
        <div class="img-next img-btn" @click="next">
            <i class="el-icon-arrow-right"></i>
        </div>
        <div class="main">
            <div class="img-box" v-if="currentBook">
                <p>{{currentBook.name}}</p>
                <img :src="currentBook.coverUrl" alt="">
                <img :src="currentBook.backcoverUrl" alt="">
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data(){
        return {
            bookList: [],
            currentIndex: 0
        }
    },
    props: {
        form: Object
    },
    computed: {
        currentBook(){
            return this.bookList[this.currentIndex];
        },
    },
    inject: ['state','mutations'],
    async created(){
        this.$parent.loading = true;
        this.dirList = this.$parent.dirList;
        let bookList = [];
        this.mutations.recursion(this.dirList,dir => {
            if(dir.type === 2){
                bookList.push(dir);
            }
        })
        
        for (let i = 0; i < bookList.length; i++) {
            const book = bookList[i];

            let dataurl = await this.renderCover(book);
            book.coverUrl = dataurl;
            let backcover = await this.renderBackcover(book);
            book.backcoverUrl = backcover;
        }
        this.bookList = bookList;
        this.$parent.loading = false;
    },
    methods: {
        prev(){
            this.currentIndex --;
            if(this.currentIndex < 0){
                this.currentIndex = this.bookList.length - 1;
            }
        },
        next(){
            this.currentIndex ++;
            if(this.currentIndex > this.bookList.length - 1){
                this.currentIndex = 0;
            }
        },
       
        renderBackcover(volume){
            const container = document.createElement('div');
            container.innerHTML = this.state.htmlTemplate.backcover;
            return new Promise((resolve, reject) => {
                this.mutations.htmlToImage(container.children[0]).then(dataurl => {
                    resolve(dataurl);
                })
            })
        },
        renderCover(volume){
            let container;
            if(window.renderCover){
                container = window.renderCover(volume,{
                    dirList: this.$parent.dirList,
                    coverHTML: this.state.htmlTemplate.cover,
                    coverData: this.state.caseInfo.extra.BASE
                });
                console.log(container);
            }
            return new Promise((resolve, rejct) => {
                this.mutations.htmlToImage(container.children[0], () => {
                    let nodeList = container.querySelectorAll('[data-id]');
                    nodeList.forEach(node => {
                        let size = this.mutations.shrink(node.parentNode.clientHeight, node);
                        node.style.fontSize = `${size}px`;
                    })
                }).then(dataurl => {
                    resolve(dataurl);
                })
            })
        }
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
.cover{
    width: 100%;
    height: 95%;
    display: flex;
    justify-content: center;
    position: relative;
    .point{
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        top: -18px;
    }
    .main{
        
        .img-box{
            // width: 100%;
            height: 100%;
            p{
                height: 30px;
                line-height: 30px;
                text-align: center;
            }
            img{
                height: calc(100% - 30px);
                margin: 0 5px;
            }
        }
    }
}
.img-btn{
    width: 40px;
    height: 40px;
    font-size: 24px;
    color: #fff;
    background-color: #606266;
    border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 999;
}
.img-prev{
    left: 40px;
    top: 50%;
    transform: translateY(-50%);
}
.img-next{
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
}

</style>