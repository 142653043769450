<template>
    <div class="menu">
        <ul class="list">
            <li class="list-item" :class="{'active': value === item.value}"
                v-for="(item,index) in data" :key="index" 
                :url="item.url" v-hasPermit
                @mouseenter="mouseenter(item)" @mouseleave="mouseleave(data)" @click="menuClick(item)">
                <i class="iconfont" :class="item.icon"></i>
                <span class="text">{{item.label}}</span>
                <i class="select el-icon-check" v-if="value === item.value"></i>
                <i class="expand el-icon-arrow-right" v-if="item.children"></i>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default(){
                return [];
            }
        },
        value: {
            type: String,
            default: ''
        }
    },
    methods: {
        mouseenter(data){
            this.$emit('expand',data);
        },
        mouseleave(data){
            this.$emit('itemMouseleave',data);
        },
        menuClick(data){
            this.$emit('menuClick',data);
        }
    },
}
</script>

<style lang="less" scoped>
    .list{
        background-color: #fff;
        border: 1px solid #bbb;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.4);
        .list-item{
            box-sizing: border-box;
            min-width: 160px;
            padding: 0 10px;
            height: 40px;
            font-size: 14px;
            color: #101010;
            display: flex;
            align-items: center;
            cursor: pointer;
            > i{
                color: #333;
            }
            .text{
                margin: 0 5px;
            }
            .expand{
                margin-left: auto;
            }
            .select{
                margin-left: auto;
            }
        }
        .list-item.active{
            color: #3291F8;
            .select{
                color: #3291F8;
            }
        }
        .list-item:hover{
            background-color: #EBF4FF;
        }
        .list-item.hover{
            background-color: #EBF4FF;
        }
    }
    .children{
        position: absolute;
    }
</style>