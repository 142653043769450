<template>
    <div>
        <div class="tabs">
            <div class="tab-item" v-for="(item,index) in tabList" :key="item.value" @click="tabClick(index)" 
                :class="{'active': currentIndex === index}">
                <div>{{item.label}}</div>
                <div class="line"></div>
            </div>
        </div>
        <div class="form-container" ref="formContainer" @scroll="scroll">
            <div class="box">
                <div class="title">
                    <span>主体信息</span>
                    <button class="btn btn-text expand" @click="mainExpand = !mainExpand">{{mainExpand ? '收起' : '展开'}}</button>
                </div>
                <FormModel v-show="mainExpand" ref="mainForm" :model="mainFormModel" :form="mainForm" :disabled="archiveFormDisabled"></FormModel>
            </div>
            <div class="box">
                <div class="title">
                    <span>辅助信息</span>
                    <button class="btn btn-text expand" @click="sideExpand = !sideExpand">{{sideExpand ? '收起' : '展开'}}</button>
                </div>
                <FormModel v-show="sideExpand" ref="sideForm" :model="sideFormModel" :form="sideForm" :disabled="archiveFormDisabled"></FormModel>
            </div>
            <div class="box">
                <div class="title">
                    <span>案件信息</span>
                    <button class="btn btn-text expand" @click="caseExpand = !caseExpand">{{caseExpand ? '收起' : '展开'}}</button>
                    
                </div>
                <FormModel v-show="caseExpand" ref="caseForm" :model="caseFormModel" :form="caseForm" :disabled="archiveFormDisabled"></FormModel>
            </div>
            <div class="box">
                <div class="title">
                    <span>当事人信息</span>
                    <button class="btn btn-text expand" @click="dsrExpand = !dsrExpand">{{dsrExpand ? '收起' : '展开'}}</button>
                </div>
                <div v-show="dsrExpand" v-for="(item,index) in dsrFormList" :key="index" >
                    <FormModel ref="dsrForm" :model="dsrFormModel" :form="item" :disabled="archiveFormDisabled"></FormModel>
                    <div class="line"></div>
                </div>
            </div>
        </div>

        <div class="footer">
            <el-button size="small" @click="$emit('cancel')">取消</el-button>
            <el-button :disabled="archiveFormDisabled" size="small" @click="save">暂存</el-button>
            <el-button :disabled="archiveFormDisabled" size="small" type="primary" @click="submit">提交归档</el-button>
            <el-button :disabled="archiveFormDisabled" size="small" type="warning">打印申请归档报结审批单</el-button>
        </div>

    </div>
</template>

<script>
import { saveArchiveForm , submitArchive } from '@/api/archiving.js';

import FormModel from './FormModel.vue';

export default {
    components: {  
        FormModel
    },
    props: {
        archiveFormDisabled: Boolean,
        mainForm: Object,
        sideForm: Object,
        caseForm: Object,
        dsrFormList: Array,
        mainFormModel: Array,
        sideFormModel: Array,
        caseFormModel: Array,
        dsrFormModel: Array,
    },
    data(){
        return {
            tabList: [
                {
                    label: '主体信息',
                    value: 'mainForm'
                },
                {
                    label: '辅助信息',
                    value: 'second'
                },
                {
                    label: '案件信息',
                    value: 'third'
                },
                {
                    label: '当事人信息',
                    value: 'fourth'
                },
            ],
            currentIndex: 0,
            mainExpand: true,
            sideExpand: true,
            caseExpand: true,
            dsrExpand: true,
        }
    },
    inject: ['state'],
    created(){
        
        
    },
    computed: {
        topList(){
            let list = [];
            this.$el.querySelectorAll('.box').forEach(item => {
                list.push(item.offsetTop);
            })
            return list;
        }
    },
    methods: {
        tabClick(index){
            this.currentIndex = index;
            this.$refs.formContainer.scrollTop = this.topList[index];
        },
        scroll(event){      //  不准确（以中点在容器内可视）
            const scrollTop = event.currentTarget.scrollTop;
            let list = [];
            this.$el.querySelectorAll('.box').forEach(item => {
                list.push(item.offsetTop + item.offsetHeight / 2);
            })
            let index = list.findIndex(item => {
                return (item - scrollTop > 0 && item - scrollTop <= event.currentTarget.offsetHeight)
            });
            this.currentIndex = index;
        },
        getFormInfo(){
            let params = {
                '主体信息': this.mainForm,
                '辅助信息': this.sideForm,
                '案件信息': this.caseForm,
                '当事人信息': this.dsrFormList,
            }
            return params;
        },
        validate(){
            let flag = true;
            this.$refs.mainForm.validate(valid => {
                if(!valid){
                    flag = false;
                }
            })
            this.$refs.sideForm.validate(valid => {
                if(!valid){
                    flag = false;
                }
            })
            this.$refs.caseForm.validate(valid => {
                if(!valid){
                    flag = false;
                }
            })
            this.$refs.dsrForm.forEach(form => {
                form.validate(valid => {
                    if(!valid){
                        flag = false;
                    }
                })
            })
            return flag;
        },
        save(){
            let valid = this.validate();
            if(valid){
                const params = this.getFormInfo();
                saveArchiveForm({ caseId: this.state.caseInfo.caseId, data: params }).then(res => {
                    this.$message.success('归档信息暂存成功!');
                    if(!this.state.caseInfo.extra){
                        this.$set(this.state.caseInfo,'extra',{});
                    }

                    this.state.caseInfo.extra.ARCHIVE = params;
                    // // console.log(123);
                    // this.$set(this.extra,'ARCHIVE',params);
                    this.$emit('cancel');
                })
            }else{
                this.$message.warning('请完善必填信息!');
            }
            
        },
        submit(){
            let valid = this.validate();
            if(valid){
                const params = this.getFormInfo();
                submitArchive({ caseId: this.state.caseInfo.caseId, data: params }).then(res => {
                    this.$message.success('提交归档成功!');
                    if(!this.state.caseInfo.extra){
                        this.$set(this.state.caseInfo,'extra',{});
                    }

                    this.state.caseInfo.extra.ARCHIVE = params;
                    // this.$set(this.extra,'ARCHIVE',params);
                    this.$emit('cancel');
                })
            }else{
                this.$message.warning('请完善必填信息!');
            }
        },
        expand(){

        }
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
.tabs{
    display: flex;
    // align-items: center;
    color: #101010;
    height: 46px;
    padding-top: 16px;
    box-sizing: border-box;
    .tab-item{
        margin-right: 37px;
        cursor: pointer;
        .line{
            margin: 8px auto;
            width: 100%;
            height: 2px;
            background-color: #1876D3;
            display: none;
        }
    }
    .tab-item.active{
        color: #1876D3;
        .line{
            display: block;
        }
    }
}
.form-container{
    height: 570px;
    overflow: auto;
    position: relative;
    .line{
        margin: 20px 0;
        width: 100%;
        border-bottom: 1px dashed #bbb;
    }
}
.title{
    width: 100%;
    height: 30px;
    background-color: #F0F6FF;
    text-align: center;
    line-height: 30px;
    color: #101010;
    position: relative;
    margin-top: 15px;
    .expand{
        position: absolute;
        top: 8px;
        right: 40px;
        color: #1990FC ;
    }
}
.footer{
    margin-top: 20px;
    text-align: center;
}
</style>