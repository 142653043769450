import Main from './Main.vue';
import Vue from 'vue';

let ImgViewContainer = Vue.extend(Main);

const ImgView = function (options,callFun) {
    const instance = new ImgViewContainer({
        data: options
    });
    
    instance.$mount();  //  不传参数会返回一个新建的div，内部query函数
    instance.visible = true;
    document.body.appendChild(instance.$el);
    
    instance.$watch('imgIndex',(val) => {
        callFun(val);
    },{
        immediate: true
    })
    
    return instance;
};

export default ImgView;