<template>
    <div class="push">
        <div class="info">
            <p>
                <span>案号：</span>
                <span>{{form.caseName}}</span>
            </p>
            <p>
                <span>归档状态：</span>
                <span>{{gdLabel()}}</span>
            </p>
            <p>
                <span>提交人：</span>
                <span>{{form.username}}</span>
            </p>
            <p>
                <span>提交时间：</span>
                <span>{{form.submitTime}}</span>
            </p>
        </div>
        <div class="action">
            <button class="btn btn-achive plain" @click="openArchive(true)" v-if="params.openDetail">查看详情</button>
            <button :disabled="archivingLoading" :class="{'rotate':archivingLoading,'disabled':archivingLoading}" class="btn btn-achive" @click="openArchive(false)" v-else url="/caseinfo/fyy/file/search-archive-info">申请归档</button>
        </div>
    </div>
</template>

<script>
import { getDict } from '@/api/commonAPI.js';

export default {
    data(){
        return {
            form: {
                caseName: '',
                achiveStatus: '',
                username: '',
                submitTime: ''
            },
        }
    },
    inject: ['state','mutations','params'],
    computed: {
        userInfo() {
            return this.$store.getters.userInfo;
        },
        name(){
            let value = this.state.caseInfo.extra['archive_status'];
            if(value === '4'){
                return '重新归档';
            }else{
                return '申请归档';
            }
        },
        archivingLoading(){
            // return true;
            return this.params.archivingLoading;
        },
        gdStatusList(){
            return this.$store.getters.dictMap['gd_status'];
        }
    },
    created(){
        const caseInfo = this.state.caseInfo;
        this.form.caseName = caseInfo.caseName;
        this.form.achiveStatus = caseInfo.extra['archive_status'];
        this.form.username = this.userInfo.userName;
    },
    methods: {
        openArchive(disabled){
            this.$emit('openArchive',disabled);
        },
        gdLabel(){
            const data = this.gdStatusList.find(item => item.value === this.form.achiveStatus);
            if(data){
                return data.label;
            }else{
                return '--';
            }
        }
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
.push{
    display: flex;
    .info{
        padding: 10px 30px;
        > p{
            margin-top: 27px;
        }
    }
    .action{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        button{
            margin-left: 0;
            margin: 10px 0;
        }
    }
    > div{
        width: 360px;
        height: 240px;
        border: 1px dashed #bbb;
        margin: 0 2px;
        box-sizing: border-box;

        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
    }
}

</style>