<template>
    <div class="file-detail">
        <div class="file-detail-action">
            <button 
                v-for="(item,index) in fileDetailOperate" :key="index" 
                class="btn btn-default iconfont" 
                :class="[item.iconfont,{'active': item.index === activeIndex}]"
                @click="activeIndex = item.index"
                :url="item.url"
                v-hasPermit
                >
                {{item.label}}
            </button>
        </div>
        <div class="file-detail-content">
            <component :is="comNameList[activeIndex]" v-if="reloadFlag" v-on="$listeners"></component>
        </div>
    </div>
</template>

<script>
import FileOverview from './FileOverview';
import HistoryRecord from './HistoryRecord';
import InspectRecord from './inspectRecord';

export default {
    components: {
        FileOverview,HistoryRecord,InspectRecord
    },
    data(){
        return {
            fileDetailOperate: [
                {
                    label: '数据预览',
                    index: 0,
                    iconfont: 'iconsousuo2',
                    url: '**'
                },
                {
                    label: '巡查记录',
                    index: 1,
                    iconfont: 'iconshizhong',
                    url: '/case-check/caseInfo/queryRecord'
                },
                {
                    label: '操作记录',
                    index: 2,
                    iconfont: 'iconic_history',
                    url: '/caseinfo/logs'
                },
                // {
                //     label: '转为电子档案',
                //     index: 3,
                //     iconfont: 'icondangan'
                // },
                // {
                //     label: '数据清空',
                //     index: 4,
                //     iconfont: 'iconqingkongshanchu'
                // },
            ],
            activeIndex: 0,
            comNameList: ['FileOverview','InspectRecord','HistoryRecord'],
            reloadFlag: true
        }
    },
    computed: {
    },
    methods:{
        reload(){
            this.reloadFlag = false;
            this.$nextTick(() => this.reloadFlag = true);
        }
    }
}
</script>

<style scoped lang='less'>
.file-detail{
    width: 30vw;
    flex-shrink: 0;
    display: flex;
    height: 100%;
    flex-direction: column;
    .file-detail-action{
        text-align: right;
    }
    .file-detail-content{
        height: 0;
        flex-grow: 1;
        margin-top: 20px;
        overflow: auto;
        background-color: #fff;

    }
}
</style>