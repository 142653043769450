<template>
    <div class="container">
        <img :src="currentDir.fileList[0].filePath" alt="">
    </div>
</template>

<script>
export default {
    data() {
        return {
            filePath: ''
        };
    },
    inject: ['state','mutations'],
    computed: {
        currentDir() {
            return this.state.currentDir;
        },
    },
    watch: {
        currentDir: {
            handler(dir){
                if(!dir.fileList[0].filePath){
                    this.mutations.setCover(dir.fileList[0]);
                }
                
            },
            immediate: true
        }
    }
};
</script>

<style lang="less" scoped>
.container{
    display: flex;
    justify-content: center;
    img{
        width: 794px;
        height: 1123px;
    }
}
</style>