<template>
    <div class="volume-view" :style="{ backgroundColor: configParams.readTypeValue }" @wheel="mousewheel">
        <div class="volume-view-top">
            <Toolbar
                v-if="!openEditNote"
                :configParams="configParams"
                :fileList="fileList"
                :currentPage="currentPage"
                @update:currentPage="pageChange"
                :otherCurrentPage="otherCurrentPage"
                @update:otherCurrentPage="otherPageChange"
                :otherFileList="otherFileList"
                :volumeType="volumeType"
                :currentFocus="currentFocus"
                @createMark="createMark"
                @fileOCR="fileOCR"
            ></Toolbar>
        </div>
        <div class="content">
            <Sidebar
                :dirList="dirList"
                :fileList="fileList"
                :currentPage="currentPage"
                @update:currentPage="pageChange"
                :commentList="commentState.commentList"
                :currentCommentId.sync="commentState.currentCommentId"
                @createNode="createNode"
            ></Sidebar>
            <div class="file-view" @wheel="contentWheel">
                <VolumeFile
                    ref="volumeFile"
                    :fileList="fileList"
                    :configParams="configParams"
                    :currentPage.sync="currentPage"
                    :commentList="commentState.commentList"
                    :currentCommentId.sync="commentState.currentCommentId"
                    tabindex="2" @focus.native="focus(true)"
                    :class="{'no-outline' : volumeType}"
                ></VolumeFile>
                <div class="editor" v-if="openEditNote">
                    <EditNote ref="editNote" :visible.sync="openEditNote" :initNoteHTML="initNoteHTML" :file.sync="commentState.noteFile"></EditNote>
                </div>
            
                
                <VolumeFile
                    v-if="!volumeType"
                    ref="otherVolumeFile"
                    :fileList="otherFileList"
                    :configParams="configParams"
                    :currentPage.sync="otherCurrentPage"
                    :commentList="commentState.commentList"
                    :currentCommentId.sync="commentState.otherCurrentComId"
                    tabindex="3" @focus.native="focus(false)"
                ></VolumeFile>
            </div>
            <Sidebar
                :reverse="!volumeType"
                v-if="!volumeType"
                :dirList="otherDirList"
                :currentPage="otherCurrentPage"
                @update:currentPage="otherPageChange"
                :commentList="commentState.commentList"
                :currentCommentId.sync="commentState.otherCurrentComId"
            ></Sidebar>
        </div>
    </div>
</template>

<script>
import FullScreen from '@/common/fullScreen/FullScreen';
import Toolbar from './toolbar';
import Sidebar from './sidebar';
import VolumeFile from './volumeFile';
import EditNote from './editNote';

import { getComment } from '@/api/commentAPI.js';
import { fileOCR } from '@/api/fileAPI.js';

export default {
    name: 'VolumeView',
    components: {
        FullScreen,
        Toolbar,
        Sidebar,
        VolumeFile,
        EditNote,
    },
    inject: ['state', 'mutations'],
    provide(){
        return {
            commentState: this.commentState,
            volumeFileList: this.fileList,
            volumeView: this
        }
    },
    data() {
        return {
            dirList: [],
            fileList: [],
            configParams: {
                readTypeValue: '#F8F4EA',
                size: 100,
                underlineColor: '#FF0101',
                highlightColor: '#FF0101',
                pageValue: 1
            },
            commentState: {
                currentCommentId: '', //  当前批注ID
                commentList: [],
                otherCurrentComId: '',
                otherCommentList: [],
                noteFile: null,     //  笔录内文件(确定操作为 新增/编辑)
            },
            currentPage: 1,
            otherDirList: [],
            otherFileList: [],
            otherCurrentPage: 1,

            currentFocus: true,
            openEditNote: false,
            initNoteHTML: '',
        };
    },
    props: {
        volumeType: Boolean
    },
    watch: {
        configParams: {
            handler(val) {
                localStorage.setItem('configParams', JSON.stringify(this.configParams));
            },
            deep: true
        }
    },
    created() {
        let params = localStorage.getItem('configParams');
        if (params) {
            this.configParams = JSON.parse(params);
        }
        this.init();
    },
    methods: {
        focus(flag){
            if(!this.volumeType){
                this.currentFocus = flag;
            }
        },
        mousewheel(event) {
            if (this.state.keyCode.ctrl) {
                event.preventDefault();
                if(document.documentMode){
                    if (event.deltaY < 0) {
                        this.configParams.size ++;
                        this.configParams.size ++;
                    } else {
                        this.configParams.size --;
                        this.configParams.size --;
                    }
                }else{
                    if (event.wheelDelta > 0) {
                        this.configParams.size ++;
                        this.configParams.size ++;
                    } else {
                        this.configParams.size --;
                        this.configParams.size --;
                    }
                    
                }
                if(this.configParams.size <= 10){
                    this.configParams.size = 10;
                }
            }
        },
        contentWheel(event) {
            if (this.state.keyCode.ctrl) {
                return;
            }
            if (this.configParams.pageValue === 1) {
            } else {
                event.preventDefault();
                event.currentTarget.children.forEach(item => {
                    item.scrollTop += event.deltaY;
                });
            }
        },
        leftInit(){
            this.dirList = this.filterDir(JSON.parse(JSON.stringify(this.state.dirList)));
            this.fileList = this.getFileList(this.mutations.getAllFiles(true));
            
            getComment({ caseId: this.state.caseInfo.caseId }).then(res => {
                //  将批注信息挂载到file上
                this.commentState.commentList = res;
                this.fileList.forEach(file => {
                    file.markList = res.filter(item => item.id === file.id && item.annotationPage === file.page);
                });
            });
        },
        rightInit(){
            this.otherDirList = this.filterDir(JSON.parse(JSON.stringify(this.state.dirList)));
            this.otherFileList = this.getFileList(this.mutations.getAllFiles(true));
            //  批注问题(同一个案件)

        },
        init() {
            this.leftInit();

            if (!this.volumeType) {      //  对比预览
                this.rightInit();
            }
        },
        filterDir(list){
            let pageIndex = 1;
            const recursion = (list) => {
                return list.filter(item => {
                    item.pageIndex = pageIndex;
                    if(item.dataType === 'dir' && item.isLocal){
                        pageIndex += item.fileList.length;
                    }
                    let hasNote;
                    this.mutations.recursion(item.children,dir => {
                        if(dir.name === '阅卷笔录'){
                            hasNote = true;
                        }
                    })
                    if(this.mutations.hasFile(item) || hasNote || item.name === '阅卷笔录'){
                        item.children = recursion(item.children || []);
                        if(item.dataType === 'file' && (/image\/.*/.test(item.fileType) || item.fileType === 'application/pdf' || item.pdfPath || item.fileType === 'text/html')){
                            pageIndex += item.pageNum;
                            return true;
                        }
                        if(item.dataType === 'dir'){
                            return true;
                        }
                    }
                })
            }
            
            return recursion(list);
        },
        getFileList(fileList){
            let pageIndex = 1;
            let list = [];
            for (const file of fileList) {
                if(file.isLocal){
                    file.pageNum = 1;
                }
                if(document.documentMode){
                    // IE才有
                    if(/image\/.*/.test(file.fileType) || file.pdfPath || file.fileType === 'text/html'){
                        let newFile = this.fileInit(file);
                        newFile.pageIndex = pageIndex;
                        pageIndex ++;
                        if(!file.isLocal){
                            newFile.globalIndex = file.globalIndex;
                            newFile.page = 1;
                        }

                        newFile.pdfUrl = '';
                        if(file.fileType === 'application/pdf'){
                            newFile.filePath = file.thumbnailPath;
                        }else{
                            newFile.filePath = file.filePath;
                        }
                        list.push(newFile);
                    }
                }else{
                    for (let i = 0; i < file.pageNum; i++) {
                        if(/image\/.*/.test(file.fileType) || file.fileType === 'application/pdf' || file.pdfPath || file.fileType === 'text/html'){
                            let newFile = this.fileInit(file);
                            newFile.pageIndex = pageIndex;
                            pageIndex ++;
                            if(!file.isLocal){
                                newFile.globalIndex = file.globalIndex + i;
                                newFile.page = i + 1;
                            }
                            list.push(newFile);
                        }
                    }
                }

            }
            return list;
        },
        fileInit(file){
            let newFile = {
                id: file.id,
                pid: file.pid,
                selected: false,
                fileType: file.fileType,
                isLocal: file.isLocal,
                fileIndex: file.fileIndex,
                pdfUrl: file.pdfPath,
                extra: file.extra,
                filePath: file.pdfPath ? '' : file.filePath,
                dataType: file.dataType,
                name: file.name,
                pageNum: file.pageNum,
                tags: file.tags
            }
            
            

            return newFile;
        },
        getFile(file) {
            let fileList = [];
            if (file.fileType === 'application/pdf') {
                for (let i = 0; i < file.pageNum; i++) {
                    let newFile = {
                        id: file.id,
                        pdfUrl: file.filePath,
                        filePath: '',
                        page: i + 1
                    };
                    if (file.pdfParams && file.pdfParams.length) {
                        newFile.pdfParams = file.pdfParams[i];
                        newFile.isRender = true;
                    }
                    fileList.push(newFile);
                }
            } else if (/image\/*/.test(file.fileType)) {
                let newFile = {
                    id: file.id,
                    filePath: file.filePath,
                    isLocal: file.isLocal,
                    dirId: file.dirId
                };
                const pdf = file.derivatives && file.derivatives.find(item => item.bizType.toLowerCase() === 'pdf');
                if (pdf) {
                    newFile.pdfUrl = pdf.filePath;
                    newFile.page = 1;
                    newFile.filePath = '';
                    if (file.pdfParams && file.pdfParams.length) {
                        newFile.pdfParams = file.pdfParams[0];
                        newFile.isRender = true;
                    }
                }

                fileList.push(newFile);
            }
            return fileList;
        },
        pageChange(val) {
            this.currentPage = val;
            
            this.$nextTick(() => {
                if (val <= 0) {
                    this.currentPage = 1;
                }
                if (val > this.fileList.length) {
                    this.currentPage = this.fileList.length;
                }
                this.fileList.forEach(item => item.selected = false);
                this.fileList[this.currentPage - 1].selected = true;
                this.$refs.volumeFile.$el.scrollTop = this.$el.querySelectorAll('.file-item')[this.currentPage - 1].offsetTop; //  让当前显示图片跟页数同步
            });
        },
        otherPageChange(val) {
            if (!this.volumeType) {
                this.otherCurrentPage = val;
                
                this.$nextTick(() => {
                    if (val <= 0) {
                        this.otherCurrentPage = 1;
                    }
                    if (val > this.otherFileList.length) {
                        this.otherCurrentPage = this.otherFileList.length;
                    }
                    this.otherFileList.forEach(item => item.selected = false);
                    this.otherFileList[this.otherCurrentPage - 1].selected = true;
                    this.$refs.otherVolumeFile.$el.scrollTop = this.$el.querySelectorAll('.file-item')[this.otherCurrentPage - 1].offsetTop; //  让当前显示图片跟页数同步
                });
            }
        },
        createNode(html,file){
            // console.log(html);
            // console.log(this.initNoteHTML);
            // this.
            this.commentState.noteFile = file;
            this.initNoteHTML = html;
            this.openEditNote = true;
        },
        createMark(){       //  创建批注(根据文件)
            let selectFile = this.fileList.find(item => item.selected);
            if(selectFile){
                this.$refs.volumeFile.createMark([],'NONE',selectFile);
            }else{
                this.$message.warning('请选择文件!');
            }
        },
        fileOCR(){
            let selectFile = this.fileList.find(item => item.selected);
            if(selectFile){
                const file = this.mutations.getAllFiles().find(item => item.id === selectFile.id);

                if(file && /image\/.*/.test(file.fileType)){
                    if(selectFile.pdfUrl){
                        this.$message.warning('无需再次进行OCR!');
                        return;
                    }
                    let params = {
                        caseId: this.state.caseInfo.caseId,
                        fileId: file.id,
                        type: 'pdf'
                    }
                    fileOCR(params).then(res => {
                        this.$message.success('OCR识别成功!');
                        // this.resetData()
                        selectFile.filePath = res;
                        selectFile.pdfUrl = res;
                        this.$refs.volumeFile.fileSrc(selectFile)
                    })
                }else{
                    this.$message.warning('只有图片才能进入OCR');
                }
            }else{
                this.$message.warning('请选择文件!');
            }
        },
    }
};
</script>

<style scoped lang='less'>
.volume-view {
    width: 100%;
    height: 100%;
    background-color: #f8f4ea;
    .volume-view-top {
        height: 70px;
    }
    .content {
        height: calc(100% - 70px);
        flex-grow: 1;
        display: flex;
        .file-view {
            width: 0;
            flex-grow: 1;
            border: 1px solid #ccc;
            box-sizing: border-box;
            display: flex;
            margin: 0 10px;
            // overflow: hidden;
            .no-outline{
                outline: none;
            }
            .editor{
                width: 50%;
                flex-shrink: 0;
            }
        }
    }
}
</style>