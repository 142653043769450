<template>
    <div class="container">
        <div class="title">目录映射</div>
        <div class="content">
            <div class="dir">
                <div class="dir-title">{{mappingTypeObj && mappingTypeObj.label.split('->')[0]}}</div>
                <div class="dir-tree">
                    <div class="tree-container"><Tree ref="leftTree" :data="leftDirs" :props="{label: 'name',value: 'id'}" :showCheckbox="true" :checkboxValues="checkboxValues"></Tree></div>
                    <LineStretch></LineStretch>
                </div>
            </div>
            <div class="opetate">
                <div class="mapping-type">
                    <el-select v-model="mappingTypeValue" @change="mappingTypeValueChange">
                        <el-option v-for="item in mappingTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </div>
                <button class="btn btn-default form-btn" @click="saveMapping" url="/caseinfo/directory/mapping">映射</button>
                <button class="btn btn-default form-btn" @click="cancelMapping" url="/caseinfo/directory/cancel-mapping">取消映射</button>
            </div>
            <div class="dir">
                <div class="dir-title">{{mappingTypeObj && mappingTypeObj.label.split('->')[1]}}</div>
                <div class="dir-tree">
                    <div class="tree-container"><Tree :data="rightDirs" :props="{label: 'name',value: 'id'}" :showRadio="true" :radioValue.sync="radioValue"></Tree></div>
                    <LineStretch></LineStretch>
                </div>
            </div>
        </div>
        
        
    </div>
</template>

<script>
import { getCaseDirInfo } from '@/api/caseReading.js';
import { queryMapping , saveMapping , cancelMapping } from '@/api/dirMapping.js';
import Tree from '@/components/tree/Tree';
import LineStretch from '@/common/utils/lineStretch';

import { batchDel } from '@/utils/f.js';

import { getDirTypeList } from '@/api/caseReading.js';

export default {
    components: {
        Tree,
        LineStretch
    },
    inject: ['state','mutations'],
    data(){
        return {
            leftDirs: [],
            rightDirs: [],
            mappingTypeList: [
                {
                    label: '制卷目录->质证目录',
                    value: 'base-oppugn'
                },
                {
                    label: '制卷目录->归档目录',
                    value: 'base-archived'
                },
                {
                    label: '制卷目录->阅卷目录',
                    value: 'base-review'
                },
            ],
            mappingTypeValue: '',       //  映射类别
            mappingTypeObj: null,    //  当前类别
            mappingList: [],
            radioValue: '',
            checkboxValues: [],
        }
    },
    watch: {
        radioValue: {
            handler(val){
                this.radioValueChange(val);
            }
        },
    },
    created(){
        getDirTypeList().then(res => {
            batchDel(this.mappingTypeList,mappingType => {          //  删除没有类型的选项
                const mapping1 = mappingType.value.split('-')[0];
                const mapping2 = mappingType.value.split('-')[1];
                return !(res.find(item => item.category === mapping1) && res.find(item => item.category === mapping2));
            })
            this.mappingTypeValue = this.mappingTypeList[0].value;
            this.init();
        })
    },
    methods: {
        init(){
            let caseId = this.state.caseInfo.caseId;
            let leftDirType = this.mappingTypeValue.split('-')[0];
            let rightDirType = this.mappingTypeValue.split('-')[1];
            
            Promise.all([
                getCaseDirInfo({ caseId , category: leftDirType }),
                getCaseDirInfo({ caseId , category: rightDirType }),
                queryMapping({ caseId , mappingName: this.mappingTypeValue })
            ]).then(res => {
                this.leftDirs = res[0];
                this.rightDirs = res[1];
                this.mappingList = res[2];

                this.radioValue = '';
                this.mutations.recursion(this.rightDirs,item => {
                    if((!item.children || item.children.length === 0) && item.type === 4){
                        this.radioValue = item.id;
                        return true;
                    }
                })
            })
        },
        getDirMapping(){
            let params = {
                caseId: this.state.caseInfo.caseId,
                mappingName: this.mappingTypeValue
            }
            queryMapping(params).then(res => {
                this.mappingList = res;
                this.radioValueChange(this.radioValue);
            })
        },
        mappingTypeValueChange(){
            this.init();
        },
        radioValueChange(val){
            this.checkboxValues = this.mappingList.filter(item => item.targetDirectoryId === val).map(item => item.originDirectoryId);
        },
        saveMapping(){
            if(!this.radioValue){
                this.$message.warning('请选择右侧被映射目录！');
                return;
            }
            const checkboxValues = this.$refs.leftTree.getCheckboxValues();

            let params = {
                caseId: this.state.caseInfo.caseId,
                mappingName: this.mappingTypeValue,
                originDrectoryIds: checkboxValues,
                targetDirectoryId: this.radioValue
            }
            saveMapping(params).then(res => {
                this.$message.success('目录关系映射成功！');
                this.getDirMapping();
            })
        },
        cancelMapping(){
            if(!this.radioValue){
                this.$message.warning('请选择右侧被映射目录！');
                return;
            }
            let params = {
                caseId: this.state.caseInfo.caseId,
                mappingName: this.mappingTypeValue,
                directoryId: this.radioValue
            }
            cancelMapping(params).then(res => {
                this.$message.success('取消目录映射成功！');
                this.getDirMapping();
            })
        }
    },
};
</script>

<style scoped lang='less'>
.title{
    background-color: #E8F2F8;
    border-bottom: 1px solid #ccc;
    font-size: 20px;
    color: #303133;
    height: 50px;
    line-height: 50px;
    padding-left: 20px;
    box-sizing: border-box;
}
.content{
    display: flex;
    margin-left: 200px;
    height: calc(100vh - 50px);
    padding-top: 30px;
    box-sizing: border-box;
    > div{
        display: flex;
        flex-direction: column;
    }
    .dir{
        display: flex;
        flex-direction: column;
    }
    .dir-title{
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    .dir-tree{
        flex-grow: 1;
        height: 0;
        border: 1px solid #ccc;
        width: 300px;
        box-sizing: border-box;
        position: relative;
        .tree-container{
            width: 100%;
            height: 100%;
            overflow: auto;
        }
    }
    .opetate{
        margin: 0 60px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .btn{
            width: 120px;
            margin: 20px 0;
        }
    }
}
</style>