<template>
    <div class="container">
        <img v-for="(item,index) in currentDir.fileList" :key="index" :src="item.filePath" alt="">
        <button class="generate-volume-dir btn" @click="render">生成纸质卷内目录</button>

        <el-dialog
            title="生成纸质卷内目录"
            :visible.sync="open"
            width="1100px"
        >
            <div class="img-container">
                <img v-for="(item,index) in dataurlList" :key="index" :src="item" alt="">
            </div>
            <div class="footer">
                <button class="btn btn-default" @click="print">打印</button>
                <button class="btn btn-default" @click="download">下载</button>

            </div>
        </el-dialog>
    </div>
</template>

<script>
import { base64ToPDF } from '@/api/fileAPI.js';
import { getTreeByChildren } from '@/utils/f.js';

export default {
    data() {
        return {
            open: false,
            dataurlList: []
        };
    },
    inject: ['state','mutations'],
    computed: {
        currentDir() {
            return this.state.currentDir;
        },
    },
    watch: {
        currentDir: {
            handler(dir){
                dir.fileList.forEach(file => {
                    this.mutations.setBookMark(file);
                })
            },
            immediate: true
        }
    },
    methods: {
        async render(){
            const currentVolume = JSON.parse(JSON.stringify(this.state.dirObj[this.currentDir.pid]));
            let files = [];
            this.mutations.recursion(currentVolume.children,item => {
                if(item.dataType === 'file' && item.tags && item.tags['MEDIA'] === 'PAPERFILE'){
                    files.push(item);
                }
            })
            let newTree = getTreeByChildren(currentVolume.children,files);
            currentVolume.children = newTree;
            // console.log(currentVolume);

            if(window.renderBookmark){
                // console.log(currentVolume);
                let containerList = window.renderBookmark({
                    tempHTML: this.state.htmlTemplate.bookMark,
                    volume: currentVolume
                });
                // console.log(containerList);
                let dataurlList = [];
                for (let i = 0; i < containerList.length; i ++) {
                    const dataurl = await this.mutations.htmlToImage(containerList[i]);
                    dataurlList.push(dataurl);
                }
                console.log(dataurlList);
                this.dataurlList = dataurlList;
                this.open = true;

            }else{
                console.error('卷内目录模板没有渲染函数!');
            }
        },
        getParams(){
            let form = new FormData();
            form.append('contentType','application/pdf');
            this.dataurlList.forEach(item => {
                form.append('content',item.replace(/data:image\/.*base64,/,''));
            })
            return form;
        },
        print(){
            const form = this.getParams();
            base64ToPDF(form).then(res => {
                const blob = res.data;
                let url = URL.createObjectURL(blob);
                window.open(url);
            })
        },
        download(){
            const form = this.getParams();
            base64ToPDF(form).then(res => {
                const blob = res.data;
                let url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.target = '_blank';
                a.download = '';

                a.click();
            })
        },
    }
};
</script>

<style lang="less" scoped>
.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow: auto;
    img{
        flex-shrink: 0;
        width: 794px;
        height: 1123px;
    }
    .generate-volume-dir{
        position: absolute;
        right: 30px;
        bottom: 20px;
        color: #101010;
        width: 148px;
        height: 40px;
        border: 1px solid #bbb;
        background-color: #fff;
        border-radius: 40px;
    }
    /deep/ .el-dialog{
        height: 820px;
        margin-top: 50px !important;
    }
}
.img-container{
    height: 600px;
    overflow: auto;
    text-align: center;
}
.footer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    .btn {
        margin: 0 30px;
        min-width: 148px;
    }
}
</style>