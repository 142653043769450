<template>
    <div class="dir-list">
        <div class="switch-dir">
            <span v-for="(item,index) in dirTypeList" :key="index"
                :class="{'active': dirCategory === item.category}"
                @click="switchDirCategory(item.category)">{{item.name}}</span>  
        </div>
        <div class="operate">
            <div v-show="!openSearch" class="btns">
                <span title="新建卷" class="btn iconfont icontianjia" v-hasPermit url="/caseinfo/directory/create" @click="createDir(null,0)"></span>
                <span :title="treeStatus ? '点击折叠' : '点击展开'" class="btn iconfont iconzuhe-zhankaihuoshouqi" @click="expandAll" :class="{'active': treeStatus}"></span>
                <span :title="showEmptyDir ? '隐藏空目录' : '显示空目录'" class="btn iconfont iconxingzhuangjiehe" @click="hiddenEmptyDir" :class="{'active': showEmptyDir}"></span>
                <BtnCascader class="ml10" :data="tagDataList" @menuClick="tagChange" :relation="true" v-model="tagType">
                    <button title="点击切换模式" class="btn iconfont iconbiaoji" :class="{'active': tagType !== 'OWWNER'}"></button>
                </BtnCascader>
                <BtnCascader class="ml10" :data="tagList" @menuClick="tagClick" :relation="true" v-model="tagKey">
                    <button title="点击筛选" class="btn iconfont iconshaixuan1" :class="{'active': tagKey !== 'all'}"></button>
                </BtnCascader>
                <span title="搜索目录" class="btn iconfont iconsousuo1" @click="searchClick"></span>
                    
                
            </div>
            <div class="search" v-show="openSearch">
                <button class="btn el-icon-arrow-left" @click="searchClick"></button>
                <el-input placeholder="请输入目录关键字" prefix-icon="el-icon-search" v-model="keyword" size="mini" clearable></el-input>
            </div>
        </div>

        <div class="dir-tree">
            <Tree
                ref="dirTree"
                @getSelectData="getSelectData"
                :data="state.dirList"
                :props="{label: 'name',value: 'id'}"
                :filter-node="filterNode"
                draggable
                @treeDragStart="treeDragStart"
                @treeDragOver="treeDragOver"
                @treeDragLeave="treeDragLeave"
                @treeDrop="treeDrop"
            >
                <template #text="scope" @mouseover.stop>
                    <span class="tree-slot" :title="scope.row.name" 
                    @contextmenu.prevent="contextmenu(scope.row,$event)" 
                    :data-id="scope.row.id">
                        <span class="tree-icon">
                            <img :src="mutations.treeIcon(scope.row)" alt="">
                        </span>
                        <span class="tree-text" ref="treeText">
                            <span class="content">{{treeName(scope.row)}}</span>
                            <span class="page" v-if="scope.row.dataType === 'dir'">{{dirCount(scope.row)}}</span>
                        </span>
                        
                        <span class="count" v-if="scope.row.dataType === 'file'" ref="treeText">{{fileGlobalIndex(scope.row)}}</span>
                        <span class="tree-suffix-icon" :title="tagLabel(scope.row,fileShowModel)">
                            <img :src="treeSuffixIcon(scope.row)" alt="">
                        </span>
                    </span>
                </template>
            </Tree>
        </div>

        <CreateDir ref="createDir" :parentDir="scopeData" />
        <ContextMenu ref="contextMenu" :data="tagData" @menuClick="menuClick" @close="menuClose"></ContextMenu>
        <ContextMenu ref="dirContextMenu" :data="dropdownOperate" @menuClick="dirMenuClick" @close="menuClose"></ContextMenu>

        
        
    </div>
</template>

<script>
import Tree from '@/components/tree/Tree';
import { deleteDir , getDirTypeList } from '@/api/caseReading.js';
import CreateDir from './CreateDir';

import ContextMenu from '@/components/model/ContextMenu';
import { fileTags } from '@/api/fileAPI.js';
import BtnCascader from '@/components/model/BtnCascader.vue';

export default {
    components: {
        CreateDir,Tree,
        ContextMenu,
        BtnCascader
    },
    data(){
        return {
            keyword: '',
            defaultProps: {
                children: "children",
                label: "name",
            },
            treeStatus: false,
            dropdownOperate: [
                {
                    value: 1,
                    label: '新增',
                    url: '/caseinfo/directory/create'
                },
                {
                    value: 2,
                    label: '编辑',
                    url: '/caseinfo/directory/modify'
                },
                {
                    value: 3,
                    label: '删除',
                    url: '/caseinfo/directory/remove'
                },
            ],
            scopeData: null,
            dirTypeList: [],
            openSearch: false,
            tagDataList: [
                {
                    label: '介质',
                    value: 'MEDIA'
                },
                {
                    label: '来源',
                    value: 'SOURCE'
                },
            ],
            tagKey: 'all',
            tagType: '',
        }
    },
    inject: ['state','mutations'],
    computed: {
        dirCategory(){
            return this.state.dirCategory;
        },
        currentDir(){
            return this.state.currentDir;
        },
        dirList(){
            return this.state.dirList;
        },
        dirObj(){
            return this.state.dirObj;
        },
        staticImg(){
            return this.state.staticImg;
        },
        moveStatus(){
            return this.state.moveStatus;
        },
        fileList(){
            return this.currentDir ? this.currentDir.fileList : [];
        },
        showEmptyDir(){
            return this.state.showEmptyDir;
        },
        caseInfo(){
            return this.state.caseInfo;
        },
        tagData(){
            let list = [
                {
                    label: '标记',
                    icon: 'iconbiaoji',
                    url: '/caseinfo/file/tags',
                    children: [
                        {
                            label: '标记材料归属',
                            url: '/caseinfo/file/tags',
                            // type: 'OWNER',
                            icon: 'iconcailiaoguishu',
                            children: this.state.dictMap['OWNER']
                        },
                        {
                            label: '标记材料介质',
                            url: '/caseinfo/file/tags',
                            // type: 'MEDIA',
                            icon: 'iconcailiaojiezhi',
                            children: this.state.dictMap['MEDIA']
                        },
                    ]
                },
                {
                    label: '材料属性',
                    type: 'property',
                    url: '/caseinfo/file/tags',
                    icon: 'iconcailiaoshuxing'
                }
            ]
            return list;
        },
        tagList(){
            let list = [
                {
                    label: '全部材料',
                    value: 'all',
                    icon: 'iconcailiaoguishu',
                    type: 'none',
                },
                {
                    label: '材料来源',
                    url: '/caseinfo/file/tags',
                    value: 'SOURCE',
                    icon: 'iconcailiaoguishu',
                    children: this.state.dictMap['SOURCE']
                },
                {
                    label: '材料归属',
                    url: '/caseinfo/file/tags',
                    value: 'OWNER',
                    icon: 'iconcailiaoguishu',
                    children: this.state.dictMap['OWNER']
                },
                {
                    label: '材料介质',
                    url: '/caseinfo/file/tags',
                    value: 'MEDIA',
                    icon: 'iconcailiaojiezhi',
                    children: this.state.dictMap['MEDIA']
                },
            ]

            return list;
        },
        fileShowModel(){
            return this.state.fileShowModel;
        }
    },
    watch: {
        'state.treeId': {
            handler(id){
                this.$refs.dirTree.selectKey = id;
            }
        },
        keyword: {
            handler(val){
                this.$refs.dirTree.filter();
                let nodes = this.$refs.dirTree.$el.querySelectorAll('.tree-text .content');
                nodes.forEach(item => {
                    let text = item.textContent;
                    item.innerHTML = text.replace(val,`<span style='color: red'>${val}</span>`);
                })
            }
        },
        showEmptyDir: {
            handler(val){
                this.$refs.dirTree.filter();
            }
        },
        dirCategory: {
            handler(val){
                if(val === 'none'){

                }else{
                    this.pageInit();

                }
            }
        },
        dirList: {
            handler(val){
                this.$nextTick(() => {
                    this.$refs.dirTree.filter();
                })
            }
        }
    },
    created(){
        this.pageInit();
        this.getDirTypeList();
        this.state.vueComData.leftTree = this;
        document.addEventListener('click',this.domClick);
    },
    destroyed(){
        document.removeEventListener('click',this.domClick);
    },
    methods: {
        searchClick(){
            this.openSearch = !this.openSearch;
            this.keyword = ''
        },
        treeName(data){
            if(data.dataType === 'file'){
                if(data.name.lastIndexOf('.') !== -1){
                    return data.name.substring(0, data.name.lastIndexOf('.'));
                }else{
                    return data.name;
                }
            }else{
                return data.name;
            }
        },
        tagLabel(data,type){
            if(data.dataType === 'file'){
                let value = data.tags[type];
                if(value){
                    return this.mutations.tagLabel(value);
                }
            }
        },
        dirCount(data){
            let text = '';
            if(data.isLocal){
                return;
            }else{
                if(data.dataType === 'dir'){
                    let num;
                    switch(data.type){
                        case 1:
                            num = data.children.filter(item => item.type === 2).length;
                            text = num ? `${num}册` : ``;
                        break;
                        case 2:
                        case 4:
                        case 8:
                            num = this.mutations.getVolumeAllFiles(data.children).length;
                            text = num ? `${num}页` : ``;
                        break;
                    }
                }
                
            }
            return text;
        },
        fileGlobalIndex(data){
            let text = '';
            if(data.pageNum === 1){
                text = `${data.globalIndex}`;
            }else{
                text = `${data.globalIndex}~${data.globalIndex + data.pageNum - 1}`;
            }
            return text;
        },
        treeSuffixIcon(data){
            if(data.dataType === 'file'){
                let value = data.tags[this.fileShowModel];
                if(value){
                    return this.mutations.tagIcon(value);
                }
            }
            
        },
        async pageInit(){
            let dirCategory = this.$route.query.dirCategory;
            if(dirCategory){
                this.mutations.setDirCategroy(dirCategory);
            }
            let params = {
                caseId: this.state.caseInfo.caseId,
                dirCategory: dirCategory || this.dirCategory
            }
            
            
            // this.$nextTick(() => {
            //     this.$refs.dirTree.filter(this.keyword);
            // })
            // await this.mutations.dictInit(params);
            this.mutations.getDirList(params).then(() => {
                this.$nextTick(() => {
                    this.$refs.dirTree.filter(this.keyword);
                })
            })
        },
        getDirTypeList(){
            getDirTypeList().then(res => {
                this.dirTypeList = res;
                this.dirTypeList.push({
                    category: 'none',
                    name: '归属'
                })
            })
        },
        switchDirCategory(dirCategory){
            // console.log(dirCategory);
            if(dirCategory === 'none'){
                
            }else{
                let params = this.$route.query;
                params.dirCategory = dirCategory;
                this.mutations.setRoute(params);
                // this.mutations.setDirCategroy(dirCategory);
            }
                this.mutations.setDirCategroy(dirCategory);
        },
        getSelectData(data) {
            this.mutations.setCurrentDir(data);
        },
        createDir(data,type){
            if(this.dirCategory === 'none'){
                this.$message.warning('归属目录不能新增编辑！');
                return;
            }
            if(data && (data.type === 11 || data.type === 12 || data.type === 13)){
                this.$message.warning('特殊目录不能新增编辑！');
                return;
            }
            this.$refs.createDir.init(data,type);
        },
        deleteDir(data){
            if(data.type === 11 || data.type === 12 || data.type === 13){
                this.$message.warning('特殊目录不能删除！');
                return;
            }
            this.$confirm('此操作将永久删除目录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                // center: true
            }).then(res => {
                let obj = {
                    caseId: this.state.caseInfo.caseId,
                    category: this.dirCategory,
                    ids: data.id
                }
                let typeName = '';
                switch(data.type){
                    case 1: typeName = '卷'; break;
                    case 2: typeName = '册'; break;
                    case 4: typeName = '目录'; break;
                }
                this.mutations.setRecordData(`${typeName}<span style="color: #1976D2">《${data.name}》</span>`,'/caseinfo/directory/remove');
                deleteDir(obj).then(res => {
                    
                    if(data.pid === "0"){
                        this.dirList.splice(this.dirList.indexOf(data),1);
                    }else{
                        const dirs = this.dirObj[data.pid].children;
                        dirs.splice(dirs.indexOf(data),1);
                    }
                    
                    this.$message.success('目录删除成功！');
                })
            }).catch(err => {
                console.log(err);
            })
        },
        hiddenEmptyDir(){
            this.state.showEmptyDir = !this.state.showEmptyDir;
        },
        expandAll(){
            this.treeStatus = !this.treeStatus;
            this.$refs.dirTree.expand(this.treeStatus);
        },
        filterNode(params,data) {
            // console.log(data.name);
            let flag1 = data.name.indexOf(this.keyword) !== -1;     //  关键字检索
            let flag2;
            let flag3 = data.type === 8;
            if(this.showEmptyDir){
                flag2 = true;
            }else{
                let hasFile = data.children && data.children.find(item => item.dataType === 'file');;
                this.mutations.recursion(data.children,dir => {
                    if(dir.children && dir.children.find(item => item.dataType === 'file')){
                        return hasFile = true;  
                    }
                })
                flag2 = data.dataType === 'file' || data.isLocal || hasFile;
            }
            // console.log(data,flag1,flag2,flag3);
            // console.log(flag1 && flag2 || flag3);
            return flag1 && flag2 || flag3;
        },
        menuClose(){
            this.activeNode.classList.remove('contextmenu');
        },
        contextmenu(data,ev){          //  鼠标右键点击事件
            if(this.dirCategory === 'none' && data.dataType === 'dir'){
                return;
            }
            if(data.isLocal){
                return;
            }
            // console.log(ev.currentTarget);
            this.activeNode = ev.currentTarget;
            this.activeNode.classList.add('contextmenu');
            this.scopeData = data;
            if(data.dataType === 'file'){
                this.$refs.contextMenu.init(ev);
            }else{
                this.$refs.dirContextMenu.init(ev);
            }
        },
        dirMenuClick(data){
            switch(data.value){
                case 1 : this.createDir(this.scopeData,0) ; break;
                case 2 : this.createDir(this.scopeData,1) ; break;
                case 3 : this.deleteDir(this.scopeData) ; break;
            }
        },
        menuClick(data){
            const file = this.scopeData;
            if(data.type === 'property'){
                let subcase = this.state.subCaseList.find(item => item.id === file.subCaseId);
                const h = this.$createElement;
                this.$msgbox({
                    title: file.name,
                    message: h('div', null, [
                        h('p', null,`材料来源：${this.tagLabel(file,'SOURCE') || '--'}`),
                        h('p', null,`材料归属：${this.tagLabel(file,'OWNER') || '--'}`),
                        h('p', null,`材料介质：${this.tagLabel(file,'MEDIA') || '--'}`),
                        h('p', null,`文件格式：${file.filePath.match(/[^\.]\w*$/)[0] || '--'}`),
                        h('p', null,`提交人：${subcase && subcase.uploadUser && subcase.uploadUser.realName || '--'}`),
                        h('p', null,`提交时间：${subcase && Date.dateFormat(Number(subcase.createTime)) || '--'}`),
                    ]),
                })
            }else if(data.type === 'MEDIA' || data.type === 'OWNER'){
                // 打标签
                let tagFiles = [
                    {
                        fileId: file.id,
                        tags: [data.value],
                        type: data.type
                    }
                ]
                let params = {
                    caseId: this.state.caseInfo.caseId,
                    tagFiles,
                }
                fileTags(params).then(res => {
                    this.$message.success('标记成功!');
                    if(!file.tags){
                        file.tags = { [data.type]: data.value };
                    }else{
                        this.$set(file.tags,data.type,data.value);
                    }
                })
            }
        },
        treeDragStart(ev,data){
            if(data.isLocal || data.type === 8){
                ev.preventDefault();
                return;
            }
            if(data.dataType === 'dir'){
                this.moveStatus.dragData = data;
                this.moveStatus.dragType = 'dir';
            }else if(data.dataType === 'file'){
                this.moveStatus.dragData = data;
                this.moveStatus.dragType = 'file';
            }
        },
        treeDragOver(ev,data){
            let isChild = false;
            if(this.moveStatus.dragData === data || data.isLocal){
                isChild = true;
            }
            if(this.moveStatus.dragType === 'dir' && data.dataType === 'file'){
                isChild = true;
            }
            
            let temp = data;
            while(temp.pid != "0"){
                temp = this.state.dirObj[temp.pid];
                if(temp === this.moveStatus.dragData){
                    isChild = true;
                    break;
                }
            }
            if(isChild){
                ev.dataTransfer.dropEffect = 'none';
                return;
            }
            this.moveStatus.dropData = data;
            if(this.moveStatus.dragType === 'dir' && data.dataType === 'dir'){  //  目录-目录，正卷待分类区只允许inner
                if(data.type === 8){
                    this.moveStyle(ev.currentTarget,'inner');
                    this.moveStatus.dragStatus = 'inner';
                }else if(ev.offsetY >= 0 && ev.offsetY <= 8){
                    this.moveStyle(ev.currentTarget,'before');
                    this.moveStatus.dragStatus = 'before';
                }else if(ev.offsetY >= 18 && ev.offsetY <= 26){
                    this.moveStyle(ev.currentTarget,'after');
                    this.moveStatus.dragStatus = 'after';
                }else{
                    this.moveStyle(ev.currentTarget,'inner');
                    this.moveStatus.dragStatus = 'inner';
                }
                this.moveStatus.dropType = 'dir';
            }
            if(this.moveStatus.dragType === 'file' && data.dataType === 'dir'){
                ev.currentTarget.style.backgroundColor = '#CDE8FF';
                this.moveStatus.dragStatus = 'inner';
                this.moveStatus.dropType = 'dir';
            }
            if(this.moveStatus.dragData.dataType === 'file' && data.dataType === 'file'){
                if(ev.offsetY >= 0 && ev.offsetY <= 13){
                    this.moveStyle(ev.currentTarget,'before');
                    this.moveStatus.dragStatus = 'before';
                }else if(ev.offsetY >= 13 && ev.offsetY <= 26){
                    this.moveStyle(ev.currentTarget,'after');
                    this.moveStatus.dragStatus = 'after';
                }
                this.moveStatus.dropType = 'file';
            }
        },
        moveStyle(target,position){
            switch(position){
                case 'inner': 
                    target.style.borderTop = '';
                    target.style.borderBottom = '';
                    target.style.backgroundColor = '#CDE8FF';
                break;
                case 'before':
                    target.style.borderBottom = '';
                    target.style.borderTop = '1px solid #1976D2';
                    target.style.backgroundColor = '';
                break;
                case 'after':
                    target.style.borderTop = '';
                    target.style.borderBottom = '1px solid #1976D2';
                    target.style.backgroundColor = '';
                break;
            }
        },
        treeDragLeave(ev){
            ev.currentTarget.style.borderTop = '';
            ev.currentTarget.style.borderBottom = '';
            ev.currentTarget.style.backgroundColor = '';
        },
        treeDrop(ev,data){
            this.treeDragLeave(ev);
            // if(data.isLocal || data.type === 8){
            //     return;
            // }
            // this.moveStatus.dropData = data;
            // this.moveStatus.dropType = 'dir';
            this.mutations.drop();
        },
        tagChange(data){
            // console.log(this.$refs.tagSelect.toggleMenu());
            this.state.fileShowModel = data.value;
        },
        tagClick(data){
            this.mutations.setTagKeyword(data);
        }
    }
};
</script>

<style scoped lang='less'>
.dir-list{
    background-color: #fff;
    height: 100%;
    flex-shrink: 0;     //  不会等比缩小
    display: flex;
    flex-direction: column;
    .switch-dir{
        // height: 51px;
        margin: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        > span{
            background-color: #E1E3E6;
            color: #101010;
            margin: 0 3px;
            width: 68px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 5px;
        }
        >span.active{
            color: #fff;
            background-color: #3774CB;
        }
    }
    .operate{
        height: 30px;
        padding: 0 15px;
        margin-bottom: 15px;
        > div{
            display: flex;
        }
        .search{
            display: flex;
            align-items: center;
            .btn {
                font-size: 16px;
                margin-right: 5px;
                cursor: pointer;
            }
        }
        .btns{
            height: 100%;
            background-color: #F8F7F8;
            border: 1px solid #D9D9D9;
            border-radius: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            > span{
                font-size: 16px;
                margin: 0 12px;
                cursor: pointer;
            }
            .active{
                color: #3774CB;
            }
            /deep/ .btn-group{
                margin: 0 12px;
                .iconfont{
                    font-size: 16px;
                }
            }
        }
    }
    .dir-tree{
        height: 0;
        flex-grow: 1;
        overflow: auto;
    }
}
.dir-operate{
    padding: 20px 10px;
    .flex-around{
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        button{
            width: 92px;
            text-align: start;
        }
    }
    .flex-around:first-child{
        margin-top: 0;
    }
}

.line{
    width: 100%;
    height: 1px;
    background-color: #DCDCDC;;
}
.tree-slot{
    width: 100%;
    height: 100%;
    padding: 2px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid transparent;
    box-sizing: border-box;
    .tree-icon{
        margin-right: 5px;
        img{
            width: 14px;
            height: 14px;
            vertical-align: middle;
        }
    }
    .tree-text{
        flex-grow: 1;
        width: 0;
        display: flex;
        .content{
            overflow: hidden;
            white-space: nowrap;
            text-overflow:ellipsis;
            margin-right: auto;
        }
        .page{
            margin-left: 10px;
            color: #ccc;
        }
    }
    .count{
        margin-right: 5px;
        color: #ccc;
    }
    .tree-suffix-icon{
        height: 18px;
        img{
            height: 100%;
        }
    }
}
.contextmenu{
    border: 1px solid #1976D2;

}
</style>