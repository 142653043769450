<template>
    <div class="btn-group">
        <div @click="labelClick">
            <slot></slot>
        </div>
        <ul ref="btnList" class="btn-list" v-show="visible" tabindex="1" @blur="blur" v-if="data.length">
            <li class="btn-item" v-for="item in data" :key="item.value" @click.stop="itemClick(item)" :url="item.url" v-hasPermit>
                {{item.label}}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default(){
                return [];
            }
        },
    },
    data(){
        return {
            visible: false
        }
    },
    methods: {
        itemClick(data){
            this.visible = false;
            this.$emit('btnClick',data.value);
        },
        labelClick(){
            this.visible = true;
            this.$nextTick(() => {
                this.$refs.btnList && this.$refs.btnList.focus();
            })
        },
        blur(){
            this.visible = false;
        },
    }
}
</script>

<style scoped lang='less'>
.btn-group{
    position: relative;
    white-space: nowrap;
    color: #666;
    .btn-list{
        position: absolute;
        min-width: 100%;
        padding: 6px 0;
        z-index: 2021;
        border: 1px solid #e4e7ed;
        background-color: #fff;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
        .btn-item{
            padding: 0 20px;
            height: 30px;
            line-height: 30px;
            cursor: pointer;
            text-align: left;
        }
        .btn-item:hover{
            background-color: #f5f7fa;
            cursor: pointer;
        }
    }
    .btn-list:focus{
        outline: none;
    }
}

</style>