export default {
	name: 'CollapseTransition',
	functional: true,
	render(h, { children }) {
		const data = {
			on: {
				beforeEnter(el) {
					el.style.height = '0';
				},
				enter(el) {
					el.offsetWidth;
					el.style.height = el.scrollHeight + 'px';
					el.classList.add('expand-transition');
				},
				afterEnter(el) {
					el.style.height = '';
				},
				beforeLeave(el) {
					el.style.height = el.scrollHeight + 'px';
				},
				leave(el) {
					el.offsetWidth;
					el.style.height = '0';
					el.classList.add('expand-transition');
				},
				afterLeave(el) {
					el.style.height = '';
				}
			}
		};
		return h('transition', data, children);
	}
};