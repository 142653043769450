<template>
    <div class="file-item" @click="$emit('selectFile',file)" :class="{'active': file.selected}">
        <div class="checkbox" @click.stop>
            <el-checkbox v-model="file.selected"></el-checkbox>
        </div>
        <div ref="img" class="img">
            <!-- <img :data-id="file.id" draggable="false" class="file" :src="lazy ? '' : file.thumbnailPath || file.filePath" alt=""> -->
            <FileViewer :data-id="file.id" v-if="!lazy" :type="file.fileType" :url="file.thumbnailPath || file.filePath"></FileViewer>
        </div>
        <div class="file-name" ref="fileName" :title="file.name">
            {{file.name}}
        </div>
    </div>
</template>

<script>
import FileViewer from '@/common/fileViewer';

export default {
    components: {
        FileViewer
    },
    props: {
        file: {
            type: Object,
            default(){
                return {};
            }
        },
    },
    inject: ['state','mutations'],
    data(){
        return {
            lazy: false
        }
    },
    watch: {
        'file.selected': {
            handler(val){
                this.$emit('selectChange');
            }
        }
    },
    methods: {
        
    }
}
</script>

<style scoped lang='less'>
.file-item{
    padding: 15px 25px;
    margin: 15px 20px;
    position: relative;
    border: 1px solid transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
    .checkbox{
        display: none;
        position: absolute;
        top: 2px;
        right: 5px;
    }
    .img{
        margin-bottom: 10px;
        width:140px; 
        height: 200px;
        border: 1px solid #ccc;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        img{
            flex: 1;
            max-width: 100%;
            max-height: 100%;
        }
    }
    .file-name{
        text-align: center;
        line-height: 28px;
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
.file-item:hover{
    background-color: #E5F3FF;
    .checkbox{
        display: inline-block;
    }
}
.file-item.active{
    background-color: #CCE8FF;
    border-color: #1976D2;
    .checkbox{
        display: inline-block;
    }
}
</style>