<template>
    <div class="list-view">
        <table style="width: 100%">
            <tr>
                <th width="50"><div class="cell"></div></th>
                <th style="width: 30%"><div class="cell">文件名</div></th>
                <th><div class="cell" style="min-width: 90px">大小</div></th>
                <th><div class="cell" style="min-width: 80px">操作人</div></th>
                <th><div class="cell" style="min-width: 160px">操作时间</div></th>
            </tr>
            <tr v-for="item in dirList" :key="item.id" :class="{'active': item.selected}" @click="selectFile(item)" @dblclick="toDir(item)">
                <td><div class="cell checkbox" @click.stop @dblclick.stop><el-checkbox v-model="item.selected"></el-checkbox></div></td>
                <td>
                    <div class="cell file-name" :title="item.name">
                        <img draggable="false" :src="mutations.treeIcon(item)" alt="">
                        <span>{{item.name}}</span>
                    </div>
                </td>
                <td><div class="cell">-</div></td>
                <td><div class="cell"></div></td>
                <td>
                    <div class="cell">{{item.updateTime}}</div>
                </td>
            </tr>
            <tr v-for="item in fileList" :key="item.id" :class="{'active': item.selected}" @click="selectFile(item)">
                <td><div class="cell checkbox" @click.stop @dblclick.stop><el-checkbox v-model="item.selected"></el-checkbox></div></td>
                <td>
                    <div class="cell file-name" :title="item.name">
                        <img :src="staticImg['img']" alt="">
                        <span>{{item.name}}</span>
                    </div>
                </td>
                <td><div class="cell">{{item.fileSize / 1000 + 'k'}}</div></td>
                <td><div class="cell"></div></td>
                <td>
                    <div class="cell">{{item.updateTime}}</div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    inject: ['state','mutations'],
    props: {
        keyword: String,
        orderFun: Function
    },
    computed: {
        currentDir(){
            return this.state.currentDir;
        },
        dataList(){
            this.$nextTick(() => {
                this.$el.scrollTop = 0;
            })
            let list = this.currentDir.children || [];
            return list.filter(item => {
                if(item.name.includes(this.keyword)){
                    if(item.dataType === 'file'){
                        return true;
                    }else if(item.dataType === 'dir' && !item.isLocal){
                        if(this.state.showEmptyDir){
                            return true;
                        }else{
                            let hasFile = item.children.find(el => el.dataType === 'file');
                            return hasFile;
                        }
                    }
                }
            })
        },
        fileList(){
            return this.dataList.filter(item => item.dataType === 'file').sort(this.orderFun);
        },
        dirList(){
            return this.dataList.filter(item => item.dataType === 'dir').sort(this.orderFun);
        },
        staticImg(){
            return this.state.staticImg;
        },
        showEmptyDir(){
            return this.state.showEmptyDir;
        }
    },
    methods:{
        selectFile(data){
            this.mutations.selectFile(data,this.dataList);
            let flag = this.dataList.find(item => !item.selected);
            this.$emit('selectChange',!flag);
        },
        toDir(data){
            if(data.id){
                this.mutations.setCurrentDir(data);
            }
        },
    },

};
</script>

<style scoped lang='less'>
.list-view{
    user-select: none;
    height: 100%;
    overflow: auto;
}
table{
    border-collapse: collapse;
    font-size: 14px;
    box-sizing: border-box;
    tr{
        height: 40px;
        border: 1px solid #ebeef5;
        box-sizing: border-box;
    }
    th{
        color: #909399;
        text-align: left;
        border-bottom: 1px solid #ebeef5;
        box-sizing: border-box;
    }
    td{
        padding: 0;
        border-bottom: 1px solid #ebeef5;
        box-sizing: border-box;
    }
    .cell{
        padding: 0 10px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        img{
            margin-right: 5px;
            width: 16px;
            height: 16px;
        }
    }
    .file-name{
        width: 80%;
        span{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .checkbox{
        padding-left: 20px;
    }
}
tr:hover{
    background-color: #E5F3FF;
}
tr.active{
    background: rgba(25, 118, 210, 0.2);
    border: 1px solid #1976D2;
}
</style>