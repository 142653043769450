<template>
    <div class="img-view" v-show="visible" @wheel.prevent="wheel">
        <div class="img-mask" @click="close"></div>
        <div class="img-prev img-btn" @click="prev">
            <i class="el-icon-arrow-left"></i>
        </div>
        <div class="img-next img-btn" @click="next">
            <i class="el-icon-arrow-right"></i>
        </div>
        <div class="img-actions" v-if="/image\/*/.test(currentType) || currentType === 'text/html'">
            <div class="box">
                <div @click="zoom(-0.2)"><i class="el-icon-zoom-out"></i></div>
                <div @click="zoom(0.2)"><i class="el-icon-zoom-in"></i></div>
                <div @click="setImgType">
                    <i v-if="original" class="el-icon-c-scale-to-original"></i>
                    <i v-else class="el-icon-full-screen"></i>
                </div>
                <div @click="rotateLeft"><i class="el-icon-refresh-left"></i></div>
                <div @click="rotateRight"><i class="el-icon-refresh-right"></i></div>
            </div>
            <div class="compare" @click="compare" v-if="hasCompare && openCompare">对比版本</div>
        </div>
        <div class="img-canvas">
            <img v-if="imgVisible && /image\/*/.test(currentType) || currentType === 'text/html'" draggable="false" :style="imgStyle()" :src="currentFile[urlKey]" alt="" @mousedown.prevent="mousedown">
            
            <div v-else-if="imgVisible && currentType === 'application/pdf'" class="img-pdf">
                <iframe v-if="isIE" :src="`/pdfjs-2.2.228-dist/web/viewer.html?file=${encodeURIComponent(currentSrc)}`" frameborder="0" width="100%" height="100%" style="z-index: 9999;"></iframe>
                <iframe v-else :src="`${currentSrc.replace(/(\w+):\/\/[^/:]+(:\d*)?/,'/api_v2')}`" frameborder="0" width="100%" height="100%" style="z-index: 9999;"></iframe>
            </div>
            <div v-else-if="imgVisible && currentFile.pdfPath" class="img-pdf">
                <iframe v-if="isIE" :src="`/pdfjs-2.2.228-dist/web/viewer.html?file=${encodeURIComponent(currentFile.pdfPath)}`" frameborder="0" width="100%" height="100%" style="z-index: 9999;"></iframe>
                <iframe v-else :src="`${currentFile.pdfPath.replace(/(\w+):\/\/[^/:]+(:\d*)?/,'/api_v2')}`" frameborder="0" width="100%" height="100%" style="z-index: 9999;"></iframe>
            </div>
            <div v-else-if="currentType === 'audio/mpeg' || currentType === 'video/mp4'" class="img-pdf">
                <iframe :src="fileList[imgIndex] && fileList[imgIndex][urlKey].replace(/(\w+):\/\/[^/:]+(:\d*)?/,'/api_v2')" frameborder="0" width="100%" height="100%" style="z-index: 9999;"></iframe>
            </div>
            <div class="no-read" v-else>
                <div>该文件暂不支持在线查看</div>
                <div class="open-file" @click="openFile">下载文件</div>
            </div>
        </div>
        <button class="img-btn close el-icon-close" @click="close"></button>

        <FullScreen :visible.sync="openFullScreen" :appendToBody="false">
            <Compare :file="currentFile" v-if="openCompare"></Compare>
        </FullScreen>
    </div>
</template>

<script>
import FullScreen from '@/common/fullScreen/FullScreen.vue';
import Compare from './Compare.vue';
import { myMove } from '@/utils/f.js';
import { htmlToCanvas } from '@/utils/htmlToCanvas.js';

import axios from 'axios';

export default {
    components: {
        FullScreen,
        Compare,

    },
    data(){
        return {
            transform: {
                rotate: 0,
                scale: 1,

            },
            original: false,
            imgVisible: true,
            imgIndex: 0,
            fileList: [],
            visible: false,
            urlKey: 'filePath',
            openFullScreen: false,
            openCompare: true,
            currentSrc: '',
            isIE: false,
        }
    },
    computed: {
        currentType(){
            let fileType = this.fileList[this.imgIndex] && this.fileList[this.imgIndex].fileType;
            return fileType;
        },
        currentFile(){
            let file = this.fileList[this.imgIndex];
            return file;
        },
        hasCompare(){
            return this.currentFile.extra && this.currentFile.extra._original_file_path && this.currentFile.extra._pre_file_path;
        },
        currentPdfPath(){
            return this.currentFile.pdfPath;
        },
    },
    created(){
        this.isIE = document.documentMode ? true : false;
    },
    watch: {
        'transform.scale': {
            handler(val){
                if(val <= 0.2){
                    this.transform.scale = 0.2;
                }
            }
        },
        'imgIndex': {
            handler(val){
                if(this.currentType === 'text/html'){
                    axios.get(this.currentFile[this.urlKey]).then(res => {
                        // console.log(res);
                        const html = res.data;
                        const container = document.createElement('div');
                        container.style.width = '794px';
                        container.style.height = '1123px';
                        container.style.padding = '128px 134px';
                        container.style.boxSizing = 'border-box';
                        container.innerHTML = html;
                        const styles = `
                            html {margin:0;padding:0;}
                            body {margin:0;padding:5px;}
                            body, td {font:12px/1.5 "sans serif",tahoma,verdana,helvetica;}
                            body, p, div {word-wrap: break-word;}
                            p {margin:5px 0;}
                            table {border-collapse:collapse;}
                            img {border:0;}
                            noscript {display:none;}
                            table.ke-zeroborder td {border:1px dotted #AAA;}

                        `
                        htmlToCanvas(container,styles).then(canvas => {
                            // console.log(url);
                            this.currentSrc = canvas.toDataURL('image/png');
                        })
                    })
                }else if(this.currentType === 'application/pdf' || (!/image\/*/.test(this.currentType) && this.pdfPath)){
                    this.currentSrc = this.currentFile[this.urlKey];
                    // this.currentSrc = encodeURIComponent(this.currentFile[this.urlKey]);
                }else{
                    this.currentSrc = this.currentFile[this.urlKey];
                }
            },
            immediate: true
        }
    },
    mounted(){
        document.addEventListener('keydown',this.keydown);
    },
    methods: {
        close(){
            this.visible = false;
            document.removeEventListener('keydown',this.keydown);
            this.$destroy(true);
            this.$el.remove();
        },
        rotateLeft(){
            this.transform.rotate -= 90;
        },
        rotateRight(){
            this.transform.rotate += 90;
        },
        zoom(step){
            this.transform.scale += step;
        },
        prev(){
            if(this.imgIndex <= 0){
                this.imgIndex = this.fileList.length - 1;
            }else{
                this.imgIndex --;
            }
            this.reset();
        },
        next(){
            if(this.imgIndex >= this.fileList.length - 1){
                this.imgIndex = 0;
            }else{
                this.imgIndex ++;
            }
            this.reset();
        },
        reset() {       //  切换图片刷新样式
            this.imgVisible = false;
            this.$nextTick(() => {
                this.imgVisible =  true;
                this.transform = {
                    scale: 1,
                    rotate: 0,
                };
            })
        },
        wheel(ev){
            if(ev.deltaY > 0){
                this.zoom(-0.015);
            }else{
                this.zoom(+0.015);
            }
        },
        keydown(ev){
            switch(ev.keyCode){
                case 37: this.prev(); ev.preventDefault(); break;
                case 39: this.next(); ev.preventDefault(); ; break;
                case 38: this.zoom(0.2); ev.preventDefault();; break;
                case 40: this.zoom(-0.2); ev.preventDefault(); ; break;
                case 27: this.close(); ev.preventDefault(); ; break;
            }
        },
        imgStyle(){
            const { rotate,scale } = this.transform;
            const style = {
                transform: `translate(-50%,-50%) rotate(${rotate}deg) scale(${scale})`,
            }
            if(!this.original){
                style.maxWidth = style.maxHeight = '100%';
            }else{
                style.maxWidth = style.maxHeight = '';
            }
            return style;
        },
        containerStyle(){
            const { rotate,scale } = this.transform;
            const style = {
                transform: `translate(-50%) rotate(${rotate}deg) scale(${scale})`,
                width: '80%',
                height: '100%',
                position: 'absolute',
                left: '50%'
            }
            return style;
        },
        setImgType(){
            this.original = !this.original;
            this.reset();
        },
        mousedown(ev){
            let dom = ev.currentTarget;
            let offsetLeft = ev.currentTarget.offsetLeft;
            let offsetTop = ev.currentTarget.offsetTop;
            myMove(event => {
                dom.style.left = offsetLeft + event.pageX - ev.pageX + 'px';
                dom.style.top = offsetTop + event.pageY - ev.pageY + 'px';
            });
        },
        compare(){
            this.openFullScreen = true;
        },
        openFile(){
            window.open(this.fileList[this.imgIndex] && this.fileList[this.imgIndex][this.urlKey],'_blank');
        }
    }
}
</script>

<style scoped lang='less'>
.img-view{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #fff;
    z-index: 2099;
    .img-mask{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: .5;
        background: #000;
    }
    .img-btn{
        width: 40px;
        height: 40px;
        font-size: 24px;
        color: #fff;
        background-color: #606266;
        border-radius: 50%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 999;
    }
    .close{
        top: 40px;
        right: 40px;
    }
    .img-prev{
        left: 40px;
        top: 50%;
        transform: translateY(-50%);
    }
    .img-next{
        right: 40px;
        top: 50%;
        transform: translateY(-50%);
    }
    .img-actions{
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        
        font-size: 23px;
        z-index: 999;
        > div{
            display: flex;
            align-items: center;
            height: 44px;
            padding: 0 23px;
            background-color: #606266;
            border-color: #fff;
            border-radius: 22px;
            margin: 0 20px;
            > div{
                margin: 0 10px;
                cursor: pointer;
            }
        }
        .compare{
            font-size: 14px;
            cursor: pointer;
        }
    }
    .img-actions.bottom50{
        left: calc(50% - 200px);
        font-size: 16px;
        cursor: pointer;
    }
    .img-canvas{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        img{
            transition: transform .3s;
            position: absolute;
            left: 50%;
            top: 50%;
            user-select: none;
        }
        .img-container{
            user-select: none;
            position: absolute;
            // left: 50%;
            // top: 50%;
            width: 100%;
            height: 100%;
        }
        .img-pdf{
            height: 100%;
            width: 80%;
            margin: 0 auto;
            z-index: 1;
        }
        .no-read{
            font-size: 30px;
            display: flex;
            align-items: center;
            color: #ddd;
            justify-content: center;
            z-index: 999;
            .open-file{
                margin-left: 15px;
                font-size: 20px;
                padding: 8px 20px;
                border-radius: 22px;
                font-size: 20px;
                color: #fff;
                background-color: #606266;
                cursor: pointer;
            }
        }
    }
}
</style>