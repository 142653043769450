export function getQueryVariable(variable) {        //  获取url上的query参数
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
}

export function batchDel(arr,call){        //  数组批量删除元素
    for (let i = 0; i < arr.length; i++) {
        let flag = call(arr[i],i,arr);      //  是否删除
        if(flag){
            arr.splice(i,1);
            i--;
        }
    }
}

export function traverse(arr,cb,children = 'children'){     //  递归遍历数据
    let flag = false;
    function recursion(arr){
        for (let index = 0; index < arr.length; index++) {
            const item = arr[index];
            if(cb(item,index,arr)){
                flag = true;
            }
            if(flag) return;
            if(item[children] && item[children].length > 0){
                recursion(item[children]);
            }
        }
    }

    if(arr instanceof Array){
        recursion(arr);
    }
}

export function dateFormat(fmt, data) {     //  日期格式化
    let date = new Date(data);
    let ret;
    const opt = {
        "Y+": date.getFullYear().toString(),        // 年
        "m+": (date.getMonth() + 1).toString(),     // 月
        "d+": date.getDate().toString(),            // 日
        "H+": date.getHours().toString(),           // 时
        "M+": date.getMinutes().toString(),         // 分
        "S+": date.getSeconds().toString()          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        };
    };
    return fmt;
}

export function myMove(moveCb,upCb){
    document.addEventListener('mousemove',mousemove);
    document.addEventListener('mouseup',mouseup);

    function mousemove(event){
        if(moveCb instanceof Function){
            moveCb(event);
        }
    }

    function mouseup(event){
        if(upCb instanceof Function){
            upCb(event);
        }
        document.removeEventListener('mousemove',mousemove);
        document.removeEventListener('mouseup',mouseup);
    }
}

export function urlDownload(url, fileName) {
    var x = new XMLHttpRequest();
    x.open("GET", url, true);
    x.responseType = 'blob';
    x.onload=function(e) {
        var url = window.URL.createObjectURL(x.response)
        var a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click()
    }
    x.send();
}

export function printImg(dataurlList){             //  打印功能，urlList打印
    const iframe = document.createElement('iframe');
    iframe.style.position = 'fixed';
    iframe.style.top = '10000px';
    document.body.appendChild(iframe);
    const contentWindow = iframe.contentWindow;
    const contentDocuemnt = iframe.contentDocument;
    
    contentDocuemnt.body.style.margin = '0';
    const contentWidth = 793;
    const contentHeight = 1122;
    
    let count = 0;
    dataurlList.forEach((dataurl,index) => {
        const img = document.createElement('img');
        img.src = dataurl;
        img.width = contentWidth;
        img.height = contentHeight;
        contentDocuemnt.body.appendChild(img);
        img.onload = function(){
            count ++;
            if(count === dataurlList.length){
                contentWindow.print();
                document.body.removeChild(iframe);
            }
        }
    })
}

export function htmlTemplateRender(str){
    let html = str.match(/(<div[\s\S]*)<\/div>/)[1];
    let styles = str.match(/(<style>([\s\S]*?)<\/style>)/g);
    let scripts = str.match(/<script>([\s\S]*?)<\/script>/g);
    // console.log(html);
    // console.log(styles);
    // console.log(scripts);

    if(scripts){
        scripts.forEach(script => {
            const scriptNode = document.createElement('script');
            scriptNode.type = 'text/javascript';
            scriptNode.innerHTML = script.match(/<script>([\s\S]*)<\/script>/)[1];
            document.head.appendChild(scriptNode);
            // console.log(renderFun);
        })
    }

}

export function unique(arr,cb){
    for (let i = 0; i < arr.length; i++) {
        let current = arr[i];
        for (let j = i + 1; j < arr.length; j++) {
            let next = arr[j];
            let flag = cb(current,next);
            if(flag){
                // console.log(i,j);
                // console.log(JSON.parse(JSON.stringify(arr)));
                arr.splice(j,1);
                j --;
            }
        }
        
    }
}

export function dataToTree(data){
    let list = JSON.parse(JSON.stringify(data));
    let map = {};
    let tree = [];

    list.forEach(item => {
        map[item.id] = item;
        item.children = null;
    });
    // console.log(map);
    list.forEach(item => {
        let parent = map[item.pid];
        if(parent){
            parent.children ? parent.children : parent.children = [];
            parent.children.push(item);
        }else{
            tree.push(item);
        }
    })
    return tree;
}

export function getTreeByChildren(tree,children){       //  通过子集组成新的tree
    let dataMap = {};
    traverse(tree,item => {
        dataMap[item.id] = item;
    })

    const arr = [];
    children.forEach(child => {
        let parent = child;
        while(parent = dataMap[parent.pid]){
            if(!arr.includes(parent)){
                arr.push(parent);
            }
        }
    })

    // console.log(arr);
    let newTree = dataToTree(arr.concat(children));
    // console.log(newTree);
    return newTree;
}

//  数字变字符串(规则显示)
export function numFix(num, length) {
    return ('' + num).length < length ? ((new Array(length + 1)).join('0') + num).slice(-length) : '' + num;
}


export function throttle(call,time){
    let startTime = 0;
    let timer = null;
    return function(...args){

        if(Date.now() - startTime >= time){
            clearTimeout(timer);
            call.apply(this,args);
            startTime = Date.now();
        }else{
            timer = setTimeout(() => {      //  必定执行最后一次
                call.apply(this,args);
            },time)
        }

        

    }
}

export function debounce(call,time){
    let timer = null;
    return function(...args){
        clearTimeout(timer);
        timer = setTimeout(() => {
            call.apply(this,args);
        },time)
    }
}

export function createSocket(url,func) {
    let time = (new Date()).getTime();
    if ("WebSocket" in window) {
        // 建立连接
        let ws = null;
        if (process.env.NODE_ENV === 'development') {
            ws = new WebSocket(`ws://192.168.0.27:8290/ws/${url}`);
            // ws = new WebSocket(`ws://192.168.0.39:8090/ws/caseinfo/socket/changed/${caseId}`);

        } else {
            // console.log(`ws://${location.host}/ws/caseinfo/socket/changed/${caseId}`);
            if(location.protocol === 'https:'){
                ws = new WebSocket(`wss://${location.host}/ws/${url}`);
            }else if(location.protocol === 'http:'){
                ws = new WebSocket(`ws://${location.host}/ws/${url}`);
            }
            // ws = new WebSocket(`ws://192.168.0.27:8500/caseinfo/socket/changed/${caseId}`);
        }
        ws.onopen = function () {
            console.log('连接成功');
            setInterval(function () {
                ws.send('');
            }, 1000 * 900);
        };
        // 接收消息的回掉
        ws.onmessage = function (evt) {
            func(evt);
        };
        // 断开连接的回掉
        ws.onclose = function (event) {
            console.log("断开了连接");
            console.log(event);
            console.log((new Date()).getTime() - time);
            // alert("断开了连接");
        };
        return ws;
    } else {
        alert("浏览器不支持WebSocket");
    }
}

//  不完全标准的uuid
export function guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}