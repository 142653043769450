<template>
    <div class="hotkey-archive">
        <div class="title">
            <span class="text">转为电子档案</span>
        </div>
        <div class="h100">
            <Stepbar ref="stepbar" :stepList="stepList" :currentIndex="currentIndex"></Stepbar>
        </div>
        <div class="archive-container">
            <div ref="content" class="content">
                <div class="step-title">
                    {{ stepList[currentIndex - 1] && stepList[currentIndex - 1].label }}
                </div>
                <div class="component">
                    <component :step="stepList[currentIndex - 1]" ref="com" :is="currentStep.comName" :form="form" :openDetail="params.openDetail"></component>
                </div>
                <div class="operate">
                    <button :disabled="loading" :class="{'rotate': loading}" v-if="currentIndex > 1" class="btn btn-default " @click="prev" v-show="!params.openDetail">上一步</button>
                    <button :disabled="loading" :class="{'rotate': loading}" v-if="currentIndex === 3 || currentIndex === 4" class="btn btn-default" @click="print">打印</button>
                    <button :disabled="loading" :class="{'rotate': loading}" v-if="currentIndex === 3 || currentIndex === 4" class="btn btn-default" @click="download">下载</button>
                    <button :disabled="loading" :class="{'rotate': loading}" v-if="currentIndex != stepList.length" class="btn btn-default" @click="next">{{nextStepName}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Stepbar from './stepbar';

import SiteData from './siteData';
import SetPage from './SetPage.vue';
import Cover from './Cover.vue';
import VolumeDir from './VolumeDir.vue';
import Result from './result';

import { archiving } from '@/api/archiving.js';
import { base64ToPDF } from '@/api/fileAPI.js';

export default {
    components: {
        Stepbar,
        SiteData,
        SetPage,
        Cover,
        VolumeDir,
        Result
    },
    inject: ['state','mutations'],
    provide(){
        return {
            params: this.params
        }
    },
    data() {
        return {
            stepList: [
                {
                    label: '确认归档目录',
                    value: 1,
                    comName: 'SiteData'
                },
                {
                    label: '设置页码',
                    value: 2,
                    comName: 'SetPage'
                },
                {
                    label: '生成卷内目录',
                    value: 3,
                    comName: 'VolumeDir'
                },
                {
                    label: '生成封皮和封底',
                    value: 4,
                    comName: 'Cover'
                },
                {
                    label: '输出案件PDF文件',
                    value: 5,
                    comName: 'Result'
                }
            ],
            currentIndex: 1,
            form: {
                caseId: this.state.caseInfo.caseId,
                caseName: this.state.caseInfo.caseName,
                category: 'archived',
                dirs: [],
                outputMode: 4, //  输出模式
                pageRule: {
                    excludes: ['封皮', '封底', '卷内目录', '证物袋', '备考表'],
                    length: 1,
                    position: 'RIGHT-TOP',
                    start: 1
                }
            },
            dirList: [],        //  目录信息
            params: {
                openDetail: false,    //  禁用上一步
                showResetArchive: false,
                archivingLoading: false
            },
            loading: false,
        };
    },
    watch: {
        currentIndex: {
            handler(val){
                if(val === 1){
                    this.params.openDetail = false;
                }
            }
        }
    },
    computed: {
        caseInfo(){
            return {};
        },
        currentStep(){
            return this.stepList[this.currentIndex - 1];
        },
        nextStepName(){
            if(this.currentIndex === 4){
                return '保存归档设置并进入下一步';
            }else{
                return '下一步';
            }
        }
    },
    methods: {
        prev() {
            this.currentIndex--;
        },
        next() {
            if(this.currentIndex === 1){
                this.dirList = this.$refs.com.dirList;
            }
            this.currentIndex++;
            if(this.currentIndex === 5){
                this.form.dirs = this.filterDir();
                // console.log(this.form);
                this.params.archivingLoading = true;
                archiving(this.form).then(res => {
                    // console.log(res);
                    this.params.archivingLoading = false;
                })
            }
        },
        getParams(){
            let form = new FormData();
            if(this.currentIndex === 4){
                const currentBook = this.$refs.com.currentBook;
                form.append('content',currentBook.coverUrl.replace(/data:image\/.*base64,/,''));
                form.append('content',currentBook.backcoverUrl.replace(/data:image\/.*base64,/,''));
            }else if(this.currentIndex === 3){
                const currentItem = this.$refs.com.currentItem;
                form.append('content',currentItem.dataurl.replace(/data:image\/.*base64,/,''));
            }
            return form;
        },
        print(){
            const form = this.getParams();
            base64ToPDF(form).then(res => {
                const blob = res.data;
                let url = URL.createObjectURL(blob);
                window.open(url,'_blank');
            })
        },
        download(){
            const form = this.getParams();
            base64ToPDF(form).then(res => {
                const blob = res.data;
                let url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.target = '_blank';
                a.download = '';

                a.click();
            })
        },
        filterDir(){
            function recursion(dirList){
                return dirList.filter(item => item.dataType === 'dir').map(item => {
                    let copyData = {
                        dirId: item.id,
                        dirName: item.name,
                        dirType: item.type,
                        fileIds: item.children && item.children.filter(file => file.dataType === 'file').map(item => item.id),
                        children: []
                    }
                    if(item.type === 2){    //  分册
                        copyData.extFiles = [];
                        copyData.extFiles.push({
                            type: '封皮',
                            content: item.coverUrl.replace(/^data:image\/\w+;base64,/, "")
                        })
                        copyData.extFiles.push({
                            type: '封底',
                            content: item.backcoverUrl.replace(/^data:image\/\w+;base64,/, "")
                        })
                        item.volumeDirUrls.forEach(url => {
                            copyData.extFiles.push({
                                type: '卷内目录',
                                content: url.replace(/^data:image\/\w+;base64,/, "")
                            })
                        })

                    }
                    if(item.children && item.children.filter(dir => dir.dataType === 'dir')){
                        copyData.children = recursion(item.children);
                    }
                    return copyData;
                })
            }
            let list = recursion(this.dirList);
            return list;
        }
    }
};
</script>

<style scoped lang='less'>
.hotkey-archive {
    width: 100%;
    height: 100vh;
    .archive-container {
        width: 100%;
        height: calc(100% - 164px);
        padding-top: 20px;
        box-sizing: border-box;
        background-color: #f7f7f7;
        display: flex;
        justify-content: center;
        .content {
            width: 70%;
            height: 100%;
            box-sizing: border-box;
            border: 1px solid #e6e6e6;
            background-color: #fff;
            position: relative;
            .step-title {
                color: #333333;
                font-size: 18px;
                margin: 15px 0;
                font-weight: bold;
                text-align: center;
            }
            .component{
                height: calc(100% - 204px);
                overflow: auto;
                overflow-x: hidden;
            }
            .operate {
                margin-top: 20px;
                display: flex;
                justify-content: center;
                .btn {
                    margin: 0 30px;
                    min-width: 148px;
                }
            }
        }
        
        
    }
}
.title {
    width: 100%;
    height: 64px;
    background-color: #303030;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    padding: 0 40px;
    box-sizing: border-box;
    font-size: 16px;
}
.h100 {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
}
</style>