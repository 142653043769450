<template>
    <div class="x-shrinkbar">
        <div class="x-shrink-left" @click="$emit('update:isShrink',!isShrink)">
            <i class="iconfont iconjiantou" :style="{transform: `rotate(${isShrink ? 180 : 0}deg)`}"></i>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isShrink: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        isShrink: {
            handler(val){
                this.$nextTick(() => {
                    let dom = this.$el.parentNode;
                    dom.style.transition = 'all .3s linear';
                    dom.style.overflow = 'hidden';
                    if(val){
                        dom.style.width = dom.scrollWidth + 'px';
                        this.$nextTick(() => {
                            dom.offsetWidth     //  未知原因需要调用一次
                            dom.style.width = '14px';
                        })
                    }else{
                        dom.style.width = dom.scrollWidth + 'px';
                    }
                })
                
            },
            immediate: true
        }
    },
    methods: {

    }
}
</script>

<style scoped lang='less'>
.x-shrinkbar{

}
.x-shrink-left{
    margin-top: 50px;
    height: calc(100% - 50px);
    width: 14px;
    box-sizing: border-box;
    background-color: #DDEBF8;
    border: 1px solid #E5E5E5;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    i{
        font-size: 14px;
        transition: all .3s linear;
    }
}
</style>