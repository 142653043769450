<template>
 <!-- v-if="length != 0 && hasReadPermit" -->
    <div class="remind" v-if="length != 0 && hasReadPermit">
        <i class="iconfont iconcailiaoshuxing"></i>
        <span>未读提醒：您还有<span class="count" @click="toFile">{{length}}份</span>材料未读</span>
    </div>
</template>

<script>
export default {
    inject: ['state','mutations'],
    computed: {
        length(){
            return this.allFiles.length;
        },
        allFiles(){
            return this.mutations.getAllFiles().filter(item => item.extra && item.extra.readFlag === 0)
        },
        hasReadPermit(){
            return this.state.hasReadPermit;
        }
    },
    methods:{
        toFile(){       //  暂时不管，批次视图未优化
            // console.log(this.allFiles[0]);
            // this.$emit('toFile',this.allFiles[0])
        }
    }
}
</script>

<style scoped lang='less'>
    .remind{
        padding: 10px 10px;
        margin-left: auto;
        color: #101010;
        background-color: #FFF1C9;
        display: flex;
        align-items: center;
        i{
            margin: 0 5px;
            color: #FCAC00 ;
        }
        .count{
            color:red;
            margin: 0 10px;
        }
        .count:hover{
            cursor: pointer;
            border-bottom: 1px solid red;
        }
    }
</style>