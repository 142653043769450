<template>
    <div class="compare-container" @wheel.stop="">
        <div class="top">
            <div class="title">对比版本</div>
            <div class="switch">
                <span>选择版本：</span>
                <el-radio-group v-model="value" @change="switchValue">
                    <el-radio label="SOURCE">原文件版本</el-radio>
                    <el-radio label="PREIMG">预处理版本</el-radio>
                </el-radio-group>
            </div>
        </div>

        <div class="content">
            <div class="img-container">
                <div class="img-title">原文件</div>
                <div class="img-box">
                    <img :src="originalPath" alt="">
                </div>
            </div>
            <div class="line">

            </div>
            <div class="img-container">
                <div class="img-title">预处理文件</div>
                <div class="img-box">
                    <img :src="prePath" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { switchPath } from '@/api/fileAPI.js';
import { state } from '@/utils/store/caseReading.js';

export default {
    props: {
        file: Object
    },
    data(){
        return {
            value: '',
        }
    },
    computed: {
        originalPath(){
            const prefix = this.file.extra._original_file_path.match(/(.*?)\//)[1];
            let basePath = this.file.filePath.match(new RegExp(`(.*\/)${prefix}`))[1];
            return basePath + this.file.extra._original_file_path;
        },
        prePath(){
            const prefix = this.file.extra._pre_file_path.match(/(.*?)\//)[1];
            let basePath = this.file.filePath.match(new RegExp(`(.*\/)${prefix}`))[1];
            return basePath + this.file.extra._pre_file_path;
        }
    },
    mounted(){
        if(this.file.filePath === this.originalPath){
            this.value = 'SOURCE';
        }else{
            this.value = 'PREIMG';
        }
    },
    methods: {
        switchValue(){
            let params = {
                caseId: state.caseInfo.caseId,
                fileId: this.file.id,
                option: this.value
            }
            switchPath(params).then(res => {
                this.$message.success('版本切换成功!');
                this.file.filePath = this.value === 'SOURCE' ? this.originalPath : this.prePath;
            })
        }
    },
}
</script>

<style lang="less" scoped>
.compare-container{
    height: 100%;
    color: #000;
    .top{
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
        .title{
            position: absolute;
            left: 30px;
            font-weight: bold;
        }
        .switch{
            height: 40px;
            border: 1px dashed #bbb;
            display: flex;
            align-items: center;
            padding: 0 20px;
            /deep/ .el-radio{
                margin: 0 35px;
            }
        }
        
    }
    .content{
        height: calc(100% - 60px);
        display: flex;
        .img-container{
            flex: 1;
            .img-title{
                width: 100%;
                height: 44px;
                background-color: #EBF4FF;
                color: #007AFF;
                font-size: 18px;
                line-height: 44px;
                text-align: center;
            }
            .img-box{
                width: 100%;
                height: calc(100% - 44px);
                overflow: auto;
                box-sizing: border-box;
                padding: 10px;
                img{
                    width: 100%;
                }
            }
        }
        .line{
            flex: 0 0 auto;
            width: 14px;
            height: 100%;
            background-color: #B8D9FD;
        }
    }
}
</style>