<template>
    <div class="trial-video">
        <div class="item" v-for="(item,index) in trialList" :key="index">
            <p>{{item.tc}}、庭次：{{item.tc}}</p>
            <p class="link" @click="toLink(item.url)">
                <i class="iconfont iconyunhang"></i> 
                <span>{{item.url}}</span>
            </p>
        </div>
        <div class="prompt" v-if="!trialList.length">本案件暂无庭审视频</div>
    </div>
</template>

<script>

export default {
    props: {
        trialList: {
            type: Array,
            default(){
                return []
            }
        }
    },
    methods: {
        toLink(url){
            window.open(url,'_blank');
        }
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
.trial-video{
    height: 450px;
    overflow: auto;
}
.item{
    margin-bottom: 30px;
}
.link{
    margin-left: 20px;
    margin-top: 12px;
    color: #1990FD;
    cursor: pointer;
    display: flex;
    align-items: center;
    span{
        padding-left: 12px;
    }
}
.prompt{
    font-size: 20px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #101010;
}
</style>