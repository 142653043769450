import PDFJS from '/public/pdfjs-2.2.228-dist/build/pdf.js';
PDFJS.GlobalWorkerOptions.workerSrc = "build/pdf.worker.js";

const pdfList = [];

export async function getPDF(url) {
    // pdfHandle(url,1)
    return new Promise((resolve,reject) => {
        let loadingTask = PDFJS.getDocument({
            url,
            withCredentials:true,
            cMapUrl: "/pdfjs-2.2.228-dist/web/cmaps/",
            cMapPacked: true
        });
        loadingTask.promise.then(function(pdf) {
            let promiseList = [];
            for (let i = 0; i < pdf.numPages; i++) {
                promiseList.push(pdf.getPage(i + 1));
            }
            Promise.all(promiseList).then(res => {
                // console.log(res);
                let pages = res;
                let pagePromiseList = [];
                pages.forEach(page => {
                    pagePromiseList.push(getUrlTextLayer(page));
                })
                Promise.all(pagePromiseList).then(res => {
                    let arr = [];
                    res.forEach(item => {
                        arr.push(item);
                    });
                    // console.log(new Date().getTime() - time);
                    resolve(arr);
                })
            })

        }).catch(err => {
            console.log(err);
            reject(err);
        })
    })


}

export function pdfHandle(url,page){
    // console.log(url);
    return new Promise(async (resolve,reject) => {
        let pdf = null;
        const pdfItem = pdfList.find(item => item.path === url);
        if(pdfItem){
            pdf = pdfItem.pdf;
        }else{
            try {
                const loadingTask = PDFJS.getDocument({
                    url,
                    withCredentials:true,
                    cMapUrl: "/pdfjs-2.2.228-dist/web/cmaps/",
                    cMapPacked: true
                });

                // console.log('正在获取初始句柄----------------');

                pdf = await loadingTask.promise;
                // console.log('获取句柄成功');
                // console.log('pdf---------------',pdf);
            } catch (err) {
                console.log('获取句柄失败!!!!!!!!!!!',err);
                // reject(err)
            }
            if(page > 1){
                pdfList.push({ path: url, pdf });       //  句柄缓存
            }
        }
        if(pdf){
            // const time = Date.now();
            pdf.getPage(page).then(page => {
                // console.log('getPage------',Date.now() - time);
                getUrlTextLayer(page).then((res) => {
                    // console.log(Date.now() - time);
                    resolve(res)
                })
            }).catch(err => {
                console.log('解析页数失败!!!!!!!!!!!',err);
                // reject(err);
            })

        }else{
            resolve()
        }

    })
}

function getUrlTextLayer(page){
    // 794 * 1123
    const viewWidth = page.view[2];
    const viewHeight = page.view[3];
    let scale = 1;
    if(viewWidth / viewHeight > 794 / 1123){        //  防止canvas过大或过小，引起效率问题，固定2.5 * (794 x 1123)的大小
        scale = 1.5 / (viewWidth / 794);
    }else{
        scale = 1.5 / (viewHeight / 1123);
    }
    
    return new Promise((resolve,reject) => {
        let viewport = page.getViewport({ scale: scale, });
        let canvas = document.createElement('canvas')
        let context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        
        
        let renderContext = {
            canvasContext: context,
            viewport: viewport
        };

        // console.log(canvas);
        // document.body.appendChild(canvas);
        Promise.all([page.render(renderContext).promise,page.getTextContent()]).then(res => {
            const textContext = res[1];
            textContext.items.forEach(item => {
                item.height *= scale;
                item.width *= scale;
                for (let i = 0; i < item.transform.length; i++) {
                    item.transform[i] = item.transform[i] * scale;
                }
            })
            const textLayer = myRenderTest(textContext,viewport);
            const imgUrl = canvas.toDataURL('image/png');
            resolve({
                imgUrl,
                viewport,
                textLayer
            })
        })
    })
}

function myRenderTest(textContext,viewport){
    const texts = textContext.items;
    const styles = textContext.styles;

    const textLayer = [];
    const ctx = document.createElement('canvas').getContext("2d");
    texts.forEach(item => {
        if(!/[^\s]/.test(item.str)){
            return;
        }
        // item.str = item.str.replace(/\s/g,'');
        // console.log(item);
        ctx.font = `${item.height}px ${styles[item.fontName].fontFamily}`;
        const metrics = ctx.measureText(item.str);
        let width = metrics.width;

        let ascent = metrics.fontBoundingBoxAscent || styles[item.fontName].ascent;
        let descent = metrics.fontBoundingBoxDescent || Math.abs(styles[item.fontName].descent);
        const top = viewport.height - item.transform[5] - ascent / (ascent + descent) * item.height;
        const left = item.transform[4];
        const scale = item.width / width;     //  
        // const scale = 1;     //  
        const text = item.str;
        const fontFamily = styles[item.fontName].fontFamily;

        textLayer.push({
            top,
            left,
            scale,
            fontSize: item.height,
            text,
            fontFamily
        })
        
    })
    return textLayer;
}
