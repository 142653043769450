<template>
    <div class="comment-container">
        <div class="search">
            <el-input v-model="keyword" size="mini" placeholder="请输入批注关键字" suffix-icon="el-icon-search" clearable></el-input>
        </div>
        <ul class="comment-list">
            <li class="comment-item" v-for="comment in filterList" :key="comment.id" :style="{border: comment.id === currentCommentId ? '1px solid red' : ''}" @click="commentClick(comment)">
                <div class="title">
                    <span class="name">{{comment.realName}}</span>
                    <div class="date">{{comment.updateTime}}</div>
                </div>
                <div class="content">
                    <div class="text" v-html="comment.commentContent">
                        <!-- {{comment.commentContent}} -->
                    </div>
                    <div class="action">
                        <div class="reply" @click.stop="createReply(comment)">
                            <span>评论({{comment.replyInfoList && comment.replyInfoList.length}})</span>
                        </div>
                        <div class="delete" v-if="userId === comment.userId" @click.stop="deleteComment(comment)">
                            <span>删除</span>
                        </div>
                    </div>
                    <div class="reply-wrap">
                        <ReplyList ref="replyList" :replyInfoList="comment.replyInfoList" :comment="comment" :currentCommentId="currentCommentId" v-on="$listeners"></ReplyList>
                        <div class="create-reply" v-if="editId == comment.id" @click.stop>
                            <el-input ref="input" type="textarea" v-model="tempText" @blur="inputBlur"></el-input>
                            <button class="btn btn-text" @click="saveReply(comment)" @mousedown.prevent>发表</button>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { deleteComment , createReply } from '@/api/commentAPI.js';
import ReplyList from './ReplyList.vue';

export default {
    components: {
        ReplyList
    },
    inject: ['state'],
    computed: {
        userId(){
            return this.$store.getters.userInfo.id;
        },
        filterList(){
            let list = JSON.parse(JSON.stringify(this.commentList));
            return list.filter(comment => {
                comment.commentContent = comment.commentContent.replace(this.keyword,`<span style='color: red'>${this.keyword}</span>`);
                comment.replyInfoList = comment.replyInfoList.filter(reply => {
                    reply.content = reply.content.replace(this.keyword,`<span style='color: red'>${this.keyword}</span>`);
                    return reply.content.includes(this.keyword);
                    // return true;
                })

                return comment.commentContent.includes(this.keyword) || comment.replyInfoList.length;
            })
        }
    },
    props: {
        commentList: {
            type: Array,
            default(){
                return [];
            }
        },
        currentCommentId: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            tempText: '',
            editId: '',     //  编辑id
            replyInfo: {},  //  当前回复人信息
            keyword: '',
        }
    },
    watch: {
        currentCommentId: {
            handler(val){
                this.$nextTick(() => {
                    let index = this.commentList.findIndex(item => item.id === this.currentCommentId);
                    let node = this.$el.querySelectorAll('.comment-item')[index];
                    if(node.offsetTop > this.$el.scrollTop && node.offsetTop + node.offsetHeight < this.$el.scrollTop + this.$el.offsetHeight){
                        // console.log(node);   //  整体内容在可见范围内
                    }else{
                        this.$el.scrollTop = node.offsetTop;
                    }
                })
            }
        },
    },
    methods: {
        commentClick(item){
            this.$emit('update:currentCommentId',item.id);
        },
        deleteComment(comment){
            this.$confirm('此操作将永久删除批注信息, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(res => {
                let params = { id: comment.id , caseId: this.state.caseInfo.caseId };
                deleteComment(params).then(res => {
                    this.$message.success('删除成功！');
                    const index = this.commentList.findIndex(item => item.id === comment.id);
                    // console.log(index);
                    this.commentList.splice(index,1);
                })
            }).catch(err => {
                console.log(err);
            })
        },
        inputBlur(){
            this.editId = '';
        },
        createReply(comment,replyInfo = {}){
            this.editId = comment.id;
            this.tempText = '';
            this.replyInfo = replyInfo;
            this.$nextTick(() => {
                console.log(this.$refs.input);
                this.$refs.input[0].focus();
            })
        },
        saveReply(comment){
            let params = {
                commentId: comment.id,
                content: this.tempText,
                repliedUserId: this.replyInfo.id,
                repliedRealName: this.replyInfo.realName,
                realName: this.$store.getters.userInfo.realName,
                userId: this.$store.getters.userInfo.id,
                caseId: this.state.caseInfo.caseId
            }

            createReply(params).then(res => {
                this.editId = '';
                const target = this.commentList.find(item => item.id === comment.id);
                target.replyInfoList.push(res);
            })
        },
    }
}
</script>

<style scoped lang="less">
.search{
    padding: 10px 8px;
    display: flex;
}
.comment-container{
    height: 100%;
    overflow: auto;
    padding-bottom: 10px;
    box-sizing: border-box;
    position: relative;
    .comment-item{
        padding: 10px;
        border: 1px solid transparent;
        /deep/ .title{
            display: flex;
            align-items: center;
            .name{
                font-weight: 700;
            }
            .date{
                margin-left: 10px;
                font-size: 12px;
                color: rgba(0,0,0,.4);
            }
        }
        /deep/ .content{
            line-height: 1.2;
            .text{
                margin: 10px 0;
                white-space: pre-wrap;
                word-break: break-all;
            }
            .action{
                font-size: 12px;
                color: rgba(0,0,0,.4);
                display: flex;
                align-items: center;
                .reply{
                    display: flex;
                    align-items: center;
                }
                > div{
                    margin-right: 10px;
                }
                > div:hover{
                    color: rgba(0,0,0,.8);
                    cursor: pointer;
                }
            }
            .reply-wrap{
                margin-left: 10px;
                margin-top: 10px;
                padding: 0 20px;
                border-left: 1px solid #ccc;
                // border-left: 1px solid ;
                .create-reply{
                    margin-top: 15px;
                    text-align: right;
                    .btn{
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}
</style>