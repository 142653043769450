import Main from './Main.vue';
import Vue from 'vue';
import { createSocket } from '@/utils/f.js';

let DownloadProgress = Vue.extend(Main);
let instance;

const progress = function (options,callFun) {
    if(options.data.outType === 'single'){
        return;
    }
    if(!instance){
        instance = new DownloadProgress({});
        instance.$mount();  //  不传参数会返回一个新建的div，内部query函数
        // instance.visible = true;
        document.body.appendChild(instance.$el);
    }

    

    const uuid = options.uuid;
    const ws = createSocket(`caseinfo/socket/file/${uuid}`,res => {
        instance.visible = true;
        const data = JSON.parse(res.data);
        const progress = Math.floor(data.index / data.total * 100);

        const downloadList = instance.$data.downloadList;
        const target = downloadList.find(item => item.uuid === uuid);
        
        if(target){
            target.progress = Math.floor(data.index / data.total * 100);
        }else{
            downloadList.push({ progress, uuid , type: options.type })
        }

        if(progress === 100){       //  
            setTimeout(() => {                
                ws.close();     //  关闭socket
                const targetIndex = downloadList.findIndex(item => item.uuid === uuid);
                downloadList.splice(targetIndex,1);         //  关闭单项进度
                if(downloadList.length === 0){              //  关闭总进度弹窗
                    instance.visible = false;
                }
                if(callFun instanceof Function){
                    callFun();
                }
            },500)
        }
        
    });

    
    
    
    
    
    
    
    // instance.$watch('imgIndex',(val) => {
    //     callFun(val);
    // },{
    //     immediate: true
    // })
    
    // return instance;
};

export default progress;