<template>
    <div class="volume-file" @scroll="scroll">
        <ul class="file-list">
            <li ref="fileItem" class="file-item" v-for="(file,index) in fileList" :key="file.id+index" @dblclick="toImgView(file)" :data-id="file.id">
                <div class="img-container" :style="containerStyle">
                    <FileItem :file="file" :key="index" :configParams="configParams" @selectText="selectText(arguments,file)" @selectFile="selectFile" :commentList="commentList" v-on="$listeners"></FileItem>
                </div>
            </li>
        </ul>
        <FloatMenu ref="floatMenu" @menuClick="menuClick"></FloatMenu>
    </div>
</template>

<script>
import { pdfHandle } from '@/utils/pdf.js';
import { createComment } from '@/api/commentAPI.js';
import FileItem from './fileItem/index.vue';
import FloatMenu from '@/components/FloatMenu.vue';

export default {
    components: {
        FileItem,
        FloatMenu,
        
    },
    props: {
        fileList: {
            type: Array,
            default(){
                return [];
            }
        },
        dirList: {
            type: Array,
            default(){
                return [];
            }
        },
        currentPage: {
            type: Number,
            default: 1
        },
        commentList: {
            type: Array,
            default(){
                return [];
            }
        },
        currentCommentId: {
            type: String,
            default: ''
        },
        configParams: Object
    },
    inject: ['state','mutations'],
    data(){
        return {
            selectionOptions: null,
            markFile: null,
            manual: true,
            lastClickIndex: -1,
            imgIndex: 0,
            timer: null,
        }
    },
    computed: {
        boxList(){
            return this.$refs.fileItem;
        },
        containerStyle(){
            return {
                width: `${this.configParams.size / 100 * 794}px`,
                height: `${this.configParams.size / 100 * 1123}px`
            }
        },
        allFiles(){
            return this.mutations.getAllFiles(true);
        }
    },
    watch: {
        'configParams.size': {
            handler(val){
                this.$nextTick(() => {
                    if(this.fileList.length){
                        this.scroll();
                        //  IE浏览器有时候一行会显示多个
                        let container = this.$el.querySelector('.img-container');
                        if(container.offsetWidth + 20 >= this.$el.offsetWidth){
                            this.$el.querySelector('.file-list').style.width = container.offsetWidth + 20 + 'px';
                        }else{
                            this.$el.querySelector('.file-list').style.width = '100%';
                        }

                    }
                })
            },
            immediate: true
        },
        currentCommentId: {
            handler(val){
                // debugger
                let comment = this.commentList.find(item => item.id === val);
                let index = this.fileList.findIndex(item => item.id === comment.fileId && item.page === comment.annotationPage);
                let fileNode = this.$el.querySelectorAll('.file-item')[index];
                let mark = Array.from(fileNode.querySelectorAll('.mark-item')).find(mark => mark.getAttribute('data-id') === val);
                if(mark){   //  找到批注
                    let scale = getComputedStyle(mark.parentNode.parentNode).transform.match(/\d+\.?\d*/g)[0];  //  获取批注层放大倍数
                    let markTop = fileNode.offsetTop + mark.offsetTop * scale;
                    if(markTop > this.$el.scrollTop && markTop + mark.offsetHeight * scale < this.$el.scrollTop + this.$el.offsetHeight){
                        
                    }else{
                        this.$el.scrollTop = fileNode.offsetTop;
                    }
                }else{
                    this.$el.scrollTop = fileNode.offsetTop;
                    this.fileList.forEach(item => item.selected = false);
                    this.fileList[index].selected = true;
                }
            }
        },
    },
    methods: {
        fileSrc(file){
            if(file.isRender){      //  是否已经渲染完成
                return;
            }
            // console.log(file);
            // const targetFile = this.allFiles.find(targetFile => targetFile.id === file.id);
            if(file.isLocal){
                const targetDir = this.state.dirObj[file.pid];
                switch(targetDir.type){
                    case 11 : 
                        this.mutations.setCover(file).then(res => {
                            // targetFile.filePath = res;
                            file.filePath = res;
                        })
                    break;
                    case 12 : 
                        this.mutations.setBookMark(file).then(res => {
                            // targetFile.filePath = res;
                            file.filePath = res;
                        })
                    break;
                    case 13 : 
                        this.mutations.setBackcover(file).then(res => {
                            // targetFile.filePath = res;
                            file.filePath = res;
                        })
                    break;
                }
            }
            // console.log(file);
            if(file.pdfUrl){
                file.isRender = true;       
                
                pdfHandle(file.pdfUrl,file.page).then(res => {
                    let pdfParams = {
                        textLayer: res.textLayer,          //  渲染好的pdf文本层进行缓存
                        imgUrl: res.imgUrl,                //  解析pdf后得到的图片url
                        viewport: res.viewport             //  解析pdf页面的容器数据
                    }
                    file.pdfParams = pdfParams;
                    file.filePath = res.imgUrl;
                    
                })
            }
        },
        scroll(event){
            let renderFiles = this.fileList.filter((file,index) => {
                const node = this.boxList[index];
                return ((node.offsetTop + node.offsetHeight) >= this.$el.scrollTop) && node.offsetTop < this.$el.scrollTop + this.$el.offsetHeight;
            })
            this.renderCurrentFiles(renderFiles);

            let currentPage = Array.from(this.boxList).findIndex(node => {
                return node.offsetTop >= this.$el.scrollTop && node.offsetTop < this.$el.scrollTop + this.$el.offsetHeight;
            })
            if(currentPage != -1){
                this.$emit('update:currentPage',currentPage + 1);
            }
        },
        renderCurrentFiles(renderFiles){
            // console.log(renderFiles);
            if(this.timer){
                clearTimeout(this.timer);
            }
            this.timer = setTimeout(() => {
                // console.log('222222222222');
                renderFiles.forEach(file => {
                    this.fileSrc(file);
                })
            },100)
        },
        menuClick(item){
            switch(item){
                case '复制': 
                    // let str = this.selectionOptions.allNode.map(item => item.textContent).reduce((str,item) => str += item,'');
                    let str = this.selectionOptions.text;
                    console.log(str);
                    try{
                        this.copyText(str);
                        this.$message.success('文字复制成功!');
                    }catch(err){
                        this.$message.error('文字复制失败!');
                    }
                break;
                case '高亮并批注': 
                    let divBoxs = this.traverseNode(this.selectionOptions.allNode);
                    let items = this.getMarkDiv(divBoxs,this.configParams.highlightColor);

                    this.createMark(items,'COMMENT',this.markFile);
                break;
                case '划线并批注': 
                    divBoxs = this.traverseNode(this.selectionOptions.allNode);
                    items = this.getMarkDiv(divBoxs,this.configParams.underlineColor);
                    this.createMark(items,'UNDERLINE',this.markFile);
                break;
            }
        },
        createMark(items,type,file){
            this.$prompt('', '请输入批注信息', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputType: 'textarea',
                inputPattern: /\S/,
                inputErrorMessage: '评论内容不能为空'
            }).then(({value}) => {
                let annotationContent = '';
                items.forEach(item => {
                    annotationContent += item.str;
                })
                let params = {
                    annotationContent: annotationContent,
                    caseId: this.state.caseInfo.caseId,
                    commentContent: value,
                    fileId: file.id,
                    html: '',
                    node: JSON.stringify(items),
                    type: type, //..UNDERLINE
                    annotationPage: file.page,
                }
                createComment(params).then(res => {
                    this.$message.success('批注成功!');
                    res.replyInfoList = [];
                    this.commentList.push(res);
                    file.pdfParams.textLayer.push(res);
                    this.$emit('update:currentCommentId',res.id);       //  聚焦当前批注
                })
            }).catch((err) => {
                console.log(err);
                
            });
        },
        selectText(args,file){      //  文字选中信息
            let event = args[0];
            this.selectionOptions = args[1];
            if(this.selectionOptions.allNode.length > 0){
                this.markFile = file;
                this.$refs.floatMenu.init(event);
                
            }
        },
        copyText(str){              //  复制文字
            str = str.replace(/\s/g,'');
            let input = document.createElement('input');
            input.style.opacity = '0';
            input.value = str;
            document.body.appendChild(input);
            input.select();
            const copyResult = document.execCommand('copy');
            document.body.removeChild(input);
            return copyResult;
        },
        traverseNode(allNode){
            let tempTop = /(.*)px/.exec(allNode[0].parentNode.style.top)[1];
            let tempList = [];
            let divBox = [];
            allNode.forEach(node => {
                let top = /(.*)px/.exec(node.parentNode.style.top)[1];
                console.log(top,tempTop);
                if(Math.abs(tempTop - top) <= 2){       //  文本垂直距离不超过2px，视为一行文字

                    tempList.push(node.parentNode);
                }else{
                    divBox.push(tempList);
                    tempTop = top;
                    tempList = [node.parentNode];
                }
            })
            divBox.push(tempList);
            return divBox;
        },
        getMarkDiv(divList,color){
            let items = [];
            let direction = this.selectionOptions.direction;
            let startIndex = direction ? this.selectionOptions.anchorOffset : this.selectionOptions.focusOffset;
            let endIndex = direction ? this.selectionOptions.focusOffset : this.selectionOptions.anchorOffset;
            let type = '';

            divList.forEach((div,index) => {
                let { width,height,left,top } = this.getPosition(div[0],div[div.length - 1]);
                let str = '';
                div.forEach(node => {
                    str += node.textContent;
                })
                if(index === 0){
                    let { offsetX } = this.replaceNode(div[0],startIndex,div[0].textContent.length);
                    left += offsetX;
                    width -= offsetX;
                    str = str.substring(startIndex);
                }
                if(index === divList.length - 1){
                    let { offsetX , offsetWidth } = this.replaceNode(div[div.length - 1],0,endIndex);
                    width = width - div[div.length - 1].offsetWidth * /scaleX\((.*)\)/.exec(div[div.length - 1].style.transform)[1] + offsetX + offsetWidth;
                    str = str.substring(0,str.length - (div[div.length - 1].textContent.length - endIndex));
                }

                let obj = {
                    width,
                    height: height + 4,
                    left,
                    top: top - 2,
                    str,
                    type,
                    color
                }
                if(obj.width){
                    items.push(obj);
                }
                
            })
            
            return items;
        },
        getPosition(startNode,endNode){
            let left = startNode.offsetLeft;
            let right = endNode.offsetLeft + endNode.offsetWidth * /scaleX\((.*)\)/.exec(endNode.style.transform)[1];
            let top = startNode.offsetTop;
            let width = right - left;
            let height = startNode.offsetHeight;
            return {
                left,top,width,height
            }
        },
        replaceNode(node,startIndex,endIndex){
            let beforeNode = document.createTextNode(node.textContent.slice(0,startIndex));
            let centerNode = document.createTextNode(node.textContent.slice(startIndex,endIndex));
            let afterNode = document.createTextNode(node.textContent.slice(endIndex,node.textContent.length));

            let textNode = node.childNodes[0];
            let span = document.createElement('span');
            span.appendChild(centerNode);

            beforeNode && node.appendChild(beforeNode);
            centerNode && node.appendChild(span);
            afterNode && node.appendChild(afterNode);
            node.removeChild(textNode);



            let left = span.offsetLeft;
            let width = span.offsetWidth;
            
            beforeNode && node.removeChild(beforeNode);
            centerNode && node.removeChild(span);
            afterNode && node.removeChild(afterNode);
            node.appendChild(textNode);

            let scale = /scaleX\((.*)\)/.exec(node.style.transform)[1];

            return {
                offsetX: left * scale,
                offsetWidth: width * scale
            }
        },
        selectFile(targetFile){
            let files = this.fileList;
            if(this.state.keyCode.shift){
                files.forEach(item => item.selected = false);
                let index = files.indexOf(targetFile);
                if(this.lastClickIndex <= index){
                    for (let i = this.lastClickIndex; i <= index; i++) {
                        files[i].selected = true;
                    }
                }else{
                    for (let i = index; i <= this.lastClickIndex; i++) {
                        files[i].selected = true;
                    }
                }
            }else{
                targetFile.selected = !targetFile.selected;
                this.lastClickIndex = files.indexOf(targetFile);
            }
            this.$emit('selectFile');
        },
        toImgView(targetFile){
            let files = this.fileList.map(item => {
                let fileType;
                if(item.fileType === 'text/html'){
                    fileType = 'text/html';
                }else{
                    fileType = 'image/png';
                }
                if(item.id === targetFile.id){
                    file = item;
                }
                return {
                    id: item.id,
                    filePath: item.filePath,
                    fileType
                }
            })
            let file = files.find(item => item.id === targetFile.id);
            this.mutations.toImgView(file,files,
                {
                    openCompare: false,     //  不开启版本比对，有可能filePath为base64
                }
            );    
            // this.mutations.toImgView(targetFile,this.fileList.filter(item => item.fileType = 'image/png'),
            //     {
            //         openCompare: false,     //  不开启版本比对，有可能filePath为base64
            //     }
            // );    
        }
    }
}
</script>

<style scoped lang='less'>
.volume-file{
    overflow: auto;
    overflow-x: hidden;
    height: 100%;
    width: 100%;
    position: relative;
    .file-list{
        display: inline-flex;
        justify-content: center;
        flex-wrap: wrap;
        .file-item{
            position: relative;
            flex-shrink: 0;
            padding: 10px;
            box-sizing: border-box;
            display: flex;
            .img-container{
                width: 794px;
                height: 1123px;
                box-shadow: 0 0 5px 5px rgba(0,0,0,.1);
                border: 1px solid #ccc;
                position: relative;
                img{
                    max-width: 100%;
                    max-height: 100%;
                    user-select: none;
                }
                .layer-container{
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }
        }
    }
}
.volume-file:focus{
    outline: #9FC1F7 auto 5px;
}
</style>