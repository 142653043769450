<template>
    <div class="btn-group">
        <div @click="labelClick">
            <slot></slot>
        </div>
        <div ref="btnList" class="btn-list" v-show="visible" v-if="data.length" tabindex="2" @blur="blur" @mousedown.prevent>
            <MenuGroup :data="data" @menuClick="menuClick" :relation="relation" :value="value"></MenuGroup>
        </div>
        
    </div>
</template>

<script>
import MenuGroup from './menuGroup';

export default {
    components: {
        MenuGroup
    },
    props: {
        data: {
            type: Array,
            default(){
                return [];
            }
        },
        relation: Boolean,
        value: [String,Number]
    },
    data(){
        return {
            visible: false
        }
    },
    methods: {
        menuClick(data){
            this.visible = false;
            this.$emit('menuClick',data);
            this.$emit('input',data.value);
        },
        labelClick(){
            this.visible = true;
            this.$nextTick(() => {
                this.$refs.btnList && this.$refs.btnList.focus();
            })
        },
        blur(){
            this.visible = false;
        },
    }
}
</script>

<style scoped lang='less'>
.btn-group{
    white-space: nowrap;
    position: relative;
    .btn-list{
        margin-top: 3px;
        min-width: 100%;
        position: absolute;
        z-index: 2021;
        color: #1976D2;
        border-radius: 5px;
        .btn-item{
            padding: 0 10px;
            height: 30px;
            line-height: 30px;
            cursor: pointer;
            text-align: left;
        }
        .btn-item:hover{
            background-color: #E0EAF3;
        }
    }
    .btn-list:focus{
        outline: none;
    }
}
</style>