<template>
    <div class="set-page">
        <div class="form">
            <span class="title">页码规则</span>
            <div class="form-row">
                <div class="form-item">
                    <span class="label">页码位数</span>
                    <el-input-number :min="1" size="small" v-model="pageRule.length"></el-input-number>
                </div>
                <div class="form-item">
                    <span class="label">起始页码</span>
                    <el-input-number :min="1" size="small" v-model="pageRule.start"></el-input-number>
                </div>
            </div>
        </div>
        <div class="form">
            <span class="title">页码位置</span>
            <div class="form-row">
                <el-radio-group v-model="pageRule.position">
                    <el-radio label="RIGHT-TOP">正面右上角   背面左上角</el-radio>
                    <el-radio label="RIGHT-BOTTOM">正面右下角   背面左下角</el-radio>
                </el-radio-group>
            </div>
        </div>
        <div class="form">
            <span class="title">以下内容不计算页码</span>
            <div class="form-row">
                <el-checkbox-group v-model="pageRule.excludes">
                    <el-checkbox label="封皮">封皮</el-checkbox>
                    <el-checkbox label="封底">封底</el-checkbox>
                    <el-checkbox label="卷内目录">卷内目录</el-checkbox>
                    <el-checkbox label="证物袋">证物袋</el-checkbox>
                    <el-checkbox label="备考表">备考表</el-checkbox>
                </el-checkbox-group>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: {
        form: {
            type: Object,
            default(){
                return {};
            }
        }
    },
    computed: {
        pageRule(){
            return this.form.pageRule
        }
    },
    methods:{
        
    }
}
</script>

<style scoped lang='less'>
.set-page{
    width: 50%;
    margin: 0 auto;

}
.title{
    color: #999;
}
.form{
    padding-top: 40px;
}
.form-row{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    /deep/ .el-checkbox-group{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
}
.form-item{
    display: flex;
    align-items: center;

    .label{
        flex-shrink: 0;
        margin-right: 10px;
    }
}
</style>