import service from '@/utils/request';
import { parseFile } from '@/utils/file.js';


export const downloadPDF = params => {
    let url = '/api_v2/caseinfo/file/download-pdf';
    urlDownload(url,params);
}

export const printPDF = params => {
    params.contentType = 'application/pdf';
    return service.get('/caseinfo/file/download-pdf',{
        params,
        responseType: 'blob'
    })
}

export const downloadPDFWatermark = params => {
    let url = '/api_v2/caseinfo/file/download-wathermark-pdf';
    urlDownload(url,params);
}

export const printPDFWatermark = params => {
    params.contentType = 'application/pdf';
    return service.get('/caseinfo/file/download-wathermark-pdf',{
        params,
        responseType: 'blob'
    })
}

export const downloadZIP = params => {
    let url = '/api_v2/caseinfo/file/download-zip';
    urlDownload(url,params);
}

export const downloadFile = params => {
    return service.get('/caseinfo/file/download',{
        params,
        responseType: 'blob'
    })
}

export function base64ToPDF(data){      
    return service.post('/caseinfo/file/base64-to-pdf',data,{
        responseType: 'blob',
    })
}

//  数据清空（清空案件所有文件）
export function clearFile(params){
    return service.delete('/caseinfo/file/clear',{
        params,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    })
}

//  文件上传
export const fileUpload = (data,config) => {
    return service.post('/caseinfo/file/upload',data,{
        baseURL: '/api_v2',
        headers: {
            'ContentType': 'multipart/form-data'
        },
        onUploadProgress (progress) {
            config(progress);
        }
    });
}

//  查询已删除的文件
export const queryDeletedFile = params => {
    return service.get('/caseinfo/file/query-deleted',{
        params
    });
}

//  恢复文件
export const restoreFile = data => {
    return service.post('/caseinfo/file/restore',data,{
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    });
}

//  硬删除
export const hardRemove = data => {
    return service.post('/caseinfo/file/hard-remove',data,{
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    });
}

//  完成上传
export const finishUpload = data => {
    return service.post('/caseinfo/file/finish-upload',data,{
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    });
}

//  文件打标签
export const fileTags = data => {
    return service.post('/caseinfo/file/tags',data,{
        headers: {'Content-Type': 'application/json'}
    });
}

//  标记已读
export const fileRead = data => {
    return service.post('/caseinfo/file/mark-read',data,{
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    });
}

//  切换文件，是否使用预处理图
export const switchPath = data => {
    return service.post('/caseinfo/file/switch',data,{
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    });
}

//  生成缩略图，PDF
export const fileOCR = data => {
    return service.post('/caseinfo/file/sync-convert',data,{
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    });
}

//  图片ocr识别-单文件同步
export function ocrFile(data){
    return service.post('/basic/ocr/recognize',data,{
        headers: {'Content-Type': 'multipart/form-data'}
    })
}

//  文件重命名
export const fileRename = data => {
    return service.patch('/caseinfo/file/rename',data)
}

//  拆分文件（PDF和ZIP）
export function splitFile(data){
    return service.post('/caseinfo/file/split',data,{
        headers: { 'Content-Type': 'multipart/form-data' },
    })
}

import downloadProgress from '@/common/downloadProgress';
//  多文件下载(参数outType = 	文件输出类型，zip=多文件打包，pdf=合并为单PDF文件)
export const fileDownload = (data) => {
    urlDownload('/api_v2/caseinfo/file/download',data);
}

import axios from 'axios';

export const mergePDF = (data,type) => {     //  合并下载PDF
    let pdfUrl = '';
    service.post('/caseinfo/file/download',data,{
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    }).then(res => {
        pdfUrl = res.filePath;
    })
    downloadProgress({ uuid: data.uuid, data , type },() => {
        if(type === 'download'){
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = pdfUrl;
            
            document.body.appendChild(iframe);
    
            let timer = setTimeout(() => {
                document.body.removeChild(iframe);
                clearInterval(timer);
            },1000)
        }else{
            //  打印
            // axios.get(pdfUrl,{responseType: 'blob'}).then(res => {
            //     const blob = res.data;
            //     parseFile(blob,'',false);
            // })
            console.log(pdfUrl);
            window.open(pdfUrl,'_blank');
        }
    });
}

function urlDownload(url,params){
    //创建临时表单
    const form = document.createElement("form");
    form.method = "post";
    form.action = url;
    // form.style.display = 'none';

    if (params) {
        //拼接参数
        Object.keys(params).forEach(key => {
            const opt = document.createElement("input");
            opt.name = key;
            opt.value = params[key];
            form.appendChild(opt);
        })
    }

    const iframe = document.createElement('iframe');
    iframe.style.position = 'fixed';
    iframe.style.top = '10000px';
    
    document.body.appendChild(iframe);
    const iframeDocuemnt = iframe.contentDocument;
    iframeDocuemnt.body.appendChild(form);
    form.submit();
    
    let time = Date.now();
    
    downloadProgress({ uuid: params.uuid, data: params,type: 'download' },() => {
        let timer = setInterval(() => {
            if(Date.now() - time > 60 * 1000){
                document.body.removeChild(iframe);
                clearInterval(timer);
            }
        },1000)
    });
    
}