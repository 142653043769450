<template>
    <div class="base-info">
        <span class="back" @click="toHome" v-if="source !== 'CLIENT'">首页</span>
        <span v-if="source !== 'CLIENT'">></span>
        <span>{{state.caseInfo && state.caseInfo.caseName}}</span>
        <button class="btn btn-text iconfont iconshuaxin" style="margin-left:10px;" @click="reloadPage"></button>
        <span v-html="gdStatus()"></span>
        <button class="btn btn-text" v-if="source !== 'CLIENT' && fastReaderUrl" @click="quickRead">快速阅卷</button>

        <UnreadRemind @toFile="toFile"></UnreadRemind>
    </div>
</template>

<script>
import UnreadRemind from '@/components/UnreadRemind';

export default {
    components: {
        UnreadRemind
    },
    inject: ['state','mutations'],
    computed: {
        fastReaderUrl(){
            return this.state.configs.GXFYY_FastReaderUrl;
        },
        archiveStatus(){
            return this.state.caseInfo && this.state.caseInfo.extra['archive_status'];
        },
        archiveStatusList(){
            return this.$store.getters.dictMap['gd_status'];
        },
        source(){
            return this.$route.query.source;
        }
    },
    methods: {
        gdStatus(){
            const item = this.archiveStatusList.find(item => item.value === this.archiveStatus);
            if(item){
                let color = '';
                switch(item.value){
                    case "1" : color = '#FEAA45'; break;
                    case "2" : color = '#8CA2AA'; break;
                    case "3" : color = '#2FBE51'; break;
                    case "4" : color = '#FF5353'; break;
                }
                return `<span style="background-color: ${color};color:#fff;padding:3px 5px;margin-left:10px;border-radius:5px;">${item.label}</span>`;
            }else{
                return '';
            }
        },
        toHome(){
            this.$router.push('/home');
        },
        quickRead(){
            let token = localStorage.getItem('token',token);
            const url = this.fastReaderUrl.replace('{}',this.state.caseInfo.caseCode).replace('{}',token);
            window.open(url);
        },
        toFile(file){
            this.mutations.setViewModel('BatchView');
            this.state.currentFile = null;
            this.$nextTick(() => {
                this.mutations.setCurrentDir(file);
            })
        },
        reloadPage(){
            this.$parent.reload();
        }
    }

}
</script>

<style scoped lang='less'>
.base-info{
    height: 40px;
    line-height: 40px;
    padding-left: 40px;
    box-sizing: border-box;
    border-bottom: 1px solid #F1F1F1;
    background-color: #fff;
    display: flex;
    .back{
        color: #1976d2;
        cursor: pointer;
    }
    .btn{
        margin-left: 30px;
    }

}
</style>