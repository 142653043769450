<template>
    <div class="history-record">
        <!-- <div class="search">
            <div class="search-item">
                <span>用户：</span>
                <div><el-input size="small" v-model="searchData.key1" placeholder="请选择"></el-input></div>
            </div>
            <div class="search-item">
                <span>类型：</span>
                <div><el-input size="small" v-model="searchData.key2" placeholder="请选择"></el-input></div>
            </div>
            <div class="search-item">
                <div><el-input size="small" suffix-icon="el-icon-search" v-model="searchData.key3" placeholder="请选择"></el-input></div>
            </div>
        </div> -->
        <div class="record">
            <ul class="record-list">
                <div class="line"></div>
                <li class="record-item" v-for="(item,index) in records" :key="index">
                    <p class="time">{{item.createTime}}</p>
                    <div class="desc">
                        <i class="record-flag" :class="{'active': item.active}"></i>
                        <span class="text">
                            <span>{{item.realName}}</span>
                            <span class="action">{{item.action}}</span>
                            <span v-html="item.notes"></span>
                        </span>
                    </div>
                </li>
                <div class="read-more" @click="getRecords">
                    {{isMore ? '查看更多' : '没有更多了'}}
                </div>
            </ul>
        </div>
    </div>
</template>

<script>
import { getRecords } from '@/api/caseReading.js';

export default {
    data(){
        return {
            searchData: {
                key1: '',
                key2: '',
                key3: ''
            },
            search: {
                current: 1,
                size: 8,
                caseId: this.state.caseInfo.caseId
            },
            records: [],
            isMore: true
        }
    },
    inject: ['state'],
    created(){
        this.getRecords();
    },
    computed: {
        stateRecords(){
            return this.state.records;
        }
    },
    watch: {
        stateRecords: {
            handler(val){
                this.records = val;
                this.search.current = 1;
            }
        }
    },
    methods: {
        getRecords(){
            if(!this.isMore) return;
            getRecords(this.search).then(res => {
                if(res.records.length < this.search.size){
                    this.isMore = false;
                }
                this.records.push(...res.records);
                this.search.current++;
            })
        }
    }
}
</script>

<style scoped lang='less'>
.history-record{
    background-color: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search{
        display: flex;
        justify-content: space-between;
        padding: 20px 9px 40px;
        .search-item{
            display: flex;
            align-items: center;
            /deep/ .el-input{ width: 160px; }
        }
    }
    .record{
        padding-top: 20px;
        height: 0;
        flex-grow: 1;
        overflow: scroll;
        .record-list{
            position: relative;
            .line{
                width: 2px;
                height: 100%;
                background-color: #DCDCDC;
                position: absolute;
                left: 27px;
                top: 0;
                z-index: 0;
            }
            .record-item{
                padding: 0 20px 30px 21px;
                line-height: 1.5;
                .time{
                    color: #999;
                    padding-left: 38px;
                }
                .desc{
                    display: flex;
                    .record-flag{
                        display: block;
                        width: 14px;
                        height: 14px;
                        background-color: #ccc;
                        border-radius: 50%;
                        margin-right: 24px;
                        margin-top: 3px;
                        flex-shrink: 0;
                        z-index: 1;
                    }
                    .record-flag.active{
                        background-color: #1976D2;
                    }
                    .text{
                        .action{
                            font-weight: 600;
                            margin: 0 2px;
                        }
                        word-break:break-all;
                    }
                }
                
            }
            .read-more{
                text-align: center;
                color: #1976D2;
                cursor: pointer;
                padding-bottom: 40px;
            }
        }
        
        
    }
}
</style>