<template>
    <div>
        <div class="file-list-action" :class="{'pointer-none': state.getDirLoading}">
            <div class="btn-list">
                <button class="btn btn-default iconfont iconshangchuan" 
                    @click="uploadFile" 
                    v-show="dirCategory === 'base'" 
                    url="/caseinfo/file/upload" 
                    v-hasPermit
                    :disabled="uploadDisabled()"
                    :class="{'disabled': uploadDisabled()}"
                >上传</button>
                <BtnCascader class="ml10" :data="tagList" @menuClick="tagClick" v-hasPermit
                    url="/caseinfo/case-type/case-template/query-file-owner,/caseinfo/file/mark-read,/caseinfo/file/tags"
                >
                    <button class="btn btn-default iconfont iconbiaoji">标记</button>
                </BtnCascader>
                
                <button class="btn btn-default el-icon-edit-outline" @click="renameClick" url="/caseinfo/file/rename" v-hasPermit>重命名</button>

                <BtnGroup class="ml10" :data="printBtn" @btnClick="printClick" :url="downloadBtn.map(item => item.url).join(',')" v-hasPermit>
                    <button class="btn btn-default iconfont icondayin">打印</button>
                </BtnGroup>
                <BtnGroup class="ml10" :data="downloadBtn" @btnClick="downloadClick" :url="downloadBtn.map(item => item.url).join(',')" v-hasPermit>
                    <button class="btn btn-default iconfont iconxiazai">下载</button>
                </BtnGroup>
                <!-- <button class="btn btn-default iconfont iconshanchu" 
                    @click="deleteFileBox"
                    v-show="dirCategory === 'base'"
                    url="/caseinfo/file/remove,/caseinfo/directory/remove"
                    v-hasPermit
                >删除</button> -->
                <button class="btn btn-default iconfont iconfangkuai" url="/caseinfo/archiving" v-show="dirCategory === 'archived'"
                    @click="hotkeyArchiveInit" v-hasPermit
                    :disabled="archiveDisabled()"
                    :class="{'disabled': archiveDisabled()}"
                    >{{archiveBtnName()}}</button>
                <BtnGroup class="ml10" :data="moreBtn" @btnClick="btnGroupClick" :url="moreBtn.map(item => item.url).join(',')" v-hasPermit>
                    <button class="btn btn-default iconfont icongengduo">更多操作</button>
                </BtnGroup>
            </div>
            <div>
                <button class="btn btn-default el-icon-help original-case-btn" 
                    @click="originalCaseClick"
                    url="/caseinfo/query-original-case"
                    v-hasPermit
                >原审案件</button>
                <button class="btn btn-default iconfont iconyunhang" @click="trialPlay">庭审视频</button>
                <button class="btn btn-default iconfont iconoperation08" @click="volumeView(true)">阅览</button>
                <button class="btn btn-default iconfont iconduibi" @click="volumeView(false)">对比</button>
            </div>
        </div>

        
        <FullScreen :visible.sync="openVolumeView">
            <VolumeView ref="volumeView" v-if="openVolumeView" :volumeType="volumeType"></VolumeView>
        </FullScreen>
        <UploadFile ref="uploadFile" />
        <RecycleBin ref="recycleBin"></RecycleBin>
        <EditCover ref="editCover"></EditCover>
        
        <FullScreen :visible.sync="openHotkeyArchive" @cancel="closeArchive">
            <HotkeyArchive ref="hotkeyArchive" v-if="openHotkeyArchive"></HotkeyArchive>
        </FullScreen>
        <FullScreen :visible.sync="openDirMapping" @cancel="closeArchive" class="dir-mapping-screen">
            <DirMapping v-if="openDirMapping"></DirMapping>
        </FullScreen>
        <el-dialog
            title="重命名"
            :visible.sync="openRename"
            width="800px"
        >
            <BatchRename :renameFiles="renameFiles" :visible.sync="openRename"></BatchRename>
        </el-dialog>

        <el-dialog
            title="庭审视频"
            :visible.sync="openTrial"
            width="800px"
        >
            <TrialVideo :trialList="trialList"></TrialVideo>
        </el-dialog>

        <el-dialog
            title="原审案件"
            :visible.sync="openOriginalCase"
            width="800px"
        >
            <OriginalCase :originalCaseList="originalCaseList"></OriginalCase>
        </el-dialog>
    </div>
</template>

<script>
import VolumeView from './volumeView/index.vue';
import UploadFile from './uploadFile';

import { clearFile , fileTags , fileRead , splitFile } from '@/api/fileAPI.js';
import { deleteFile , deleteDir , trialList , originalCaseList , getWaitRollIn } from '@/api/caseReading.js';
import { batchDel , urlDownload } from '@/utils/f.js';

import EditCover from './EditCover';
import RecycleBin from './recycleBin';
import HotkeyArchive from './hotkeyArchive';


import BtnGroup from '@/components/model/BtnGroup.vue';
import BtnCascader from '@/components/model/BtnCascader.vue';

import FullScreen from '@/common/fullScreen/FullScreen.vue';
import DirMapping from '@/components/caseReading/DirMapping.vue';
import BatchRename from './batchRename';
import TrialVideo from './trialVideo';
import OriginalCase from './originalCase';


export default {
    components: {
        VolumeView,
        UploadFile,
        RecycleBin,
        EditCover,
        HotkeyArchive,
        BtnGroup,
        BtnCascader,
        FullScreen,
        DirMapping,
        BatchRename,
        TrialVideo,
        OriginalCase
    },
    data(){
        return{
            openUpdateCover: false,
            printBtn: [
                {
                    value: 'PDF',
                    permit: '/caseinfo/file/download-pdf',
                    label: 'PDF文件'
                },
                {
                    value: 'PDFMark',
                    permit: '/caseinfo/file/download-wathermark-pdf',
                    label: 'PDF文件(带水印)'
                }
            ],
            downloadBtn: [
                {
                    value: 'ZIP',
                    url: '/caseinfo/file/download-zip',
                    label: '原文件'
                },
                {
                    value: 'singlePDF',
                    url: '/caseinfo/file/download-pdf',
                    label: 'PDF文件(单个)'
                },
                {
                    value: 'mergePDF',
                    url: '/caseinfo/file/download-pdf',
                    label: 'PDF文件(合并)'
                },
                {
                    value: 'mergePDFMark',
                    url: '/caseinfo/file/download-wathermark-pdf',
                    label: 'PDF文件(带水印合并)'
                }
            ],
            openHotkeyArchive: false,
            openVolumeView: false,      //  整卷预览
            volumeType: true,          //  预览类别（整卷/对比）
            openDirMapping: false,      //  打开目录映射
            openRename: false,          //  打开文件批量规则重命名
            renameFiles: [],        //  批量重命名文件
            openTrial: false,           //  庭审视频
            trialList: [],              //  庭审列表
            openOriginalCase: false,    //  是否打开原审案件
            originalCaseList: [],       //  原审案件列表
        }
    },
    inject: ['state','mutations','fileListState'],
    computed: {
        tagList(){
            let list = [
                {
                    label: '标记材料归属',
                    url: '/caseinfo/file/tags',
                    // type: 'OWNER',
                    icon: 'iconcailiaoguishu',
                    children: this.state.dictMap['OWNER']
                },
                {
                    label: '标记材料介质',
                    url: '/caseinfo/file/tags',
                    // type: 'MEDIA',
                    icon: 'iconcailiaojiezhi',
                    children: this.state.dictMap['MEDIA']
                },
                {
                    label: '标记为已读',
                    type: 'read',
                    icon: 'iconyidu',
                    url: '/caseinfo/file/mark-read'
                },
                {
                    label: '标记全部为已读',
                    type: 'allRead',
                    icon: 'iconchenggong',
                    url: '/caseinfo/file/mark-read'
                },
            ]
            return list;
        },
        currentDir(){
            return this.state.currentDir;
        },
        dirList(){
            return this.state.dirList;
        },
        dirCategory(){
            return this.state.dirCategory;
        },
        dirObj(){
            return this.state.dirObj;
        },
        activeFile(){
            return this.state.activeFile;
        },
        currentView(){
            return this.state.viewModel;
        },
        moreBtn(){
            return [
                {
                    value: 'recycleBin',
                    url: '/caseinfo/file/query-deleted',
                    label: '回收站'
                },
                {
                    value: 'dataClear',
                    url: '/caseinfo/file/clear',
                    label: '数据清空'
                },
                {
                    value: 'editCover',
                    url: '/caseinfo/save-extra',
                    label: '编辑封皮'
                },
                {
                    value: 'dirMapping',
                    url: '/caseinfo/directory/query-mapping',
                    label: '目录映射'
                },
                {
                    value: 'splitFile',
                    url: '/caseinfo/file/split',
                    label: '拆分文件'
                },
                {
                    value: 'delete',
                    url: '/caseinfo/file/remove,/caseinfo/directory/remove',
                    hidden: this.dirCategory !== 'base',
                    label: '删除'
                },
                {
                    value: 'getWaitRollIn',
                    url: '/caseinfo/hb-extra/sync-hy-drj',
                    label: '获取待入卷材料',
                }
            ]
        }
    },
    methods: {
        getSelectData(){
            const state = this.state;
            let files = [];     //  已选文件
            let dirs = [];      //  已选目录
            let speFiles = [];  //  已选特殊文件(封皮封底)
            if(state.viewModel === 'AxisView' || state.viewModel === 'BatchView'){      //  轴线、批次视图
                files = state.dataList.filter(item => item.selected && !item.isLocal);
                speFiles = state.dataList.filter(item => item.selected && item.isLocal);
            }else if(state.viewModel === 'GridView' || state.viewModel === 'ListView'){
                if(state.currentDir.dataType === 'dir' && state.currentDir.isLocal){
                    speFiles = state.currentDir.fileList;
                }else{
                    files = state.dataList.filter(item => item.selected && item.dataType === 'file');
                    dirs = state.dataList.filter(item => item.selected && item.dataType === 'dir')
                }
            }
            return {
                files,
                dirs,
                speFiles
            }
        },
        async printClick(value){       //  打印
            this.mutations.fileDownload(this.getSelectData(),{ outType: 'pdf', watermark: value === 'PDF' ? false : true , type: 'print' });
        },
        downloadClick(value){
            const data = this.getSelectData();
            if(value === 'ZIP'){
                this.mutations.fileDownload(data,{ outType: 'zip' });
            }else if(value === 'mergePDF' || value === 'mergePDFMark'){
                this.mutations.fileDownload(data,{ outType: 'pdf' , watermark: value === 'mergePDF' ? false : true , type: 'download' });
            }else if(value === 'singlePDF'){
                this.mutations.fileDownload(data,{ outType: 'zip' , convertType: 'pdf' });
            }
        },
        deleteFileBox(){
            const { dirs , files } = this.getSelectData();
            // console.log(selectDirs,selectFiles);

            if(!(dirs.length || files.length)){
                this.$message.warning('请选择文件或目录！');
                return;
            }

            this.$confirm('此操作将删除文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                if(files.length){
                    this.deleteFiles(files);
                }
                if(dirs.length){
                    this.deleteDirs(dirs);
                }
                // const fileListNode = document.querySelectorAll('.file-container');
            }).catch(err => {
                console.log(err);
            })
        },
        async deleteFiles(files){     //  删除无法回显BatchView数据
            let params = {
                caseId: this.state.caseInfo.caseId,
                category: this.dirCategory,
            }

            let fileArr = this.group(files,10);     //  删除分批次删除，一次删除10个文件
            let flag = true;

            for (let i = 0; i < fileArr.length; i ++) {
                const list = fileArr[i];
                try {
                    let str = `${list.map(item => item.name).join('、')}`;
                    this.mutations.setRecordData(str,'/caseinfo/file/remove');

                    params.ids = list.map(item => item.id).join(',');
                    
                    await deleteFile(params);
                    
                    list.forEach(file => {
                        file.deleted = true;
                        let dir = this.dirObj[file.pid];
                        dir.children.splice(dir.children.indexOf(file),1);
                        this.mutations.fileOrder(dir.children.filter(item => item.dataType === 'file'));
                    })

                } catch (error) {
                    console.log(error);
                    flag = false;
                }
            }
            if(flag){
                this.$message.success('文件删除完成！');
            }
            
        },
        group(arr,length){
            let index = 0;
            let newArr = [];
            while(index < arr.length){
                // console.log(index);
                newArr.push(arr.slice(index,index += length));
            }
            return newArr;
        },
        deleteDirs(selectDirs){
            let params = {
                caseId: this.state.caseInfo.caseId,
                category: this.dirCategory,
                ids: selectDirs.map(item => item.id).join(',')
            }

            // let str = `位于<span style="color: #1976D2">《${this.currentDir.name}》</span>目录中的：`;
            let str = `、`;
            selectDirs.forEach(dir => {
                str += `<span style="color: #1976D2">《${dir.name}》</span>、`
            })
            str = str.substring(0,str.length - 1);
            
            this.mutations.setRecordData(str,'/caseinfo/directory/remove');

            deleteDir(params).then(res => {
                this.$message.success('目录删除成功！');
                batchDel(this.currentDir.children,item => item.selected && item.dataType === 'dir');
                this.mutations.dirOrder(this.currentDir.children);
            })
        },
        recursion(arr,cb){
            arr.forEach((item,index) => {
                cb(item,index,arr);
                if(item.children && item.children.length > 0){
                    this.recursion(item.children,cb);
                }
            })
        },
        uploadDisabled(){
            if(!this.currentDir || this.currentDir.type === 1 || this.currentDir.type === 2 || this.currentDir.isLocal || this.currentDir.dataType === 'file'){
                return true;
            }
        },
        archiveDisabled(){
            if(this.state.caseInfo.closed){ //  已结案才能归档
                return false;
                // let status = this.state.caseInfo.extra.archive_status;
                // if(status === "2" || status === "3"){     //待归档
                //     return true;
                // }else{
                //     return false;
                // }
            }else{
                return true;
            }
        },
        archiveBtnName(){
            let status = this.state.caseInfo.extra.archive_status;
            if(status === "2" || status === "3"){     //待归档
                return '查看归档信息';
            }else{
                return '一键归档';
            }
        },
        uploadFile(){
            this.$refs.uploadFile.init();
        },
        volumeView(flag){           //  整卷视图

            this.openVolumeView = true;
            this.volumeType = flag;
        },
        editCoverClick(){   //  编辑模板
            this.$refs.editCover.init();
        },
        btnGroupClick(val){
            switch(val){
                case 'recycleBin': 
                    this.$refs.recycleBin.init();   //  打开回收站
                break;
                case 'dataClear':                   //  数据清空
                    this.dataClear();
                break;
                case 'editCover':
                    this.$refs.editCover.init();    //  编辑封皮
                break;
                case 'dirMapping':
                    this.openDirMapping = true;     //  目录映射
                break;
                case 'splitFile':                   //  拆分文件(PDF和ZIP)
                    const { files } = this.getSelectData();
                    if(files.length > 1 || files.length === 0){
                        this.$message.warning('请选择单个文件！');
                        return;
                    }
                    const params = {
                        caseId: this.state.caseInfo.caseId,
                        fileId: files[0].id || '',
                    }
                    const loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    splitFile(params).then(res => {
                        this.$message.success('拆分成功');
                        this.closeArchive();
                    }).finally(() => {
                        loading.close();
                    })
                break;
                case 'delete':
                    this.deleteFileBox();           //  删除文件(目录)
                break;
                case 'getWaitRollIn':
                    this.getWaitRollIn();           //  获取待入卷
                break;
            }
        },
        getWaitRollIn(){
            getWaitRollIn({ caseId: this.state.caseInfo.caseId }).then(res => {
                
            })
        },
        dataClear(){      //  数据清空
            const caseName = this.state.caseInfo.caseName;
            this.$prompt(`请输入案件名称 "${caseName}" 清空数据!`,'数据清空',{
                inputValidator: (value) => {
                    if(value !== caseName){
                        return `请输入案件名称${caseName}`;
                    }
                }
            }).then(({value}) => {
                clearFile({ caseId: this.state.caseInfo.caseId }).then(res => {
                    this.$message.success('数据清空成功!');
                    location.reload();
                })
            }).catch(() => {})
        },
        hotkeyArchiveInit(){
            this.openHotkeyArchive = true;
            //  未归档、待归档、退档状态------可编辑，可重新设置
            //  归档处理中、已归档------不可编辑，不可重新设置
            //  归档处理中、已归档、退档------
            this.$nextTick(() => {
                let status = this.state.caseInfo.extra.archive_status;
                const hotkeyArchive = this.$refs.hotkeyArchive;
                if(status === '2' || status === '3'){
                    hotkeyArchive.params.openDetail = true;
                }
                if(status === '2' || status === '3' || status === '4'){
                    hotkeyArchive.currentIndex = 5;
                }
                if(status === '1' || status === '4' || !status){
                    hotkeyArchive.params.showResetArchive = true;
                }

            })
        },
        closeArchive(){
            this.$parent.$parent.reloadDirList();
        },
        tagClick(data){     //  打标签
            let value = data.type;
            const { dirs , files } = this.getSelectData();
            let allFiles = [];
            this.mutations.recursion(dirs,dir => {
                if(dir.children && dir.children.length){
                    allFiles.push(...dir.children.filter(item => item.dataType === 'file'));
                }
            })
            allFiles = allFiles.concat(files);

            if(!allFiles.length && value != 'allRead'){
                this.$message.warning('请选择文件!');
                return;
            }
            // console.log(value);
            switch(value){
                case 'OWNER':
                case 'MEDIA':
                    // 打标签
                    let tagFiles = allFiles.map(item => {
                        return {
                            fileId: item.id,
                            tags: [data.value],
                            type: data.type
                        }
                    })
                    let params = {
                        caseId: this.state.caseInfo.caseId,
                        tagFiles,
                    }
                    fileTags(params).then(res => {
                        this.$message.success('标记成功!');
                        allFiles.forEach(item => {
                            if(!item.tags){
                                item.tags = { [data.type]: data.value };
                            }else{
                                this.$set(item.tags,data.type,data.value);
                            }
                        })
                    })
                break;
            }
            if(value === 'read' || value === 'allRead'){
                let files = [];
                if(value === 'read'){
                    files = allFiles;
                }else{
                    files = this.mutations.getAllFiles();
                }
                let params = {
                    caseId: this.state.caseInfo.caseId,
                    fileIds: files.map(item => item.id).join(',')
                }

                fileRead(params).then(res => {
                    let msg = value === 'read' ? '材料成功标记为已读' : '材料成功标记全部为已读';
                    this.$message.success(msg);
                    files.forEach(item => this.$delete(item.extra,'readFlag'));
                })
            }
            
        },
        renameClick(){
            const { files } = this.getSelectData();
            if(files.length){
                this.renameFiles = files.map(file => {
                    return {
                        fileId: file.id,
                        fileName: file.name,
                        filePath: file.filePath,
                        suffix: file.filePath.match(/[^\.]\w*$/)[0]
                    }
                })
                this.openRename = true;
            }else{
                this.$message.warning('请选择文件!');
            }
        },
        trialPlay(){
            trialList({ caseId: this.state.caseInfo.caseId }).then(res => {
                this.trialList = res;
                this.openTrial = true;
            })
        },
        originalCaseClick(){
            originalCaseList({ caseName: this.state.caseInfo.caseName }).then(res => {
                this.openOriginalCase = true;
                this.originalCaseList = res;
            })
        }
    }
}
</script>

<style scoped lang='less'>
.file-list-action{
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
}
.pointer-none{
    pointer-events: none;
}
.dialog-cover{
    display: flex;
    justify-content: center;
}
.btn-list{
    display: flex;
}
.dir-mapping-screen{
    /deep/ .close{
        top: 5px;
    }
}
.original-case-btn{
    border-color: #10AD36;
    color: #10AD36;
}
.original-case-btn:hover{
    background-color: #10AD36;
    color: #fff;
}
</style>