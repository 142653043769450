<template>
    <div style="height: 100%;">
        <div class="header">
            <div class="current" v-for="item in list" 
            :key="item.value" 
            @click="click(item)"
            :class="{'active': item === current}"
            >{{item.label}}</div>
        </div>
        <div v-if="result" class="com">
            <component :is="current.comName" :result="result"></component>
        </div>
    </div>
</template>

<script>
import { inspectRecord } from '@/api/check.js';
import InspectRecord from './InspectRecord.vue';
import CurrentInfo from './CurrentInfo.vue';

export default {
    components: {
        CurrentInfo,
        InspectRecord
    },
    inject: ['state'],
    data(){
        return {
            list: [
                {
                    label: '当前结果',
                    value: 1,
                    comName: 'CurrentInfo'
                },
                {
                    label: '阶段记录',
                    value: 2,
                    comName: 'InspectRecord'
                },
            ],
            current: null,
            result: null,
        }
    },
    created(){
        this.current = this.list[0];
    },
    methods: {
        click(data){
            this.current = data;
        }
    },
    mounted(){
        let params = {
            caseId: this.state.caseInfo.caseId,
            courtId: this.state.caseInfo.court.id
        }
        inspectRecord(params).then(res => {
            this.result = res;

        })        
    },
}
</script>

<style lang="less" scoped>
.header{
    height: 40px;
    box-sizing: border-box;
    padding: 0 20px;
    background-color: #EDEDED;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    color: #6C6C6C;
    .current{
        cursor: pointer;
        margin-right: 32px;
        
    }
    .current.active{
        color: #3291F8;
    }
}
.com{
    height: calc(100% - 40px);
    overflow: auto;
}
</style>