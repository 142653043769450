<template>
    <div>
        <div class="tabs">
            <div class="title" v-for="item in tabList" :key="item.value" @click="tabClick(item)" 
                :class="{'active': item.comName === activeName}">
                <div>{{item.label}}</div>
                <div class="line"></div>
            </div>
        </div>
        <div class="content">
            <component :is="activeName" @openArchive="openArchive" @download="download" v-model="form.outputMode"></component>
        </div>

        <el-dialog
            :title="`归档信息-${state.caseInfo.caseName}`"
            :visible.sync="openArchiveForm"
            :append-to-body="true"
            :close-on-click-modal="false"
            width="1000px">
            <ArchiveForm v-if="openArchiveForm" @cancel="openArchiveForm = false" 
                :archiveFormDisabled="archiveFormDisabled"
                :mainForm="mainForm"
                :sideForm="sideForm"
                :caseForm="caseForm"
                :dsrFormList="dsrFormList"
                :mainFormModel="mainFormModel"
                :sideFormModel="sideFormModel"
                :caseFormModel="caseFormModel"
                :dsrFormModel="dsrFormModel"


            ></ArchiveForm>
        </el-dialog>
        
        <div class="reset-archive" v-if="params.showResetArchive"><el-button size="small" plain @click="resetArchive">重新归档设置</el-button></div>
    </div>
</template>

<script>
import Push from './Push.vue';
import OutputPDF from './OutputPDF.vue';
import ArchiveForm from './archiveForm';
import { mainFormModel , sideFormModel , caseFormModel , dsrFormModel } from './data';
import { getDict } from '@/api/commonAPI.js';
import { getArchiveForm , archivingDownload } from '@/api/archiving.js';
import { parseFile } from '@/utils/file.js';


export default {
    components: {
        Push,
        OutputPDF,
        ArchiveForm,
    },
    inject: ['state','mutations','params'],
    data(){
        return {
            tabList:[
                {
                    label: '推送至档案系统',
                    value: 1,
                    comName: 'Push'
                },
                {
                    label: '输出案件电子档案',
                    value: 2,
                    comName: 'OutputPDF'
                },
            ],
            activeName: 'Push',
            openArchiveForm: false,
            archiveFormDisabled: false,
            mainForm: {},
            sideForm: {},
            caseForm: {},
            dsrFormList: [],
            mainFormModel: mainFormModel,
            sideFormModel: sideFormModel,
            caseFormModel: caseFormModel,
            dsrFormModel: dsrFormModel,
        }
    },
    props: {
        form: Object
    },
    methods: {
        tabClick(data){
            this.activeName = data.comName;
        },
        openArchive(disabled){
            let dictKeys = ['gd_status','gd_secrecy','gd_duration','gd_source','gd_model'];
            getDict({ category: dictKeys.join(',')}).then(res => {
                // this.gdStatusList = res[0];     //  归档状态
                // this.gdSecrecyList = res[1];    //  案卷密级
                // this.gdDurationList = res[2];   //  保管期限
                // this.gdSourceList = res[3];     //  归档来源
                // this.gdModelList = res[4];      //  归档模式

                this.mainFormModel.find(item => item.value === 'MJ').selectList = res[1];
                this.mainFormModel.find(item => item.value === 'BGQX').selectList = res[2];
            })
            getArchiveForm({caseId: this.state.caseInfo.caseId}).then(res => {  //  数据回显
                this.mainForm = res['主体信息'];
                this.sideForm = res['辅助信息'];
                this.caseForm = res['案件信息'];
                this.dsrFormList = res['当事人信息'];

                const archiveInfo = this.state.caseInfo.extra.ARCHIVE;

                if(archiveInfo){

                    this.mainForm = JSON.parse(JSON.stringify(archiveInfo['主体信息']));
                    this.caseForm = JSON.parse(JSON.stringify(archiveInfo['案件信息']));
                    this.dsrFormList = JSON.parse(JSON.stringify(archiveInfo['当事人信息']));
                }
                console.log(this.mainForm);
                this.openArchiveForm = true;
                this.archiveFormDisabled = disabled;
            })
        },
        download(){
            archivingDownload(this.form).then(res => {
                let fileName = this.mutations.getResFileName(res);
                parseFile(res.data,fileName);

            })
        },
        resetArchive(){
            this.$parent.currentIndex = 1;
        }
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body{
    padding-top: 0;
}
.tabs{
    display: flex;
    justify-content: center;
    color: #101010;
    .title{
        margin: 0 22px;
        cursor: pointer;
        .line{
            margin: 8px auto;
            width: 36px;
            height: 2px;
            background-color: #1876D3;
            display: none;
        }
    }
    .title.active{
        color: #1876D3;
        .line{
            display: block;
        }
    }
}
.reset-archive{
    position: absolute;
    left: 20px;
    top: 20px;
}
.content{
    margin-top: 40px;
    display: flex;
    justify-content: center;
}
/deep/ .btn-achive{
    background-color: #207DFE;
    border: 1px solid #207DFE;
    color: #fff;
    width: 100px;
    height: 36px;
    box-sizing: border-box;
}
/deep/ .btn-achive.plain{
    color: #207DFE;
    background-color: transparent;
}
</style>