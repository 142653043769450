<template>
    <div class="context-menu" @blur="blur" tabindex="1" v-if="visible" :style="style">
        <div @mousedown.prevent>
            <MenuGroup :data="data" @menuClick="menuClick"></MenuGroup>
        </div>
    </div>
</template>

<script>
import MenuGroup from './menuGroup';

export default {
    components: {
        MenuGroup
    },
    props: {
        data: Array
    },
    data(){
        return {
            visible: false,
            style: {
                left: 0,
                top: 0
            }
        }
    },
    methods:{
        init(event){
            // console.log(this.$el.scrollHeight);
            this.visible = true;
            
            this.$nextTick(() => {
                this.$el.focus();
                let y = event.pageY;
                if(document.body.offsetHeight - event.pageY < 80){
                    y = event.pageY - 80;
                }
                this.style.left = event.pageX + 5 + 'px';
                this.style.top = y + 5 + 'px';
            })
        },
        menuClick(data){
            this.visible = false;
            this.$emit('menuClick',data);
            this.$emit('close');
        },
        blur(){
            console.log(123);
            this.visible = false;
            this.$emit('close');
        },

    }
}
</script>

<style scoped lang='less'>
    .context-menu{
        position: fixed;
        z-index: 99;
    }
    .context-menu:focus{
        outline: none;
    }
</style>