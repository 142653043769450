<template>
    <div class="axis-item" :style="{'height':`${height}px`}">
        <div class="title">
            <div class="point"></div>
            <el-checkbox v-model="allSelected" @change="checkboxChange"></el-checkbox>
            <div class="name-path">
                <div class="name-item" v-for="(item,index) in namePath" :key="index">
                    <span :class="{'last-name': index + 1 === namePath.length}">{{item}}</span>
                    <span class="icon iconfont iconjiantou" v-if="namePath.length != index + 1"></span>
                </div>
            </div>
        </div>
        <div class="file-list" :style="{'padding-top': `${paddingTop}px`,'padding-bottom': `${paddingBottom}px`}" v-show="reload">
            <FileModel 
                ref="fileItem"
                v-for="item in fileList"
                :key="item.id"
                :file="item"
                @selectFile="$listeners.selectFile"
                @selectChange="selectChange"
                @dbClick="$listeners.toImgView"
            ></FileModel>
        </div>
        <div class="line"></div>
    </div>
</template>

<script>
import FileModel from '@/components/model/FileModel.vue';


export default {
    name: 'AxisItem',
    components: {
        FileModel,
    },
    props: {
        dir: {
            type: Object,
            default(){
                return null;
            }
        },
        orderFun: Function
    },
    inject: ['state','fileListState'],
    data() {
        return {
            allSelected: false,
            fileList: [],
            paddingTop: 0,
            paddingBottom: 0,
            reload: true
        };
    },
    computed: {
        namePath(){
            const dirMap = this.state.dirObj;
            let temp = this.dir;
            let arr = [];
            while(temp && temp.id != 0){
                arr.push(temp.name);
                temp = dirMap[temp.pid];
            }

            return arr.reverse();
        },
        col(){
            return this.dir.col;
        },
        row(){
            return this.dir.row;
        },
        height(){
            return this.row * this.fileListState.fileContainerHeight + 21;
        },
    },
    watch: {
        dir: {
            handler(val){
                this.reload = false;
                this.$nextTick(() => {
                    this.paddingTop = this.dir.paddingTop;
                    this.paddingBottom = this.dir.paddingBottom;
                    this.fileList = this.dir.children.sort(this.orderFun);
                    this.$nextTick(() => {
                        this.reload = true;
                    })
                })
            },
            immediate: true
        },
    },
    methods: {
        checkboxChange(val){
            this.$emit('checkboxChange',this.dir,val);
            this.allSelected = val;
        },
        selectChange(){
            const target = this.$parent.dirs.find(item => item.id === this.dir.id);
            let file = target.children.find(item => !item.selected);
            if(file){
                this.allSelected = false;
            }else{
                this.allSelected = true;
            }
            
            this.$emit('selectChange');
        },
    },
};
</script>

<style scoped lang='less'>
.axis-item{
    position: relative;
    .line{
        width: 2px;
        background: #E6E6E6;
        position: absolute;
        left: 3px;
        top: 0;
        bottom: 0;
        margin-top: 20px;
    }
}
.title{
    display: flex;
    align-items: center;
    font-size: 16px;
    .point {
        width: 8px;
        height: 8px;
        background: #1976d2;
        border-radius: 50%;
        margin-right: 16px;
        box-shadow: 0 0 0 2px rgba(25,118,210,.2);
    }
    .name-path{
        display: flex;
        align-items: center;
        margin-left: 12px;
        color: #808080;
        .name-item{
            display: flex;
            align-items: center;
        }
        .last-name{
            color: #000;
        }
        .icon{
            margin: 0 5px;
            font-size: 12px;
        }
    }
}
.file-list{
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    user-select: none;
    position: relative;
    
}

</style>