<template>
    <div class="main-content">
        <component :is="currentTab"></component>
    </div>
</template>

<script>
import Dossier from './dossier';
// import CaseInfo from './caseInfo';

export default {
    components: {
        Dossier,
        // CaseInfo
    },
    props: {
        currentTab: {
            type: String,
            default: ''
        }
    }
}
</script>

<style scoped lang='less'>
.main-content{
    height: calc(100vh - 100px);
}
</style>