<template>
    <div class="kindeditor">
        <textarea id="kindeditor" name="content">
            
        </textarea>
    </div>
</template>

<script>
import 'kindeditor/kindeditor-all.js';
import 'kindeditor/lang/zh-CN.js';
import 'kindeditor/themes/default/default.css';

export default {
    props: {
        value: String
    },
    data(){
        return {
            editor: null,
            params: {
                // width: '450px',
                // height: '100%',
                items: [
                    'hello','undo','redo','fontname','fontsize','bold','italic','underline','strikethrough',
                    'justifyleft','justifycenter','justifyright','justifyfull',
                ],
                afterChange: this.change,
            }
        }
    },
    mounted(){
        this.params.width = this.$parent.$el.offsetWidth + 'px';
        this.params.height = this.$parent.$el.offsetHeight + 'px';
        this.editor = KindEditor.create('#kindeditor',this.params);
        this.editor.html(this.value);
    },
    methods: {
        change(){
            this.editor && this.editor.sync();     //  同步改变textarea的value值
            this.$emit('input',document.querySelector('#kindeditor').value);
        }
    },
    
}
</script>

<style lang="less" >
.kindeditor{
    height: 100%;
}
body .ke-toolbar .ke-outline{
    width: 30px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>