<template>
    <div class="edit-note">
        <div class="title">
            <el-button size="small" plain @click="exit">退出编辑</el-button>
            <el-button size="small" type="primary" @click="save">保存并退出</el-button>
        </div>
        <div class="editor-container">
            <Editor v-model="html" ref="editor"></Editor>

        </div>
    </div>
</template>

<script>
import Editor from './editor';
import { createNote , modifyNote } from '@/api/commentAPI.js';


export default {
    components: {
        Editor
    },
    data(){
        return {
            html: '',
        }
    },
    inject: ['state','mutations'],
    props: {
        initNoteHTML: String,
        visible: Boolean,
        file: Object
    },
    watch: {
        initNoteHTML: {
            handler(val){
                this.$nextTick(() => {
                    this.html = this.initNoteHTML;      //  editor初始化的值
                    this.$refs.editor.editor && this.$refs.editor.editor.html(this.initNoteHTML);       //  正在新增的时候点击编辑
                })
            },
            immediate: true
        }
    },
    methods: {
        exit(){
            this.$emit('update:file',null);     //  清除正在编辑的笔录文件
            this.$emit('update:visible',false);
        },
        save(){
            let fileName = this.html.match(/>(阅卷笔录.*)<\//)[1];
            let params = {
                caseId: this.state.caseInfo.caseId,
                fileName: fileName,
                html: this.html,
                fileId: this.file && this.file.id
            }
            return new Promise((resolve,reject) => {
                if(this.file){
                    modifyNote(params).then(res => {
                        this.$message.success('笔录编辑成功!');
                        this.resetData(res);
                        this.exit();
                        resolve();
                    })
                }else{
                    createNote(params).then(res => {
                        this.$message.success('笔录新增成功!');
                        this.resetData(res);
                        this.exit();
                        resolve();
                    })
    
                }

            })
            
        },
        resetData(target){
            let file = target;
            file.filePath += `?x=${this.state.id++}`;
            // console.log(this.state.vueComData.leftTree);
            this.mutations.recursion(this.state.dirList,dir => {        //  首页删除
                if(dir.name === '阅卷笔录'){
                    if(file){
                        let index = dir.children.findIndex(item => item.id === file.fileId);
                        let initFile = this.mutations.fileInit(file,dir);
                        // console.log(initFile);
                        if(index === -1){
                            dir.children.push(initFile);
                        }else{
                            dir.children.splice(index,1);
                            dir.children.splice(index,0,initFile);
                        }
                        this.mutations.globalOrder();
                    }
                    this.$parent.leftInit();
                    this.$nextTick(() => {
                        this.state.vueComData.leftTree.$refs.dirTree.filter();      //  首页左侧树不更新(阅卷笔录不更新)
                    })
                    return true;
                }
            })
            
        }
    },
    
}
</script>

<style lang="less" scoped>
.edit-note{
    height: 100%;
    position: relative;
    .title{
        height: 32px;
        position: fixed;
        top: 20px;
        right: 100px;
    }
    .editor-container{
        height: 100%;
    }
}
</style>