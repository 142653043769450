<template>
    <div class="file-list">
        <TopBtn></TopBtn>
        <div class="file-list-content">
            <div class="header">
                <el-checkbox v-model="allSelected" @change="selectAllClick">全选</el-checkbox>
                <div class="header-icon">
                    <el-select v-if="viewModel === 'BatchView'" v-model="isRead" size="mini" style="width: 100px;margin-right:5px;">
                        <el-option label="全部材料" :value="true"></el-option>
                        <el-option label="全部未读" :value="false"></el-option>
                    </el-select>
                    <el-input size="mini" class="el-input--inline" v-model="fileListState.keyword" placeholder="请输入关键词搜索" suffix-icon="el-icon-search"></el-input>
                    <el-dropdown trigger="click" title="排序方式" @command="fileOrder">
                        <i class="iconfont iconpaixu"></i>
                        <el-dropdown-menu>
                            <el-dropdown-item command="default"><i class="el-icon-check" v-show="fileListState.orderRule === 'default'"></i>默认排序</el-dropdown-item>
                            <el-dropdown-item command="date"><i class="el-icon-check" v-show="fileListState.orderRule === 'date'"></i>按修改日期排序</el-dropdown-item>
                            <el-dropdown-item command="name"><i class="el-icon-check" v-show="fileListState.orderRule === 'name'"></i>按名称排序</el-dropdown-item>
                            <el-dropdown-item command="size"><i class="el-icon-check" v-show="fileListState.orderRule === 'size'"></i>按大小排序</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <i class="iconfont iconyanjing" title="护眼模式" @click="state.protectionEye = !state.protectionEye"></i>
                    <!-- <i class="iconfont iconpici" title="批次视图" @click="setViewModel('BatchView')" :class="{'active': viewModel === 'BatchView'}"></i> -->
                    <i class="iconfont iconshijianzhou" title="轴线视图" @click="setViewModel('AxisView')" :class="{'active': viewModel === 'AxisView'}"></i>
                    <i class="iconfont iconego-menu" title="列表视图" @click="setViewModel('ListView')" :class="{'active': viewModel === 'ListView'}"></i>
                    <i class="iconfont icongrid" title="网格视图" @click="setViewModel('GridView')" :class="{'active': viewModel === 'GridView'}"></i>
                </div>
            </div>
            <div class="main" ref="main" @scroll="mainScroll">
                <component 
                    ref="viewCom"
                    v-if="!currentDir.isLocal || viewModel === 'AxisView' || viewModel === 'BatchView'" 
                    :is="viewModel"
                    @selectChange="selectChange"
                    :keyword="fileListState.keyword"
                    :orderFun="orderFun"
                    :isRead="isRead"
                ></component>
                <SpecModel v-else></SpecModel>
            </div>
            <div class="footer">
                <span>已全部加载，共{{ totalList.length }}个,已选中{{ selectTotal }}个</span>
                <!-- <span class="prompt" v-if="state.configs.classifyFile && currentDir && currentDir.type === 8">备注：待分类区内材料已默认开启自动归目</span> -->
                <div class="footer-slider">
                    <Slider :value="imgScale" :min="20" @input="imgScaleChange"></Slider>
                    <span>{{imgScale.toFixed(2) + '%'}}</span>
                </div>
            </div>
            <TurnPage v-if="!(currentDir.dataType === 'file' && currentDir.fileType === 'application/pdf')" ref="turnPage" @prvePage="prvePage" @nextPage="nextPage" />
        </div>
                
    </div>
</template>

<script>
import TopBtn from './topBtn';

import GridView from './gridView';
import ListView from './ListView';
import AxisView from './axisView';
import BatchView from './batchView';
import SpecModel from './specModel';

import Slider from '@/components/slider';
import TurnPage from './TurnPage.vue';


export default {
    components: {
        TopBtn,
        GridView,ListView,AxisView,BatchView,
        Slider,
        SpecModel,
        TurnPage
    },
    provide() {
        return {
            fileListState: this.fileListState,
            orderFun: this.orderFun
        }
    },
    data() {
        return {
            allSelected: false,
            fileListState: {
                orderRule: 'default',
                keyword: '',
                maxWidth: 0,
                maxHeight: 0,
                imgWidth: 0,
                imgHeight: 0,
                fileContainerWidth: 0,
                fileContainerHeight: 0,
            },
            isRead: true
        };
    },
    mounted(){
        this.initFileListState();
        window.onresize = () => {
            this.initFileListState();
        }
    },
    inject: ['state','mutations'],
    computed: {
        currentDir(){
            return this.state.currentDir || {};
        },
        fileList(){
            // return this.currentDir.fileList || [];
            return this.currentDir.children && this.currentDir.children.filter(item => item.dataType === 'file') || [];
        },
        dirObj(){
            return this.state.dirObj;
        },
        activeFile(){
            return this.state.activeFile;
        },
        imgScale(){
            return this.$store.getters.imgScale;
        },
        totalList(){     //  当前视图文件总数
            if(this.viewModel === 'AxisView'){
                return this.mutations.getAllFiles(true).filter(item => item.name.includes(this.fileListState.keyword));
            }else if(this.viewModel === 'BatchView'){
                return this.mutations.getAllFiles(false).filter(item => item.name.includes(this.fileListState.keyword));
            }else{
                return this.currentDir.children && this.currentDir.children.filter(item => item.name.includes(this.fileListState.keyword) && !item.isLocal) || [];
            }
        },
        selectTotal(){
            return this.totalList.filter(item => item.selected).length;
        },
        viewModel(){
            return this.state.viewModel;
        },
        orderFun(){
            const type = this.fileListState.orderRule;
            let obj = {
                'default': (a,b) => {
                    if(a.dataType === 'file'){
                        return a.fileIndex - b.fileIndex;
                    }else{
                        return a.sn - b.sn;
                    }
                },
                'date': (a,b) => {
                    let strA = a.updateTime.replace(/[:-\s]/g,'')
                    let strB = b.updateTime.replace(/[:-\s]/g,'')
                    return strA - strB;
                },
                'name': (a,b) => a.name.localeCompare(b.name),
                'size': (a,b) => a.fileSize - b.fileSize,
            }
            return obj[type];
        },
    },
    watch: {
        viewModel: {  //  模式切换
            handler(name){
                this.allSelected = false;
                if(name === 'GridView' || name === 'AxisView'){
                    this.selectReset();
                }else{
                    this.resetGridSelect(this.currentDir);
                }
            }
        },
        currentDir: {
            handler(val){
                if(this.viewModel === 'GridView' || this.viewModel === 'ListView'){
                    this.allSelected = false;
                }
            }
        },
        'state.protectionEye': {
            handler(val){
                if(val){
                    this.$refs.main.style.backgroundColor = 'rgb(235, 243, 233)';
                }else{
                    this.$refs.main.style.backgroundColor = '';
                }
            }
        },
    },
    methods: {
        setViewModel(name){     //  切换视图
            this.mutations.setViewModel(name);
        },
        imgScaleChange(val){
            this.$store.dispatch('common/setSettingKey',{
                key: 'imgScale',
                value: val
            })
            this.initFileListState();
        },
        initFileListState(){        //  设置图片大小及部分参数
            this.fileListState.maxWidth = this.$refs.main.offsetWidth - 2;      //  左右1px的边框,
            this.fileListState.maxHeight = this.$refs.main.offsetHeight;
            this.fileListState.imgWidth = this.fileListState.maxHeight * this.imgScale / 100 * 0.7;
            this.fileListState.imgHeight = this.fileListState.maxHeight * this.imgScale / 100;
            //  padding 15 25 margin 15 20 border 1 , margin-10 p-28
            this.fileListState.fileContainerWidth = this.fileListState.imgWidth + 92;
            this.fileListState.fileContainerHeight = this.fileListState.imgHeight + 62 + 38;
        },
        selectReset(){
            Object.keys(this.dirObj).forEach(key => {
                let dir = this.dirObj[key];
                dir.fileList && dir.fileList.forEach(file => {
                    file.selected = false;
                })
            })
        },
        resetGridSelect(data){
            console.log(data);
            if(!data) return;
            data.children && data.children.forEach(item => {
                this.$set(item,'selected',false);
            });
            data.fileList && data.fileList.forEach(item => {
                this.$set(item,'selected',false);
            })
        },
        selectChange(flag){
            this.allSelected = flag;
        },
        selectAllClick(val){
            // this.allSelected = !this.allSelected;
            if(this.viewModel === 'GridView' || this.viewModel === 'ListView'){
                this.$refs.viewCom.dataList.forEach(item => item.selected = val);
            }else if(this.viewModel === 'AxisView' || this.viewModel === 'BatchView'){
                //  轴线视图,批次视图
                this.$refs.viewCom.$children.forEach(com => com.checkboxChange(val));
            }
        },
        fileOrder(rule){
            this.fileListState.orderRule = rule;
            return;
        },
        mainScroll(event){
            this.state.mainScrollTop = this.$refs.main.children[0].scrollTop;
            if(this.state.viewModel === 'AxisView'){
                let container = this.$refs.viewCom;
                let mainClient = this.$refs.main.getBoundingClientRect();
                let mainTop = mainClient.top;
                let mainHeight = mainClient.height;
                
                this.mutations.recursion(container.$children,item => {
                    if(item.$options.name === 'FileModel'){
                        let clientObj = item.$el.getBoundingClientRect();
                        // console.log(clientObj);
                        const top = clientObj.top - mainTop;   //  元素距离容器顶部的距离
                        const height = clientObj.height;
                        let flagTop = (top + height) / 2;
                        if(flagTop >= mainHeight * 0.25 && flagTop <= mainHeight * 0.75){   //  在中间区域
                            const file = item.file;
                            if(file.isLocal){
                                this.state.treeId = file.pid;
                            }else{
                                this.state.treeId = file.id;
                            }
                            return true;
                        }
                    }
                },'$children')

            }
        },
        prvePage(){
            this.$refs.main.scrollTop -= 643; 
            this.$refs.main.children[0].scrollTop -= 643; 
        },
        nextPage(){
            this.$refs.main.scrollTop += 643; 
            this.$refs.main.children[0].scrollTop += 643; 
        },
    },
};
</script>

<style lang="less" scoped>
.file-list {
    height: 100%;
    margin: 0 20px;
    .file-list-content{
        height: calc(100% - 40px);
        padding-top: 20px;
        box-sizing: border-box;
        position: relative;
        .header{
            height: 40px;
            box-sizing: border-box;
            padding: 0 20px;
            background-color: #EDEDED;
            border: 1px solid #ccc;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .header-icon{
                display: flex;
                align-items: center;
                font-size: 16px;
                i{
                    margin-left: 20px;
                    cursor: pointer;
                }
                i.active{
                    color: #1976D2;
                }
                i:hover{
                    color: #1976D2;
                }
            }
        }
        .main{
            height: calc(100% - 80px);
            background-color: #fff;
            border-left: 1px solid #ccc;
            border-right: 1px solid #ccc;
            overflow: auto;
            position: relative;
            .goback{
                padding: 10px 0 20px 10px;
                color: #1976D2;
                cursor: pointer;
            }
        }
        .footer{
            border: 1px solid #ccc;
            height: 40px;
            line-height: 40px;
            box-sizing: border-box;
            background-color: #EDEDED;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            .prompt{
                color: #666;
            }
            .footer-slider{
                user-select: none;
                width: 280px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
    
    .el-input--inline {
        display: inline-block;
        width: auto;
    }
}
</style>
