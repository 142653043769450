import { render, staticRenderFns } from "./CoverBack.vue?vue&type=template&id=0e61b138&scoped=true&"
import script from "./CoverBack.vue?vue&type=script&lang=js&"
export * from "./CoverBack.vue?vue&type=script&lang=js&"
import style0 from "./CoverBack.vue?vue&type=style&index=0&id=0e61b138&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e61b138",
  null
  
)

export default component.exports