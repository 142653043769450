<template>
    <div class="select-file">
        <div class="left">
            <el-input style="margin-bottom:20px;" size="small" v-model="searchText" @input="keywordChange" placeholder="请输入关键字"></el-input>
            <div class="left-tree">
                <el-tree
                    :data="dirList"
                    show-checkbox
                    ref="tree"
                    :props="{
                        label: 'name',
                        disabled: (data) => data.isLocal
                    }"
                    node-key="id"
                    @check-change="checkChange"
                    :filter-node-method="filterNode"
                    :default-expanded-keys="defaultExpandKeys"
                    :default-checked-keys="defaultCheckedKeys">
                    <span class="tree-slot" :title="data.name"  slot-scope="{ data }">
                        <span class="tree-icon">
                            <img :src="mutations.treeIcon(data)" alt="">
                        </span>
                        <span class="tree-text" ref="treeText">
                            <span class="content">{{data.name}}</span>
                            <span class="page" v-if="data.dataType === 'dir'">{{dirCount(data)}}</span>
                        </span>
                        
                        <span class="count" v-if="data.dataType === 'file'" ref="treeText">{{fileGlobalIndex(data)}}</span>
                    </span>
                </el-tree>
            </div>
        </div>
        <div class="right">
            <div class="status">
                <span>已选{{selectList.length}}个</span>
                <button size="mini" type="text" style="color:#FF2525;" @click="clean">清空</button>
            </div>
            <div class="list">
                <div class="item" v-for="data in selectList" :key="data.id">
                    <span class="tree-slot" :title="data.name" >
                        <span class="tree-icon">
                            <img :src="mutations.treeIcon(data)" alt="">
                        </span>
                        <span class="tree-text" ref="treeText">
                            <span class="content">{{data.name}}</span>
                            <i class="el-icon-circle-close" @click="delItem(data)"></i>
                        </span>
                    </span>
                </div>
            </div>
            <div class="btn-group">
                <el-button size="small" @click="clear">取消</el-button>
                <el-button size="small" type="primary" @click="save">确认</el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            searchText: '',
            selectList: [],
            defaultExpandKeys: [],
            defaultCheckedKeys: [],
        }
    },
    inject: ['volumeView','mutations','state','textExtract'],
    computed: {
        dirList(){
            return this.volumeView.dirList;
        },
        caseId(){
            return this.state.caseInfo.caseId;
        }
    },
    methods: {
        dirCount(data){
            let text = '';
            if(data.isLocal){
                return;
            }else{
                if(data.dataType === 'dir'){
                    let num;
                    switch(data.type){
                        case 1:
                            num = data.children.filter(item => item.type === 2).length;
                            text = num ? `${num}册` : ``;
                        break;
                        case 2:
                        case 4:
                        case 8:
                            num = this.mutations.getVolumeAllFiles(data.children).length;
                            text = num ? `${num}页` : ``;
                        break;
                    }
                }
                
            }
            return text;
        },
        fileGlobalIndex(data){
            let text = '';
            if(data.pageNum === 1){
                text = `${data.globalIndex}`;
            }else{
                text = `${data.globalIndex}~${data.globalIndex + data.pageNum - 1}`;
            }
            return text;
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        keywordChange(val){
            this.$refs.tree.filter(val);
        },
        checkChange(){
            const nodes = this.$refs.tree.getCheckedNodes();
            this.selectList = nodes.filter(item => item.dataType === 'file');
        },
        delItem(data){
            this.selectList.forEach(file => {
                this.$refs.tree.setChecked(file.id,false);
            })
            this.selectList.splice(this.selectList.indexOf(data),1);
            this.selectList.forEach(file => {
                this.$refs.tree.setChecked(file.id,true);
            })
        },
        clean(){
            this.selectList.forEach(file => {
                this.$refs.tree.setChecked(file.id,false);
            })
            this.selectList = [];
        },
        clear(){
            this.$emit('clear');
        },
        save(){
            if(this.selectList.length){
                const ids = Array.from(new Set(this.selectList.map(item => item.id)));
                
                this.$emit('selectChange',ids);
                this.clear();
            }else{
                this.$message.warning('请选择文件!');
            }
        },
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
.select-file{
    display: flex;
    background-color: #fff;
    height: 600px;
    .left{
        width: 50%;
        height: 100%;
        box-sizing: border-box;
        padding: 20px 30px;
        border-right: 1px solid #E6E6E6;
        .left-tree{
            height: calc(100% - 72px);
            overflow: auto;
        }
    }
    .right{
        flex-grow: 1;
        height: 100%;
        .status{
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 30px;
        }
        .list{
            padding: 0 30px;
            box-sizing: border-box;
            height: calc(100% - 124px);
            overflow: auto;
            .item{
                height: 30px;
                display: flex;
                align-items: center;
                i{
                    cursor: pointer;
                }
            }
        }
        .btn-group{
            width: 100%;
            height: 64px;
            background-color: #F2F2F2;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
.tree-slot{
    width: 100%;
    height: 100%;
    padding: 2px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid transparent;
    box-sizing: border-box;
    .tree-icon{
        margin-right: 5px;
        img{
            width: 14px;
            height: 14px;
            vertical-align: middle;
        }
    }
    .tree-text{
        flex-grow: 1;
        width: 0;
        display: flex;
        align-items: center;
        .content{
            overflow: hidden;
            white-space: nowrap;
            text-overflow:ellipsis;
            margin-right: auto;
        }
        .page{
            flex-shrink: 0;
            margin-left: 10px;
            color: #ccc;
        }
    }
    .count{
        margin-right: 5px;
        color: #ccc;
    }
    .tree-suffix-icon{
        height: 18px;
        img{
            height: 100%;
        }
    }
}
</style>