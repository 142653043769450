<template>
    <div class="file-container">
        <img ref="img" :src="dataurl" alt="">
    </div>
</template>

<script>
import axios from 'axios';
import { htmlToCanvas } from '@/utils/htmlToCanvas.js';
import { htmlToImg } from '@/utils/tool.js';

export default {
    props: {
        type: String,
        url: String,
    },
    data(){
        return {
            dataurl: ''
        }
    },
    mounted(){
        const img = this.$refs.img;
        img.onload = () => {
            if(img.naturalWidth / img.naturalHeight > this.$el.offsetWidth / this.$el.offsetHeight){
                img.style.width = '100%';
                img.style.height = 'auto';
            }else{
                img.style.height = '100%';
                img.style.width = 'auto';
            }
        }
    },
    watch: {
        url: {
            handler(val){
                if(val){
                    if(this.type === 'text/html'){
                        axios.get(this.url).then(res => {
                            const html = res.data;
                            const container = document.createElement('div');
                            // container.classList.add('ke-content');
                            container.style.width = '794px';
                            container.style.height = '1123px';
                            container.style.padding = '128px 134px';
                            container.style.boxSizing = 'border-box';
                            container.innerHTML = html;
                            const styles = `
                                html {margin:0;padding:0;}
                                body {margin:0;padding:5px;}
                                body, td {font:12px/1.5 "sans serif",tahoma,verdana,helvetica;}
                                body, p, div {word-wrap: break-word;}
                                p {margin:5px 0;}
                                table {border-collapse:collapse;}
                                img {border:0;}
                                noscript {display:none;}
                                table.ke-zeroborder td {border:1px dotted #AAA;}

                            `
                            htmlToImg(container).then(res => {
                                this.dataurl = res;
                            })
                            // htmlToCanvas(container,styles).then(dataurl => {
                            //     this.dataurl = canvas.toDataURL('image/png');
                            // })
                        })
                    }else{
                        this.dataurl = this.url;
                    }

                }
            },
            immediate: true
        },
    }
}
</script>

<style lang="less" scoped>
.file-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        width: 100%;
        flex-shrink: 0;
    }
}



</style>