<template>
    <div class="turn-page">
        <div class="prve-page" @click="prvePage">
            <i class="iconfont iconshangjiantou"></i>
        </div>
        <div class="next-page" @click="nextPage">
            <i class="iconfont iconxiajiantou"></i>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        prvePage(){
            this.$emit('prvePage');
        },
        nextPage(){
            this.$emit('nextPage');
        },
    }
}
</script>

<style scoped lang='less'>
.turn-page{
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
.prve-page,.next-page{
    width: 30px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    cursor: pointer;
}
</style>