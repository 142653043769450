<template>
    <div class="tabs" :class="{'reverse': reverse}">
        <ul class="tab-list">
            <li class="tab-item" :class="{'active': currentTab === item,'right': reverse}" v-for="item in tabList" :key="item.value" @click="tabClick(item)">
                <i :class="item.icon"></i>
                <p class="text">{{item.label}}</p>
            </li>
        </ul>
        <div class="tab-content" v-show="contentVisible">
            <div class="title">
                <span>{{currentTab && currentTab.label}}</span>
                <button class="btn iconfont iconclose" @click="closeContent"></button>
            </div>
            <div class="com">
                <BookMark v-if="currentTab && currentTab.value === 0" :dirList="dirList" v-on="$listeners" :currentPage="currentPage"></BookMark>
                <Comment v-if="currentTab && currentTab.value === 1" :currentCommentId="currentCommentId" :commentList="commentList" v-on="$listeners"></Comment>
                <Note v-if="currentTab && currentTab.value === 2" :dirList="dirList" :commentList="commentList" @createNode="$listeners.createNode" v-on="$listeners" :fileList="fileList"></Note>
                <!-- <LatticeBar v-if="currentTab && currentTab.value === 3" :fileList="fileList"></LatticeBar> -->
            </div>
            
        </div>
    </div>
</template>

<script>

import BookMark from './BookMark.vue';
import Comment from './comment';
import Note from './note';
import LatticeBar from './latticeBar';

export default {
    components: {
        BookMark,
        Comment,
        Note,
        LatticeBar
    },
    props: {
        dirList: Array,
        fileList: Array,
        currentPage: {
            type: Number,
            default: 0
        },
        reverse: Boolean,
        currentCommentId: {
            type: String,
            default: ''
        },
        commentList: {
            type: Array,
            default(){
                return [];
            }
        }
    },
    data(){
        return {
            tabList: [
                {
                    label: '目录',
                    value: 0,
                    icon: 'iconfont iconmulu'
                },
                {
                    label: '注释',
                    value: 1,
                    icon: 'iconfont iconzhushi'
                },
                {
                    label: '笔录',
                    value: 2,
                    icon: 'iconfont iconbilu'
                },
                // {
                //     label: '点阵输入',
                //     value: 3,
                //     icon: 'iconfont iconbilu'
                // },
            ],
            currentTab: null,
            contentVisible: true
        }
    },
    mounted(){
        this.currentTab = this.tabList[0];
    },
    methods: {
        tabClick(item){
            this.contentVisible = true;
            this.currentTab = item;
        },
        closeContent(){
            this.contentVisible = false;
        }
    }
}
</script>

<style scoped lang='less'>
.tabs{
    display: flex;
    border-right: 1px solid #E2E4E9;
    .tab-content{
        width: 300px;
        background-color: #fff;
        .title{
            height: 32px;
            background-color: #E6E6E6;
            color: #666;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 15px;
        }
        .com{
            height: calc(100% - 32px);
            overflow: auto;
        }
    }
}
.tabs.reverse{
    flex-direction: row-reverse;
}
.tab-list{
    width: 45px;
    height: 100%;
    background-color: #020202;
    opacity: .5;
    color: #fff;
    overflow: hidden;
    .tab-item{
        margin: 10px 0;
        padding: 5px;
        box-sizing: border-box;
        width: 100%;
        text-align: center;
        cursor: pointer;
        position: relative;
        .text{
            margin-top: 5px;
        }
    }
    .tab-item.active::after{
        content: '';
        display: inline-block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0,-50%);
        border-top: 6px solid #020202;
        border-right: 6px solid #fff;
        border-bottom: 6px solid #020202;
        background-color: #fff;
    }
    .tab-item.active.right::after{
        left: 0;
        right: auto;
        border-left: 6px solid #fff;
        border-right: none;
    }
}

</style>