<template>
    <div class="menu-group">
        <Menu 
            v-for="(item,index) in filterList"
            :data="item"
            :key="index"
            :url="item.url"
            v-hasPermit
            @expand="expand"
            @itemMouseleave="mouseleave"
            @menuClick="menuClick"
            :value="value"></Menu>
    </div>
</template>

<script>
import Menu from './Menu.vue'
import { batchDel } from '@/utils/f.js';

export default {
    components: {
        Menu,
    },
    props: {
        data: Array,
        relation: {
            type: Boolean,
            default: false
        },
        value: [String,Number]
    },
    data(){
        return {
            filterList: [],
            timer: null,
            leaveTimer: null,
        }
    },
    watch: {
        data: {
            handler(val){
                this.filterList = [this.data];
            },
            immediate: true
        }
    },
    methods: {
        expand(data){
            this.timer = setTimeout(() => {
                let currentIndex = this.filterList.findIndex(item => item.find(item => item === data));
                batchDel(this.filterList,(item,index) => index > currentIndex);
                if(data.children && data.children.length){
                    this.filterList.push(data.children);
                }
                this.timer = null;
            },300)
        },
        mouseleave(data){
            if(this.timer){
                clearTimeout(this.timer);
            }
        },
        menuClick(data){
            if(this.relation){
                // this.value = data.value;
                this.$emit('input',data.value);
            }else{
                if(data.children && data.children.length){
                    return;
                }
            }
            
            this.$emit('menuClick',data);
        },
        unknow(data){       //  获取父子级所有选项
            let temp = data;
            let list = [];
            let endIndex = this.filterList.findIndex(item => item.find(el => el === data));     //  最后一级
            // console.log(endIndex);
            for (let index = endIndex; index >= 0 ; index--) {
                list.push(temp);
                if(index - 1 >= 0){
                    const next = this.filterList[index - 1];
                    // console.log(next);
                    temp = next.find(item => item.children && item.children.find(item => item === temp));
                }
            }
            console.log(list);
        }
    },
}
</script>

<style lang="less" scoped>
.menu-group{
    display: flex;
    border-radius: 5px;
}
</style>