import html2canvas from 'html2canvas';
import jsPDF from "jspdf";

export function htmlToImg(container) {
    let cloneNode = container.cloneNode(true);
    cloneNode.style.position = 'fixed';
    cloneNode.style.left = '10000px';
    return new Promise((resolve, reject) => {
        document.body.children.forEach(node => {
            node.setAttribute('data-html2canvas-ignore',true);
        })
        document.body.appendChild(cloneNode);
        html2canvas(cloneNode, { 
            scale: 2,
        }).then(res => {
            document.body.removeChild(cloneNode);
            resolve(res.toDataURL('image/jpeg'));
        })
    })
}

export function dataurlToPDF(dataurlList,options = {}){
    // [595.28,841.89]
    // console.log((new Date()).getTime());
    
    // console.log((new Date()).getTime());

    
    // console.log((new Date()).getTime());
    return new Promise((resolve,reject) => {
        // console.log((new Date()).getTime());
        let containerWidth = 595.28;
        let containerHeight = 841.89;
        options.orientation ? '' : options.orientation = 'p';
        options.unit ? '' : options.unit = 'pt';
        options.compress ? '' : options.compress = false;
        let pdf = new jsPDF(options);
        dataurlList.forEach((dataurl,index) => {
            pdf.addImage(dataurl, 'PNG', 0, 0, containerWidth, containerHeight);
            if(index != dataurlList.length - 1){
                pdf.addPage();
            }
        })
        // console.log((new Date()).getTime());
        let blob = pdf.output('blob');
        // console.log((new Date()).getTime());
        let url = URL.createObjectURL(blob);
        resolve(url);
    })
    
    
    // return url;
}