<template>
    <div class="batch-view" @scroll="scroll">
        <div class="list-container" :style="{'height': `${containerHeight}px`}">
            <BatchItem 
                ref="batchItem"
                v-for="item in batchList" :key="item.id" 
                :keyword="keyword"
                :batch="item"
                @selectFile="selectFile"
                @selectChange="selectChange"
                @toImgView="toImgView"
                :orderFun="orderFun"></BatchItem>
        </div>
    </div>
</template>

<script>
import BatchItem from './BatchItem.vue';

export default {
    name: 'BatchView',
    components: {
        BatchItem,
    },
    props: {
        keyword: String,
        orderFun: Function,
        isRead: Boolean,
    },
    data(){
        return {
            subCaseList: [],
            containerHeight: 0,
        }
    },
    inject: ['state','mutations','fileListState'],
    computed: {
        dataList(){
            let files = [];
            this.batchList.forEach(subCase => {
                subCase.fileList.forEach(file => {
                    files.push(file);
                })
            })
            return files;
        },
        filterFiles(){
            let allFiles = this.mutations.getAllFiles().filter(item => item.name.includes(this.keyword));
            if(!this.isRead){
                return allFiles.filter(item => item.extra && item.extra.readFlag === 0);
            }
            return allFiles;
        },
        ctrlKey(){
            return this.state.keyCode.ctrl;
        },
        shiftKey(){
            return this.state.keyCode.shift;
        },
        batchList(){
            let subCaseList = this.state.subCaseList.map(item => {
                return {
                    id: item.id,
                    subIndex: item.subIndex,
                    source: item.source,
                    uploadUser: item.uploadUser,
                    createTime: item.createTime,
                    fileList: [],
                    dirs: []
                }
            })

            subCaseList.forEach(subCase => {
                const files = this.filterFiles.filter(file => file.subCaseId === subCase.id);
                subCase.fileList.push(...files);

                let dirs = [];
                files.forEach(file => {
                    let dir = this.state.dirObj[file.pid];
                    if(!dirs.find(item => item.id === dir.id)){
                        let newDir = {
                            id: dir.id,
                            pid: dir.pid,
                            name: dir.name,
                            children: []
                        }
                        dirs.push(newDir);
                    }
                    let target = dirs.find(item => item.id === dir.id);
                    target.children.push(file);
                })

                subCase.dirs = dirs;
                
            })

            return subCaseList.filter(item => item.dirs.length);
        },
        col(){
            return Math.floor((this.fileListState.maxWidth - 8 * 2 - 13 * 2) / this.fileListState.fileContainerWidth);
        },
        
    },
    watch: {
        batchList: {
            handler(val){
                this.scrollInit();
            },
            immediate: true
        }
    },
    methods: {
        scrollInit(){
            let height = 0;
            this.batchList.forEach(batch => {
                console.log(batch);
                height += 40 + 19;       //  BatchItem padding 20px 8px   batchItem的title
                batch.dirs.forEach(dir => {
                    height += 21 + 40;       //  AxisItem 轴线title高度 padding 20px 13px
                    dir.col = this.col;
                    dir.row = Math.ceil(dir.children.length / dir.col);
                    height += dir.row * this.fileListState.fileContainerHeight;
                })
            })
            console.log(height);
            this.containerHeight = height;
        },
        scroll(){
            
        },
        toImgView(data){
            this.mutations.toImgView(data,this.dataList);
        },
        selectFile(data){
            this.mutations.selectFile(data,this.dataList);
        },
        selectChange(){
            let flag = this.$refs.batchItem.find(item => !item.allSelected);
            if(flag){
                this.$emit('selectChange',false);
            }else{
                this.$emit('selectChange',true);
            }
        }
    }
}
</script>

<style scoped lang='less'>
.batch-view{
    position: relative;
    height: 100%;
    overflow: auto;
}
</style>