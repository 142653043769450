<template>
    <div class="axis-view" @scroll="scroll">
        <div class="list-container" :style="{'height': `${containerHeight}px`}">
            <div class="axis-view-container" v-for="item in filterDirs" :key="item.id" >
                <AxisItem 
                    ref="axisItem"
                    :dir="item"
                    :keyword="keyword"
                    @selectFile="selectFile"
                    @selectChange="selectChange"
                    @toImgView="toImgView"
                    :orderFun="orderFun"
                    @checkboxChange="checkboxChange"
                    
                ></AxisItem>
            </div>
            
        </div>
    </div>
</template>

<script>
import AxisItem from './AxisItem.vue';
import { throttle , debounce } from '@/utils/f.js';

export default {
    name: 'AxisView',
    components: {
        AxisItem,
    },
    inject: ['state','mutations','fileListState'],
    props: {
        keyword: String,
        orderFun: Function
    },
    data(){
        return {
            containerHeight: 0,
            containerWidth: 0,
            filterDirs: [],
            stopSetTreeId: false,
            lastElWidth: 0,     //  容器大小变化，防止多次变动
        }
    },
    computed: {
        dirList(){
            return this.state.dirList;
        },
        dataList(){
            let files = [];
            this.dirs.forEach(dir => {
                files.push(...dir.children);
            })
            return files;
        },
        dirs(){
            let dirs = [];
            this.mutations.recursion(this.dirList,dir => {
                if(dir.dataType === 'dir'){
                    let newDir = {
                        id: dir.id,
                        pid: dir.pid,
                        name: dir.name,
                        type: dir.type,
                        dataType: dir.dataType,
                        isLocal: dir.isLocal,
                        children: [],
                    }
                    let files = [];
                    if(dir.isLocal){
                        files = dir.fileList;
                    }else{
                        files = dir.children.filter(item => item.dataType === 'file');
                    }
                    files = files.filter(item => item.name.includes(this.keyword)); //  关键字查询
                    if(files.length){
                        newDir.children = files;
                        dirs.push(newDir);
                    }
                }
            })
            return dirs;
        },
        imgScale(){
            return this.$store.getters.imgScale;
        },
        paramsChange(){
            return {
                dirs: this.dirs,
                imgScale: this.imgScale,
            }
        }
    },
    watch: {
        paramsChange: {
            handler(val){
                this.$nextTick(() => {
                    this.lastElWidth = 0;
                    this.$nextTick(() => {
                        this.scrollInit();
                    })
                })
            },
            immediate: true
        },
        'state.currentFile': {
            handler(file){
                let top = 0;
                for (let i = 0; i < this.dirs.length; i++) {
                    const dir = this.dirs[i];
                    // top -= 41;      //  paddingTop和title高度20 + 21
                    let index = dir.children.indexOf(file);
                    if(index != -1){
                        //  找到了
                        const row = Math.ceil((index + 1) / Math.floor(this.containerWidth / this.fileListState.fileContainerWidth));
                        const rowHeight = (row - 1) * this.fileListState.fileContainerHeight;
                        top += rowHeight
                        break;
                    }else{
                        top += dir.row * this.fileListState.fileContainerHeight;
                        top += 61
                    }
                }

                this.stopSetTreeId = true;
                this.$el.scrollTop = top;
            }
        },

    },
    mounted(){
        this.throttleFn = throttle(this.filterDirsFun,50);
    },
    methods:{
        scrollInit(){
            this.initScrollData();
            this.filterDirsFun();
        },
        initScrollData(){       //  计算容器高度,监听容器宽度变化
            if(this.lastElWidth === this.$el.offsetWidth){
                return;
            }
            this.lastElWidth = this.$el.offsetWidth;
            // console.log('initScrollData',this.lastElWidth);
            // containerHeight
            let height = 0;
            for (let i = 0; i < this.dirs.length; i++) {
                const dir = this.dirs[i];
                height += 20 + 21;
                dir.col = Math.floor((this.$el.offsetWidth - 13) / this.fileListState.fileContainerWidth);      //  axis-view-container的padding-left---13px;
                dir.row = Math.ceil(dir.children.length / dir.col);
                height += dir.row * this.fileListState.fileContainerHeight;
                height += 20;
                if(height > this.$el.offsetHeight){
                    this.containerWidth = this.$el.offsetWidth - 17 - 13;        //  有滚动条
                    break;
                }else{
                    this.containerWidth = this.$el.offsetWidth;             //  无滚动条
                }
            }
            height = 0;
            for (let i = 0; i < this.dirs.length; i++) {
                height += 20 + 21;
                const dir = this.dirs[i];
                dir.col = Math.floor(this.containerWidth / this.fileListState.fileContainerWidth);
                dir.row = Math.ceil(dir.children.length / dir.col);
                // console.log(this.containerWidth,this.fileListState.fileContainerWidth);
                // console.log(dir.col);
                height += dir.row * this.fileListState.fileContainerHeight;

                height += 20;
            }
            this.containerHeight = height;
        },
        scroll(){
            this.throttleFn();
            // console.log('scroll:' + this.$el.scrollTop);
        },
        filterDirsFun(){
            // console.log('filterDirsFun');
            this.initScrollData();
            let scrollTop = this.$el && this.$el.scrollTop || 0;
            

            let innerRow = Math.floor(this.fileListState.maxHeight / this.fileListState.fileContainerHeight) + 2;     //  完全容纳最大值+上下2个
            // console.log(innerRow);

            let newDirs = [];
            let flag = true;
            for (let i = 0; i < this.dirs.length; i++) {
                const dir = this.dirs[i];
                let newDir = {
                    ...dir,
                    children: [],
                    paddingTop: 0,
                }
                scrollTop -= 61;
                for (let j = 0; j < dir.row; j++) {
                    scrollTop -= this.fileListState.fileContainerHeight;
                    if(scrollTop > 0){      //  代表不渲染的行
                        newDir.paddingTop += this.fileListState.fileContainerHeight;        //-------------------------- 
                    }else{                  //  需要渲染的行
                        if(innerRow === 0){
                            newDir.paddingBottom += this.fileListState.fileContainerHeight;
                            break;
                        }else{
                            innerRow --;
                            for (let k = 0; k < dir.col; k++) {
                                if(dir.children[j * dir.col + k]){
                                    let file = dir.children[j * dir.col + k];
                                    newDir.children.push(file);
                                }
                            }
                        }
                    }
                }
                newDirs.push(newDir);

                // console.log(this.stopSetTreeId);
                if(!this.stopSetTreeId){
                    if(flag && newDir.children.length){

                        if(newDir.isLocal){
                            this.state.treeId = newDir.children[0].pid;
                        }else{
                            this.state.treeId = newDir.children[0].id;
                        }
                        flag = false;
                    }
                }
                
            }
            // console.log(newDirs);
            this.filterDirs = newDirs;
            
            if(this.stopSetTreeId){             //  防止点击左侧文件或目录时重置treeId
                this.stopSetTreeId = false;
            }
        },
        checkboxChange(dir,val){
            const target = this.dirs.find(item => item.id === dir.id);
            target.children.forEach(file => file.selected = val);
        },
        toImgView(data){
            this.mutations.toImgView(data,this.dataList);
        },
        selectFile(data){
            this.mutations.selectFile(data,this.dataList);
        },
        selectChange(){
            let flag = this.$refs.axisItem.find(item => !item.allSelected);
            if(flag){
                this.$emit('selectChange',false);
            }else{
                this.$emit('selectChange',true);
            }
        }
    }
}
</script>

<style scoped lang='less'>
.axis-view{
    position: relative;
    height: 100%;
    overflow: auto;
    .list-container{
        box-sizing: border-box;
        .axis-view-container{
            padding: 20px 0 20px 13px;
        }
    }
}
</style>