<template>
    <div class="rename-container">
        <div class="flex">
            <p>命名方式：</p>
            <div>
                <el-radio-group v-model="radio">
                    <el-radio :label="0">智能命名</el-radio>
                    <el-radio :label="1">逐个命名</el-radio>
                </el-radio-group>
            </div>
        </div>

        <div class="zhineng" v-if="radio === 0">
            <div class="flex">
                <p>命名规则：</p>
                <div>
                    <el-select v-model="form.rule" size="small">
                        <el-option label="默认排序" :value="0"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="flex">
                <p>内容标题：</p>
                <div>
                    <el-input size="small" v-model="form.name" placeholder="请输入新名称"></el-input>
                </div>
            </div>
            <div class="flex prompt">
                <p>温馨提示：</p>
                <div>
                    <p>1. 目录或文件标题会根据选择的规则进行自动命名</p>
                    <p>2. 命名范例：起诉状1.jpg，起诉状1.jpg，起诉状1.jpg...</p>
                </div>
            </div>
        </div>

        <div class="alone" v-if="radio === 1">
            <table class="table" border="1">
                <tr class="head">
                    <td style="width: 326px;">原文件名</td>
                    <td>内容标题</td>
                </tr>
            </table>
            <div class="scroll">
                <table class="table" border="1">
                    <tr class="row" v-for="item in renameFiles" :key="item.fileId">
                        <td>
                            <span class="label" @click="openFile(item)">{{item.fileName}}</span>
                        </td>
                        <td>
                            <div class="input">
                                <el-input size="small" v-model="item.newName" placeholder="请输入新标题，若不输入则保留原文件名"></el-input>
                                <span class="suffix">{{`.${item.suffix}`}}</span>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="footer">
            <el-button size="small" plain @click="cancel">取消</el-button>
            <el-button size="small" type="primary" @click="submit">确定</el-button>
        </div>
    </div>
</template>

<script>
import { fileRename } from '@/api/fileAPI.js';

export default {
    inject: ['state'],
    props: {
        renameFiles: {
            type: Array,
            default(){
                return [];
            }
        }
    },
    data(){
        return {
            radio: 0,
            form: {
                rule: 0,
                name: ''
            },
        }
    },
    methods:{
        openFile(file){
            window.open(file.filePath);
        },
        submit(){
            let params = {
                caseId: this.state.caseInfo.caseId,
                fileInfos: []
            }
            if(this.radio === 0){
                if(!this.form.name){
                    this.$message.warning('请输入新名称!');
                    return;
                }
                this.renameFiles.forEach((file,index) => {
                    params.fileInfos.push({
                        id: file.fileId,
                        name: this.form.name + `${index + 1}.` + file.suffix
                    })
                })
            }else{
                this.renameFiles.forEach((file,index) => {
                    params.fileInfos.push({
                        id: file.fileId,
                        name: file.newName ? (file.newName + '.' + file.suffix) : file.fileName
                    })
                })
            }
            console.log(params);
            fileRename(params).then(res => {
                this.$message.success('文件批量重命名成功!');
                res.forEach(file => {
                    this.state.dirObj[file.fileId].name = file.fileName;
                    this.state.dirObj[file.fileId].updateTime = file.updateTime;
                    this.cancel();
                })
            })
        },
        cancel(){
            this.$emit('update:visible',false);
            this.form.rule = 0;
            this.form.name = '';
            this.radio = 0;
        }
    }
}
</script>

<style scoped lang='less'>
.rename-container{
    padding: 0 16px;
}
.flex{
    display: flex;
    align-items: center;
    height: 32px;
    margin-bottom: 24px;
    p{
        margin-right: 20px;
    }
    > div{
        width: 400px;
        /deep/ .el-select{
            width: 100%;
        }
        /deep/ .el-radio{
            margin-right: 60px;
        }
    }
}
.flex.prompt{
    align-items: flex-start;
}

.alone{
    .scroll{
        max-height: 483px;
        overflow: auto;
    }
    .table{
        width: 100%;
        border-collapse: collapse;
        font-size: 14px;
        box-sizing: border-box;
        border-color: #DCDFE6;
        tr{
            td:first-child{
                width: 326px;
            }
        }
        td{
            padding: 7px 20px;
            box-sizing: border-box;
        }
        .head{
            height: 32px;
            background-color: #F5F5F5;
        }
        .row{
            height: 48px;
            .label{
                color: #1990FC;
                overflow: hidden;
                cursor: pointer;
            }
            .input{
                display: flex;
                align-items: center;
                .suffix{
                    margin-left: 10px;
                    flex-shrink: 0;
                }
            }
        }
    }
}

.footer{
    margin-top: 40px;
    display: flex;
    justify-content: center;
    /deep/ .el-button{
        width: 80px;
    }
}
</style>