<template>
    <div class="line-stretch" @mousedown="mousedown">
        
    </div>
</template>

<script>
import { myMove } from '@/utils/f.js';

export default {
    data(){
        return {
            startX: 0,
            offsetX: 0
        }
    },
    props: {
        position: {
            type: String,
            default: 'right'
        }
    },
    watch: {
        position: {
            handler(val){
                this.$nextTick(() => {
                    if(val === 'left'){
                        this.$el.style.left = '-2px';
                    }
                })
            },
            immediate: true
        }
    },
    methods: {
        mousedown(event){
            this.startX = event.pageX;
            this.offsetX = this.$el.parentNode.offsetWidth;
            event.preventDefault();
            myMove(mouseoverEvent => {
                let width;
                switch(this.position){
                    case 'right': width = this.offsetX + mouseoverEvent.pageX - this.startX; break;
                    case 'left': width = this.offsetX - mouseoverEvent.pageX + this.startX; break;
                }
                if(width < 0){
                    this.$el.parentNode.style.width = '0';
                }else{
                    this.$el.parentNode.style.width = width + 'px';
                }
            })
        },
    }
}
</script>

<style scoped lang='less'>
.line-stretch{
    width: 5px;
    height: 100%;
    position: absolute;
    top: 0;
    right: -2px;
    opacity: 0;
    // background-color: #ccc;
}
.line-stretch{
    cursor: w-resize;
}
</style>