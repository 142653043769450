import service from '@/utils/request';

//  文件列表
export function extractList(params){
    return service.get('/caseinfo/nlp/list',{
        params
    })
}

//  要素提取
export function extract(data){
    return service.post('/caseinfo/nlp/extract',data,{
        headers: {'Content-Type': 'multipart/form-data'},
    })
}
//  拼接文件为一份PDF
export function concatPDF(params){
    return service.get('/caseinfo/nlp/get-pdf-by-file-ids',{
        params,
        responseType: 'blob',
    })
}
//  编辑保存
export function saveExtract(data){
    return service.post('/caseinfo/nlp/save',data,{
        // headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    })
}

//  查询文件类型列表
export function queryType(params){
    return service.get('/caseinfo/nlp/list-file-type',{
        params,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    })
}

//  删除文件
export function deleteFile(data){
    return service.post('/caseinfo/nlp/delete',data,{
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    })
}

