import { render, staticRenderFns } from "./VolumeDir.vue?vue&type=template&id=2dad4372&scoped=true&"
import script from "./VolumeDir.vue?vue&type=script&lang=js&"
export * from "./VolumeDir.vue?vue&type=script&lang=js&"
import style0 from "./VolumeDir.vue?vue&type=style&index=0&id=2dad4372&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dad4372",
  null
  
)

export default component.exports