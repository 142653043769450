<template>
    <div>
        
    </div>
</template>

<script>
function  loadScript(url,callback){
    let script = document.createElement('script');
    if (script.readyState) { // IE
        script.onreadystatechange = function () {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
                script.onreadystatechange = null;
                callback();
            }
        } 
    } else { // 其他浏览器
        script.onload = function () {
            callback();
        }
    }
    script.src = url;
    // document.getElementsTagName('head')[0].appendChild('script');
    document.head.appendChild(script);
}

export default {
    data() {
        return {
            DWObject: null,
            imgList: [],
            index: 0,
        };  
    },
    mounted() {
        if(window.Dynamsoft){
            this.$nextTick(() => {
                this.DWObject = Dynamsoft.WebTwainEnv.GetWebTwain(
                    "dwtcontrolContainer"
                );
            })
        }
    },
    created(){
        
    },
    watch: {
        DWObject: {
            handler(){
                this.DWObject.RegisterEvent('OnPostTransfer', this.sideBarOnClick);
                this.DWObject.RemoveAllImages();
            }
        }
    },
    methods: {
        readyLoadScan(){
            if(!window.Dynamsoft){
                loadScript('/Resources/dynamsoft.webtwain.initiate.js',() => {
                    loadScript('/Resources/dynamsoft.webtwain.config.js',() => {
                        Dynamsoft.WebTwainEnv.RegisterEvent('OnWebTwainReady', () => {
                            this.DWObject = Dynamsoft.WebTwainEnv.GetWebTwain(
                                "dwtcontrolContainer"
                            );
                            this.AcquireImage();
                        });
                    })
                })
            }else{
                this.AcquireImage();
            }
        },
        sideBarOnClick(){
            var src = this.DWObject.GetImageURL(this.index);
            this.index ++;
            this.$emit('scanItem',src);
        },
        AcquireImage() {
            if (this.DWObject) {
                if (Dynamsoft.Lib.env.bMobile) {
                    this.DWObject.DWObject.LoadImageEx(
                        "",
                        5,
                        function () {
                            console.log("success");
                        },
                        function (errCode, error) {
                            console.log(error);
                        }
                    );
                } else {
                    this.DWObject.SelectSource();
                    this.DWObject.CloseSource();
                    this.DWObject.OpenSource();
                    this.DWObject.IfDisableSourceAfterAcquire = true;
                    this.DWObject.AcquireImage();
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
</style>