import { render, staticRenderFns } from "./BtnGroup.vue?vue&type=template&id=926f66cc&scoped=true&"
import script from "./BtnGroup.vue?vue&type=script&lang=js&"
export * from "./BtnGroup.vue?vue&type=script&lang=js&"
import style0 from "./BtnGroup.vue?vue&type=style&index=0&id=926f66cc&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "926f66cc",
  null
  
)

export default component.exports