<template>
    <div class="case-reading">
        <TopBar />
        <BaseInfo />
        <!-- <Tabbar v-model="currentTab" /> -->
        <MainContent v-if="xxf" :currentTab="currentTab" />
        <div class="download-progress">
            <Progress :data="downloadList" :visible="visible"></Progress>
        </div>
    </div>
</template>

<script>
import TopBar from '@/layout/TopBar';
import BaseInfo from './BaseInfo';
// import Tabbar from './Tabbar';
import MainContent from './mainContent';

import { state , mutations } from '@/utils/store/caseReading.js';
import { getCaseInfo , getSubCase } from '@/api/caseReading.js';
import { getConfigs , hasPermit } from '@/api/commonAPI.js';

import { fileRead } from '@/api/fileAPI.js';
import Vue from 'vue';
// import { createSocket } from '@/utils/socket.js';
import Progress from './Progress.vue';

import { createSocket } from '@/utils/f.js';

export default {
    components: {
        TopBar,
        BaseInfo,
        // Tabbar,
        MainContent,
        Progress
    },
    provide(){
        return {
            state: state,
            mutations: mutations
        }
    },
    data(){
        return {
            xxf: false,
            state: state,
            currentTab: 'Dossier',
            downloadList: [],
            visible: false,
            progress: 0
        }
    },
    mounted(){
        document.addEventListener('keydown',this.keydown);
        document.addEventListener('keyup',this.keydown);
        // this.getConfigs();

        
    },
    destroyed(){
        document.removeEventListener('keydown',this.keydown);
        document.removeEventListener('keyup',this.keydown);
    },
    watch: {
        $route:{
            handler(to,form){
                if(form && JSON.stringify(form.query) === JSON.stringify(to.query)){

                }else{
                    let caseId = this.$route.query.caseId;
                    let caseCode = this.$route.query.caseCode;
                    let caseName = this.$route.query.caseName;
                    if(!caseId && !caseCode && !caseName){
                        this.$message.error('未携带案件ID或案件标识或案号！');
                        return;
                    }
                    // // 859854407844900
                    getCaseInfo({caseId: caseId,caseCode: caseCode,caseName: decodeURIComponent(caseName)}).then(caseInfo => {
                        mutations.setCaseInfo(caseInfo);
                        document.title = caseInfo.caseName;
                        createSocket(`caseinfo/socket/changed/${caseInfo.caseId}`,res => {
                            let data = JSON.parse(res.data);
                            let message = data.message;
                            this.$message.success(message);
                        });

                        hasPermit({ caseId: state.caseInfo.caseId, url: '/caseinfo/file/mark-read' }).then(res => {
                            // console.log(res);
                            if(res === true){
                                state.hasReadPermit = true;
                            }else{

                            }
                            this.directive();
                        }).finally(() => {
                            
                            this.reload();
                        })
                        // getSubCase({ caseId: state.caseInfo.caseId }).then(list => {     //  暂时隐藏
                        //     mutations.setSubCaseList(list);
                        // })

                        // createSocket(res => {
                        //     console.log('download-event');
                        //     this.visible = true;
                        //     const data = JSON.parse(res.data);
                        //     console.log(data);
                        //     const download = {
                        //         progress: Math.floor(data.index / data.total * 100),
                        //         id: data.uuid,
                        //     }

                        //     const target = this.downloadList.find(item => item.id === data.uuid);
                        //     if(target){
                        //         target.progress = download.progress;
                        //         if(download.progress === 100){
                        //             setTimeout(() => {
                        //                 this.downloadList.splice(this.downloadList.indexOf(target),1);
                        //             },1000)
                        //         }
                        //     }else{        
                        //         this.downloadList.push(download);
                        //     }

                        // },`caseinfo/socket/file/${this.$store.getters.userInfo.id}`)
                    })
                   
                    
                }
            },
            immediate: true
        }
    },
    methods: {
        reload(){
            this.xxf = false;
            this.$nextTick(() => {
                this.xxf = true;
            })
        },
        keydown(ev){
            state.keyCode.ctrl = ev.ctrlKey;
            state.keyCode.shift = ev.shiftKey;
        },
        directive(){
            function isView(dom,scale){
                let container;
                let tempNode = dom;
                let top = 0;
                while(tempNode.parentNode){
                    let overflow = getComputedStyle(tempNode).overflow;
                    if(overflow.includes('scroll') || overflow.includes('auto')){
                        container = tempNode;
                        break;
                    }else{
                        top += tempNode.offsetTop;
                    }
                    tempNode = tempNode.parentNode;
                }
                if(dom.offsetHeight / container.offsetHeight >= scale){
                    if(top < container.scrollTop + container.offsetHeight && top + dom.offsetHeight > container.scrollTop){
                        return true;
                    }
                }
            }
            const _this = this;
            // 注册一个全局自定义指令 `v-hasPermit`
            Vue.directive('read', {
                // 当被绑定的元素插入到 DOM 中时……
                inserted: function (el, binding, vnode, oldVnode) {
                    if(!state.hasReadPermit){
                        return;
                    }
                    const { file , scale } = binding.value;
                    if(!file.extra || file.extra.readFlag != "0"){
                        return;
                    }
                    let isIn = false;
                    let length = _this.$store.getters.configs.reviewSettings.readDurationTime;        //  时长
                    Vue.nextTick(() => {
                        let count = 0;
                        el.timer = setInterval(() => {
                            isIn = isView(el,scale);
                            if(isIn){
                                count ++;
                            }else{
                                count = 0;
                            }
                            if(count === length){
                                clearInterval(el.timer);
                                let params = {
                                    caseId: state.caseInfo.caseId,
                                    fileIds: file.id
                                }
                
                                fileRead(params).then(res => {
                                    Vue.delete(file.extra,'readFlag');
                                })
                            }
                        },1000)
                    })
                },
                unbind: function(el, binding, vnode, oldVnode){
                    clearInterval(el.timer);
                }

            })
        }
    }
}
</script>

<style scoped lang='less'>
.case-reading{
    width: 100%;
    height: 100vh;
    .download-progress{
        position: fixed;
        right: 78px;
        bottom: 61px;
    }
}
</style>