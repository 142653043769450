<template>
    <ul class="toolbar-list">
        <div style="position:absolute;left:10px;top:10px;">
            <UnreadRemind @toFile="toFile"></UnreadRemind>
        </div>
        <li class="page">
            <button class="xbtn iconfont iconfanhuijian" @click="prevPage('left')"></button>
            <span class="page-count">
                <el-input size="mini" style="width:60px" :value="currentPage" @input="pageChange"></el-input>
                <span class="mg5">/</span>
                <span>{{fileList.length}}页</span>
            </span>
            <button class="xbtn iconfont iconjiantou" @click="nextPage('left')"></button>
        </li>
        <li>
            <el-select size="mini" style="width: 100px;" v-model="configParams.readTypeValue">
                <el-option value="#F8F4EA" label="默认模式"></el-option>
                <el-option value="#EBF3E9" label="护眼模式"></el-option>
            </el-select>
        </li>
        <li>
            <div class="size">
                <button class="xbtn iconfont iconsuoxiao mr10" @click="zoomOut"></button>
                <div class="mr5">
                    <el-select style="width: 100px;" size="small" :value="configParams.size + '%'" @input="val => configParams.size = parseInt(val)">
                        <el-option v-for="item in sizeList" :key="item.value" :value="item.value" :label="item.label"></el-option>
                    </el-select>
                </div>
                <button class="xbtn iconfont iconfangda" @click="zoomIn"></button>
            </div>
        </li>
        <li>
            <ColorPicker v-model="configParams.underlineColor" title="下划线颜色" icon="iconbi"></ColorPicker>
        </li>
        <li>
            <ColorPicker v-model="configParams.highlightColor" title="高亮颜色" icon="iconwenzi"></ColorPicker>
        </li>
        <li :url="printTypeList.map(item => item.url).join(',')" v-hasPermit>
            <BtnGroup :data="printTypeList" @btnClick="printClick">
                <button class="xbtn xbtn-input">
                    <span>打印</span>
                    <i class="iconfont iconzhankai"></i>
                </button>
            </BtnGroup>
        </li>
        <li :url="downloadTypeList.map(item => item.url).join(',')" v-hasPermit>
            <BtnGroup :data="downloadTypeList" @btnClick="downloadClick">
                <button class="xbtn xbtn-input">
                    <span>下载</span>
                    <i class="iconfont iconzhankai"></i>
                </button>
            </BtnGroup>
        </li>
        <li class="page" v-if="!volumeType">
            <button class="xbtn iconfont iconfanhuijian" @click="prevPage('right')"></button>
            <span class="page-count">
                <el-input size="mini" style="width:60px" :value="otherCurrentPage" @input="otherPageChange"></el-input>
                <span class="mg5">/</span>
                <span>{{otherFileList.length}}页</span>
            </span>
            <button class="xbtn iconfont iconjiantou" @click="nextPage('right')"></button>
        </li>
        <li v-if="!volumeType" style="width: 100px;">
            <el-select v-model="configParams.pageValue">
                <el-option :value="1" label="单独滚动"></el-option>
                <el-option :value="2" label="同时滚动"></el-option>
            </el-select>
        </li>
        <li>
            <BtnCascader class="ml10" :data="tagList" @menuClick="tagClick">
                <button class="xbtn xbtn-input">
                    <i class="iconfont iconbiaoji"></i>
                    <span>标记</span>
                </button>
            </BtnCascader>
        </li>
        <li>
            <button class="xbtn xbtn-input" @click="fileOCR" url="/caseinfo/file/sync-convert" v-hasPermit>
                <span>OCR</span>
            </button>
        </li>
        <li>
            <button class="xbtn xbtn-input" @click="createMark" url="/caseinfo/review/comment/save">
                <span>创建批注</span>
            </button>
        </li>
        <li>
            <button class="xbtn xbtn-input" @click="extractClick" url="/caseinfo/nlp/list,/caseinfo/nlp/list-file-type" v-hasPermit>
                <span>长文本分析</span>
            </button>
        </li>

        
        <FullScreen :visible.sync="openExtract" @cancel="closeExtract" class="dir-mapping-screen">
            <TextExtract v-if="openExtract"></TextExtract>
        </FullScreen>
    </ul>
</template>

<script>
import BtnGroup from './BtnGroup.vue';
import ColorPicker from './ColorPicker.vue';
import FullScreen from '@/common/fullScreen/FullScreen.vue';
import TextExtract from './textExtract';

import { fileRead } from '@/api/fileAPI.js';
import UnreadRemind from '@/components/UnreadRemind';
import BtnCascader from '@/components/model/BtnCascader.vue';
import { fileTags } from '@/api/fileAPI.js';

export default {
    // name: 'Toolbar',
    components: {
        BtnGroup,
        ColorPicker,
        UnreadRemind,
        BtnCascader,
        FullScreen,
        TextExtract
    },
    computed: {
        tagList(){
            let list = [
                {
                    label: '标记材料归属',
                    url: '/caseinfo/file/tags',
                    type: 'OWNER',
                    icon: 'iconcailiaoguishu',
                    children: this.state.dictMap['OWNER']
                },
                {
                    label: '标记材料介质',
                    url: '/caseinfo/file/tags',
                    type: 'MEDIA',
                    icon: 'iconcailiaojiezhi',
                    children: this.state.dictMap['MEDIA']
                },
                {
                    label: '标记为已读',
                    type: 'read',
                    icon: 'iconyidu',
                    url: '/caseinfo/file/mark-read'
                },
                {
                    label: '标记全部为已读',
                    type: 'allRead',
                    icon: 'iconchenggong',
                    url: '/caseinfo/file/mark-read'
                },
            ]
            return list;
        },
    },
    data(){
        return {
            sizeList: [
                {
                    label: '25%',
                    value: 25,
                },
                {
                    label: '50%',
                    value: 50,
                },
                {
                    label: '75%',
                    value: 75,
                },
                {
                    label: '100%',
                    value: 100,
                },
                {
                    label: '125%',
                    value: 125,
                },
                {
                    label: '150%',
                    value: 150,
                },
                {
                    label: '175%',
                    value: 175,
                },
                {
                    label: '200%',
                    value: 200,
                },
            ],
            printTypeList: [
                {
                    value: 'PDF',
                    url: '/caseinfo/file/download-pdf',
                    label: 'PDF文件'
                },
                {
                    value: 'PDFMark',
                    url: '/caseinfo/file/download-wathermark-pdf',
                    label: 'PDF文件(带水印)'
                },
                {
                    value: 'allPDF',
                    url: '/caseinfo/file/download-pdf',
                    label: 'PDF文件(整卷)'
                },
                {
                    value: 'allPDFMark',
                    url: '/caseinfo/file/download-wathermark-pdf',
                    label: 'PDF文件(整卷带水印)'
                },
            ],
            downloadTypeList: [
                {
                    value: 'ZIP',
                    url: '/caseinfo/file/download-zip',
                    label: '原文件'
                },
                {
                    value: 'singlePDF',
                    url: '/caseinfo/file/download-pdf',
                    label: 'PDF文件(单个)'
                },
                {
                    value: 'mergePDF',
                    url: '/caseinfo/file/download-pdf',
                    label: 'PDF文件(合并)'
                },
                {
                    value: 'mergePDFMark',
                    url: '/caseinfo/file/download-wathermark-pdf',
                    label: 'PDF文件(带水印合并)'
                },
                {
                    value: 'allZIP',
                    url: '/caseinfo/file/download-zip',
                    label: '原文件(整卷)'
                },
                {
                    value: 'allMergePDF',
                    url: '/caseinfo/file/download-pdf',
                    label: 'PDF文件(整卷)'
                },
                {
                    value: 'allMergePDFMark',
                    url: '/caseinfo/file/download-wathermark-pdf',
                    label: 'PDF文件(整卷带水印)'
                },
            ],
            predefineColors: ["#FF0101","#FFFF01","#72D887","#52A4F8","#454545","#F5A3A5","#F5C057","#6DD0D9","#9A9AF5","#E6E6E6"],
            openExtract: false,
        }
    },
    inject: ['state','mutations'],
    props: {
        pageCount: {
            type: Number,
            default: 0
        },
        currentPage: {
            type: Number,
            default: 0
        },
        otherCurrentPage: {
            type: Number,
            default: 0
        },
        configParams: {
            type: Object,
            default(){
                return {};
            }
        },
        fileList: {
            type: Array,
            default(){
                return [];
            }
        },
        otherFileList: {
            type: Array,
            default(){
                return [];
            }
        },
        volumeType: Boolean,
        currentFocus: Boolean
    },
    methods:{
        tagClick(data){     //  打标签
            let value = data.type;
            // const { allFiles } = this.getSelectData();
            const allFiles = this.fileList.concat(this.otherFileList).filter(item => item.selected && !item.isLocal);
            // console.log(allFiles);
            if(!allFiles.length && value != 'allRead'){
                this.$message.warning('请选择文件!');
                return;
            }
            
            switch(value){
                case 'OWNER':
                case 'MEDIA':
                    // 打标签
                    let tagFiles = allFiles.map(item => {
                        return {
                            fileId: item.id,
                            tags: [data.value],
                            type: data.type
                        }
                    })
                    let params = {
                        caseId: this.state.caseInfo.caseId,
                        tagFiles,
                    }
                    fileTags(params).then(res => {
                        this.$message.success('标记成功!');
                        allFiles.forEach(item => {
                            if(!item.tags){
                                item.tags = { [data.type]: data.value };
                            }else{
                                this.$set(item.tags,data.type,data.value);
                            }
                        })
                    })
                break;
            }
            if(value === 'read' || value === 'allRead'){
                let files = [];
                if(value === 'read'){
                    files = allFiles;
                }else{
                    files = this.mutations.getAllFiles();
                }
                let params = {
                    caseId: this.state.caseInfo.caseId,
                    fileIds: files.map(item => item.id).join(',')
                }

                fileRead(params).then(res => {
                    let msg = value === 'read' ? '材料成功标记为已读' : '材料成功标记全部为已读';
                    this.$message.success(msg);
                    files.forEach(item => this.$delete(item.extra,'readFlag'));
                })
            }
            
        },
        printClick(val){

            this.print(val,this.currentFocus ? this.fileList : this.otherFileList);
        },
        downloadClick(val){
            this.download(val,this.currentFocus ? this.fileList : this.otherFileList);
        },
        print(value,fileList){
            let selectIds = fileList.filter(item => !item.isLocal && item.selected).map(item => {
                return { id: item.id + `:${item.page - 1}` || '' }
            });
            
            switch(value){
                case 'PDF': 
                case 'PDFMark': 
                    this.mutations.fileDownload({ files: selectIds },{ outType: 'pdf', watermark: value === 'PDF' ? false : true , type: 'print' });
                break;
                case 'allPDF': 
                case 'allPDFMark': 
                    const dirs = this.state.dirList;
                    this.mutations.fileDownload({ dirs },{ outType: 'pdf', watermark: value === 'allPDF' ? false : true , type: 'print' });
                    
                break;
            }
        },
        download(value,fileList){
            let selectIds = fileList.filter(item => !item.isLocal && item.selected).map(item => {
                return { id: item.id + `:${item.page - 1}` || '' }
            });

            switch(value){
                case 'ZIP': 
                    this.mutations.fileDownload({ files: selectIds },{ outType: 'zip' });
                break;
                case 'singlePDF': 
                    this.mutations.fileDownload({ files: selectIds },{ outType: 'zip', convertType: 'pdf' });
                break;
                case 'mergePDF': 
                case 'mergePDFMark':
                    this.mutations.fileDownload({ files: selectIds },{ outType: 'pdf' , watermark: value === 'mergePDF' ? false : true , type: 'download' });
                break;
                case 'allZIP': 
                    this.mutations.fileDownload({ dirs: this.state.dirList },{ outType: 'zip' });
                break;
                case 'allMergePDF':
                case 'allMergePDFMark': 
                    this.mutations.fileDownload({ dirs: this.state.dirList },{ outType: 'pdf', watermark: value === 'allMergePDF' ? false : true , type: 'download' });
                break;
            }
        },
        pageChange(val){
            val = val.replace(/[^\d]/g,'');
            this.$emit('update:currentPage',Number(val));
        },
        otherPageChange(val){
            val = val.replace(/[^\d]/g,'');
            this.$emit('update:otherCurrentPage',Number(val));
        },
        prevPage(direction){
            if(direction === 'left'){
                this.$emit('update:currentPage',this.currentPage - 1);
            }else if(direction === 'right'){
                this.$emit('update:otherCurrentPage',this.otherCurrentPage - 1);
            }
        },
        nextPage(direction){
            if(direction === 'left'){
                this.$emit('update:currentPage',this.currentPage + 1);
            }else if(direction === 'right'){
                this.$emit('update:otherCurrentPage',this.otherCurrentPage + 1);
            }
        },
        zoomOut(){
            this.configParams.size -= 25;
            if(this.configParams.size <= 0){
                this.configParams.size = 25;
            }
        },
        zoomIn(){
            this.configParams.size += 25;
            if(this.configParams.size > 200){
                this.configParams.size = 200;
            }
        },
        markRead(){
            const leftFiles = this.fileList.filter(item => item.selected && !item.isLocal);
            const rightFiles = this.otherFileList.filter(item => item.selected && !item.isLocal);
            if(leftFiles.length || rightFiles.length){
                let params = {
                    caseId: this.state.caseInfo.caseId,
                    fileIds: leftFiles.map(item => item.id).join(',') + rightFiles.map(item => item.id).join(',')
                }

                fileRead(params).then(res => {
                    let msg = '材料成功标记为已读';
                    this.$message.success(msg);
                    leftFiles.forEach(item => this.$delete(item.extra,'readFlag'));
                    rightFiles.forEach(item => this.$delete(item.extra,'readFlag'));
                })
            }else{
                this.$message.warning('请选择文件!');
            }
        },
        toFile(file){
            let index = this.fileList.findIndex(item => item.id === file.id);
            if(index != -1){
                this.$emit('update:currentPage',index + 1);
            }

            if(!this.volumeType){
                let otherIndex = this.otherFileList.findIndex(item => item.id === file.id);
                if(otherIndex != -1){
                    this.$emit('update:otherCurrentPage',otherIndex + 1);
                }
            }
        },
        createMark(){
            this.$emit('createMark');
        },
        fileOCR(){
            console.log(213123213);
            this.$emit('fileOCR');
        },
        extractClick(){
            this.openExtract = true;
        },
        closeExtract(){
            
        }
    }
}
</script>

<style scoped lang='less'>
.toolbar-list{
    height: 100%;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
    li{
        padding: 0 15px;
        position: relative;
    }
    > li::after{
        content: '';
        // display: ;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -10px;
        width: 1px;
        height: 20px;
        background: #CCC;
        margin: 0 10px;
    }
    .page{
        display: flex;
        align-items: center;
        .page-count{
            display: flex;
            align-items: center;
            margin: 0 10px;
        }
    }
    .size{
        display: flex;
        
    }
    .underline,.highlight{
        .icon{
            display: inline-flex;
            flex-direction: column;
            .line{
                display: inline-block;
                width: 14px;
                height: 2px;
            }
        }
    }
}

.xbtn{
    height: 30px;
    line-height: 28px;
    box-sizing: border-box;
    font-size: 14px;
    > i{
        position: absolute;
        right: 5px;
    }
    .iconbiaoji{
        left: 5px;
        right: auto;
    }
}
.xbtn-input{
    border: 1px solid #dcdfe6;
    background-color: #fff;
    width: 100px;
    position: relative;
}
.xbtn.xbtn-input.disabled{
    cursor: not-allowed;
    color: #fff;
    background-color: #eee;
    border-color: #eee;
    opacity: .5;
}
.xbtn:hover{
    border-color: #c0c4cc;
}

.xbtn.rotate{
    cursor: not-allowed;
    // color: #fff;
    background-color: #eee;
    border-color: #eee;
    opacity: .5;
    font-family: "iconfont" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>