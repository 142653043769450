<template>
    <div class="lattice-bar">
        <button class="btn btn-default iconfont iconjiahao" @click="createLattice">笔记输入</button>
        <div class="list">
            <div class="lattice-item" :class="{'active': activeLattice === item}" v-for="item in latticeList" :key="item.id" @click="itemClick(item)">
                <div>{{`${item.userName} / ${item.updateTime}`}}</div>
                <div class="img-box"><img :src="item.filePath" alt=""></div>
            </div>

        </div>
    </div>
</template>

<script>
import { queryLattice } from '@/api/commentAPI.js';
import { base64ToFile } from '@/utils/file.js';
import { saveLattice } from '@/api/commentAPI.js';

export default {
    data(){
        return {
            
        }
    },
    inject: ['state','latticePenConfig'],
    computed: {
        latticeList(){
            return this.latticePenConfig.latticeList;
        },
        activeLattice(){
            return this.latticePenConfig.activeLattice;
        },
        currentUserId(){
            return this.$store.getters.userInfo.id;
        },
    },
    props: {
        fileList: Array
    },
    created(){
        this.pageInit();
    },
    methods: {
        async pageInit(){
            const params = {
                caseId: this.state.caseInfo.caseId
            }
            const res = await queryLattice(params);
            this.latticePenConfig.latticeList = res;
            this.latticePenConfig.openLatticePen = true;
            const currentLattice = res.find(item => item.userId === this.currentUserId);    //  获取当前用户的点阵信息
            if(currentLattice){
                this.latticePenConfig.linkFiles = currentLattice.fileIds.split(',');
                this.fileList.forEach(file => {     //  文件选中
                    if(this.latticePenConfig.linkFiles.includes(file.id)){
                        file.selected = true;
                    }
                })
            }
        },
        async createLattice(){
            //  编辑页面画布初始化
            const lattice = this.latticeList.find(lattice => lattice.userId === this.currentUserId);
            if(!lattice){
                const canvas = document.createElement('canvas');
                canvas.width = 595;
                canvas.height = 842;
                const file = base64ToFile(canvas.toDataURL('image/png'),Date.now() + '.png');
                const params = {
                    caseId: this.state.caseInfo.caseId,
                    extra: JSON.stringify([ { id: '', } ]),
                    file: file,
                    fileIds: '',
                    id: '',
                }
                await saveLattice(params);
                await this.pageInit();
            }

            this.$nextTick(() => {
                this.latticePenConfig.latticePenCom.canvasInit();
                this.latticePenConfig.latticePenCom.connect();
            })
        },
        itemClick(data){
            this.latticePenConfig.activeLattice = null;
            this.$nextTick(() => {
                this.latticePenConfig.activeLattice = data;
                this.latticePenConfig.latticePenCom.tagClick(data);
            })
        }
    },
}
</script>

<style lang="less" scoped>
.lattice-bar{
    padding: 10px 8px;
    .list{
        padding: 10px;
        .lattice-item{
            margin-bottom: 20px;
            .img-box{
                width: 112px;
                height: 147px;
                margin: 16px auto 0;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #bbb;
            }
        }
        // .active{
        //     border: 1px solid red;
        // }
        img{
            max-width: 100%;
            max-height: 100%;
        }
    }
}
</style>