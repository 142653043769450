<template>
    <div class="textLayer">
        <span v-for="(item,index) in file.pdfParams.textLayer" :key="index" :style="textStyle(item)">{{item.text}}</span>
        <div class="end-content" tabindex="1"></div>
    </div>
</template>

<script>
export default {
    props: {
        file: {
            type: Object,
            default(){
                return {};
            }
        },
        configParams: {
            type: Object,
            default(){
                return {};
            }
        }
    },
    data(){
        return {
            top: ''
        }
    },
    methods: {
        textStyle(item){
            return {
                left: `${item.left}px`,
                top: `${item.top}px`,
                transform: `scaleX(${item.scale})`,
                fontSize: `${item.fontSize}px`,
                fontFamily: `${item.fontFamily}`
            }
        },
    }
}
</script>

<style scoped lang="less">
.textLayer{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 16px;
    line-height: 1;
    white-space: nowrap;
    transform-origin: 0 0;
    z-index: 2021;
    > span{
        position: absolute;
        transform-origin: 0 0;
        color: transparent;
    }
    .end-content{
        // position: absolute;
        // left: 0px;
        // top: 100%;
        // right: 0px;
        // bottom: 0px;
        user-select: none;
        // z-index: -1;
    }
}
.textLayer{
    opacity: .2;
    ::selection{
        background-color: rgba(0, 0, 255, 1);
        color: transparent;
    }
}
</style>