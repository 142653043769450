<template>
    <div class="progress" v-if="visible">
        <div class="circle" slot="reference">
            <el-progress ref="elPopover" type="circle" :percentage="progress" :width="40" :stroke-width="2" :show-text="false" color="#1990FD"></el-progress>
            <div class="iconfont iconxiazai-"></div>
            <div class="popover-container">
                <div class="popover" v-for="item in data" :key="item.id">
                    <div class="title">
                        <i class="iconfont iconxiazai5"></i>
                        <span>下载</span>
                    </div>
                    <el-progress :percentage="item.progress"  color="#1990FD"></el-progress>
                </div>

            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    props: {
        progress: {
            type: Number,
            default: 0
        },
        visible: {
            type: Boolean,
            default: false,
        },
        data: {
            type: Array,
            default(){
                return [];
            }
        }
    },
    watch: {
        
    },
    methods: {
        
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
.progress{
    // width: 400px;
    // position: relative;
    .circle{
        width: 40px;
        height: 40px;
        position: relative;
        .iconxiazai-{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            color: #666;
        }
    }
    .popover-container{
        position: absolute;
        width: 400px;
        padding: 18px 20px;
        right: 50px;
        bottom: 0;
        background: #fff;
        border: 1px solid #ebeef5;
        border-radius: 4px;
        box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
        .popover{
            margin-bottom: 10px;
            .title{
                margin-bottom: 5px;
                i{
                    margin-right: 10px;
                    color: #FCCA00;
                }

            }
        }
    }
    .popover-container::after{
        content: " ";
        display: block;
        position: absolute;
        border: 6px solid transparent;
        border-left-color: #fff;
        right: 0;
        bottom: 14px;
        transform: translateX(100%);
    }
}



</style>