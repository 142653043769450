function getAllHasScrollNode(container){				//	获取所有带滚动条的节点
	let children = container.children;
	let nodeList = [];
	traverseNode(children,node => {
        // console.log(node.tagName);
		if(node.tagName === 'SCRIPT' || node.tagName === 'NOSCRIPT'){
			return;
		}
		let style = getComputedStyle(node);
		if(style.display === 'none'){
			return;
		}
		if(style.overflow === 'scroll' || style.overflow === 'auto'){
			nodeList.push(node);
		}
	})
	return nodeList;
}

function domPosition(node,container){			//	获取两个元素相对位置
	let rect1 = container.getBoundingClientRect();
	let rect2 = node.getBoundingClientRect();
	let top = rect2.top - rect1.top;
	return {
		node,
		offsetTop: top + container.scrollTop,
		clientTop: top,
		offsetHeight: node.offsetHeight
	}
}

function traverseNode(nodeList,call){
	function recursion(children){
        for (let index = 0; index < children.length; index++) {
            const node = children[index];
            if(call(node)){
                return;
            }
            if(node.children.length){
                recursion(node.children);
            }
        }
	}
	recursion(nodeList);
}

export function removeNodeNotView(parentNode){
    let hasScrollNodeList = getAllHasScrollNode(parentNode);		//	所有带scroll的元素

	//	1. 获取node节点
	hasScrollNodeList.forEach(container => {
		let nodeList = [];
		traverseNode(container.children,node => {	
			const position = domPosition(node,container);
			nodeList.push(position);
		})
		let flag = true;
		//	删除节点
        //  删除每个节点之前，记录当时位置情况，否则删除元素后布局会出现变化
		while(flag){
			flag = false;
			for (let index = 0; index < nodeList.length; index++) {
				const position = nodeList[index];
				const node = position.node;
				let isIn = position.clientTop <= container.offsetHeight && position.clientTop + node.offsetHeight >= 0;
				if(!isIn){
                    if(node.tagName === 'IMG'){
                        // console.log(node);
                    }
					if(!node.children.length){
						node.parentNode.removeChild(node);
						flag = true;
						nodeList.splice(index,1);
						index --;
					}
				}
			}
		}
        container.scrollTop = 0;        //  scrollTop会影响子元素位置
		nodeList.forEach(position => {
			let newPosition = domPosition(position.node,container);
			if(newPosition.clientTop === position.clientTop && newPosition.offsetHeight === position.offsetHeight){

			}else{      //  解决因删除视野外元素造成的布局变化
                console.log(position);
				let style = getComputedStyle(position.node);
				let top = Number(/(.*)px$/.exec(style.marginTop)[1]);
				position.node.style.marginTop = (top + position.clientTop - newPosition.clientTop) + 'px';
			}
		})
		
        if(container.offsetHeight < container.scrollHeight){
            container.style.overflow = 'hidden';
            container.style.paddingRight = '17px';
        }
		
	})
}

// 找到所有style
function getStyleAndLink() {
    var styleArr = [];
    var styles = document.querySelectorAll('style');
    // console.log(styles);
    if (styles.length > 0) {
        for (var i = 0; i < styles.length; i++) {
            styleArr.push(styles[i].innerText);
        }
    }

    return new Promise((resolve,reject) => {
        let links = Array.from(document.querySelectorAll('link')).filter(item => /css\/.*/.test(item.href));
        let promiseAll = [];
        for (let i = 0; i < links.length; i++) {
            promiseAll.push(fetch(links[i].href).then(res => {
                return res.text();
            }));
        }
        Promise.all(promiseAll).then(res => {
            resolve(res.concat(styleArr).join(''));
        })
    })
}

function draw(img) {	//	img转base64，（已经渲染好的img）
    var canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d'),
        width = img.width,
        height = img.height;

    canvas.width = width;
    canvas.height = height;

    ctx.clearRect(0, 0, width, height);
    ctx.drawImage(img, 0, 0,width,height);
    return canvas.toDataURL('image/png');
}

function setScrollTop(cloneNode,container){
    // container.sele
    // traverseNode(cloneNode)
    let containerList = getAllHasScrollNode(container);
    let cloneNodeList = getAllHasScrollNode(cloneNode);
    cloneNodeList.forEach((cloneNode,index) => {
        console.log(containerList[index].scrollTop);
        cloneNode.scrollTop = containerList[index].scrollTop;
    })
}

//  html转Image----(svg)
export async function htmlToCanvas(html,addStyles) {
    const container = html.cloneNode(true);
    // container.innerHTML = html;
    

    container.style.position = 'fixed';
    container.style.zIndex = '99999';
    container.style.left = '10000';
    // container.style.right = '0';
    // container.style.top = '0';
    // container.style.bottom = '0';
	document.body.appendChild(container);
    // return;
    const styles = await getStyleAndLink();

    let imgs = container.querySelectorAll('img');  
    for (let i = 0; i < imgs.length; i++) {
        if(imgs[i].src){
            imgs[i].src = draw(imgs[i]);
        }
    }

    let svgHTML = `
        data:image/svg+xml;charset=utf-8,
        <svg width="${container.offsetWidth}" height="${container.offsetHeight}" xmlns="http://www.w3.org/2000/svg" style="background-color: #fff;">
            <foreignObject width="${container.offsetWidth}" height="${container.offsetHeight}">
                ${new XMLSerializer().serializeToString(container)}
                <style>${addStyles || styles}</style>
            </foreignObject>
        </svg>
    `
    // ${new XMLSerializer().serializeToString(cloneDom)}
    document.body.removeChild(container);
    
    svgHTML = svgHTML.replace(/\n/g, '').replace(/\t/g, '').replace(/#/g, '%23').replace(/<noscript>.*<\/noscript>/, '');
    // let div = document.createElement('div');
    // div.innerHTML = svgHTML;
    // document.body.appendChild(div);

    var img = new Image();
    img.src = svgHTML;
    // document.body.appendChild(img);

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    return new Promise((resolve, reject) => {
        // let time1 = new Date().getTime();
        img.onload = function () {
            console.log(img.width);
            // console.log(new Date().getTime - time1);
            // canvas.width = img.width * 2;
            // canvas.height = img.height * 2;
            // ctx.drawImage(img, 0, 0,img.width * 2,img.height * 2);

            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            resolve(canvas);
        }
    })
}

// //  html转Image----(svg)
// export async function htmlToCanvas(data) {

//     let cloneDom = container.cloneNode(true);
//     const styles = await getStyleAndLink();

//     cloneDom.style.position = 'fixed';
//     cloneDom.style.zIndex = '99999';
//     cloneDom.style.left = '0';
//     cloneDom.style.right = '0';
//     cloneDom.style.top = '0';
//     cloneDom.style.bottom = '0';
// 	document.body.appendChild(cloneDom);
//     setScrollTop(cloneDom,container);
//     removeNodeNotView(cloneDom);

//     let imgs = cloneDom.querySelectorAll('img');  
//     let time = new Date().getTime();
//     for (let i = 0; i < imgs.length; i++) {
//         if(imgs[i].src){
//             imgs[i].src = draw(imgs[i]);
//         }
//         console.log(new Date().getTime() - time);
//         time = new Date().getTime();
//     }

//     let svgHTML = `
//         data:image/svg+xml;charset=utf-8,
//         <svg width="${window.innerWidth}" height="${window.innerHeight}" xmlns="http://www.w3.org/2000/svg" style="background-color: #fff;">
//             <foreignObject width="${window.innerWidth}" height="${window.innerHeight}">
//                 ${new XMLSerializer().serializeToString(cloneDom)}
//                 <style>${styles}</style>
//             </foreignObject>
//         </svg>
//     `
//     // ${new XMLSerializer().serializeToString(cloneDom)}
//     document.body.removeChild(cloneDom);
    
//     svgHTML = svgHTML.replace(/\n/g, '').replace(/\t/g, '').replace(/#/g, '%23').replace(/<noscript>.*<\/noscript>/, '');
//     var img = new Image();
//     img.src = svgHTML;
//     const canvas = document.createElement('canvas');
//     const ctx = canvas.getContext('2d');
//     return new Promise((resolve, reject) => {
//         let time1 = new Date().getTime();
//         img.onload = function () {
//             console.log(new Date().getTime - time1);
//             canvas.width = img.width;
//             canvas.height = img.height;
//             ctx.drawImage(img, 0, 0);
//             resolve(canvas);
//         }
//     })
// }