<template>
    <div @mousedown="mousedown" class="selection">
        <slot></slot>
    </div>
</template>

<script>
import { myMove } from "@/utils/f.js";

export default {
    data() {
        return {
            selection: null,
            dataId: 0
        };
    },
    methods: {
        mousedown(event) {
            myMove(() => {

            },(mouseupEvent) => {
                this.getSelection(mouseupEvent);
            });
        },
        getSelection(event) {
            const selection = document.getSelection();
            this.selection = selection;
            if (!selection.toString() || (selection.toString().length === 1 && selection.toString().charCodeAt() === 10)){
                return;
            }
            const allNode = this.getAllNodes(this.$el, selection);
            const { anchorOffset , focusOffset , anchorNode } = selection;
            this.$emit('textMark',event,{
                allNode,
                anchorOffset,
                focusOffset,
                direction: anchorNode === allNode[0] ? true : false,
                text: selection.toString(),
            })
        },
        getAllNodes(node, selection) {
            let allNode = [];
            let index = 0;
            function recursion(arr) {
                arr.forEach((item) => {
                    if(selection.anchorNode === item || selection.focusNode === item){  //  第一次匹配到
                        index ++;
                        if(selection.anchorNode === selection.focusNode){
                            index ++;
                        }
                    }
                    if(index && item.nodeName === "#text"){
                        allNode.push(item);
                    }
                    if(index === 2){    //  index为2，代表1、只有一个节点，首尾相同；2、由头到尾，遍历结束;
                        index = 0;
                    }
                    // if(selection.containsNode(item) && item.nodeName === "#text"){
                    //     allNode.push(item);
                    // }
                    if(item.childNodes && item.childNodes.length > 0){
                        recursion(item.childNodes);
                    }
                });
            }

            recursion(node.childNodes || []);
            return allNode;
        },
    },
};
</script>

<style scoped lang='less'>
</style>