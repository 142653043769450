<template>
    <div class="form">
        <el-form ref="form" :model="form" label-width="120px" :inline="true">
            <ul class="list">
                <li v-for="(item,index) in model" :key="index" class="form-item" :class="item.type">
                    <el-form-item :label="item.label" :prop="item.value" :required="item.require" :rules="item.rules">
                        <el-select v-if="item.type === 'select'" v-model="form[item.value]" size="small" clearable
                            :disabled="item.disabled || disabled">
                            <el-option v-for="select in item.selectList" :key="select.value" :value="select.value" :label="select.label"></el-option>
                        </el-select>
                        <el-date-picker v-else-if="item.type === 'time'"
                            v-model="form[item.value]"
                            :type="item.detailType || 'date'"
                            placeholder="选择日期"
                            size="small" :disabled="item.disabled || disabled"
                            :value-format="item.valueFormat || 'yyyyMMdd'">
                        </el-date-picker>
                        <el-input v-else v-model="form[item.value]" :disabled="item.disabled || disabled" :type="item.type" :rows="2"></el-input>

                    </el-form-item>
                </li>
            </ul>
        </el-form>
    </div>
</template>

<script>

export default {
    props: {
        model: {
            type: Array,
            default(){
                return [];
            }
        },
        form: {
            type: Object,
            default(){
                return {};
            }
        },
        disabled: Boolean
    },
    methods:{
        validate(fun){
            this.$refs.form.validate(fun);
        },
    }
}
</script>

<style scoped lang='less'>
.form{
    .list{
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 10px;
        .form-item{
            width: 50%;
            margin-top: 15px;
        }
        .form-item.textarea{
            width: 100%;
            height: 70px;
            display: flex;
            /deep/ .el-form-item{
                .el-form-item__content{
                    width: 712px;
                }
            }
        }
    }
    
}
    
/deep/ .el-select{
    width: 240px;
}
/deep/ .el-input__inner{
    height: 32px;
    line-height: 32px;
}
/deep/ .el-form-item{
    margin-bottom: 0;
    display: flex;
    // justify-content: center;
    align-items: center;
    .el-form-item__label{
        line-height: 1;
    }
}
/deep/ .el-textarea{
    // height: 70px;
    .el-textarea__inner{
        height: 70px;
    }
}
.el-input{
    width: 240px;
}
</style>