<template>
    <div class="text-extract">
        <div class="topbar">长文本分析</div>
        <div class="extract-content-container">
            <div class="pdf-viewer" v-loading="loading" element-loading-text="文件加载中...">
                <iframe v-if="pdfURL" :src="`/pdfjs-2.2.228-dist/web/viewer.html?file=${pdfURL}`" frameborder="0" width="100%" height="100%" style="z-index: 9999;">
                </iframe>
                <div class="err-text" v-else-if="!loading">
                    <i class="iconfont iconcailiaoguishu"></i>
                    <p>{{errText}}</p>
                </div>
            </div>
            <div class="text-content">
                <ExtractContent></ExtractContent>
            </div>
        </div>
    </div>
</template>

<script>
import { concatPDF } from '@/api/textExtractAPI.js';
import ExtractContent from './ExtractContent.vue';

export default {
    components: {
        ExtractContent
    },
    data(){
        return {
            pdfURL: '',
            pdfBlob: null,
            fileIds: '',
            errText: '未获取到任何文件',
            loading: false,
        }
    },
    inject: ['state'],
    provide(){
        return {
            textExtract: this,
        }
    },
    computed: {
        caseId(){
            return this.state.caseInfo.caseId;
        }
    },
    methods: {
        concatPDF(ids){
            return new Promise((resovle,reject) => {
                this.loading = true;
                concatPDF({ caseId: this.caseId , fileIds: ids }).then(res => {
                    const url = URL.createObjectURL(res.data);
                    this.pdfURL = url;
                    this.pdfBlob = res.data;
                    resovle();
                }).catch(err => {
                    this.pdfURL = '';
                    reject(err);
                }).finally(() => {
                    this.loading = false;
                })
            })
        }
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
.text-extract{
    background-color: #EFF3F7;
    width: 100vw;
    height: 100vh;
}
.topbar{
    height: 50px;
    background-color: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.13);
    color: #101010;
    padding-left: 20px;
    line-height: 50px;
    font-size: 16px;
    font-family: SourceHanSansSC-medium;
}
.extract-content-container{
    height: calc(100% - 50px);
    padding: 10px 20px;
    box-sizing: border-box;
    display: flex;
    .pdf-viewer{
        width: 50%;
        flex-shrink: 0;
        height: 100%;
        background-color: #fff;
        .err-text{
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #101010;
            i{
                color: #A1A1A1;
                font-size: 90px;
                display: inline-block;
                margin-bottom: 30px;
            }
            p{
                color: #101010;
                font-size: 18px;
            }
        }
    }
    .text-content{
        margin-left: 20px;
        flex-grow: 1;
        height: 100%;
        background-color: #fff;
        border: 1px solid #bbb;
    }
}
</style>