import Vue from 'vue';
import store from '@/store';
import { moveFile, moveDir, sortFile, getCaseDirInfo , getRecords } from '@/api/caseReading';
import { openPDF , parseFile } from '@/utils/file.js';
import { printPDF , printPDFWatermark , fileRead , fileDownload ,base64ToPDF , mergePDF } from '@/api/fileAPI.js';
import { batchDel , getTreeByChildren , guid , urlDownload } from '@/utils/f.js';
import { htmlToImg } from '@/utils/tool.js';
import { Message } from 'element-ui';
import { queryFileOwner } from '@/api/commonAPI.js';
import { htmlToCanvas } from '@/utils/htmlToCanvas.js';

import ImgView from '@/common/imgView/index.js';
// import axios from 'axios';

let svgHTML = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" height="16" width="20">
        <ellipse cx="10" cy="8" rx="9" ry="7" style="fill:backColor; stroke:myColor;" />
        <text x="5" y="11.5" fill="myColor" style="font-size: 10px;font-weight:600;">myText</text>
    </svg>`
function toBase64(text){
    // return ''
    return 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(text)));
}

const staticObj = {
    staticImg: {
        '1': require('@/assets/caseReading/卷.png'),
        '2': require('@/assets/caseReading/分册.png'),
        '4': require('@/assets/caseReading/目录.png'),
        '8': require('@/assets/caseReading/待分类区.png'),
        '11': require('@/assets/caseReading/封皮.png'),
        '12': require('@/assets/caseReading/卷内目录.png'),
        '13': require('@/assets/caseReading/封底.png'),

        'empty': require('@/assets/caseReading/空目录.png'),
        'word': require('@/assets/imgs/word.png'),
        'img': require('@/assets/imgs/img.png'),
        'pdf': require('@/assets/imgs/pdf.png'),
        'text': require('@/assets/imgs/text.png'),
        'ppt': require('@/assets/imgs/ppt.png'),
    },
    tagImg: {
        // '电': require('@/assets/fileTypeIcon/dianzi.svg'),
        // '纸': require('@/assets/fileTypeIcon/zhizhi.svg'),
        // '留': require('@/assets/fileTypeIcon/zhizhi_tuihui.svg'),
        'EFILE': toBase64(svgHTML.replace(/myColor/g,'#3291F8').replace(/backColor/g,'#EBF4FF').replace(/myText/,'电')),
        'PAPERFILE': toBase64(svgHTML.replace(/myColor/g,'#27B148').replace(/backColor/g,'#DDFAE4').replace(/myText/,'纸')),
        '-PAPERFILE': toBase64(svgHTML.replace(/myColor/g,'#FF2525').replace(/backColor/g,'#FFE9E9').replace(/myText/,'留')),
        
        'tag': require('@/assets/fileTypeIcon/tag.png'),

        '立': toBase64(svgHTML.replace(/myColor/g,'#3291F8').replace(/backColor/g,'#EBF4FF').replace(/myText/,'立')),
        '庭': toBase64(svgHTML.replace(/myColor/g,'#FF2525').replace(/backColor/g,'#FFE9E9').replace(/myText/,'庭')),
        '生': toBase64(svgHTML.replace(/myColor/g,'#27B148').replace(/backColor/g,'#DDFAE4').replace(/myText/,'生')),
        '查': toBase64(svgHTML.replace(/myColor/g,'#00C7FF').replace(/backColor/g,'#E5FAFF').replace(/myText/,'查')),
        '费': toBase64(svgHTML.replace(/myColor/g,'#3291F8').replace(/backColor/g,'#EBF4FF').replace(/myText/,'费')),
        '证': toBase64(svgHTML.replace(/myColor/g,'#FF2525').replace(/backColor/g,'#FFE9E9').replace(/myText/,'证')),
        '执': toBase64(svgHTML.replace(/myColor/g,'#27B148').replace(/backColor/g,'#DDFAE4').replace(/myText/,'执')),
        '合': toBase64(svgHTML.replace(/myColor/g,'#00C7FF').replace(/backColor/g,'#E5FAFF').replace(/myText/,'合')),
        '审': toBase64(svgHTML.replace(/myColor/g,'#3291F8').replace(/backColor/g,'#EBF4FF').replace(/myText/,'审')),
        '送': toBase64(svgHTML.replace(/myColor/g,'#FF2525').replace(/backColor/g,'#FFE9E9').replace(/myText/,'送')),
        '随': toBase64(svgHTML.replace(/myColor/g,'#27B148').replace(/backColor/g,'#DDFAE4').replace(/myText/,'随')),
    },
    treeIcon: {
        'image/bmp': require('@/assets/fileTypeIcon/bmp.png'),
        'application/msword': require('@/assets/fileTypeIcon/doc+docx.png'),
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': require('@/assets/fileTypeIcon/doc+docx.png'),
        'image/gif': require('@/assets/fileTypeIcon/gif.png'),
        'image/jpeg': require('@/assets/fileTypeIcon/jpg.png'),
        'audio/mpeg': require('@/assets/fileTypeIcon/mp3.png'),
        'video/mp4': require('@/assets/fileTypeIcon/mp4.png'),
        'application/pdf': require('@/assets/fileTypeIcon/pdf.png'),
        'image/png': require('@/assets/fileTypeIcon/gif.png'),
        'application/rtf': require('@/assets/fileTypeIcon/rtf.png'),
        'image/tiff': require('@/assets/fileTypeIcon/tif+tiff.png'),
        'application/vnd.ms-works': require('@/assets/fileTypeIcon/wps.png'),
        'application/vnd.ms-excel': require('@/assets/fileTypeIcon/xls+xlsx.png'),
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': require('@/assets/fileTypeIcon/xls+xlsx.png'),
        'other': require('@/assets/fileTypeIcon/其他.png'),
    },
    fileTypeDict: [
        { type: 'image/tiff', value: 'tiff tif'},
        { type: 'image/gif', value: 'gif' },
        { type: 'image/bmp', value: 'bmp' },
        { type: 'image/jpeg', value: 'jpeg jpg jpe' },
        { type: 'text/html', value: 'html htm shtml' },
        { type: 'text/xml', value: 'xml' },
        { type: 'application/json', value: 'json' },
        { type: 'application/msword', value: 'doc' },
        { type: 'application/pdf', value: 'pdf' },
        { type: 'application/rtf', value: 'rtf' },
        { type: 'application/vnd.ms-excel', value: 'xls' },
        { type: 'application/x-rar-compressed', value: 'rar' },
        { type: 'application/zip', value: 'zip' },
        { type: 'audio/mpeg', value: 'mp3' },
        { type: 'video/mp4', value: 'mp4' },
        { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', value: 'docx' },
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', value: 'xlsx' },
        { type: 'application/vnd.ms-works', value: 'wps' },
        { type: 'audio/x-wav', value: 'wav' },
        { type: 'audio/x-ms-wma', value: 'wma' },
        { type: 'audio/ogg', value: 'ogg' },
        { type: 'audio/amr', value: 'amr' },
        { type: 'application/vnd.rn-realmedia-vbr', value: 'rmvb rm' },
        { type: 'video/x-msvideo', value: 'avi' },
        { type: 'video/mpeg', value: 'mpeg' },
        { type: 'video/x-ms-wmv', value: 'wmv' },
        { type: 'video/quicktime', value: 'mov' },
        { type: 'model/vnd.mts', value: 'mts' },
    ],
    fileRules: [
        {
            type: 'img',
            suffix: ["jpg", "jpeg", "png", "bmp", "tif", "tiff", "gif"]
        },
        {
            type: 'doc',
            suffix: ["doc", "docx", "wps", "rtf", "xls", "xlsx", "pdf", "html", "htm", "txt"]
        },
        {
            type: 'mp3',
            suffix: ["mp3", "wav", "wma", "ogg", "amr"]
        },
        {
            type: 'mp4',
            suffix: ["mp4", "rmvb", "avi", "mpeg", "wmv", "rm", "mov", "dav", "mts"]
        },
    ]
}

const staticFun = {
    toImgView(file, files,obj) {
        let options = {
            imgIndex: files.indexOf(file),
            fileList: files,
            ...obj
        }
        
        ImgView(options,imgIndex => {
            let targetFile = files[imgIndex];
            // console.log(targetFile);
            if(targetFile.extra && targetFile.extra.readFlag === 0 && state.hasReadPermit){
                let params = {
                    caseId: state.caseInfo.caseId,
                    fileIds: targetFile.id
                }
                fileRead(params).then(res => {
                    Vue.delete(targetFile.extra,'readFlag');
                })
            }
            if(targetFile.isLocal && targetFile.filePath === ''){
                switch(targetFile.name){
                    case '封皮':
                        mutations.setCover(targetFile);
                    break;
                    case '卷内目录':
                        mutations.setBookMark(targetFile);
                    break;
                    case '封底':
                        mutations.setBackcover(targetFile);
                    break;
                }
                // console.log(targetFile);
            }
        });
    }
}

const oneFun = {
    getDirList(params = {}) {            //  目录数据初始化
        state.getDirLoading = true;
        return new Promise((resolve, reject) => {
            const { caseId, category } = params;
            getCaseDirInfo({ caseId: caseId || state.caseInfo.caseId, category: category || state.dirCategory, fileTypes: 'jpg,pdf,json' }).then(async res => {
                const dirList = this.dataInit(res);
                state.tempDirList = JSON.parse(JSON.stringify(dirList));
                let dirObj = {};
                this.recursion(dirList, dir => {
                    dirObj[dir.id] = dir;
                })
                this.setDirObj(dirObj);
                this.setDirList(dirList);
                this.setCurrentDir(state.dirList[0]);
                this.globalOrder();

                this.fileGlobalOrder();
                state.getDirLoading = false;
                
                this.setCoverData();    //  初始化封皮数据

                resolve();  //  数据完成

            })
        })
    },
    loadStyleScript(html){
        let scriptStr = html.match(/(<script>([\s\S]*?)<\/script>)/g)
        let styleStr = html.match(/(<style>([\s\S]*?)<\/style>)/g);
        // console.log(scriptStr);
        // console.log(styleStr);
        
        scriptStr && scriptStr.forEach(script => {
            const scriptNode = document.createElement('script');
            scriptNode.type = 'text/javascript';
            scriptNode.innerHTML = script.match(/<script>([\s\S]*)<\/script>/)[1];
            document.head.appendChild(scriptNode);
        })

        styleStr && styleStr.forEach(style => {
            const styleNode = document.createElement('style');
            styleNode.innerHTML = style.match(/<style>([\s\S]*)<\/style>/)[1];
            document.head.appendChild(styleNode);
        })

    },
    async dictInit() {         //  特殊目录模板对应以及案件扩展信息
        const resList = [store.getters.dictMap['case_template_mapping'],store.getters.dictMap['case_template'],store.getters.dictMap['case_type_std']]

        const mappingList = resList[0] || [];     //  映射列表，案件模板对应的案件类型
        const templateList = resList[1] || [];    //  模板列表
        
        let mapping = mappingList.find(mapping => mapping.value.split(',').includes(state.caseInfo.caseType.code));
        let coverLabel = mapping && mapping.label;

        let coverTemplate = templateList.find(item => item.label === coverLabel);
        if (!coverTemplate) {
            coverTemplate = templateList.find(item => item.label === `default-封皮`);
        }
        
        state.htmlTemplate.cover = coverTemplate.value.match(/(<div[\s\S]*)<\/div>/)[1];
        
        this.loadStyleScript(coverTemplate.value);

        let bookMark = templateList.find(item => item.label === `default-卷内目录`);
        if(!bookMark){
            bookMark = templateList.find(item => item.label === `default-卷内目录`).value;
        }
        state.htmlTemplate.bookMark = bookMark.value.match(/(<div[\s\S]*)<\/div>/)[1];
        this.loadStyleScript(bookMark.value);


        let backcover = templateList.find(item => item.label === `default-封底`);
        state.htmlTemplate.backcover = backcover && backcover.value || templateList.find(item => item.label === `default-封底`).value;

        let stdList = resList[2];
        state.caseInfo.caseTypeName = '';
        this.recursion(stdList, type => {
            let value = JSON.parse(type.value);
            if (value && value.code === state.caseInfo.caseType.code) {
                state.caseInfo.caseTypeName = type.label;
                return true;
            }
        })

        let category = ['file_source','file_media'];
        
        const res = [store.getters.dictMap['file_source'],store.getters.dictMap['file_media']];
        const dictMap = {};
        category.forEach((item,index) => {
            let type = category[index].match(/file_(.*)/)[1].toUpperCase()
            res[index].forEach(item => {
                item.type = type;
            })
            dictMap[type] = res[index];
        })
        
        const permitList = store.getters.permitList;
        
        if(permitList.includes('/caseinfo/case-type/case-template/query-file-owner')){      //  广西无权限
            queryFileOwner({ code: state.caseInfo.caseType.code }).then(res => {
                
                res.forEach(item => {
                    item.type = 'OWNER';
                })
                dictMap['OWNER'] = res;
                state.dictMap = dictMap;
            })
        }
        
        
    },
    dirInit(dir){
        return {
            id: dir.id,
            name: dir.name,
            pid: dir.pid,
            sn: dir.sn,
            type: dir.type,
            description: dir.description,
            updateTime: dir.updateTime,
            children: [],
            selected: false,
            dataType: 'dir',
        }
    },
    fileInit(file,dir){
        let pathObj = this.filePathInit(file);
        const copyFile = {
            ...pathObj,
            id: file.fileId,
            name: file.fileName,
            fileIndex: file.fileIndex,
            filePath: file.filePath,
            fileType: file.fileType,
            globalIndex: file.globalFileIndex,
            updateTime: file.updateTime,
            subCaseId: file.subCaseId,
            fileSize: file.fileSize,
            pid: dir.id,
            selected: false,
            dataType: 'file',
            pageNum: file.pageNum,
            extra: file.extra,
            tags: file.tags || {},
            deleted: false,
        }
        return copyFile;
    },
    filePathInit(file){
        let pathObj = {
            thumbnailPath: '',
            pdfPath: ''
        }
        const easyImg = file.derivatives && file.derivatives.find(item => item.bizType.toLowerCase() === 'thumbnail');  //  是否有缩略图
        if (easyImg) {
            pathObj.thumbnailPath = easyImg.filePath;
        }else{
            if (/image\/*/.test(file.fileType)) {
                if (!file.isLocal) {
                    file.thumbnailPath = file.filePath;     //  广西(切图中文路径有问题)
                    // pathObj.thumbnailPath = file.filePath + '?process_image=resize,400x400';
                }
            }
            const fileTypeDictItem = state.fileTypeDict.find(item => item.type === file.fileType);
            if(fileTypeDictItem){
                const fileRules = store.state.common.configs.uploadFileRules;
                const fileRule = fileRules.find(item => item.suffix.find(suffixItem => fileTypeDictItem.value.includes(suffixItem)));
                switch(fileRule.type){
                    case 'doc': pathObj.thumbnailPath = require('@/assets/imgs/default-word.png'); break;
                    case 'mp3': pathObj.thumbnailPath = require('@/assets/imgs/default-audio.png'); break;
                    case 'mp4': pathObj.thumbnailPath = require('@/assets/imgs/default-video.png'); break;
                }
            }
        }
        if(file.fileType === 'application/pdf'){
            pathObj.pdfPath = file.filePath;
        }
        const pdf = file.derivatives && file.derivatives.find(item => item.bizType.toLowerCase() === 'pdf');
        if(pdf){
            pathObj.pdfPath = pdf.filePath;
        }
        return pathObj;
    },
    dataInit(dirList){      //  数据初始化
        let copyList = [];
        const recursion = (children,copyList) => {
            children.forEach(child => {
                const copyDir = this.dirInit(child);
                copyList.push(copyDir);
                if(child.fileList && child.fileList.length){
                    child.fileList.forEach(file => {
                        const copyFile = this.fileInit(file,child);
                        copyDir.children.push(copyFile);    //  左侧显示文件
                    })
                }
                if(child.children && child.children.length){
                    recursion(child.children,copyDir.children);
                }
            })
        }
        recursion(dirList,copyList);
        this.speDirInit(copyList);
        return copyList;

    },
    speDirInit(list) {   //  特殊目录初始化(卷下有分册，则无封皮封底，分册有，卷下无分册，则卷有封皮封底)
        this.recursion(list,volume => {
            if(volume.type === 1){
                if(volume.children.length && !volume.children.find(item => item.type === 2)){
                    this.setSpecDir(volume);
                }
            }else if(volume.type === 2){
                this.setSpecDir(volume);
                
            }
        })

    },
    setSpecDir(parentDir) {
        if(!this.hasFile(parentDir)){
            return;
        }
        let cover = {
            id: (state.id++).toString(),
            pid: parentDir.id,
            name: '封皮',
            sn: -2,
            type: 11,
            isLocal: true,
            dataType: 'dir',
            children: [],
            fileList: []
        }
        cover.fileList.push(this.setSpeFile(cover));
        let volumeDir = {
            id: (state.id++).toString(),
            pid: parentDir.id,
            name: '卷内目录',
            sn: -1,
            type: 12,
            isLocal: true,
            dataType: 'dir',
            children: [],
            fileList: []
        }
        let backcover = {
            id: (state.id++).toString(),
            pid: parentDir.id,
            name: '封底',
            sn: 999,
            type: 13,
            isLocal: true,
            dataType: 'dir',
            children: [],
            fileList: []
        }
        let num = parentDir.children && parentDir.children.filter(item => this.hasFile(item)).length || 1;
        let count;
        if(window.getBookmarkPage){
            count = window.getBookmarkPage(num);
        }else{
            console.error('卷内目录没有获取卷内目录页数!');
            count = Math.floor((num - 1) / 15) + 1;     //  
        }
        for (let i = 0; i < count; i++) {
            let file = this.setSpeFile(volumeDir);
            file.fileIndex = i + 1;
            volumeDir.fileList.push(file);
        }
        backcover.fileList.push(this.setSpeFile(backcover));
        
        parentDir.children.unshift(cover, volumeDir);
        parentDir.children.push(backcover);
    },
    setSpeFile(dir) {       //  11-封皮，12-卷内目录，13-封底
        let file = {
            id: (state.id++).toString(),
            name: dir.type === 11 ? '封皮' : dir.type === 12 ? '卷内目录' : '封底',
            pid: dir.id,
            fileType: 'image/png',
            filePath: '',
            selected: false,
            isLocal: true,
            isRender: false,
            dataType: 'file'
        }
        return file;
    },
}

const viewFun = {       //  显示类型函数
    treeIcon(data){
        if(data.dataType === 'dir'){
            if(data.type === 4){
                let hasFile = this.hasFile(data);
                if(!hasFile){
                    return state.staticImg['empty'];
                }
            }
            return state.staticImg[data.type];
        }else if(data.dataType === 'file'){
            return state.treeIcon[data.fileType] || state.treeIcon['other'];
        }
    },
    tagIcon(value){      //     标签value
        return state.tagImg[value] || state.tagImg['tag'];
    },
    tagLabel(value){
        let dictList = [];
        Object.keys(state.dictMap).forEach(key => {
            dictList.push(...state.dictMap[key]);
        })
        let dict = dictList.find(item => item.value === value);
        return dict && dict.label;
    }
}

const viewModelMapping = {
    'gird': 'GridView',
    'list': 'ListView',
    'axis': 'AxisView',
    'batch': 'BatchView'
}
const reviewSettings = store.getters.configs['reviewSettings'];

export const state = Vue.observable({
    vueComData: {
        
    },
    caseInfo: null,
    subCaseList: [],
    dirList: [],        //  目录列表
    dirObj: {},         //  目录列表(对象型)
    activeFile: null,     //  文件详情
    currentDir: {},       //  当前目录
    currentFile: {},        //  当前文件(左侧选中文件)   左右互相关联(左侧选中与滚动条)
    treeId: '',             //  左侧树节点key           左右互相关联(左侧选中与滚动条)
    dirCategory: 'base',       //  目录模板类型
    ...staticObj,
    showEmptyDir: false,
    moveStatus: {
        dragData: null,
        dragType: 'file',
        dropData: null,
        dropType: 'dir',
        dragStatus: 'before',     //  before,inner,after----代表拖拽落点状态
    },
    id: 1,
    keyCode: {
        ctrl: false,
        shift: false
    },
    protectionEye: false,           //  护眼模式
    htmlTemplate: {
        cover: '',
        backcover: '',
        bookMark: ''
    },
    records: [],    //  最新操作历史记录
    configs: store.getters.configs,
    viewModel: viewModelMapping[reviewSettings && reviewSettings['view']] || 'GridView',  //  视图模式(网格、列表、轴线、批次轴线)
    // viewModel: 'GridView',  //  视图模式(网格、列表、轴线、批次轴线)
    mainScrollTop: 0,           //  主页main滚动条高度
    lastClickIndex: 0,      //  多选情况下开始下标
    dictMap: {},            //  数据字典
    hasReadPermit: false,
    tagFilterDirList: null,
    fileShowModel: 'MEDIA',
    tempDirList: [],        //  暂存的侧边栏目录数据
    getDirLoading: false,   //  获取目录信息的loading
    dataList: [],           //  当前视图内所有文件
})

export const mutations = {
    ...staticFun,
    ...oneFun,
    ...viewFun,
    updateHistory() {        //  更新操作记录
        let params = {
            current: 1,
            size: 8,
            caseId: state.caseInfo.caseId
        }
        getRecords(params).then(res => {
            state.records = res.records;
        })
    },
    recursion(arr, cb, children = 'children') {
        if (!arr) return;
        let flag = false;
        function traverse(arr) {
            for (let index = 0; index < arr.length; index++) {
                const item = arr[index];
                if (cb(item, index, arr)) {
                    flag = true;
                }
                if (flag) return;
                if (item[children] && item[children].length > 0) {
                    traverse(item[children]);
                }
            }
        }
        traverse(arr);
    },
    hasFile(dir) {       //  目录及子目录下是否有文件(特殊目录为有文件)
        let flag;
        if(dir.dataType === 'dir'){
            flag = dir.children.find(item => item.dataType === 'file');
            if(dir.isLocal){
                flag = true;
            }
        }
        if(dir.dataType === 'file'){
            flag = true;
        }
        this.recursion(dir.children || [], item => {
            if(item.isLocal){
                return flag = true;
            }
            if(item.dataType === 'file'){
                return flag = true;
            }
            if(item.dataType === 'dir'){
                if(item.children && item.children.find(el => el.dataType === 'file')){
                    return flag = true;
                }
            }
        })
        return flag;
    },
    getResFileName(response) {   //  获取响应文件流的文件名
        let fileName = '';
        let contentDisposition = response.headers['content-disposition'];
        if (contentDisposition) {
            // fileName = window.decodeURI(contentDisposition.split('=')[1],"UTF-8");
            fileName = /"(.*)"/.exec(window.decodeURI(contentDisposition))[1];
        }
        return fileName;
    },
    fileDownload(data,options){
        const { files = [] , dirs = [] , speFiles = [] } = data;
        let params = {
            caseId: state.caseInfo.caseId,
            category: state.dirCategory,
            outType: options.outType,
            watermark: options.watermark || '',
            convertType: options.convertType || '',
            // contentType: 'application/pdf',
            // fileIds: selectFiles.map(item => item.id).join(','),
            // directoryIds: selectDirs.map(item => item.id).join(','),
            fileIds: files.map(item => item.id),
            directoryIds: dirs.map(item => item.id),
            uuid: guid(),
        }
        
        if(options.outType === 'pdf'){          //  输出为PDF
            if(files.length || dirs.length || speFiles.length){
                if(files.length || dirs.length){        //  w
                    if(options.type === 'print'){       //  打印在浏览器本地，不超过500页(浏览器缓存有限)
                        params.contentType = 'application/pdf';
                        mergePDF(params,'print'); 
                    }else{
                        mergePDF(params,'download');           //  合并PDF
                    }
                }

                if(speFiles.length){        //  特殊文件只能调用转化接口
                    let form = new FormData();
                    form.append('contentType','application/pdf');
                    speFiles.forEach(file => {
                        form.append('content',file.filePath.replace(/data:image\/.*base64,/,''));
                    })
                    base64ToPDF(form).then(res => {
                        const fileName = this.getResFileName(res);
                        const blob = res.data;
                        if(options.type === 'print'){
                            parseFile(blob,'',false);
                        }else{
                            parseFile(blob,fileName,true);
                        }
                    })
                }
            }else{
                Message.warning('请选择文件!');
            }
        }

        if(options.outType === 'zip'){
            if(files.length || dirs.length || speFiles.length){
                if(files.length || dirs.length){
                    fileDownload(params,'download');
                }
                if(speFiles.length){        //  特殊目录只能下载原文件
                    speFiles.forEach(file => {
                        urlDownload(file.filePath,file.name);
                    })
                }
            }else{
                Message.warning('请选择文件!');
            }
        }

    },

    setCurrentDir(data) {       //  切换目录(/文件)(点击左侧树)
        if(data){
            if(data.dataType === 'dir'){
                state.treeId = data.id;
                if(state.viewModel === 'AxisView' || state.viewModel === 'BatchView'){      //  轴线、批次视图
                    if(data.isLocal){       
                        state.currentFile = data.fileList[0];
                    }else{
                        this.recursion(data.children,item => {
                            if(item.isLocal){
                                return state.currentFile = item.fileList[0];
                            }
                            if(item.dataType === 'file'){
                                return state.currentFile = item;
                            }
                        })
                    }
                }else{
                    //  取消之前目录内文件选中状态(仅在网格和列表视图中)
                    state.currentDir.children && state.currentDir.children.forEach(item => item.selected = false);
                }
                data.children && data.children.forEach(item => item.selected = false);
                state.currentDir = data || {};
            }else if(data.dataType === 'file'){
                //  选中文件并跳转至对应目录文件
                
                state.currentDir = state.dirObj[data.pid];
                state.currentFile = data;
            }
            state.activeFile = null;
            if (state.viewModel != 'AxisView') {  //  轴线视图下目录为标识
                this.setLastClickIndex(0);  //  初始化选择文件的下标
            }
            this.setDataList();
        }
    },
    setDataList(){      //  设置当前视图内所有文件(方便选择文件)
        let dataList = [];      
        if(state.viewModel === 'AxisView' || state.viewModel === 'BatchView'){      //  轴线、批次视图
            dataList = this.getAllFiles(true);
        }else{
            dataList = state.currentDir.children;
        }
        state.dataList = dataList;
    },
    setViewModel(name){     //  切换视图
        state.viewModel = name;
        state.treeId = state.currentDir.id;
        this.getAllFiles(true).forEach(item => item.selected = false);
        this.setDataList();
    },
    dataReset(){        //  初始数据重置
        state.dirList = [];
        // state.dictMap = {};
        state.dirObj = {};
        state.currentDir = [];
        state.activeFile = null;
        state.currentFile = null;

    },
    setDirCategroy(type) {      //  切换案件目录类型
        if(type === state.dirCategory){
            return;
        }
        state.dirCategory = type;
        this.dataReset();
        
        if(type === 'none'){
            state.fileShowModel = 'OWNER';
            this.getDirList({category: 'base'}).then(res => {
                //  点击归属按钮,筛选文件,重组目录
                let tagFiles = JSON.parse(JSON.stringify(this.getAllFiles()));

                const dictList = state.dictMap['OWNER'];
                let newList = [];
                let globalIndex = 1;
                [{
                    label: '未标记',
                    value: 'none',
                    type: 'none',
                }].concat(dictList).forEach(dict => {
                    let fileList = tagFiles.filter(item => item.tags['OWNER'] === dict.value);
                    if(dict.value === 'none'){
                        fileList = tagFiles.filter(item => !item.tags['OWNER']);
                    }
                    let newDir = {
                        id: dict.value,
                        name: dict.label,
                        category: dict.type,
                        value: dict.value,
                        children: fileList,
                        dataType: 'dir',
                        type: 4,
                    }

                    fileList.forEach(item => {
                        item.pid = newDir.id;
                        item.globalIndex = globalIndex ++;
                    });
                    newList.push(newDir);
                    state.dirObj[newDir.id] = newDir;
                })
                
                state.dirList = newList;
                state.currentDir = dictList[0];
                state.tempDirList = JSON.parse(JSON.stringify(newList));
            })
            
        }else{
            state.fileShowModel = 'MEDIA';
        }
    },
    filterListByTag(tag){       //  根据标签筛选左侧列表（目录文件）
        const dirList = JSON.parse(JSON.stringify(state.tempDirList));
        
        // return;
        if(tag.value === 'all'){
            state.dirList = dirList;
        }else{
            let files = [];
            this.recursion(dirList,item => {
                if(tag.value === 'SOURCE' || tag.value === 'OWNER' || tag.value === 'MEDIA'){
                    if(item.tags && item.tags[tag.value]){
                        files.push(item);
                    }
                }
                if(item.dataType === 'file' && item.tags && item.tags[tag.type] === tag.value){
                    files.push(item);
                }
            })
            let newTree = getTreeByChildren(dirList,files);
            state.dirList = newTree;
        }

    },
    setTagKeyword(data){         //  切换文件标签查询条件

        this.filterListByTag(data);

        return;
    },
    
    setCaseInfo(data) {
        state.caseInfo = data;
    },
    setDirList(data) {
        state.dirList = data;
    },
    setDirObj(data) {
        state.dirObj = data;
    },
    setActiveFile(data) {
        state.activeFile = data;
    },
    setSubCaseList(data){
        state.subCaseList = data;
    },
    setRoute(params) {   //  同步当前url的params参数
        let str = '?';
        Object.keys(params).forEach(key => {
            str += `${key}=${params[key]}&`
        })
        str = str.substring(0, str.length - 1);
        history.pushState(null, null, str);
    },
    fileOrder(files) {   //  文件目录内排序
        files.forEach((file, index) => {
            file.fileIndex = index + 1;
            file.selected = false;
        })
        this.fileGlobalOrder();
    },
    fileGlobalOrder(){  //  文件全局排序(计算pdf文件页数)
        this.recursion(state.dirList,volume => {
            if(volume.type === 1 || volume.type === 2){
                let count = 1;
                this.recursion(volume.children,item => {
                    if(item.dataType === 'file' && !item.isLocal){
                        item.globalIndex = count;
                        count += item.pageNum || 1;     //  计算pdf文件页数
                    }
                })
            }
        })
    },
    dirOrder(dirs) {     //  目录内排序
        if (dirs instanceof Array && dirs.length > 0) {
            if (dirs[0].type === 1 || dirs[0].type === 2) {
                for (let i = 2; i < dirs.length; i++) {
                    dirs[i].sn = i + 1 - 2;
                }
            } else {
                dirs.forEach((dir, index) => {
                    dir.sn = index + 1;
                })
            }
            this.globalOrder();
        }
    },
    volumeOrder(dir){    //  卷册目录内排序
        let localDir = dir.children.find(item => item.isLocal);     //  是否含有特殊目录
        if(localDir){
            dir.children.forEach((item,index) => item.sn = index + 1);
        }else{
            for (let i = 2; i < dir.children.length - 1; i++) {     //  忽略封皮封底卷内目录
                const item = dir.children[i];
                item.sn = i + 1;
            }
        }
    },
    globalOrder() {      //  调整目录排序以及文件全局排序(卷、册内单独排序)
        state.dirList.forEach(item => {
            let globalIndex = 0;
            this.recursion(item.children || [], dir => {
                if (dir.isLocal) {
                    return;
                }
                if (dir.children && dir.children.length > 0) {
                    const fileList = dir.children.filter(item => item.dataType === 'file');
                    fileList.forEach((file,index) => {
                        globalIndex++;
                        file.globalIndex = globalIndex;
                        file.fileIndex = index ++;
                    })
                }
            })
        })
        this.updateHistory();
    },
    setRecordData(str, url) {     //  请求携带操作记录文本
        store.commit('common/setRecord', {
            key: url,
            value: {
                _log_notes: encodeURIComponent(str),
                _log_params: {
                    caseId: state.caseInfo.caseId
                }
            }
        })
    },
    drop() {     //  所有推拽事件
        let { dragData, dragType, dropData, dropType, dragStatus } = state.moveStatus;
        if (dragData === dropData) {
            return;
        }

        if (dragType === 'file' && dropType === 'file') {
            let dragDir = state.dirObj[dragData.pid];
            let dropDir = state.dirObj[dropData.pid];
            let startIndex = dragDir.children.filter(item => item.dataType === 'file').indexOf(dragData);
            let endIndex = dropDir.children.filter(item => item.dataType === 'file').indexOf(dropData);
            if(dragDir === dropDir){
                if (startIndex < endIndex) {
                    if (dragStatus === 'before') {
                        endIndex--;
                    }
                } else {
                    if (dragStatus === 'after') {
                        endIndex++;
                    }
                }
                this.setRecordData(`${dragData.name}至${dropData.name}${dragStatus === 'before' ? '之前' : '之后'}`, '/caseinfo/file/move');
            }else{
                if (dragStatus === 'after') {
                    endIndex++;
                }
                this.setRecordData(`${dragData.name}至${dropData.name}${dragStatus === 'before' ? '之前' : '之后'}`, '/caseinfo/file/move');
            }
            let params = {
                caseId: state.caseInfo.caseId,
                category: state.dirCategory,
                dirId: dropDir.id,
                fileId: dragData.id,
                fileIndex: endIndex + 1
            }
            
            moveFile(params).then(res => {
                dragDir.children.splice(dragDir.children.indexOf(dragData),1);
                dropDir.children.splice(endIndex, 0, dragData);

                dragData.pid = dropDir.id;
                this.fileOrder(dragDir.children.filter(item => item.dataType === 'file'));
                this.fileOrder(dropDir.children.filter(item => item.dataType === 'file'));
            })

        } else if (dragType === 'file' && dropType === 'dir') {
            dragData.selected = true;
            let files = this.getAllFiles().filter(item => item.selected);
            let dirs = [];      //  有文件移动的目录
            files.forEach(file => {
                let dir = state.dirObj[file.pid];
                if (!dirs.includes(dir)) {
                    dirs.push(dir);
                }
            })
            let params = {
                caseId: state.caseInfo.caseId,
                category: state.dirCategory,
                dirId: dropData.id,
                fileIds: files.map(item => item.id)
            }

            sortFile(params).then(res => {
                dirs.forEach(dir => {
                    batchDel(dir.children, item => item.selected);   //  被移动文件目录内文件排序
                    this.fileOrder(dir.children);
                })

                dropData.children ? '' : dropData.children = [];
                dropData.children.push(...files);
                files.forEach(file => file.pid = dropData.id);     //  修改目录ID
                this.fileOrder(dropData.children);
            })

        } else if (dragType === 'dir' && dropType === 'dir') {
            let isChild = false;
            this.recursion(dragData.children, item => {
                if (item === dropData) {
                    return isChild = true;
                }
            })
            if (isChild) {
                return;
            }
            dropData.children ? '' : Vue.set(dropData, 'children', []);
            let dragDirs = dragData.pid != "0" ? state.dirObj[dragData.pid].children : state.dirList;

            let dropDirs = dropData.pid != "0" ? state.dirObj[dropData.pid].children : state.dirList;
            if(dragStatus === 'inner'){
                dropDirs = dropData.children;
            }
            // debugger
            let dragIndex = dragDirs.indexOf(dragData);
            let dropIndex = dropDirs.indexOf(dropData);
            let sn = dropIndex;
            if (dragDirs === dropDirs) {      //  同目录移动
                if(dragStatus === 'before' && dragIndex < dropIndex){
                    sn -= 1;
                }
                if(dragStatus === 'after' && dragIndex > dropIndex){
                    sn += 1;
                }

            } else {
                if(dragStatus === 'after'){
                    sn += 1;
                }
            }
            let hasLocal = dropDirs.find(item => item.isLocal);
            if(hasLocal){           //  卷册不计入页数中
                sn -= 2;
            }

            let params = {
                caseId: state.caseInfo.caseId,
                category: state.dirCategory,
                id: dragData.id,
                pid: dragStatus === 'inner' ? dropData.id : dropData.pid,
                sn: sn + 1
            }
            
            this.setRecordData(`目录<span style="color: #1976D2">《${dragData.name}》</span>至<span style="color: #1976D2">《${dropData.name}》</span>目录
                ${dragStatus === 'before' ? '之前' : dragStatus === 'after' ? '之后' : '子级'}`, '/caseinfo/directory/move');
                

            moveDir(params).then(res => {
                dragDirs.splice(dragIndex, 1);

                if(hasLocal){           //  卷册不计入页数中
                    sn += 2;
                }
                dropDirs.splice(sn, 0, dragData);
                if (dragStatus === 'inner') {
                    dragData.pid = dropData.id;
                    this.volumeOrder(dropData);
                } else {
                    dragData.pid = dropData.pid;
                    if(dropData.pid === "0"){
                        this.dirOrder(state.dirList);
                    }else{
                        this.dirOrder(state.dirObj[dropData.pid].children);
                    }
                }
            })
        }
    },
    getAllFiles(hasLocal) {
        let files = [];
        this.recursion(state.dirList, dir => {
            if(dir.dataType === 'file'){
                files.push(dir);
            }else if(dir.dataType === 'dir'){
                if(hasLocal && dir.isLocal){
                    files.push(...dir.fileList);
                }
            }
        })

        return files;     //  获取所有(文件/实体文件)
    },
    getVolumeAllFiles(dirList,hasLocal){
        let files = [];
        this.recursion(dirList, dir => {
            if(dir.dataType === 'file'){
                files.push(dir);
            }else if(dir.dataType === 'dir'){
                if(hasLocal && dir.isLocal){
                    files.push(...dir.fileList);
                }
            }
        })

        return files;     //  获取所有(文件/实体文件)
    },
    getDirChildren(volume) {     //  获取所有子级目录
        let dirs = [];
        this.recursion(volume.children, dir => {
            if(dir.isLocal){
                return;
            }
            if(dir.dataType === 'dir' && dir.children.find(item => item.dataType === 'file')){
                dirs.push(dir);
            }
        })
        return dirs;
    },
    htmlToImage(container, cb) {
        return new Promise((resolve, reject) => {
            if (cb instanceof Function) {
                cb();
            }
            htmlToImg(container).then(url => {
                resolve(url);
            })
        })
    },
    setCoverData() {
        let coverData = {};
        if(window.renderFun){
            coverData = window.renderFun({
                caseInfo: state.caseInfo,
                dirList: state.dirList
            });       //  模板中的script中的函数

        }
        // console.log(JSON.stringify(coverData));
        state.caseInfo.extra.BASE = coverData;
    },
    setCover(file) {
        if (file.isRender) {
            // return;
        } else {
            file.isRender = true;
        }
        return new Promise((resolve, rejct) => {
            let cover = state.dirObj[file.pid];
            let volume = state.dirObj[cover.pid];
            this.renderCover(volume).then(dataurl => {
                file.filePath = dataurl;
                resolve(dataurl);
            })
        })
    },
    renderCover(volume){
        let container;
        if(window.renderCover){
            container = window.renderCover(volume,{
                dirList: state.dirList,
                coverHTML: state.htmlTemplate.cover,
                coverData: state.caseInfo.extra.BASE
            });
            
        }else{
            console.error('模板没有封皮渲染函数!');
        }
        
            
        // container.querySelector('[data-id=JM]').innerHTML = volume.name;

        return new Promise((resolve, rejct) => {
            this.htmlToImage(container.children[0], () => {
                let nodeList = container.querySelectorAll('[data-id]');
                nodeList.forEach(node => {
                    let size = this.shrink(node.parentNode.clientHeight, node);
                    node.style.fontSize = `${size}px`;
                })
            }).then(dataurl => {
                resolve(dataurl);
            })
        })
    },
    shrink(clientHeight, el) {
        const max = parseInt(getComputedStyle(el).fontSize);
        const min = 12;
        const step = 0.5;
        let size = max;
        el.style.fontSize = `${size}px`;

        while (el.offsetHeight > clientHeight && size > min) {
            size -= step
            el.style.fontSize = `${size}px`
        }

        if (el.offsetHeight > clientHeight) {
            let lineHeight = parseFloat(getComputedStyle(el).lineHeight);
            let rows = (clientHeight / lineHeight);
            let rowLength = Math.floor(rows)
            let point = rows - rowLength;

            let height1 = point * lineHeight;   //  多余的高度
            let height2 = height1 / rowLength;  //  每一行多出的高度
            
            size += height2 / 1.2;
        }
        return size;
    },
    setBackcover(file) {
        if (file.isRender) {
            return;
        } else {
            file.isRender = true;
        }
        const container = document.createElement('div');
        container.innerHTML = state.htmlTemplate.backcover;
        return new Promise((resolve, reject) => {
            this.htmlToImage(container.children[0]).then(dataurl => {
                file.filePath = dataurl;
                resolve(dataurl);
            })
        })

    },
    setBookMark(file) {     //  卷内目录
        if (file.isRender) {
            return;
        } else {
            file.isRender = true;
        }
        if(window.renderBookmark){
            let containerList = window.renderBookmark({
                tempHTML: state.htmlTemplate.bookMark,
                volume: state.dirObj[state.dirObj[file.pid].pid]
            });

            return new Promise((resolve, reject) => {
                this.htmlToImage(containerList[file.fileIndex - 1]).then(dataurl => {
                    file.filePath = dataurl;
                    resolve(dataurl);
                })
            })

        }else{
            console.error('卷内目录模板没有渲染函数!');
        }
    },
    selectFile(targetFile, files) {       //      选中文件
        if(!targetFile){
            return;
        }
        const { ctrl, shift } = state.keyCode;   //  按键状态
        const lastClickIndex = state.lastClickIndex;    //  上一次选择
        if(!files){
            files = state.dataList;
        }
        if (ctrl) {
            targetFile.selected = !targetFile.selected;
            this.setLastClickIndex(files.findIndex(file => file === targetFile))
        } else if (shift) {
            files.forEach(item => item.selected = false);
            let index = files.findIndex(item => item === targetFile);
            if (lastClickIndex <= index) {
                for (let i = lastClickIndex; i <= index; i++) {
                    files[i].selected = true;
                }
            } else {
                for (let i = index; i <= lastClickIndex; i++) {
                    files[i].selected = true;
                }
            }
        } else {
            this.setLastClickIndex(files.findIndex(file => file === targetFile))
            files.forEach(item => item.selected = false);
            targetFile.selected = true;
            this.setActiveFile(targetFile);
        }
    },
    setLastClickIndex(val) { //  设置最后一次点击的文件下标
        state.lastClickIndex = val;
    },
    noteToImage(url){
        return new Promise((resolve,reject) => {
            axios.get(url).then(res => {
                const html = res.data;
                const container = document.createElement('div');
                // container.classList.add('ke-content');
                container.style.width = '794px';
                container.style.height = '1123px';
                container.style.padding = '128px 134px';
                container.style.boxSizing = 'border-box';
                container.innerHTML = html;
                const styles = `
                    html {margin:0;padding:0;}
                    body {margin:0;padding:5px;}
                    body, td {font:12px/1.5 "sans serif",tahoma,verdana,helvetica;}
                    body, p, div {word-wrap: break-word;}
                    p {margin:5px 0;}
                    table {border-collapse:collapse;}
                    img {border:0;}
                    noscript {display:none;}
                    table.ke-zeroborder td {border:1px dotted #AAA;}
                `

                htmlToCanvas(container,styles).then(canvas => {
                    resolve(canvas.toDataURL('image/png'))
                })
            })

        })
    }
}

