<template>
    <div class="file-item" @click="$emit('selectFile',file)" :class="{'active': file.selected}" @dblclick="$emit('dbClick',file)" 
        draggable
        @dragstart="dragstart"
        @dragover.prevent="dragover"
        @dragleave="dragleave"
        @drop="drop"
        v-read="{file,scale: 0.97}">
        <div class="checkbox" @click.stop>
            <el-checkbox v-model="file.selected"></el-checkbox>
        </div>
        <div ref="img" class="img-box">
            <!-- <img :data-id="file.id" draggable="false" :src="lazy ? '' : file.thumbnailPath || file.filePath" alt=""> -->
            <div class="img">
                <FileViewer :data-id="file.id" v-if="!lazy" :type="file.fileType" :url="file.thumbnailPath || file.filePath"></FileViewer>
            </div>
            <div class="point" :style="pointStyle">{{fileGlobalIndex}}</div>
            <div class="read" v-if="this.file.extra && this.file.extra.readFlag === 0 && state.hasReadPermit">未读</div>
            <div class="left-point" url="/caseinfo/file/tags" v-hasPermit>
                <span v-if="file.tags && file.tags['SOURCE']" class="source" v-tooltip :data-tip="tagLabel('SOURCE')">
                    <img :src="state.tagImg[file.tags['SOURCE']]" alt="">
                </span>
                <span v-if="file.tags && file.tags['MEDIA']" class="medium" v-tooltip :data-tip="tagLabel('MEDIA')">
                    <img :src="state.tagImg[file.tags['MEDIA']]" alt="">
                </span>
                <span v-if="file.tags && file.tags['OWNER']" class="belong" v-tooltip :data-tip="tagLabel('OWNER')">
                    <img src="@/assets/fileTypeIcon/tag.png" alt="">
                </span>
            </div>
        </div>
        <div class="file-name" ref="fileName">
            <el-input v-if="modify" @keydown.native="keydown" @blur="modify = false" ref="input" size="mini" v-model="modifyText" 
                draggable="true" @dragstart.native.prevent.stop>
            </el-input>
            <p v-else @click.stop="renameClick" :title="file.name">{{file.name}}</p>
        </div>
        
    </div>
</template>

<script>
import { fileRename } from '@/api/fileAPI.js';
import { numFix } from '@/utils/f.js';
import FileViewer from '@/common/fileViewer';

export default {
    name: 'FileModel',
    components: {
        FileViewer
    },
    props: {
        file: {
            type: Object,
            default(){
                return {};
            }
        },
    },
    inject: ['fileListState','state','mutations'],
    computed: {
        imgNode(){
            return this.$refs.img;
        },
        moveStatus(){
            return this.state.moveStatus;
        },
        imgScale(){
            return this.$store.getters.imgScale;
        },
        fileGlobalIndex(){
            const extra = this.state.caseInfo.extra;
            if(extra && extra.pageRule){
                const length = extra.pageRule.length;
                const num = extra.pageRule.start + this.file.globalIndex;
                return numFix(num || '',length)
            }else{
                return this.file.globalIndex || '';
            }
        },
        pointStyle(){
            const extra = this.state.caseInfo.extra;
            if(extra && extra.pageRule){
                const position = extra.pageRule.position;
                if(position === 'RIGHT-BOTTOM'){
                    return {
                        right: '10px',
                        bottom: '5px',
                        top: 'auto'
                    }
                }
            }else{
                return {};
            }
        }
    },
    data(){
        return {
            showImageViewer: false,
            modify: false,
            modifyText: '',
            lazy: true,
            tempText: ''
        }
    },
    watch: {
        'state.mainScrollTop': {    //..懒加载图片
            handler(val){
                this.$nextTick(() => {
                    const imgTop = this.imgNode.getBoundingClientRect().top;
                    if(imgTop < window.innerHeight){
                        this.lazy = false;
                        if(this.file.isLocal && !this.file.isRender){   //  为封皮等特殊图片
                            switch(this.file.name){
                                case '封皮':
                                    if(window.renderCover)
                                    this.mutations.setCover(this.file);
                                break;
                                case '卷内目录':
                                    if(window.renderBookmark)
                                    this.mutations.setBookMark(this.file);
                                break;
                                case '封底':
                                    this.mutations.setBackcover(this.file);
                                break;
                            }
                        }
                    }
                })
            },
            immediate: true
        },
        imgScale: {
            handler(val){
                this.$nextTick(() => {
                    this.imgNode.style.height = this.fileListState.maxHeight * val / 100 + 'px';
                    this.imgNode.style.width = this.fileListState.maxHeight * val / 100 * 0.7 + 'px';
                    this.$refs.fileName.style.width = this.fileListState.maxHeight * val / 100 * 0.7 + 'px';
                }) 
            },
            immediate: true
        },
        modify: {
            handler(val){
                if(val){
                    this.$nextTick(() => {
                        // this.tempText = /(.*)\./.exec(this.file.name)[1];
                        let text = /(.*)\./.exec(this.file.name);
                        if(text){
                            this.modifyText = text[1];
                        }else{
                            this.modifyText = this.file.name;
                        }
                        
                        this.$refs.input.focus();
                        this.$refs.input.select();
                    })
                }else{
                    const ex = this.file.filePath.match(/[\.]\w*$/);        //  后缀
                    let text = this.modifyText;
                    if(ex){
                        text += ex[0];
                    }else{
                        let nameSuffix = this.file.name.match(/[\.]\w*$/);
                        if(nameSuffix){
                            text += nameSuffix[0];
                        }
                    }
                    if(text != this.file.name){
                        let str = `${this.file.name}为${text}`;
                        this.mutations.setRecordData(str,'/caseinfo/file/rename');
                        fileRename({
                            caseId: this.state.caseInfo.caseId,
                            fileInfos: [
                                {
                                    id: this.file.id,
                                    name: text
                                }
                            ]
                        }).then(res => {
                            this.file.name = text;
                        })
                    }
                }
            }
        },
        'file.selected': {
            handler(val){
                this.$emit('selectChange');     //  selectFile可能不会触发,因为shift全选的情况
            }
        }
    },
    methods: {
        tagLabel(type){

            let key = this.file.tags[type];     //  标签的value
            let tag = this.state.dictMap[type] && this.state.dictMap[type].find(item => item.value === key);
            return tag && tag.label;
        },
        keydown(ev){
            if(ev.key === 'Enter'){
                this.modify = false;
            }
        },
        dragstart(ev){
            if(this.state.viewModel === 'AxisView' || this.state.dirCategory === 'none'){
                ev.preventDefault();
                return;
            }
            
            if(this.fileListState.orderRule != 'default' || this.fileListState.keyword != ''){
                this.$message.error('在非默认排序和关键字搜索的情况下不允许重新排序！');
                ev.preventDefault();
                return;
            }
            this.moveStatus.dragData = this.file;
            this.moveStatus.dragType = 'file';
        },
        dragover(ev){
            if(this.moveStatus.dragData === this.file || this.state.viewModel === 'BatchView'){
                return;
            }
            ev.currentTarget.style.border = '';
            if(ev.offsetX <= this.$el.offsetWidth / 2){
                ev.currentTarget.style.borderLeft = '1px solid red';
                this.moveStatus.dragStatus = 'before';
            }else{
                ev.currentTarget.style.borderRight = '1px solid red';
                this.moveStatus.dragStatus = 'after';
            }
        },
        dragleave(ev){
            ev.currentTarget.style.border = '';
        },
        drop(ev){
            if(this.state.viewModel === 'BatchView'){
                return;
            }
            ev.currentTarget.style.border = '';
            this.moveStatus.dropData = this.file;
            this.moveStatus.dropType = 'file';
            this.mutations.drop();
        },
        checkboxInput(){
            this.file.selected = !this.file.selected;
        },
        renameClick(){
            if(this.file.isLocal){
                return;
            }else{
                this.modify = true;
            }
        }
    }
}
</script>

<style scoped lang='less'>
/deep/ .el-input input{
    text-align: center;
}
.file-item{
    padding: 15px 25px;
    margin: 15px 20px;
    position: relative;
    border: 1px solid transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    .checkbox{
        display: none;
        position: absolute;
        top: 2px;
        right: 5px;
    }
    .img-box{
        margin-bottom: 10px;
        width: 70px; 
        height: 100px;
        border: 1px solid #ccc;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        box-sizing: border-box;
        .point{
            // width: 14px;
            // height: 14px;
            position: absolute;
            right: 10px;
            top: 5px;
        }
        .left-point{
            position: absolute;
            left: 10px;
            top: 5px;
            display: flex;
            > span{
                margin-right: 5px;
                width: 20px;
                height: 16px;
                img{
                    width: 100%;
                }
            }
        }
        .read{
            width: 30px;
            height: 18px;
            text-align: center;
            line-height: 18px;
            font-size: 12px;
            color: #fff;
            background-color: #FF6262;
            position: absolute;
            left: 0;
            bottom: 5px;
        }
        .img{
            flex: 1;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }
    }
    .file-name{
        text-align: center;
        // height: 28px;
        line-height: 28px;
        > p{
            // overflow: hidden;
            // text-overflow:ellipsis; 
            // word-wrap: break-word;
            // display: -webkit-box;
            // -webkit-line-clamp: 2;
            // -webkit-box-orient: vertical;
            // /*! autoprefixer: off */
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
.file-item:hover{
    background-color: #E5F3FF;
    .checkbox{
        display: inline-block;
    }
}
.file-item.active{
    background-color: #CCE8FF;
    border-color: #1976D2;
    .checkbox{
        display: inline-block;
    }
}
</style>