<template>
    <div class="spec-model">
        <component :is="comName" :currentDir="currentDir"></component>
    </div>
</template>

<script>
import Cover from './Cover.vue';
import BookMark from './BookMark.vue';
import CoverBack from './CoverBack.vue';

export default {
    components: {
        Cover,BookMark,CoverBack
    },
    inject: ['state'],
    data(){
        return {
            comName: ''
        }
    },
    computed: {
        currentDir(){
            return this.state.currentDir;
        },
    },
    watch: {
        'currentDir': {
            handler(val){
                if(!val){
                    return;
                }
                if(val.type === 11){
                    this.comName = 'Cover';
                }else if(val.type === 12){
                    this.comName = 'BookMark';
                }else if(val.type === 13){
                    this.comName = 'CoverBack';
                }
            },
            immediate: true
        }
    },
    methods:{
        
    }
}
</script>

<style scoped lang='less'>
    .spec-model{
        height: 100%;
        overflow: auto;
    }
</style>