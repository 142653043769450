<template>
    <div class="slider">
        <div ref="point" class="point" @mousedown="mousedown"></div>
    </div>
</template>

<script>
export default {
    computed: {
        pointNode(){
            return this.$refs.point;
        },
        startX(){
            return this.$el.getBoundingClientRect().left + this.offsetX;
        },
        maxWidth(){
            return this.$el.offsetWidth - this.pointNode.offsetWidth;
        },
        minOffsetX(){
            return this.startX + this.min * this.maxWidth / 100;
        },
        maxOffsetX(){
            return this.startX + this.max * this.maxWidth / 100;
        }
    },
    data(){
        return {
            offsetX: 0,     //  偏移位置

        }
    },
    props: {
        value: {
            type: Number,
            default: 0
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 100
        }
    },
    watch: {
        value: {
            handler(val){
                this.$nextTick(() => {
                    this.pointNode.style.left = this.maxWidth * val / 100 + 'px';
                })
            },
            immediate: true
        }
    },
    methods: {
        mousedown(ev){
            this.offsetX = ev.offsetX;
            document.addEventListener('mousemove',this.mousemove);
            document.addEventListener('mouseup',this.mouseup);
        },
        mousemove(ev){
            if(ev.pageX <= this.minOffsetX){
                this.$emit('input',this.min);
                return;
            }else if(ev.pageX >= this.maxOffsetX){
                this.$emit('input',this.max);
                return;
            }
            this.$emit('input',(ev.pageX - this.startX) / this.maxWidth * 100);
        },
        mouseup(){
            document.removeEventListener('mousemove',this.mousemove);
            document.removeEventListener('mouseup',this.mouseup);
        }
    }
}
</script>

<style scoped lang='less'>
.slider{
    width: 200px;
    height: 1px;
    background: #999999;
    position: relative;
    // cursor: pointer;
    .point{
        width: 12px;
        height: 12px;
        background: #FFFFFF;
        border: 1px solid #999999;
        border-radius: 50%;
        position: absolute;
        top: -7px;
        cursor: pointer;
    }

}
</style>