<template>
    <div class="grid-view scroll-container"  @scroll="scroll" >
        <div class="list-container" :style="{height: `${containerHeight}px`}">
            <ul class="grid-list" v-show="reload">
                <FolderItem 
                    v-for="item in dirList"
                    :key="item.id"
                    :dir="item"
                    @selectFile="selectFile"
                    @selectChange="selectChange"
                    @dbClick="toDir"
                ></FolderItem>
            </ul>
            <ul class="grid-list" ref="fileList" v-show="reload" :style="{'padding-top': `${paddingTop}px`}">
                <FileModel 
                    v-for="item in files"
                    :key="item.id"
                    :file="item"
                    :imgScale="imgScale"
                    :maxHeight="fileListState.maxHeight"
                    @selectFile="selectFile"
                    @selectChange="selectChange"
                    @dbClick="dbClick"
                ></FileModel>
            </ul>
        </div>
    </div>
</template>

<script>
import FileModel from '@/components/model/FileModel.vue';
import FolderItem from './FolderItem.vue';
import { throttle , debounce } from '@/utils/f.js';

export default {
    components: {
        FileModel,FolderItem,
    },
    inject: ['state','mutations','fileListState'],
    props: {
        keyword: String,
        orderFun: Function,
    },
    computed: {
        currentDir(){
            return this.state.currentDir || {};
        },
        showEmptyDir(){
            return this.state.showEmptyDir;
        },
        imgScale(){
            return this.$store.getters.imgScale;
        },
        paramsChange(){
            return {
                showEmptyDir: this.showEmptyDir,
                keyword: this.keyword,
                children: this.currentDir.children,
                maxHeight: this.fileListState.maxHeight,
                imgScale: this.imgScale,
                orderFun: this.orderFun
            }; 
        },
    },
    data(){
        return {
            dataList: [],
            fileList: [],
            dirList: [],
            files: [],
            containerHeight: 0,
            containerWidth: 0,
            paddingTop: 0,
            dirCol: 0,
            dirRow: 0,
            dirWidth: 162,
            dirHeight: 171,
            reload: true,
            col: 0,
            row: 0,
        }
    },
    watch: {
        paramsChange: {
            handler(val){
                let list = this.currentDir.children || [];
                this.dataList = list.filter(item => {
                    if(item.name.includes(this.keyword)){
                        if(item.dataType === 'file'){
                            return true;
                        }else if(item.dataType === 'dir' && !item.isLocal){
                            if(this.showEmptyDir){
                                return true;
                            }else{
                                let hasFile = item.children.find(el => el.dataType === 'file');
                                return hasFile;
                            }
                        }
                    }
                })
                this.fileList = this.dataList.filter(item => item.dataType === 'file').sort(this.orderFun);
                this.dirList = this.dataList.filter(item => item.dataType === 'dir').sort(this.orderFun);
                // console.log(this.fileList);

                this.$nextTick(() => {
                    this.initContainer();
                    this.filterFiles();
                })
            },
            immediate: true
        },
        'state.currentFile': {
            handler(file){
                const index = this.dataList.indexOf(file);
                const invisibleRow = Math.ceil((index + 1) / this.col) - 1;
                const height = invisibleRow * this.fileListState.fileContainerHeight + this.dirRow * this.dirHeight;
                this.$el.scrollTop = height;
            }
        }
    },
    created(){
        this.throttleFn = throttle(this.filterFiles,50);
        // this.debounceFn = debounce(this.filterFiles,20);
    },
    methods:{
        initContainer(){
            this.$el.scrollTop = 0;
            this.paddingTop = 0;
            this.initScrollData();
        },
        initScrollData(){
            this.dirCol = this.$el.offsetWidth / this.dirWidth;      //  目录行列数
            this.dirRow = Math.ceil(this.dirList.length / this.dirCol);

            this.containerHeight += this.dirRow * this.dirHeight;

            this.col = Math.floor(this.$el.offsetWidth / this.fileListState.fileContainerWidth);        //  文件行列数
            this.row = Math.ceil(this.fileList.length / this.col);

            this.containerHeight += this.row * this.fileListState.fileContainerHeight;
            
            if(this.containerHeight > this.$el.offsetHeight){
                this.containerWidth = this.$el.offsetWidth - 17;        //  滚动条的宽度

                this.dirCol = this.containerWidth / this.dirWidth;      //  目录行列数
                this.dirRow = Math.ceil(this.dirList.length / this.dirCol);

                this.col = Math.floor(this.containerWidth / this.fileListState.fileContainerWidth);
                this.row = Math.ceil(this.fileList.length / this.col);

                this.containerHeight = this.dirRow * this.dirHeight + this.row * this.fileListState.fileContainerHeight;
            }
        },
        scroll(){
            this.throttleFn();
        },
        filterFiles(){
            this.initScrollData();
            const scrollTop = this.$el && this.$el.scrollTop || 0;

            const offset = this.dirRow * this.dirHeight;        //  目录元素占的高度
            // console.log(scrollTop - offset);
            let invisibleHeight = scrollTop - offset;
            if((scrollTop - offset) < 0){
                invisibleHeight = 0;
            }
            
            const invisibleRow = Math.floor(invisibleHeight / this.fileListState.fileContainerHeight);       //  上方不可见行数
            // console.log(invisibleRow);
            //  根据滚动条所在的位置，算出将渲染的元素位置
            const startIndex = invisibleRow * this.col;
            const innerRow = Math.floor(this.fileListState.maxHeight / this.fileListState.fileContainerHeight) + 2;     //  完全容纳最大值+上下2个
            const step = innerRow * this.col;

            this.paddingTop = invisibleRow * this.fileListState.fileContainerHeight;
            // console.log(this.paddingTop);
            const files = this.fileList.filter((item,index) => index > (startIndex - 1) && index <= step + (startIndex - 1));
            // console.log(files);
            
            this.reload = false;
            this.$nextTick(() => {
                this.files = files;
                this.$nextTick(() => {
                    this.reload = true;
                })
            })
            
        },
        selectFile(data){
            this.mutations.selectFile(data,this.dataList);
        },
        dbClick(data){
            this.mutations.toImgView(data,this.fileList);
        },
        toDir(data){
            if(data.id){
                this.mutations.setCurrentDir(data);
            }
        },
        selectChange(){
            let flag = this.dataList.find(item => !item.selected);
            this.$emit('selectChange',!flag);
        },
        
    }
}
</script>

<style scoped lang='less'>
.scroll-container{
    height: 100%;
    overflow: auto;
    .list-container{
        box-sizing: border-box;
    }
}
.grid-view{
    // position: relative;
    // height: 100%;
}
.grid-list {
    // height: 100%;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    user-select: none;
}
</style>