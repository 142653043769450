<template>
    <div class="cover">
        <div class="img-prev img-btn" @click="prev">
            <i class="el-icon-arrow-left"></i>
        </div>
        <div class="img-next img-btn" @click="next">
            <i class="el-icon-arrow-right"></i>
        </div>
        <div class="main">
            <div class="img-box" v-if="currentItem">
                <p>{{currentItem.name}}</p>
                <img :src="currentItem.dataurl" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            bookList: [],
            currentIndex: 0
        }
    },
    props: {
        form: {
            type: Object,
            default(){
                return {};
            }
        }
    },
    computed: {
        list(){
            let list = [];
            this.bookList.forEach(item => {
                item.volumeDirUrls.forEach(url => {
                    list.push({
                        name: item.name,
                        dataurl: url
                    })
                })
            })
            return list;
        },
        currentItem(){
            return this.list[this.currentIndex] || null;
        },
        excludes(){
            return this.form.pageRule.excludes;
        },
        startPage(){
            return this.form.pageRule.start;
        }
    },
    inject: ['state','mutations'],
    async created(){
        this.$parent.loading = true;

        let bookList = this.getBookList();
        for (let i = 0; i < bookList.length; i++) {
            const book = bookList[i];
            const containerList = window.renderBookmark({
                tempHTML: this.state.htmlTemplate.bookMark,
                volume: book,
                startPage: this.getStartPage(bookList.length)
            })
            let dataurlList = [];
            for (let j = 0; j < containerList.length; j++) {
                const container = containerList[j];
                const dataurl = await this.mutations.htmlToImage(container);
                dataurlList.push(dataurl);
            }
            book.volumeDirUrls = dataurlList;
        }
        this.bookList = bookList;
        this.$parent.loading = false;
        // console.log(bookList);
    },
    methods: {
        prev(){
            this.currentIndex --;
            if(this.currentIndex < 0){
                this.currentIndex = this.list.length - 1;
            }
        },
        next(){
            this.currentIndex ++;
            if(this.currentIndex > this.list.length - 1){
                this.currentIndex = 0;
            }
        },
        getBookList(){
            const dirList = this.$parent.dirList;
            const bookList = [];
            this.mutations.recursion(dirList,dir => {
                if(dir.type === 2){
                    bookList.push(dir);
                }
            })
            return bookList;
        },
        getStartPage(length){
            let pageNum = this.startPage;
            if(this.excludes.indexOf('封皮') === -1){
                pageNum += 1;
            }

            if(this.excludes.indexOf('卷内目录') === -1){
                pageNum += window.getBookmarkPage(length);
            }
            return pageNum;
        },
        async renderVolumeDir(volume){
            let childs = this.mutations.getDirChildren(volume);       //  获取所有子级
            let total = childs.reduce((total, item) => total + item.children.filter(item => item.dataType === 'file').length, 0);        //  文件总数
            // console.log(total);
            let dataList = [];

            let pageNum = this.startPage;
            if(this.excludes.indexOf('封皮') === -1){
                pageNum += 1;
            }

            if(this.excludes.indexOf('卷内目录') === -1){
                pageNum += Math.ceil(childs.length / 15)
            }

            let arr = [];
            childs.forEach((child,index) => {
                dataList.push({
                    index: index + 1,
                    name: child.name,
                    pageNum: pageNum
                })
                pageNum += child.children.length;
                if(index === childs.length - 1){
                    dataList[dataList.length - 1].pageNum += `~${pageNum - 1}`;
                }
                if((index + 1) % 15 === 0){
                    arr.push(JSON.parse(JSON.stringify(dataList)));
                    dataList = [];
                }
            });
            if(dataList.length){
                arr.push(JSON.parse(JSON.stringify(dataList)));
            }
            total += arr.length + 2;

            let dataurlList = [];
            for (let i = 0; i < arr.length; i++) {
                const list = arr[i];
                const dataurl = await this.renderTemplate(list,total);
                dataurlList.push(dataurl);
            }
            return dataurlList;

        },
        renderTemplate(list,total){
            const container = document.createElement('div');
            container.innerHTML = this.state.htmlTemplate.bookMark;
            const indexList = Array.from(container.querySelectorAll('[data-id=index]'));
            const dirList = Array.from(container.querySelectorAll('[data-id=dir]'));
            const pageList = Array.from(container.querySelectorAll('[data-id=page]'));
            list.forEach((item, index) => {
                indexList[index].textContent = item.index;
                dirList[index].textContent = item.name;
                pageList[index].textContent = item.pageNum;
            })
            container.querySelector('[data-id=page-count]').innerHTML = total;
            return new Promise((reslove, reject) => {
                this.mutations.htmlToImage(container).then(dataurl => {
                    reslove(dataurl);
                })
            })
        }
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
.cover{
    width: 100%;
    height: 95%;
    display: flex;
    justify-content: center;
    position: relative;
    .point{
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        top: -18px;
    }
    .main{
        width: 100%;
        .img-box{
            // width: 100%;
            height: 100%;
            text-align: center;
            p{
                height: 30px;
                line-height: 30px;
                text-align: center;
            }
            img{
                height: calc(100% - 30px);
                margin: 0 5px;
            }
        }
    }
}
.img-btn{
    width: 40px;
    height: 40px;
    font-size: 24px;
    color: #fff;
    background-color: #606266;
    border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 999;
}
.img-prev{
    left: 40px;
    top: 50%;
    transform: translateY(-50%);
}
.img-next{
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
}

</style>