import service from '@/utils/request';

export function archiving(data){    //  一键归档
    return service.post('/caseinfo/archiving',data,{
        baseURL: '/api_v2',
    })
}

export function archivingDownload(data){    //  一键归档
    return service.post('/caseinfo/archiving-download',data,{
        baseURL: '/api_v2',
        responseType: 'blob',
    })
}

export function getTemplate(params){    //  一键归档
    return service.get('/caseinfo/template',{
        params,
        baseURL: '/api_v2',
    })
}

//  获取归档信息（初始化）
export function getArchiveForm(params){
    return service.get('/caseinfo/fyy/file/search-archive-info',{
        params
    })
}
//  暂存归档表单信息
export function saveArchiveForm(data){
    return service.post('/caseinfo/fyy/file/save-archive-info?caseId=' + data.caseId,data.data,{
        // params
    })
}
//  提交归档
export function submitArchive(data){
    return service.post('/caseinfo/fyy/file/commit-Ss-Archive?caseId=' + data.caseId,data.data,{
        // params
    })
}

