import service from '@/utils/request';

export function queryMapping(params){
    return service.get('/caseinfo/directory/query-mapping',{
        params,
        baseURL: '/api_v2'
    })
}

export function saveMapping(data){
    return service.post('/caseinfo/directory/mapping',data,{
        baseURL: '/api_v2'
    })
}

export function cancelMapping(params){
    return service.delete('/caseinfo/directory/cancel-mapping',{
        params,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        baseURL: '/api_v2'
    })
}

