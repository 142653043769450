<template>
    <div class="wrap" :class="status">
        <div v-if="status === 'success' || status === 'error'" class="finish">
            <div style="color: #3774CB" v-if="status === 'success'" class="el-icon-success"></div>
            <div style="color: red;" v-if="status === 'error'" class="el-icon-error"></div>
        </div>
        <div class="progress">
            <el-progress type="circle" :percentage="value" :width="50" :stroke-width="2"></el-progress>
        </div>
    </div>
</template>

<script>
export default {
    mounted(){
        this.$el.parentNode.style.position = 'relative';
    },
    props: {
        value: {
            type: Number,
            default: 0
        },
        status: {
            type: String,
            default: 'no'
        }
    },
}
</script>

<style scoped lang='less'>
    .wrap{
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        .progress{
            display: none;
        }
        .finish{
            font-size: 36px;
        }
    }
    .wrap.no{
        background-color: transparent;
        .progress{
            display: none;
        }
    }
    .wrap.wait{
        background-color: rgba(0,0,0,.7);
        .progress{
            display: block;
        }
    }
    .wrap.ing{
        background-color: rgba(0,0,0,0.4);
        .progress{
            display: block;
        }
    }
    .wrap.success{
        background-color: transparent;
        .progress{
            display: none;
        }
    }
    .wrap.error{
        background-color: transparent;
        .progress{
            display: none;
        }
    }
</style>