<template>
    <div>
        <div ref="treeItem" class="tree-item" v-show="visible" :draggable="root.draggable" 
            @dragstart="root.draggable && dragstart($event)" 
            @dragover.prevent="root.draggable && dragover($event)" 
            @dragleave="root.draggable && dragleave($event)" 
            @drop="root.draggable && drop($event)"
        >
            <span class="expand" :class="{'active': !isExpand}" @click="expandClick">
                <i v-if="treeItem.children && treeItem.children.length > 0" class="iconfont iconzhankai"></i>
            </span>
            <span class="checkbox" v-if="(root.showRadio || root.showCheckbox) && level != 1">
                <el-radio v-if="root.showRadio" :value="root.radioValue" :label="treeItem[props.value || 'value']" @input="setRadioValue"></el-radio>
                <el-checkbox v-if="root.showCheckbox" :value="root.checkboxValues.includes(treeItem[props.value || 'value'])" @change="checkboxChange"></el-checkbox>
            </span>
            <span class="tree-item-content" :class="{'selected': root.selectKey === treeItem[props.value || 'value']}" @click="select" @dblclick.stop="expandClick">
                <TreeItemSpan></TreeItemSpan>
            </span>
        </div>

        <!-- <div  v-if="onceExpand"></div> -->
        <CollapseTransition>
            <div class="pl20" ref="subMenu" v-show="isExpand" v-if="onceExpand" style="overflow: hidden">
                <TreeItem
                    v-on="$listeners" 
                    @select="select" 
                    v-for="subItem in treeItem.children" 
                    :key="subItem[props.value || 'value']"
                    :treeItem="subItem"
                    :treeList="treeItem.children"
                    :level="level + 1"
                >
                </TreeItem>
            </div>
        </CollapseTransition>
    </div>
</template>

<script>
import CollapseTransition from '@/components/animate/CollapseTransition';

export default {
    name: 'TreeItem',
    components: {
        CollapseTransition,
        TreeItemSpan: {
            inject: ['root'],
            render(h){
                if(this.root.$scopedSlots.text){
                    let node = this.root.$scopedSlots.text({row: this.$parent.treeItem,node: this});
                    return node;
                }else{
                    const str = this.$parent.treeItem[this.$parent.props.label || 'label'];
                    return <span title={str}>{str}</span>;
                }
            }
        }
    },
    inject: ['root'],
    props: {
        treeItem: {
            type: Object,
            default(){
                return {};
            }
        },
        treeList: {
            type: Array,
            default(){
                return [];
            }
        },
        level: {
            type: Number,
            default: 1
        }
    },
    data(){
        return {
            isExpand: false,    //  页面展开
            onceExpand: false,
            visible: true,    //  项是否可见
            props: {
                value: this.root.props['value'] || 'value',
                label: this.root.props['label'] || 'label'
            },
            flag: false
        }
    },
    created(){
        this.visible = this.root.filterNode('',this.treeItem);      //  不涉及关键字，
        if(this.level <= 1){
            this.expandClick();
        }
    },
    methods: {
        async expandClick(){
            if(!this.isExpand){
                this.onceExpand = true;
                await this.$nextTick()
            }
            this.isExpand = !this.isExpand;
        },
        select(){
            this.root.selectKey = this.treeItem[this.props.value || 'value'];
            if(this.treeItem.pid != '0'){
                if(this.root.showRadio){
                    this.setRadioValue();
                }
                if(this.root.showCheckbox){
                    this.checkboxChange();
                }
            }
            this.$emit('getSelectData',this.treeItem);      //      选中数据传递
        },
        setRadioValue(){
            // this.root.radioValue = this.treeItem[this.props.value || 'value'];
            this.root.$emit('update:radioValue',this.treeItem[this.props.value || 'value']);
        },
        checkboxChange(val){
            let index = this.root.checkboxValues.indexOf(this.treeItem[this.props.value || 'value']);
            if(index === -1){
                this.root.checkboxValues.push(this.treeItem[this.props.value || 'value']);
            }else{
                this.root.checkboxValues.splice(index,1);
            }
            // console.log(flag);
            // this.root.$emit('update:checkboxValues',[]);
        },
        dragstart(ev){
            this.$emit('treeDragStart',ev,this.treeItem);
        },
        dragover(ev){
            this.$emit('treeDragOver',ev,this.treeItem);
        },
        dragleave(ev){
            this.$emit('treeDragLeave',ev,this.treeItem);
        },
        drop(ev){
            this.$emit('treeDrop',ev,this.treeItem);
        },
        recursion(moveItem = this.root.moveItem,endItem = this.treeItem){
            for (const item of moveItem.children || []) {
                if(item === endItem){
                    this.flag = true;
                }
                if(this.flag) return this.flag;

                this.recursion(item,endItem,this.flag);
            }
            
        }
    },
}
</script>

<style scoped lang='less'>
.tree-item{
    height: 26px;
    line-height: 26px;
    box-sizing: border-box;
    display: flex;
    // border-bottom: 1px solid red;
    > span{
        display: inline-block;
    }
    .tree-item-content{
        height: 100%;
        // padding: 0 5px;
        box-sizing: border-box;
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .tree-item-content:hover{
        background-color: #CDE8FF;
        cursor: pointer;
    }
    .tree-item-content.selected{
        background-color: #CDE8FF;
    }
    .expand{
        width: 26px;
        height: 26px;
        text-align: center;
        line-height: 26px;
        transition: all .3s linear;
        flex-shrink: 0;
    }
    .expand.active{
        transform: rotate(-90deg);
    }
    .expand:hover{
        color: #86E0FB;
    }
    .checkbox{
        min-width: 20px;
        height: 26px;
        /deep/ .el-checkbox{
            width: 20px;
            text-align: center;
        }
        /deep/ .el-radio{
            width: 20px;
            text-align: center;
            margin-right: 0;
            .el-radio__label{
                display: none;
            }
        }
    }
}
.pl20{
    padding-left: 20px;
}
</style>