<template>
    <div class="info">
        <div class="title">基本信息</div>
        <div class="line"></div>
        <div class="form">
            <div class="row">
                <div>是否合格：{{caseInfo.checkCaseResult === 0 ? '合格' : caseInfo.checkCaseResult === 1 ? '不合格' : ''}}</div>
                <div>巡查时间：{{caseInfo.receiptTime && Date.dateFormat(Number(caseInfo.receiptTime))}}</div>
            </div>
            <div class="row">
                <div>案件阶段：{{caseInfo.rpCasePhaseName}}</div>
                <div>补充时限：{{caseInfo.warnMsg}}</div>
            </div>
        </div>
        <div class="title">不合格原因</div>
        <div class="line"></div>
        <div>
            <div class="reason level1" v-for="(item,index) in list" :key="index">
                <p class="reason-title">{{item.name}}</p>
                <div class="level2" v-if="item.children">
                    <div v-for="(child,childIndex) in item.children" :key="childIndex">
                        <p class="level2-title">{{child.name}}<span v-if="child.children">：</span></p>
                        <div class="level3" v-if="child.children">
                            <div v-for="(grandChild,grandIndex) in child.children" :key="grandIndex">
                                <p>{{grandChild.name}}：{{grandChild.reason}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        result: Object
    },
    computed: {
        caseInfo(){
            return this.result && this.result.caseInfo || {};
        }
    },
    data(){
        return {
            list: [],
            hasReason: true
        }
    },
    created(){
        // console.log(this.caseInfo.failReason);
        const result = []
        let reasonStr = this.caseInfo.failReason;
        if(!reasonStr){
            return;
        }

        reasonStr.split(' ').forEach(item => {
            let key = item.split('：')[0];
            let value = item.split('：')[1];
            let obj = {
                name: key,
                children: []
            }
            if(key === '缺少卷宗' || key === '未归目' || key === '卷宗与目录不匹配'){
                value = key === '缺少卷宗' ? this.caseInfo.deadLine : value;
                let list = value.split('、');
                list.forEach(item => {
                    obj.children.push({
                        name: item
                    })
                })
            }

            if(key === '无卷宗'){
                obj.children.push({
                    name: ' 经系统判定，该案件未制作电子卷宗，请尽快在润普阅卷系统或润普电子卷宗随案生成系统中进行卷宗制作！'
                })
            }

            
            if(key === '材料质量不佳'){
                console.log(value.split(';'));
                value.split(';').forEach(item => {
                    const volume = item.slice(0,item.indexOf('——'));
                    const fileName = item.slice(item.indexOf('——') + 2,item.indexOf(':'));
                    const reason = item.slice(item.indexOf(':') + 1,item.length);
                    let tar = obj.children.find(child => child.name === volume);
                    if(tar){
                        tar.children.push({
                            name: fileName,
                            reason: reason
                        })

                    }else{
                        obj.children.push({
                            name: volume,
                            children: [
                                {
                                    name: fileName,
                                    reason: reason
                                }
                            ]
                        })
                    }

                })
                // console.log(fileItem);
            }
            
            result.push(obj);
        })
        console.log(result);
        this.list = result;
    },
    methods: {
        compose(){

        }
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
.info{
    padding: 10px 40px;
    .form{
        .row{
            display: flex;
            justify-content: space-between;
            margin-top: 22px;
        }
    }
    .reason{
        color: #4F4F4F;
        .reason-title{
            color: #101010;
            font-size: 16px;
            margin-bottom: 10px;
        }
        .level2{
            padding-left: 10px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .level2-title{
                margin-bottom: 10px;
            }
            .level3{
                padding-left: 10px;
                display: flex;
                flex-wrap: wrap;
                > div{
                    margin-bottom: 6px;
                    margin-right: 30px;
                    display: flex;
                    flex-wrap: wrap;
                }
            }
        }
    }
}
.title{
    color: #3291F8;
    margin-top: 30px;
        font-size: 16px;
}
.line{
    width: 100%;
    height: 1px;
    margin: 10px 0;
    background-color: #3291F8;
}
</style>