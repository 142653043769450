<template>
    <div class="inspect">
        <div class="title">
            <p>电子卷宗巡查结果</p>
            <button class="btn btn-default" @click="check" url="/case-check/caseInfo/receiveCaseId" v-hasPermit>立即检测</button>
        </div>
        <div class="table">
            <el-table :data="tableData" border :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
                <el-table-column prop="casePhaseName" label="案件阶段"> </el-table-column>
                <el-table-column prop="checkCaseResult" label="是否合格">
                    <template slot-scope="scope">
                        {{ scope.row.checkCaseResult === 0 ? '合格' : scope.row.checkCaseResult === 1 ? '不合格' : ''}}
                    </template>
                </el-table-column>
                <el-table-column prop="caseType" label="不合格原因">
                    <template slot-scope="scope">
                        <div>
                            <p v-for="(item,index) in scope.row.detailList" :key="index">{{item.key}}</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <button class="btn btn-text" @click="detailClick(scope.row)">详情</button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="title">
            <p>案件评注内容</p>
        </div>
        <ul class="comment">
            <li class="comment-item" v-for="item in commentList" :key="item.id">
                <span>{{Date.dateFormat(item.createTime,'YY-mm-dd HH:MM:SS')}}</span>
                <span>评注内容：{{item.comment}}</span>

            </li>
        </ul>

        <el-dialog title="案件不合格详情" :visible.sync="pageShow" width="60%" >
            <el-table :data="detailList" border :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}" :height="550">
                <el-table-column prop="key" label="不合格原因"></el-table-column>
                <el-table-column prop="reason" label="详情"></el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
import { checkCase } from '@/api/check.js';

export default {
    name: 'InspectRecord',
    data(){
        return {
            tableData: [],
            commentList: [],
            detailList: [],
            pageShow: false
        }
    },
    props: {
        result: Object
    },
    inject: ['state'],
    created(){
        if(this.state.caseInfo.caseType.id === '8'){
            this.result.checkCaseRecords = this.result.checkCaseRecords.filter(item => item.casePhase === 2 || item.casePhase === 6 || item.casePhase === 7);
        }
        this.result.checkCaseRecords.forEach(record => {
            record.detailList = [];
            let list = record.detailInfo && record.detailInfo.split(' ') || [];
            list.forEach(item => {
                let strList = item.split('：');
                let obj = {
                    key: strList[0],
                    reason: strList[1]
                }
                record.detailList.push(obj);
            })
        })
        this.tableData = this.result.checkCaseRecords;
        this.commentList = this.result.commentRecords;
    },
    methods: {
        detailClick(row){
            this.detailList = row.detailList;
            this.pageShow = true;
        },
        check(){
            let params = {
                caseId: this.state.caseInfo.caseId,
                courtId: this.state.caseInfo.court.id
            }
            checkCase(params).then(res => {
                if(res.code === "0" && res.message === 'Success'){
                    this.$message.success('请求检测成功!');
                }else{
                    this.$message.error('');
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.inspect{
    padding: 15px;
    .title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: 1px solid #3291F8;
        color: #3291F8;
    }
    .table{
        margin: 10px 0;
    }
    .comment{
        .comment-item{
            margin-top: 10px;
            > span{
                margin: 0 5px;
            }
        }
    }
}
</style>