<template>
    <div>
        <el-dialog
            :title="pageType ? `编辑${formData.pid !== '0' ? '目录' : '卷'}` : `新建${formData.pid !== '0' ? '目录' : '卷'}`"
            :visible.sync="pageShow"
            width="40%"
        >
            <el-form ref="form" :model="formData" label-width="80px" :rules="rules">
                <el-form-item label="名称" prop="name">
                    <el-input size="small" v-model="formData.name"></el-input>
                </el-form-item>
                <el-form-item label="所属卷册" v-show="this.formData.pid !== '0'">
                    <el-input size="small" v-model="parentName" disabled></el-input>
                </el-form-item>
                <el-form-item label="类别" v-show="this.formData.pid !== '0'" prop="type">
                    <el-select size="small" v-model="formData.type">
                        <!-- <el-option :value="2" label="册" v-if="showSelect(2)"></el-option>
                        <el-option :value="4" label="目录" v-if="showSelect(4)"></el-option> -->
                        <el-option v-for="item in selects" :key="item.value" :value="item.value" :label="item.label" :disabled="item.disabled"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注" prop="description">
                    <el-input size="small" v-model="formData.description" type="textarea"></el-input>
                </el-form-item>
            </el-form>
            
            <div slot="footer" class="dialog-footer">
                <!-- <button class="btn btn-default form-btn" @click="pageShow = false">取消</button>
                <button class="btn btn-default form-btn" type="primary" @click="submit" url="/caseinfo/directory/create,/caseinfo/directory/modify">保存</button> -->
                <button class="btn btn-default form-btn" @click="pageShow = false">取消</button>
                <button class="btn btn-default form-btn" v-show="pageType === 0" type="primary" @click="submit" url="/caseinfo/directory/create">保存</button >
                <button class="btn btn-default form-btn" v-show="pageType === 1" type="primary" @click="submit" url="/caseinfo/directory/modify">保存</button >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { createDir , modifyDir } from '@/api/caseReading.js';

export default {
    data() {
        return {
            pageShow: false,
            formData: {
                caseId: this.state.caseInfo.caseId,
                category: '',
                name: '',
                pid: '',
                type: '',
                description: '',
            },
            rules: {
                name: [
                    { required: true, message: '请输入目录名称', trigger: 'blur' },
                    { min: 0, max: 50, message: '长度在 0 到 50 个字符', trigger: 'blur' }
                ],
                type: [
                    { required: true, message: '请选择目录类别', trigger: 'blur' },
                ],
                description: [
                    { min: 0, max: 200, message: '长度在 0 到 200 个字符', trigger: 'blur' }
                ]
            },
            parentName: '',
            pageType: -1,
            oldDirName: ''
        };
    },
    props: {
        parentDir: Object
    },
    inject: ['state','mutations'],
    computed: {
        dirList(){
            return this.state.dirList;
        },
        dirObj(){
            return this.state.dirObj;
        },
        dirCategory(){
            return this.state.dirCategory;
        },
        selects(){
            let list = [
                {
                    label: '册',
                    value: 2
                },
                {
                    label: '目录',
                    value: 4
                },
            ]
            let pType = this.parentDir && this.parentDir.type;
            if(this.pageType){
                list = [
                    {
                        label: '卷',
                        value: 1,
                        disabled: true
                    },
                    {
                        label: '册',
                        value: 2,
                        disabled: true
                    },
                    {
                        label: '目录',
                        value: 4,
                        disabled: true
                    },
                    {
                        label: '待分类区',
                        value: 8,
                        disabled: true
                    },
                ]
            }else{      // 新增
                switch(pType){
                    case 1: 
                        let flag = this.parentDir.children.find(item => item.type === 2);
                        if(flag){
                            list.splice(1,1);
                        }
                    break;
                    case 2:
                        list.splice(0,1);
                    break;
                    case 4:
                        list.splice(0,1);
                    break;
                    case 8:
                        list.splice(0,1);
                    break;
                }
            }
            return list;
        }
    },
    methods: {
        init(data,type){        //  1为编辑，0为新增
            this.pageType = type;
            this.formData.caseId = this.state.caseInfo.caseId;
            this.formData.category = this.dirCategory;
            this.formData.description = this.dirCategory;
            if(type){
                this.parentName = this.dirObj[data.pid] ? this.dirObj[data.pid].name : '';
                this.formData.name = data.name;
                this.formData.id = data.id;
                this.formData.pid = data.pid;
                this.formData.type = data.type;
                this.formData.sn = data.sn;
                this.oldDirName = data.name;
                this.formData.description = data.description;
            }else{
                delete this.formData.id;
                this.parentName = data ? data.name : '';
                this.formData.name = '';
                this.formData.pid = data ? data.id : "0";
                this.formData.type = this.formData.pid === '0' ? 1 : '';
                this.formData.description = '';
            }
            this.pageShow = true;
        },
        submit(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.createDir();
                }
            });
        },
        createDir(){
            // if(this.formData.name === ''){
            //     this.$message.error('名称不能为空！');
            //     return;
            // }
            let typeName = '';
            switch(this.formData.type){
                case 1: typeName = '卷'; break;
                case 2: typeName = '册'; break;
                case 4: typeName = '目录'; break;
            }
            let str;
            if(this.pageType){
                str = `${typeName}<span style="color: #1976D2">《${this.oldDirName}》</span>为<span style="color: #1976D2">《${this.formData.name}》</span>`;
            }else{
                str = `${typeName}<span style="color: #1976D2">《${this.formData.name}》</span>`;
            }
            


            this.$store.commit('common/setRecord',{
                key: this.pageType ? '/caseinfo/directory/modify' : '/caseinfo/directory/create',
                value :{
                    _log_notes: str,
                    _log_params: {
                        caseId: this.state.caseInfo.caseId
                    }
                }
            })
            if(this.pageType){
                modifyDir(this.formData).then(res => {
                    this.$message.success('修改成功！');
                    this.dirObj[res.id].name = res.name;
                    this.dirObj[res.id].type = res.type;
                    this.dirObj[res.id].updateTime = res.updateTime;
                    this.dirObj[res.id].description = res.description;
                    
                    this.pageShow = false;
                })
            }else{
                createDir(this.formData).then(res => {
                    this.$message.success('新建成功！');

                    // let newDir = this.mutations.dirInit(res);
                    // this.dirObj[newDir.id] = newDir;
                    // if(newDir.pid === "0"){
                    //     this.dirList.push(newDir);
                    // }else{
                    //     this.parentDir.children.push(newDir);
                    //     if(this.parentDir.type === 1 || this.parentDir.type === 2){
                    //         this.parentDir.children.sort((a,b) => a.sn - b.sn);
                    //     }
                    // }
                    
                    // if(newDir.type === 1 || newDir.type === 2){   //  创建卷和分册
                    //     newDir.children = [this.mutations.dirInit(res.children[0])];
                    //     this.mutations.setSpecDir(newDir);
                    // }
                    this.pageShow = false;
                    this.$parent.pageInit();
                })
            }
            
        },
    }
};
</script>

<style scoped lang='less'>

</style>