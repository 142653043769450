<template>
    <div>
        <TreeItem 
            v-on="$listeners" 
            ref="treeItem" 
            v-for="(item,index) in data" 
            :key="index" 
            :treeItem="item"
            :treeList="data"
            :level="1">
        </TreeItem>
        <div ref="line" v-show="lineShow" class="line"></div>
    </div>
</template>

<script>
import TreeItem from './TreeItem';

export default {
    name: 'Tree',
    components: {
        TreeItem
    },
    provide(){
        return {
            root: this,
        }
    },
    props: {
        allowDrop: {
            type: Function,
            default(){
                return function(){ return true }
            }
        },
        filterNode: {
            type: Function,
            default(){
                return function(){return true};
            }
        },
        data: {
            type: Array,
            default(){
                return [];
            }
        },
        props: {
            type: Object,
            default(){
                return {};
            }
        },
        showRadio: {        //  显示单选框
            type: Boolean,
            default: false
        },
        showCheckbox: {     //  显示多选框
            type: Boolean,
            default: false
        },
        checkboxValues: {   //  多选框初始值
            type: Array,
            default(){
                return []
            }
        },
        draggable: {
            type: Boolean,
            default: false
        },
        radioValue: {
            type: String,
            default: ''
        }
    },
    computed: {
        line(){
            return this.$refs.line;
        }
    },
    data(){
        return {
            // radioValue: '',
            selectKey: '',
            lineShow: false,
            dragState: {
                draggingNode: null,
                dropNode: null,
                dropType: null,

            }
        }
    },
    methods: {
        recursion(node,call){   //  起始节点
            node.$children.forEach(item => {
                if(item.$options.name != 'TreeItem') return;
                call(item,node);    //  当前节点，父节点
                item.$children.length ? this.recursion(item,call) : '';
            });
        },
        expand(flag){
            this.recursion(this,(item,node) => {
                item.isExpand = flag;
            })
        },
        getCheckboxValues(){ 
            return this.checkboxValues;
        },
        filter(val){
            this.recursion(this,item => {
                item.visible = this.filterNode(val,item.treeItem);
                if(item.visible){
                    let parent = item.$parent;
                    while(parent.$options.name === 'TreeItem'){
                        parent.visible = true;
                        parent = parent.$parent;
                    }
                }
                
            })
        },
        showLine({left,top,width,height}){
            const line = this.$refs.line;
            this.lineShow = true;
            line.style.top = top + 'px';
            line.style.left = left + 'px';
            line.style.width = width + 'px';
            if(height){
                line.style.height = height + 'px';
            }else{
                line.style.height = '2px';
            }
        },
        hiddenLine(){
            this.lineShow = false;
        },
        treeDragover(event,treeNode){
            const dropNode = event.currentTarget;
            const dropPosition = dropNode.getBoundingClientRect();   
            const contentPosition = dropNode.querySelector('.tree-item-content').getBoundingClientRect();
            //  
            let isPrev = this.allowDrop(this.dragState.draggingNode.treeItem,treeNode.treeItem,'before');
            let isInner = this.allowDrop(this.dragState.draggingNode.treeItem,treeNode.treeItem,'inner');
            let isNext = this.allowDrop(this.dragState.draggingNode.treeItem,treeNode.treeItem,'after');

            // this.dragState.dragData
                
                const prevPercent = isPrev ? ( isInner ? (isNext ? 0.25 : 0.5) : (isNext ? 0.5 : 1)) : -1;
                const nextPercent = isNext ? ( isInner ? (isPrev ? 0.75 : 0.5) : (isPrev ? 0.5 : 1)) : 1;  
                
                let dropType;

                const distance = event.clientY - dropPosition.top;        //  距离
                
                if (distance <= dropPosition.height * prevPercent) {
                    dropType = 'before';
                } else if (distance >= dropPosition.height * nextPercent) {
                    dropType = 'after';
                } else if (isInner) {
                    dropType = 'inner';
                }else{
                    dropType = 'none';
                }
                // console.log(dropType);
                

                this.dragState.dropType = dropType;
                
                
                if(dropType === 'inner'){
                    treeNode.isInner = true;
                    this.hiddenLine();
                }else{
                    treeNode.isInner = false;
                    if(dropType === 'before' || dropType === 'after'){
                        const options = {};
                        options.left = contentPosition.left;
                        options.width = contentPosition.width;
                        if(dropType === 'before'){
                            options.top = contentPosition.top;
                        }else if(dropType === 'after'){
                            options.top = contentPosition.bottom;
                        }
                        this.showLine(options);
                    }else{
                        this.hiddenLine();
                    }
                }
            

        },
        traverse(arr, cb, children = 'children') {
            if (!arr) return;
            let flag = false;
            function recursion(arr) {
                for (let index = 0; index < arr.length; index++) {
                    const item = arr[index];
                    if (cb(item, index, arr)) {
                        flag = true;
                    }
                    if (flag) return;
                    if (item[children] && item[children].length > 0) {
                        recursion(item[children]);
                    }
                }
            }
            recursion(arr);
        },
    },
    created(){
        let timer = null;
        this.isTree = true;
        this.$on('tree-dragstart',(event,treeNode) => {
            this.dragState.draggingNode = treeNode;
            this.dragState.dragData = treeNode.treeItem;
        });
        this.$on('tree-dragover',(event,treeNode) => {
            this.dragState.dropNode = treeNode;
            this.dragState.dropData = treeNode.treeItem;

            let permitDrop = true;
            const { dragData , dropData } = this.dragState;
            this.traverse(dragData.children,child => {      //  父级不允许拖拽到子级
                if(child === dropData){
                    permitDrop = false;
                    return true;
                }
            })
            // if(dropData.children && dropData.children.find(item => item === dragData)){
            //     permitDrop = false;
            // }
            if(this.dragState.draggingNode === this.dragState.dropNode){
                permitDrop = false;
            }

            if(permitDrop){
                this.treeDragover(event,treeNode);
            }else{
                this.dragState.dropType = 'none';
                // return;
            }

        });
        this.$on('tree-dragend',(event,treeNode) => {
            // this.hiddenLine();
            // treeNode.$el.isInner = false;

        })
        this.$on('tree-dragleave',(event,treeNode) => {
            this.hiddenLine();
            treeNode.isInner = false;
        })
        this.$on('tree-drop',(event,treeNode) => {

            treeNode.isInner = false;
            this.hiddenLine();
            this.dragState.dropNode = treeNode;

            const { draggingNode ,dropNode , dropType } = this.dragState;

            if(this.dragState.dropType != 'none'){
                let dragData = draggingNode.treeItem;
                let dropData = dropNode.treeItem;

                let dragParentData = draggingNode.$parent.treeItem;
                let dropParentData = dropNode.$parent.treeItem;

                this.$emit('treeDrop',{
                    dragData,
                    dropData,
                    dropType,
                    dragParentData,
                    dropParentData
                });
            }
            



        });
        
    }
}
</script>

<style scoped lang='less'>
.line{
    width: 100%;
    height: 2px;
    background-color: #CDE8FF;
    position: fixed;
    top: 0;
    pointer-events: none;
}
</style>