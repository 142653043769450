<template>
    <div>
        <div class="search">
            <el-input v-model="keyword" size="mini" placeholder="请输入笔录关键字" suffix-icon="el-icon-search" clearable></el-input>
            <button class="btn btn-default iconfont iconjiahao" @click="openDialog">创建笔录</button>
        </div>
        <div class="content">
            <ul>
                <li class="content-item" :class="{'active': item === currentNote}" v-for="item in dataList" :key="item.id" @click="noteClick(item)">
                    <img :src="mutations.treeIcon(item)">
                    <span class="name">{{item.name}}</span>
                    <div @click.stop>
                        <button class="btn btn-icon iconfont iconshanchu" @click="deleteFile(item)"></button>
                        <button class="btn btn-icon iconfont iconbianji" @click="editNote(item)"></button>
                    </div>
                </li>
            </ul>
        </div>

        <el-dialog
            title="请先选择批注导入笔录"
            :visible.sync="dialogVisible"
            append-to-body
            width="600px">
            <div class="dialog-content">
                <el-checkbox-group v-model="checkList">
                    <el-checkbox v-for="item in commentList" :key="item.id" :label="item.id" :title="item.commentContent">{{item.commentContent}}</el-checkbox>
                </el-checkbox-group>
            </div>
            <div class="footer">
                <p class="remark">备注：支持不选择任何批注，直接手动编辑</p>
                <div>
                    <el-button size="mini" plain @click="dialogVisible = false">取消</el-button>
                    <el-button size="mini" type="primary" @click="createNote(false)">手动编辑</el-button>
                    <el-button size="mini" type="primary" @click="createNote(true)">导入并编辑</el-button>
                </div>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="openAction" title="提示" append-to-body>
            <div>{{`${this.noteFile && this.noteFile.name}文件正在编辑中，是否要切换编辑对象`}}</div>
            <div slot="footer">
                <el-button type="primary" size="small" @click="switchNote(1)">保存并切换</el-button>
                <el-button type="primary" size="small" @click="switchNote(0)">直接切换</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { deleteFile } from '@/api/caseReading.js';
import { getNoteTitle } from '@/api/commentAPI.js';
import axios from 'axios';

export default {
    data(){
        return {
            keyword: '', 
            dialogVisible: false,
            checkList: [],
            currentNote: null,
            openAction: false,      //  切换编辑内容弹窗
            tempNote: null,         //  即将编辑的笔录
        }
    },
    inject: ['state','mutations','commentState'],
    props: {
        dirList: {
            type: Array,
            default(){
                return [];
            }
        },
        commentList: {
            type: Array,
            default(){
                return [];
            }
        },
        fileList: {
            type: Array,
            default(){
                return [];
            }
        }
    },
    computed: {
        dataList(){
            let list = [];
            this.mutations.recursion(this.dirList,dir => {
                if(dir.name === '阅卷笔录'){
                    list = dir.children;
                }
            })
            
            return list.filter(item => item.name.includes(this.keyword));
        },
        realName(){
            return this.$store.getters.userInfo.realName;
        },
        noteFile(){
            return this.commentState.noteFile;
        }
    },
    methods: {
        noteClick(data){
            this.currentNote = data;
            this.$emit('update:currentPage',data.pageIndex || 0);
        },
        openDialog(){
            let has = false;
            this.mutations.recursion(this.dirList,dir => {
                if(dir.name === '阅卷笔录'){
                    has = true;
                }
            })
            // console.log(has);
            if(has){
                if(this.noteFile){
                    this.$message.warning('当前有笔录正在编辑!');
                    // this.$emit('createNode',html,null);
                    // this.tempNote = null;
                    // this.openAction = true;
                }else{
                    this.dialogVisible = true;
                }
            }else{
                this.$message.warning('副卷下没有阅卷笔录目录,无法创建笔录!');
            }
            
        },
        createNote(flag){
            let selectComment;
            if(flag){
                selectComment = this.commentList.filter(comment => this.checkList.includes(comment.id));
            }else{
                selectComment = [];
            }
            
            this.setHTML(this.state.caseInfo,selectComment).then(html => {
                this.dialogVisible = false;
                this.$emit('createNode',html,null);
            })
        },
        editNote(file){
            if(this.noteFile){
                if(file === this.noteFile){
                    this.$message.warning('当前有笔录正在编辑中!')
                    return;
                }else{
                    this.tempNote = file;
                    this.openAction = true;
                }
            }else{
                axios.get(file.filePath).then(res => {
                    const html = res.data;
                    this.$emit('createNode',html,file);
                    this.noteClick(file);
                })
            }
            
        },
        async switchNote(flag){
            if(flag){
                await this.$parent.$parent.$refs.editNote.save();
            }
            // this.$
            axios.get(this.tempNote.filePath).then(res => {
                this.openAction = false;
                const html = res.data;
                this.$emit('createNode',html,this.tempNote);
                this.noteClick(this.tempNote);
            })
        },
        fix(num, length) {
           return ('' + num).length < length ? ((new Array(length + 1)).join('0') + num).slice(-length) : '' + num;
        },
        async setHTML(caseInfo,commentList){
            let partyList = caseInfo.partyList;
            // console.log(partyList);
            let DSR_1 = {},DSR_2 = {};
            let dsrStr = '';
            if(partyList && partyList.length){
                DSR_1 = partyList[0] || {};
                DSR_2 = partyList[1] || {};
                let str1 = DSR_1.userName || '';
                let str2 = DSR_1.roleName ? `（${DSR_1.roleName}）;` : '';
                let str3 = DSR_2.userName || '';
                let str4 = DSR_2.roleName ? `（${DSR_2.roleName}）` : '';
                dsrStr = str1 + str2 + str3 + str4;
            }

            const title = await getNoteTitle();

            let pList = [
                title,
                `时间：${Date.dateFormat(new Date(),'YY.mm.dd HH:MM')}`,
                `案号：${caseInfo.caseName}`,
                `案件类型：${caseInfo.caseType.name}`,
                `案由：${caseInfo.caseReason || ''}`,
                `当事人：${dsrStr}`,
                `阅卷人：${this.realName}`
            ]

            let commentPList = commentList.map((comment,index) => {
                let str1 = `${index + 1}、${comment.updateTime}`;
                let str2 = `【${comment.realName}】${comment.commentContent}`;

                let strList = comment.replyInfoList && comment.replyInfoList.map(reply => {
                    let str = reply.repliedRealName ? `【${reply.repliedRealName}】` : '';
                    return `【${reply.realName}】回复${str}：${reply.content}`;
                })
                return [str1,str2,...strList];
            })
            
            commentPList.forEach(list => {
                pList.push(...list);
            })
            // console.log(commentPList);

            return pList.map((item,index) => {
                if(index === 0){
                    return `<p style="text-align:center;font-size:18px">
                        <strong>${item}</strong>
                    </p>`
                }else{
                    return `<p style="font-size:14px">${item}</p>`
                }
            }).join('');
        },
        deleteFile(file){
            if(file === this.noteFile){
                this.$message.warning('文件正在编辑!');
                return;
            }
            this.$confirm('删除笔录会连同文件一起删除!', '删除笔录', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let params = {
                    caseId: this.state.caseInfo.caseId,
                    category: this.state.dirCategory,
                    ids: file.id
                }
                deleteFile(params).then(res => {
                    this.$message.success('操作成功!');
                    this.resetData(file);
                })
            }).catch(err => {
                console.log(err);
            })
        },
        resetData(file){
            console.log(this.fileList.length);
            let index = this.fileList.findIndex(item => item.id === file.id);
            console.log(index);
            this.fileList.splice(index,1);
            console.log(this.fileList.length);
            this.mutations.recursion(this.dirList,dir => {        //  阅卷删除
                if(dir.name === '阅卷笔录'){
                    console.log(dir.children.length);
                    dir.children.splice(dir.children.indexOf(file),1);
                    console.log(dir.children.length);

                }
            })
            this.mutations.recursion(this.state.dirList,dir => {        //  首页删除
                if(dir.name === '阅卷笔录'){
                    dir.children.splice(dir.children.findIndex(item => item.id === file.id),1);
                }
            })

        }
    },
    
}
</script>

<style lang="less" scoped>
.search{
    padding: 10px 8px;
    display: flex;
    .btn{
        height: 28px;
        line-height: 28px;
        flex-shrink: 0;
    }
}
.content{
    padding: 0 10px;
    .content-item{
        display: flex;
        align-items: center;
        height: 26px;
        line-height: 26px;
        padding: 0 5px;
        .name{
            width: 75%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        > div{
            margin-left: auto;
        }
    }
    .content-item:hover{
        background-color: #CDE8FF;
        cursor: pointer;
    }
    .content-item.active{
        background-color: #CDE8FF;
    }
    img{
        width: 14px;
        // height: 16px;
        padding-right: 10px;
    }
}

.dialog-content{
    width: 100%;
    height: 500px;
    border: 1px solid #ccc;
    background-color: #F9F9F9;
    padding: 12px;
    box-sizing: border-box;
    overflow-y: auto;
    /deep/ .el-checkbox{
        margin-right: 0;
        margin-top: 10px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    
}
.footer{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
</style>