import service from '@/utils/request';

export function getComment(params){    //  获取评论信息
    return service.get('/caseinfo/review/get-review-comment',{
        params,
        
    })
}

export function createComment(data){    //  保存批注评论信息
    return service.post('/caseinfo/review/comment/save',data,{
        
    })
}

export function createReply(data){    //  保存批注评论信息
    return service.post('/caseinfo/review/comment/reply/save',data,{
        
    })
}

export function deleteComment(params){    //  删除批注评论信息
    return service.delete('/caseinfo/review/comment/remove',{
        params,
        
    })
}

export function deleteReply(params){    //  删除批注评论回复信息
    return service.delete('/caseinfo/review/comment/reply/remove',{
        params,
        
    })
}

export function getMarkLayerInfo(params){    //  查询文件信息
    return service.get('/caseinfo/review/file/query-by-caseId',{
        params,
    })
}

export function createNote(data){
    return service.post('/caseinfo/notes/create',data,{
        
    })
}

export function modifyNote(data){
    return service.post('/caseinfo/notes/modify',data,{
        
    })
}

export function getNoteTitle(params){
    return service.get('/caseinfo/notes/get-file-title',{
        params
    })
}

//  查询点阵信息
export function queryLattice(params){
    return service.get('/caseinfo/lattice/query-by-case-id',{
        params
    })
}

//  保存点阵信息
export function saveLattice(data){
    return service.post('/caseinfo/lattice/save',data,{
        headers: {'Content-Type': 'multipart/form-data'}
    })
}


