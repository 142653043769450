<template>
    <div class="reply-container" v-if="currentCommentId == comment.id || (replyInfoList && replyInfoList.length > 0)">
        <ul class="reply-list">
            <li class="reply-item" v-for="(item,index) in replyInfoList" :key="index">
                <div class="title">
                    <span class="name">{{item.realName}}</span>
                    <div class="date">{{item.updateTime}}</div>
                </div>
                <div class="content">
                    <div class="text">
                        <span>回复</span>
                        <span class="replied-name">{{item.repliedRealName}}：</span>
                        <span v-html="item.content"></span>
                    </div>
                    <div class="action">
                        <div class="reply" @click.stop="createReply(item)">
                            <span>回复</span>
                        </div>
                        <div class="delete" v-if="userId === item.userId" @click="deleteReply(item,index)">
                            <span>删除</span>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        
    </div>
</template>

<script>
import { createReply , deleteReply } from '@/api/commentAPI.js';

export default {
    props: {
        replyInfoList: {
            type: Array,
            default(){
                return [];
            }
        },
        comment: {
            type: Object,
            default(){
                return {};
            }
        },
        currentCommentId: {
            type: String,
            default: ''
        }
    },
    inject: ['state'],
    computed: {
        userId(){
            return this.$store.getters.userInfo.id;
        }
    },
    data(){
        return {
            tempText: '',
            repliedUserId: '',
            repliedRealName: ''
        }
    },
    methods: {
        createReply(data){
            this.$parent.createReply(this.comment,data);
        },
        deleteReply(data,index){
            this.$confirm('此操作将永久删除回复信息, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(res => {
                let params = { id: data.id, caseId: this.state.caseInfo.caseId };
                deleteReply(params).then(res => {
                    this.$message.success('删除成功！');
                    this.getReplyList(data.id);
                })
            }).catch(err => {
                console.log(err);
            })
        },
        getReplyList(replyId){
            this.$parent.commentList.find(comment => {
                let replyIndex = comment.replyInfoList.findIndex(reply => reply.id === replyId);
                if(replyIndex != -1){
                    comment.replyInfoList.splice(replyIndex,1);
                }
            })
        }
    }
}
</script>

<style scoped lang='less'>
.reply-list{
    .reply-item{
        margin-bottom: 14px;
        .replied-name{
            font-weight: 700;
            margin: 0 5px;
        }
    }
}


</style>