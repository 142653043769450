<template>
    <div class="draw-line" :title="title">
        <button class="btn" @click="$refs.colorPicker.handleTrigger()">
            <span class="btn-draw-line">
                <i class="iconfont" :class="icon"></i>
                <span class="line" :style="{'backgroundColor': value}"></span>
            </span>
            <i class="iconfont icondown"></i>
        </button>
        <div style="width: 0;height: 0;overflow: hidden;">
            <el-color-picker ref="colorPicker" :value="value" @input="changeColor" :predefine="predefineColors"></el-color-picker>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value:{
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            predefineColors: [
                "#FF0101","#FFFF01","#72D887","#52A4F8","#454545","#F5A3A5","#F5C057","#6DD0D9","#9A9AF5","#E6E6E6"
            ],
        }
    },
    methods: {
        changeColor(value){
            this.$emit('input',value);
        },
    }
}
</script>

<style scoped lang="less">
.color-picker{
    display: flex;

}
.draw-line{
    .btn-draw-line{
        display: inline-flex;
        flex-direction: column;
        .line{
            display: inline-block;
            width: 14px;
            height: 2px;
        }
    }
}
</style>