<template>
    <div class="markLayer">

        <div v-for="(item,index) in list" :key="index" 
            :style="itemStyle(item)" 
            class="mark-item" 
            @click="markClick(item,$event)" 
            :data-id="item.dataId" 
            @mouseenter="mouseenter(item,$event)"
            @mouseleave="mouseleave($event)">
            <div class="mark-item-active" v-if="item.type === 'UNDERLINE'" :style="{'borderBottom': `2px solid ${item.color}`}"></div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        file: Object,
        commentList: Array
    },
    inject: ['commentState'],
    computed: {
        underlineColor(){
            return this.commentState.underlineColor;
        },
        highlightColor(){
            return this.commentState.highlightColor;
        },
        list(){
            let list = [];
            this.commentList.forEach(comment => {
                if(comment.fileId === this.file.id && comment.annotationPage === this.file.page){
                    let arr = JSON.parse(comment.node);
                    arr.forEach(item => {
                        item.dataId = comment.id;
                        item.type = comment.type;
                    })
                    list.push(...arr);
                }
                
            })
            return list;
        }
    },
    data(){
        return {
            text: '',
            timer: null
        }
    },
    methods: {
        itemStyle(item){
            return {
                width: `${item.width}px`,
                height: `${item.height}px`,
                left: `${item.left}px`,
                top: `${item.top}px`,
                backgroundColor: item.type === 'COMMENT' ? `${this.hexToRgb(item.color)}` : '',
                border: this.commentState.currentCommentId === item.dataId ? '2px dashed #000' : '',
            }
        },
        markClick(item){
            // this.commentState.currentCommentId = item.dataId;
            this.$emit('update:currentCommentId',item.dataId);
        },
        hexToRgb(hex) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);  
            let rgbObj = {       
                r: parseInt(result[1], 16),              
                g: parseInt(result[2], 16),       
                b: parseInt(result[3], 16) 
            }
            let str = `rgba(${rgbObj.r},${rgbObj.g},${rgbObj.b},.6)`;
            return str;
        },
        mouseenter(item,event){
            this.timer = setTimeout(() => {
                let commentText = this.commentList.find(comment => comment.id === item.dataId).commentContent;
                
                this.$emit('popover',commentText,item);
                this.timer = null;
            },1000)
        },
        mouseleave(event){
            if(this.timer){
                clearTimeout(this.timer);
            }
            this.$emit('popoverLeave');
        }
    }
}
</script>

<style scoped lang='less'>
.markLayer{
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: 0 0;
    z-index: 2022;
    > div{
        position: absolute;
    }
    .mark-item-active{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}
</style>