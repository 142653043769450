<template>
    <div>
        <el-table :data="originalCaseList" height="545" border :header-cell-style="{'background-color': '#F5F5F5'}" empty-text="本案件暂无原审案件">
            <el-table-column prop="caseName" label="原审案号">
                <template slot-scope="{ row }">
                    <div class="case-name" @click="goCase(row)">{{row.caseName}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="courtName" label="承办法院"></el-table-column>
            <el-table-column prop="closeDate" label="结案日期"></el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    props: {
        originalCaseList: {
            type: Array,
            default(){
                return [];
            }
        }
    },
    data(){
        return {
            
        }
    },
    methods: {
        goCase(row){
            const routerUrl = this.$router.resolve(`/caseReading?caseName=${row.caseName}`);
            window.open(routerUrl.href,'_blank');
        }
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
.case-name{
    color: #3291F8;
    cursor: pointer;
}
</style>