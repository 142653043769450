<template>
    <div
        class="file-item"
        @click="$emit('selectFile', dir)"
        :class="{ active: dir.selected }"
        @dblclick="$emit('dbClick', dir)"
        draggable
        @dragstart="dragstart"
        @dragover.prevent="dragover"
        @dragleave="dragleave"
        @drop="drop"
    >
        <div class="checkbox" @click.stop>
            <el-checkbox v-model="dir.selected"></el-checkbox>
        </div>
        <div class="img">
            <img draggable="false" :src="mutations.treeIcon(dir)" alt="">
        </div>
        <!-- <el-input v-show="modify" @keydown.native="keydown" @blur="modify = false" ref="input" size="mini" v-model="modifyText"></el-input> -->
        <p :title="dir.name" class="file-name">{{ dir.name }}</p>
    </div>
</template>

<script>
export default {
    props: {
        dir: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    inject: ["state", "mutations", "fileListState"],
    computed: {
        dirs() {
            return this.state.currentDir.children;
        },
        staticImg() {
            return this.state.staticImg;
        },
        moveStatus() {
            return this.state.moveStatus;
        },
    },
    watch: {
        'dir.selected': {
            handler(val){
                this.$emit('selectChange');
            }
        }
    },
    methods: {
        dragstart(ev) {
            if (
                this.fileListState.orderRule != "default" ||
                this.fileListState.keyword != ""
            ) {
                this.$message.error(
                    "在非默认排序和关键字搜索的情况下不允许重新排序！"
                );
                ev.preventDefault();
                return;
            }
            if(this.dir.type === 8){
                ev.preventDefault();
                return;
            }
            this.moveStatus.dragData = this.dir;
            this.moveStatus.dragType = "dir";
        },
        dragover(ev) {
            if (this.moveStatus.dragData === this.dir || this.dir.type === 8) {
                return;
            }
            ev.currentTarget.style.border = "";
            if (ev.offsetX <= this.$el.offsetWidth / 2) {
                ev.currentTarget.style.borderLeft = "1px solid red";
                this.moveStatus.dragStatus = "before";
            } else {
                ev.currentTarget.style.borderRight = "1px solid red";
                this.moveStatus.dragStatus = "after";
            }
        },
        dragleave(ev) {
            ev.currentTarget.style.border = "";
        },
        drop(ev) {
            if (this.moveStatus.dragData === this.dir || this.dir.type === 8) {
                return;
            }
            ev.currentTarget.style.border = "";
            this.moveStatus.dropData = this.dir;
            this.moveStatus.dropType = "dir";
            this.mutations.drop();
        },
    },
};
</script>

<style scoped lang='less'>
/deep/ .el-input input {
    width: 70px;
    text-align: center;
}
.file-item {
    padding: 15px 25px;
    margin: 15px 20px;
    position: relative;
    border: 1px solid transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    .checkbox {
        display: none;
        position: absolute;
        top: 2px;
        right: 5px;
    }
    .img {
        margin-bottom: 10px;
        width: 70px;
        height: 80px;
        pointer-events: none;
        img {
            width: 100%;
            line-height: 100px;
        }
    }
    .file-name {
        width: 70px;
        // text-align: center;
        // word-wrap: break-word;
        // display: -webkit-box;
        // -webkit-line-clamp: 2;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
.file-item:hover {
    background-color: #e5f3ff;
    .checkbox {
        display: inline-block;
    }
}
.file-item.active {
    background-color: #cce8ff;
    border-color: #1976d2;
    .checkbox {
        display: inline-block;
    }
}
</style>