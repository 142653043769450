<template>
    <div class="book-mark">
        <div class="search">
            <el-input v-model="keyword" size="mini" placeholder="请输入目录关键字" suffix-icon="el-icon-search"></el-input>
        </div>
        <Tree :data="dirList" :props="{label: 'name',value: 'id'}"
            ref="dirTree"
            :filter-node="filterNode"
            @getSelectData="selectDir">
            <template #text="scope">
                <span class="tree-slot" :title="scope.row.name">
                    <span class="tree-icon">
                        <img :src="dirIcon(scope.row)" alt="">
                    </span>
                    <span class="tree-text" ref="treeText">{{scope.row.name}}</span>
                    <span class="count" ref="treeText">{{fileCount(scope.row)}}</span>
                </span>
            </template>
        </Tree>
    </div>
</template>

<script>
import Tree from '@/components/tree/Tree.vue';

export default {
    components: {
        Tree
    },
    props: {
        dirList: {
            type: Array,
            default(){
                return [];
            }
        },
        currentPage: {
            type: Number,
            default: 0
        },
    },
    inject: ['state','mutations'],
    data(){
        return {
            keyword: '',
            dirs: []
        }
    },
    watch: {
        dirList: {
            handler(val){

            },
            immediate: true
        },
        keyword: {
            handler(val){
                this.$nextTick(() => {
                    this.$refs.dirTree.filter(val);
                })
            },
            immediate: true
        },
        currentPage: {
            handler(val){
                this.mutations.recursion(this.dirList,dir => {
                    if(val >= dir.pageIndex){
                        this.$refs.dirTree.selectKey = dir.id;
                    }
                })
            }
        }
    },
    methods: {
        filterNode(params,data) {
            let flag1 = data.name.indexOf(this.keyword) !== -1;     //  关键字检索
            return flag1;
        },
        fileCount(data){
            let text = '';
            if(data.isLocal){
                return;
            }else{
                if(data.dataType === 'dir'){
                    let num;
                    switch(data.type){
                        case 1:
                            num = data.children.filter(item => item.type === 2).length;
                            text = num ? `${num}册` : ``;
                        break;
                        case 2:
                            num = this.mutations.getVolumeAllFiles(data.children).length;
                            text = num ? `${num}页` : ``;
                        break;
                        case 4:
                            num = this.mutations.getVolumeAllFiles(data.children).length;
                            text = num ? `${num}页` : ``;
                        case 8:
                            num = this.mutations.getVolumeAllFiles(data.children).length;
                            text = num ? `${num}页` : ``;
                        break;
                    }
                }else{
                    if(data.pageNum === 1){
                        text = `${data.globalIndex}`;
                    }else{
                        text = `${data.globalIndex}~${data.globalIndex + data.pageNum - 1}`;
                    }
                }
                
            }
            return text;
        },
        dirIcon(data){
            if(data.dataType === 'dir'){
                if(data.type === 4){
                    let hasFile = this.mutations.hasFile(data);
                    if(!hasFile){
                        return this.state.staticImg['empty'];
                    }
                }
                return this.state.staticImg[data.type];
            }else if(data.dataType === 'file'){
                return this.state.treeIcon[data.fileType] || this.state.treeIcon['other'];
            }
        },
        selectDir(row){
            this.$emit('update:currentPage',row.pageIndex || 0);
        }
    }
}
</script>

<style scoped lang="less">
.book-mark{
    height: 100%;
    .search{
        padding: 10px 20px;
    }
    .mark-list{
        border-top: 1px solid #ccc;
    }
    .mark-item{
        padding: 0 5px;
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icon{
            width: 16px;
            height: 16px;
            margin-right: 5px;
            img{
                max-width: 100%;
                max-height: 100%;
            }
        }
        .page{
            color: #ccc;
            margin-left: auto;
        }
    }
    .mark-item:hover{
        background-color: #cde8ff;
        cursor: pointer;
    }
}
.tree-slot{
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
    .tree-icon{
        margin-right: 5px;
        img{
            width: 16px;
            height: 16px;
            vertical-align: middle;
        }
    }
    .tree-text{
        flex-grow: 1;
        width: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow:ellipsis;
    }
    .count{
        color: #ccc;
    }
}
</style>