<template>
    <div class="extract-content">
        <div class="type-list">
            <div class="radio" v-if="!openEdit">
                <el-radio-group v-model="type" @change="change">
                    <el-radio :label="item.label" v-for="item in typeList" :key="item.id">{{item.label}}</el-radio>
                </el-radio-group>
            </div>
            <div v-else class="title">{{type}}</div>
            <div class="btns" v-if="openEdit === false">
                <el-button v-if="permitList.includes('/caseinfo/nlp/extract')" size="small" type="primary" @click="extractClick" :loading="formLoading" :disabled="!activeFile || !$parent.pdfURL">要素提取</el-button>
            </div>
            <div v-else>
                <el-button size="small" type="primary" @click="openEdit = false">退出编辑</el-button>
                <el-button size="small" type="success" @click="submitForm">保存信息</el-button>
            </div>
        </div>
        <div class="num" v-if="!openEdit">
            <div class="num-left">
                <div class="page-item-container" v-for="(item,index) in currentList" :key="item.id" @click="fileClick(index)">
                    <span class="page-item" :class="{'active': activeFile === item}">{{text(index)}}</span>
                </div>
                <div class="add" @click="selectFileClick" v-if="typeList.length">
                    <i class="el-icon-plus"></i>
                </div>
            </div>
                
            <div class="btns">
                <el-button v-if="permitList.includes('/caseinfo/nlp/save')" size="small" icon="el-icon-edit-outline" type="primary" :disabled="!activeFile || formLoading" @click="editClick">编辑</el-button>
                <el-button v-if="permitList.includes('/caseinfo/nlp/delete')" size="small" icon="el-icon-delete" type="primary" @click="deleteFile" :disabled="currentList.length === 0 || formLoading">删除</el-button>
            </div>
        </div>
        <div class="form-container" ref="formContainer" v-if="activeFile">
            <div v-if="openEdit" v-loading="formLoading">
                <Edit ref="edit" :formTemplate="formTemplate[type]" :form="form"></Edit>
            </div>
            <div v-else-if="showTable" v-loading="formLoading">
                <Table :formTemplate="formTemplate[type]" :form="form"></Table>
            </div>
            <div v-else class="no-res">
                <i class="iconfont iconempty"></i>
                <p>没有任何抽取结果，</p>
                <p>请先【选择文件】后进行【要素提取】</p>
            </div>
        </div>
        <div v-else class="form-container" ref="formContainer">
            <div class="no-res">
                <i class="iconfont iconempty"></i>
                <p>没有任何抽取结果，</p>
                <p>请先【选择文件】后进行【要素提取】</p>
            </div>
        </div>
        
        <el-dialog title="选择文件" :visible.sync="openSelectFile" width="60%" :append-to-body="true" custom-class="dialog-select-file">
            <SelectFile v-if="openSelectFile" @clear="openSelectFile = false" @selectChange="selectChange"></SelectFile>
        </el-dialog>
    </div>
</template>

<script>
import { extractList , queryType , extract , saveExtract , deleteFile } from '@/api/textExtractAPI.js';
import Table from './components/Table.vue';
import Edit from './components/Edit.vue';
import SelectFile from './components/SelectFile.vue';

export default {
    components: {
        Table,
        Edit,
        SelectFile
    },
    data(){
        return {
            typeList: [],       //  类型列表
            type: '',           //  当前类型
            formTemplate: {
                '起诉状': {
                    '当事人': ['诉讼地位','当事人名称','证件号码','性别','民族','出生日期','住址','手机号码','职务','法人名称'],
                    '诉讼信息': ['案由','诉讼请求','事实与理由','受理法院','编写日期']
                },
                '上诉状': {
                    '当事人': ['诉讼地位','当事人名称','证件号码','性别','民族','出生日期','住址','手机号码','职务','法人名称'],
                    '上诉信息': ['原审案号','案由','上诉请求','上诉理由','事实与理由','受理法院','编写日期']
                },
                '答辩状': {
                    '当事人': ['诉讼地位','当事人名称','证件号码','性别','民族','出生日期','住址','手机号码','职务','法人名称'],
                    '答辩信息': ['答辩请求','事实与理由','受理法院','编写日期']
                },
                '判决书': {
                    '当事人': ['诉讼地位','当事人名称','证件号码','性别','民族','出生日期','住址','职务','法人名称','委托代理人','委托代理人单位'],
                    '判决信息': ['案号','诉讼请求','事实结论','判决结果','审判长','审判员','书记员','审判法院','签发日期']
                }
            },
            form: {},
            data: {},
            currentList: [],        //  第一页、第二页
            activeFile: {},
            openSelectFile: false,
            openEdit: false,
            formLoading: false,
        }
    },
    inject: ['state'],
    computed: {
        caseId(){
            return this.state.caseInfo.caseId;
        },
        showTable(){        //  form是否为空
            return Object.keys(this.form).length;
        },
        permitList(){
            return this.$store.getters.permitList;
        }
    },
    created(){
        queryType({ caseId: this.caseId }).then(res => {
            this.typeList = res || [];
            this.type = res && res.length && res[0].label;
            this.getData().then(res => {
                this.change(this.type);
            })
        })
    },
    watch: {
        openEdit: {
            handler(val){
                this.$refs.formContainer && (this.$refs.formContainer.scrollTop = 0);
            }
        }
    },
    methods: {
        getData(){
            return new Promise((resolve,reject) => {
                extractList({ caseId: this.caseId }).then(res => {
                    let obj = {};
                    for (let i = 0; i < res.length; i++) {
                        const item = res[i];
                        if(Object.keys(obj).includes(item.modelName)){
                            obj[item.modelName].push(item);
                        }else{
                            obj[item.modelName] = [item];
                        }
                    }
                    this.data = obj;
                    resolve();
                })
            })
        },
        change(val,index = 0){
            this.openEdit = false;
            this.currentList = this.data[this.type] || [];      //  当前选中类型的文件列表
            this.fileClick(index);
        },
        fileClick(index){           //  选择文件
            if(this.currentList.length){
                this.activeFile = this.currentList[index];
                this.$parent.concatPDF(this.activeFile.fileIds);
                this.resetForm();
            }else{
                this.activeFile = null;
                this.resetForm();       //  重置表单
                this.$parent.pdfURL = '';   //  重置PDF
            }
            this.$refs.formContainer && (this.$refs.formContainer.scrollTop = 0);
        },
        selectChange(ids){      //  选择文件手动提取
            this.$parent.concatPDF(ids.join(',')).then(() => {
                this.activeFile = {
                    fileIds: ids.join(','),
                    modelName: this.type,
                };
                this.resetForm();
                this.saveForm();
            })
        },
        text(index){
            const list = ['第一份','第二份','第三份','第四份','第五份','第六份','第七份','第八份','第九份','第十份','第十一份','第十二份'];
            return list[index];
        },
        extractClick(){         //  要素提取
            const params = {
                file: this.$parent.pdfBlob,
                fileType: this.type
            }
            this.formLoading = true;
            extract(params).then(res => {
                if(Object.keys(res).length > 0){        //  区分是否进行过要素提取
                    this.resetForm(res);
                    if(!this.activeFile.content){
                        this.saveForm();
                    }
                    this.$message.success('信息提取成功!');
                }else{
                    this.$message.warning('未提取到信息！');
                }
                
            }).finally(() => {
                this.formLoading = false;
            })
        },
        parseDSR(dsrKeys,content,formKeys){      //  原告，被告
            const dsrList = [];
            dsrKeys.forEach(dsrKey => {
                content[dsrKey] && content[dsrKey].forEach(DSR => {     //  
                    const obj = {};
                    formKeys.forEach(dsrKey => {
                        obj[dsrKey] = DSR.relations && DSR.relations[dsrKey] && DSR.relations[dsrKey].length && DSR.relations[dsrKey][0].text || '';
                    })
                    obj['诉讼地位'] = dsrKey;
                    dsrList.push(obj);
                })
            })
            return dsrList;
        },
        resetForm(params){      //  有参数代表手动点击文字提取
            const template = this.formTemplate[this.type]       //  表单模板(查找匹配的)
            const contentSave = this.activeFile && this.activeFile.contentSave;
            if(params || !contentSave){
                let content;
                if(params){
                    content = params;
                }else{
                    content = this.activeFile && this.activeFile.content;
                }
                if(content && Object.keys(content).length > 0){
                    const form = {};
                    const formKeys = template['当事人'];

                    form['当事人'] = this.parseDSR(['原告','被告','第三人','上诉人','被上诉人','答辩人','被答辩人','原审原告','原审被告'],content,formKeys);

                    const baseInfoKey = Object.keys(template).find(item => item !== '当事人');
                    const baseInfoKeys = template[baseInfoKey];
                    baseInfoKeys.forEach(key => {
                        form[key] =  content[key] && content[key][0].text;
                    })

                    this.form = form;
                }else{
                    this.form = {};
                }
            }else{
                // console.log(typeof contentSave);
                if(typeof contentSave === 'object'){
                    this.form = contentSave;
                }
            }
            
        },
        selectFileClick(){
            this.openSelectFile = true;
        },
        editClick(){
            this.openEdit = true;
        },
        submitForm(){
            this.form = this.$refs.edit.copyForm;
            this.saveForm();
        },
        saveForm(){
            const params = {
                caseId: this.caseId,
                fileIds: this.activeFile.fileIds,
                modelName: this.activeFile.modelName,
                contentSave: this.form,
                id: this.activeFile.id,
            }
            
            // console.log(params);
            saveExtract(params).then(res => {
                this.$message.success('保存成功!');
                this.openEdit = false;
                this.getData().then(() => {
                    if(!params.id){
                        this.change('',this.currentList.length);
                    }else{
                        this.change('',this.currentList.indexOf(this.activeFile));
                    }
                })
            })
        },
        deleteFile(){
            const params = {
                id: this.activeFile.id
            }
            this.$confirm('确认要删除此份抽取结果吗 ？删除后将无法恢复，只能重新抽取。', '确认删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteFile(params).then(res => {
                    this.$message.success('删除成功');
                    this.currentList.splice(this.currentList.indexOf(this.activeFile),1);
                    this.change();
                    this.resetForm();
                })
            }).catch(() => {
                      
            });
        }
    },
}
</script>

<style lang="less" scoped>
.extract-content{
    height: 100%;
    display: flex;
    flex-direction: column;
}
.type-list{
    height: 76px;
    border-bottom: 1px dashed #bbb;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    flex-shrink: 0;
    .title{
        color: #101010;
        font-weight: bold;
        font-size: 18px;
    }
}
.num{
    flex-shrink: 0;
    width: 100%;
    padding: 16px 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .num-left{
        display: flex;
        flex-wrap: wrap;
        .page-item-container{
            background-color: #F0F2F5;
            margin-bottom: 10px;
            .page-item{
                display: inline-block;
                height: 32px;
                padding: 6px 20px;
                margin: 2px;
                box-sizing: border-box;
                color: #303133;
                cursor: pointer;
            }
            > span.active{
                background-color: #fff;
                color: #1990FD;
            }
        }
        .page-item-container:last-child{
            margin-right: 10px;
        }
        .add{
            width: 40px;
            height: 36px;
            background-color: #F0F2F5;
            border: 1px solid #E6E9F0;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            i{
                color: #101010;
            }
        }
    }
    .btns{
        padding-left: 20px;
        flex-shrink: 0;
    }
    
    i{
        cursor: pointer;
        color: #FF2525;
    }
}
.form-container{
    flex-grow: 1;
    // height: calc(100% - 76px - 67px);
    overflow: auto;
    padding: 15px 30px;
    box-sizing: border-box;
    .no-res{
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 18px;
        color: #101010;
        padding-top: 200px;
        box-sizing: border-box;
        i{
            font-size: 100px;
            color: #A1A1A1;
            margin-bottom: 30px;
        }
    }
}
</style>

<style lang="less">
.dialog-select-file{
    .el-dialog__header{
        background-color: #fff;
        border-bottom-color: #E6E6E6;
    }
    .el-dialog__body{
        padding: 0;
    }
}
</style>