<template>
    <div class="screen-view" v-show="visible" v-if="firstLoad">
        <div class="close iconfont iconclose" @click="close"></div>
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        appendToBody: {
            type: Boolean,
            default: true
        }
    },
    data(){
        return {
            firstLoad: false
        }
    },
    watch: {
        visible: {
            handler(val){
                if(val){
                    this.firstLoad = true;
                }
            },
            immediate: true
        }
    },
    mounted(){
        if(this.appendToBody){
            document.body.appendChild(this.$el);
        }
    },
    methods: {
        close(){
            this.$emit('update:visible',false);
            this.$emit('cancel');
        }
    }
}
</script>

<style scoped lang='less'>
.screen-view{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 2000;
    overflow: auto;
    .close{
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-color: #606266;
        font-size: 24px;
        position: absolute;
        top: 15px;
        right: 15px;
        color: #fff;
        border-radius: 50%;
        cursor: pointer;
    }
}
</style>