import service from '@/utils/request';

export function checkCase(params){    //  检测案件
    return service.get('/case-check/caseInfo/receiveCaseId',{
        params
    })
}

export function inspectRecord(params){    //  阅卷请求质检巡查记录
    return service.get('/case-check/caseInfo/queryRecord',{
        params
    })
}