<template>
    <div>
        <TreeItem 
            v-on="$listeners" 
            ref="treeItem" 
            v-for="item in data" 
            :key="item[props.value || 'value']" 
            :treeItem="item"
            :treeList="data"
            :level="1">
        </TreeItem>
    </div>
</template>

<script>
import TreeItem from './TreeItem';

export default {
    name: 'Tree',
    components: {
        TreeItem
    },
    provide(){
        return {
            root: this,
        }
    },
    props: {
        filterNode: {
            type: Function,
            default(){
                return function(){return true};
            }
        },
        data: {
            type: Array,
            default(){
                return [];
            }
        },
        props: {
            type: Object,
            default(){
                return {};
            }
        },
        showRadio: {        //  显示单选框
            type: Boolean,
            default: false
        },
        showCheckbox: {     //  显示多选框
            type: Boolean,
            default: false
        },
        checkboxValues: {   //  多选框初始值
            type: Array,
            default(){
                return []
            }
        },
        draggable: {
            type: Boolean,
            default: false
        },
        radioValue: {
            type: String,
            default: ''
        }
    },
    computed: {
        line(){
            return this.$refs.line;
        }
    },
    data(){
        return {
            // radioValue: '',
            selectKey: ''
        }
    },
    methods: {
        recursion(node,call){   //  起始节点
            node.$children.forEach(item => {
                if(item.$options.name != 'TreeItem') return;
                call(item,node);    //  当前节点，父节点
                item.$children.length ? this.recursion(item,call) : '';
            });
        },
        expand(flag){
            this.recursion(this,(item,node) => {
                if(flag){
                    item.onceExpand = true;
                }
                item.isExpand = flag;
            })
        },
        getCheckboxValues(){ 
            return this.checkboxValues;
        },
        filter(val){
            this.recursion(this,item => {
                item.visible = this.filterNode(val,item.treeItem);
                if(item.visible){
                    let parent = item.$parent;
                    while(parent.$options.name === 'TreeItem'){
                        parent.visible = true;
                        parent = parent.$parent;
                    }
                }
                
            })
        }
        // getRadioValue(){
        //     return this.radioValue;
        // }
    }
}
</script>

<style scoped lang='less'>
</style>