<template>
    <div class="stepbar">
        <ul class="step-list">
            <li class="step-item" v-for="(item,index) in stepList" :key="index" :class="{'active': currentIndex === index + 1,'xxxx': currentIndex > index + 1}">
                <div class="label">{{item.label}}</div>
                <div class="index">
                    <span v-if="currentIndex > index + 1" class="el-icon-check"></span>
                    <span v-else>{{index + 1}}</span>
                </div>
            </li>
        </ul>
        <div class="line"></div>
    </div>
</template>

<script>
export default {
    props: {
        stepList: {
            type: Array,
            default(){
                return [];
            }
        },
        currentIndex: {
            type: Number,
            default: 0
        }
    },
    data(){
        return {
            
        }
    },
    methods:{
        
    }
}
</script>

<style scoped lang='less'>
.stepbar{
    font-size: 16px;
    position: relative;
    width: 100%;
    .step-list{
        display: flex;
        justify-content: center;
        .step-item{
            width: 200px;
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 1;
            .label{

            }
            .index{
                width: 30px;
                height: 30px;
                border-radius: 50%;
                text-align: center;
                line-height: 30px;
                border: 1px solid #BFBFBF;
                color: #BFBFBF;
                background-color: #fff;
                margin-top: 10px;
            }
        }
        .step-item.active{
            .label{
                color: #1976D2;
                font-weight: bold;
            }
            .index{
                background-color: #1976D2;
                border-color: #1976D2;
                color: #fff;
            }
        }
        .step-item.xxxx{
            .index{
                border-color: #1976D2;
                color: #1976D2;
            }
        }
    }
    .line{
        width: 100%;
        height: 3px;
        background-color: #ccc;
        position: absolute;
        bottom: 14px;
    }
}
</style>