<template>
    <div class="box" v-if="visible" tabindex="1" @blur="blur" :style="style">
        <ul class="list">
            <li class="menu-item" v-for="(item,index) in list" :key="index" @click="menuClick(item)">
                <span class="text">{{item}}</span>
                <div class="line" v-if="!(index === list.length - 1)"></div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data(){
        return {
            list: ["复制",'高亮并批注','划线并批注'],
            visible: false,
            style: {
                left: 0,
                top: 0
            }
        }
    },
    methods: {
        init(event){
            this.visible = true;
            
            this.$nextTick(() => {
                this.$el.focus();
                let y = event.pageY;
                // if(document.body.offsetHeight - event.pageY < 80){
                //     y = event.pageY - 80;
                // }
                // console.log(event.pageX,y);
                this.style.left = event.pageX + 5 + 'px';
                this.style.top = y + 5 + 'px';
            })
        },
        menuClick(data){
            this.visible = false;
            this.$emit('menuClick',data);
            this.$emit('close');
        },
        blur(){
            this.visible = false;
            this.$emit('close');
        },
    }
}
</script>

<style scoped lang='less'>
.box{
    border: 1px solid #e8e8e9;
    padding: 2px 3px;
    font-size: 14px;
    line-height: 20px;
    box-shadow: 5px 5px 2px -3px #666;
    z-index: 9999;
    background-color: #fff;
    position: fixed;
}
.box:focus{
    outline: none;
}
.list{
    display: flex;
    white-space: nowrap;
    .menu-item{
        position: relative;
        padding: 0px 5px;
        .text{
            padding: 5px 15px;
            display: inline-block;

        }
        .text:hover{
            background-color: #e8e8e9;
            cursor: pointer;
        }
        .line{
            position: absolute;
            width: 1px;
            height: 16px;
            right: 0;
            top: 8px;
            background-color: #E6E6E6;
        }
    }
}

// .menu-item:hover{
//     background-color: #e8e8e9;
//     cursor: pointer;
// }
</style>