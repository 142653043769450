<template>
    <div>
        <div ref="treeItem" class="tree-item" v-show="visible" :draggable="root.draggable" 
            @dragstart="root.draggable && dragstart($event)" 
            @dragover.prevent="root.draggable && dragover($event)" 
            @dragleave="root.draggable && dragleave($event)" 
            @drop="root.draggable && drop($event)"
            @dragend="dragend"
        >
            <span class="expand" :class="{'active': !isExpand}" @click="isExpand = !isExpand">
                <i v-if="treeItem.children && treeItem.children.length > 0" class="iconfont iconzhankai"></i>
            </span>
            <span class="checkbox" v-if="(root.showRadio || root.showCheckbox) && level != 1">
                <el-radio v-if="root.showRadio" :value="root.radioValue" :label="treeItem[props.value || 'value']" @input="setRadioValue"></el-radio>
                <el-checkbox v-if="root.showCheckbox" :value="root.checkboxValues.includes(treeItem[props.value || 'value'])" @change="checkboxChange"></el-checkbox>
            </span>
            <span ref="treeContent" class="tree-item-content" 
                :class="{'selected': root.selectKey === treeItem[props.value || 'value'],'inner': isInner}" 
                @click="select" 
                @dblclick.stop="isExpand = !isExpand">
                <TreeItemSpan></TreeItemSpan>
            </span>
        </div>
        <!-- <CollapseTransition> -->
            <div class="pl20" ref="subMenu" v-show="isExpand" style="overflow: hidden">
                <TreeItem
                    v-on="$listeners" 
                    @select="select" 
                    v-for="(subItem,index) in treeItem.children" 
                    :key="index"
                    :treeItem="subItem"
                    :treeList="treeItem.children"
                    :level="level + 1"
                >
                </TreeItem>
            </div>
        <!-- </CollapseTransition> -->
    </div>
</template>

<script>
// import CollapseTransition from '@/components/animate/CollapseTransition';

export default {
    name: 'TreeItem',
    components: {
        // CollapseTransition,
        TreeItemSpan: {
            inject: ['root'],
            render(h){
                if(this.root.$scopedSlots.text){
                    let node = this.root.$scopedSlots.text({row: this.$parent.treeItem,node: this});
                    return node;
                }else{
                    const str = this.$parent.treeItem[this.$parent.props.label || 'label'];
                    return <span title={str}>{str}</span>;
                }
            }
        }
    },
    inject: ['root'],
    props: {
        treeItem: {
            type: Object,
            default(){
                return {};
            }
        },
        treeList: {
            type: Array,
            default(){
                return [];
            }
        },
        level: {
            type: Number,
            default: 1
        }
    },
    data(){
        return {
            isExpand: true,    //  页面展开
            visible: true,    //  项是否可见
            props: {
                value: this.root.props['value'] || 'value',
                label: this.root.props['label'] || 'label'
            },
            flag: false,
            isInner: false
        }
    },
    computed: {
        treeContent(){
            return this.$refs.treeContent;
        }
    },
    methods: {
        select(){
            this.root.selectKey = this.treeItem[this.props.value || 'value'];
            if(this.treeItem.pid != '0'){
                if(this.root.showRadio){
                    this.setRadioValue();
                }
                if(this.root.showCheckbox){
                    this.checkboxChange();
                }
            }
            this.$emit('getSelectData',this.treeItem);      //      选中数据传递
        },
        setRadioValue(){
            // this.root.radioValue = this.treeItem[this.props.value || 'value'];
            this.root.$emit('update:radioValue',this.treeItem[this.props.value || 'value']);
        },
        checkboxChange(val){
            let index = this.root.checkboxValues.indexOf(this.treeItem[this.props.value || 'value']);
            if(index === -1){
                this.root.checkboxValues.push(this.treeItem[this.props.value || 'value']);
            }else{
                this.root.checkboxValues.splice(index,1);
            }
            // console.log(flag);
            // this.root.$emit('update:checkboxValues',[]);
        },
        dragstart(event){
            this.tree.$emit('tree-dragstart',event,this);
        },
        dragover(event){
            this.tree.$emit('tree-dragover',event,this);
        },
        dragleave(event){
            this.tree.$emit('tree-dragleave',event,this);
        },
        dragend(event){
            this.tree.$emit('tree-dragend',event,this);
        },
        drop(event){
            this.tree.$emit('tree-drop',event,this);
        },
        
        
    },
    created(){
        const parent = this.$parent;
        if(parent.isTree){
            this.tree = parent;
        }else{
            this.tree = parent.tree;
        }
    }
}
</script>

<style scoped lang='less'>
.tree-item{
    height: 26px;
    line-height: 26px;
    box-sizing: border-box;
    display: flex;
    // border-bottom: 1px solid red;
    > span{
        display: inline-block;
    }
    .tree-item-content{
        height: 100%;
        padding: 0 5px;
        box-sizing: border-box;
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .tree-item-content:hover{
        background-color: #CDE8FF;
        cursor: pointer;
    }
    .tree-item-content.selected{
        background-color: #CDE8FF;
    }
    .tree-item-content.inner{
        background-color: #CDE8FF;
    }
    .expand{
        width: 26px;
        height: 26px;
        text-align: center;
        line-height: 26px;
        transition: all .3s linear;
        flex-shrink: 0;
    }
    .expand.active{
        transform: rotate(-90deg);
    }
    .expand:hover{
        color: #86E0FB;
    }
    .checkbox{
        min-width: 20px;
        height: 26px;
        /deep/ .el-checkbox{
            width: 20px;
            text-align: center;
        }
        /deep/ .el-radio{
            width: 20px;
            text-align: center;
            margin-right: 0;
            .el-radio__label{
                display: none;
            }
        }
    }
}
.pl20{
    padding-left: 20px;
}
// .tree-item.inner{
//     background-color: #CDE8FF;
// }
</style>