<template>
    <div class="table">
        <table v-for="(item,index) in form['当事人']" :key="index">
            <col style="width:136px" />  
            <col />  
            <col style="width:136px" />  
            <col />  
            <tr>
                <th colspan="4">当事人{{index + 1}}</th>
            </tr>
            <tr>
                <td>诉讼地位</td>
                <td>{{item['诉讼地位']}}</td>
                <td>当事人名称</td>
                <td>{{item['当事人名称']}}</td>
            </tr>
            <tr>
                <td>证件号码</td>
                <td>{{item['证件号码']}}</td>
                <td>性别</td>
                <td>{{item['性别']}}</td>
            </tr>
            <tr>
                <td>民族</td>
                <td>{{item['民族']}}</td>
                <td>出生日期</td>
                <td>{{item['出生日期']}}</td>
            </tr>
            <tr>
                <td>住址</td>
                <td>{{item['住址']}}</td>
                <td>{{ otherTitle === '判决信息' ? '' : '手机号码'}}</td>
                <td>{{ otherTitle === '判决信息' ? '' : item['手机号码']}}</td>
            </tr>
            <tr>
                <td>职务</td>
                <td>{{item['职务']}}</td>
                <td>法人名称</td>
                <td>{{item['法人名称']}}</td>
            </tr>
            <tr v-if="otherTitle === '判决信息'">
                <td>委托代理人</td>
                <td>{{item['委托代理人']}}</td>
                <td>委托代理人单位</td>
                <td>{{item['委托代理人单位']}}</td>
            </tr>

        </table>
        <table>
            <col style="width:136px" />  
            <col />  
            <tr>
                <th colspan="2">{{otherTitle}}</th>
            </tr>
            <tr v-for="item in otherKeys" :key="item">
                <td>{{item}}</td>
                <td>{{form[item]}}</td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        form: {
            type: Object,
            default(){
                return {};
            }
        },
        formTemplate: {
            type: Object,
            default(){
                return {};
            }
        }
    },
    data(){
        return {
            userForm: '',
        }
    },
    computed: {
        dsrKeys(){
            return this.formTemplate['当事人'];
        },
        otherTitle(){
            return Object.keys(this.formTemplate)[1];
        },
        otherKeys(){
            return this.formTemplate[this.otherTitle] || [];
        },
    },
    methods: {
        
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
.table{
    .title{
        color: #27B148;
    }
    table{
        margin-top: -1px;
        border-collapse: collapse;
        width: 100%;
        table-layout: fixed;
        word-break: break-all;
        word-wrap: break-word;
        th{
            height: 48px;
            text-align: left;
            color: #27B148;
        }
        td:nth-child(odd){
            width: 136px;
            height: 46px;
            background-color: #F0F2F5;
        }
    }
    table,th,td{
        border: 1px solid #ccc;
        text-indent: 5px;
    }
}
</style>