const whether1 = [
    {
        label: '是',
        value: "1"
    },
    {
        label: '否',
        value: "0"
    },
]
const whether2 = [
    {
        label: '是',
        value: 'Y'
    },
    {
        label: '否',
        value: 'N'
    },
]

const whether3 = [
    {
        label: '是',
        value: "1"
    },
    {
        label: '否',
        value: null
    },
]

export const mainFormModel = [
    {
        label: '年度',
        value: 'NNDD',
        type: 'time',
        detailType: 'year',
        require: true,
        valueFormat: 'yyyy',
        rules: [
            { required: true, message: '年度不能为空'},
        ]
    },
    {
        label: '档案分类编码',
        value: 'DABM',
        require: true,
        rules: [
            { required: true, message: '档案分类编码不能为空'},
        ]
    },
    {
        label: '归档号',
        value: 'DH'
    },
    {
        label: '全宗号',
        value: 'QZH'
    },
    {
        label: '目录号',
        value: 'MLH'
    },
    {
        label: '案卷号',
        value: 'JZH'
    },
    {
        label: '密级',
        value: 'MJ',
        type: 'select',
        require: true,
        rules: [
            { required: true, message: '请选择密级'}
        ],
    },
    {
        label: '保管期限',
        value: 'BGQX',
        type: 'select',
        require: true,
        rules: [
            { required: true, message: '请选择保管期限'}
        ],
        selectList: []
    },
    {
        label: '立卷部门',
        value: 'LJBM'
    },
    {
        label: '立卷人',
        value: 'LJR',
        require: true,
    },
    {
        label: '立卷日期',
        value: 'LJRQ',
        type: 'time',
        require: true,
    },
    {
        label: '归档部门',
        value: 'GDBM'
    },
    {
        label: '提交归档人',
        value: 'GDR',
        require: true,
    },
    {
        label: '提交归档日期',
        value: 'GDRQ',
        type: 'time'
    },
    {
        label: '移交人（实体）',
        value: 'YJR',
        require: true,
    },
    {
        label: '接收人',
        value: 'JSR'
    },
    {
        label: '档案接收日期',
        value: 'JSRQ',
        type: 'time'
    },
    {
        label: '可在互联网上查阅利用',
        value: 'YXHLWLY',
        type: 'select',
        selectList: whether2
    },
    {
        label: '可来院查阅利用(限制互联网利用)',
        value: 'YXBYLY',
        type: 'select',
        selectList: whether2
    },
    {
        label: '不对外提供利用',
        value: 'JZDWLY',
        type: 'select',
        selectList: whether2
    },
    {
        label: '是否预归档',
        value: 'SFYGD',
        type: 'select',
        selectList: whether3
    },
    {
        label: '归档来源',
        value: 'GDLY',
        type: 'select',
        selectList: [
            {
                label: '华宇',
                value: "1"
            },
            {
                label: '法研院',
                value: "2"
            }
        ]
    },
    {
        label: '归档模式',
        value: 'GDMS',
        type: 'select',
        selectList: [
            {
                label: '纸质',
                value: "1"
            },
            {
                label: '纸质+电子',
                value: "2"
            },
            {
                label: '电子',
                value: "3"
            }
        ]
    }
]

export const sideFormModel = [
    {
        label: '正卷册数',
        value: 'ZJCS',
        require: true,
        disabled: true,
    },
    {
        label: '正卷页数',
        value: 'ZJYS',
        require: true,
        disabled: true,
    },
    {
        label: '副卷册数',
        value: 'FJCS'
    },
    {
        label: '副卷页数',
        value: 'FJYS'
    },
    {
        label: '原审卷册数',
        value: 'YSJS'
    },
    {
        label: '原审卷页数',
        value: 'YSYS'
    },
    {
        label: '审判卷册数',
        value: 'YSJCS'
    },
    {
        label: '审判卷页数',
        value: 'YSJYS'
    },
    {
        label: '公安卷册数',
        value: 'GAJCS'
    },
    {
        label: '公安卷页数',
        value: 'GAJYS'
    },
    {
        label: '检察卷册数',
        value: 'JCJCS'
    },
    {
        label: '检察卷页数',
        value: 'JCJYS'
    },
    {
        label: '并卷情况',
        value: 'BJQK'
    },
    {
        label: '鉴定情况',
        value: 'JDQK'
    },
    {
        label: '备注',
        value: 'BEIZ',
        type: 'textarea'
    },
]

export const caseFormModel = [
    {
        label: '案号',
        value: 'AH',
        require: true,
        rules: [
            { required: true, message: '案号不能为空'},
        ]
    },
    {
        label: '原审法院',
        value: 'JBFY',
    },
    {
        label: '原审案号',
        value: 'YSAH',
    },
    {
        label: '相关案号',
        value: 'XGAH',
    },
    {
        label: '立案案由',
        value: 'LAAY',
        require: true,
        rules: [
            { required: true, message: '立案案由不能为空'},
        ]
    },
    {
        label: '立案日期',
        value: 'LARQ',
        type: 'time',
        require: true,
        rules: [
            { required: true, message: '请选择立案日期'},
        ]
    },
    {
        label: '立案人',
        value: 'LAR',
    },
    {
        label: '案件来源',
        value: 'AJLY',
    },
    {
        label: '执行依据案号或文书编号',
        value: 'ZXYJWSBH',
    },
    {
        label: '适用程序',
        value: 'SYCX',
    },
    {
        label: '是否采取财产保全措施',
        value: 'CCBQ',
        type: 'select',
        selectList: whether1
    },
    {
        label: '是否采取证据保全措施',
        value: 'ZJBQ',
        type: 'select',
        selectList: whether1
    },
    {
        label: '是否采取先予执行措施',
        value: 'XYZX',
        type: 'select',
        selectList: whether1
    },
    {
        label: '是否提出管辖（执行管辖）异议',
        value: 'TCYYF',
        type: 'select',
        selectList: whether1
    },
    {
        label: '承办部门',
        value: 'CBBM',
        require: true,
        rules: [
            { required: true, message: '承办部门不能为空'},
        ]
    },
    {
        label: '审判长',
        value: 'SPZH',
    },
    {
        label: '承办人',
        value: 'CBRR',
        require: true,
        rules: [
            { required: true, message: '承办人不能为空'},
        ]
    },
    {
        label: '承办人所属角色',
        value: 'CBRSSJS',
    },
    {
        label: '合议成员',
        value: 'HYCY',
    },
    {
        label: '合议成员所属角色',
        value: 'HYCYSSJS',
    },
    {
        label: '书记员',
        value: 'SJY',
    },
    {
        label: '是否不公开审理',
        value: 'SFBGK',
        type: 'select',
        selectList: whether1
    },
    {
        label: '是否延期审理',
        value: 'SFYQSL',
        type: 'select',
        selectList: whether1
    },
    {
        label: '审理是否超审限',
        value: 'SFCSX',
        type: 'select',
        selectList: whether1
    },
    {
        label: '是否中止审理',
        value: 'MQZZ',
        type: 'select',
        selectList: whether1
    },
    {
        label: '结案方式',
        value: 'JAFS',
        require: true,
    },
    {
        label: '一审结果',
        value: 'YSJG',
    },
    {
        label: '二审结果',
        value: 'ESJG',
    },
    {
        label: '再审结果',
        value: 'ZSJG',
    },
    {
        label: '执行结果',
        value: 'ZXJG',
    },
    {
        label: '结案日期',
        value: 'JARQ',
        type: 'time',
        require: true,
        rules: [
            { required: true, message: '结案日期不能为空'},
        ]
    },
    {
        label: '结案标的额',
        value: 'JABDJE',
    },
    {
        label: '结案案由',
        value: 'JAAY',
        require: true,
        rules: [
            { required: true, message: '结案案由不能为空'},
        ]
    },
    {
        label: '生效日期',
        value: 'SXRQ',
        type: 'time',
    },
    {
        label: '上诉日期',
        value: 'SSRQ',
        type: 'time',
    },
    {
        label: '法官助理',
        value: 'FGZL',
    },
    {
        label: '二审法院',
        value: 'ESFY',
    },
    {
        label: '再审法院',
        value: 'ZSFY',
    },
    {
        label: '案件类型代码',
        value: 'AJLXDM',
        require: true,
        rules: [
            { required: true, message: '案件类型代码不能为空'},
        ]
    },
    {
        label: '案件类型名称',
        value: 'AJLXMC',
        require: true,
        rules: [
            { required: true, message: '案件类型名称不能为空'},
        ]
    },
]

export const dsrFormModel = [
    {
        label: '序号',
        value: 'XH',
        require: true,
        rules: [
            { required: true, message: '序号不能为空'},
        ]
    },
    {
        label: '诉讼地位',
        value: 'SSDW',
        require: true,
        rules: [
            { required: true, message: '诉讼地位不能为空'},
        ]
    },
    {
        label: '姓名或名称',
        value: 'XM',
        require: true,
        rules: [
            { required: true, message: '姓名或名称不能为空'},
        ]
    },
    {
        label: '自然人性别',
        value: 'XB',
    },
    {
        label: '单位性质',
        value: 'DWXZ',
    },
    {
        label: '年龄',
        value: 'NL',
    },
    {
        label: '国籍',
        value: 'GJ',
    },
    {
        label: '籍贯',
        value: 'JG',
    },
    {
        label: '法定代表人',
        value: 'FDDBR',
    },
    {
        label: '职业',
        value: 'ZY',
    },
    {
        label: '文化程度',
        value: 'WHCD',
    },
    {
        label: '出生日期',
        value: 'CSRQ',
        type: 'time'
    },
    {
        label: '证件种类',
        value: 'ZJZL',
    },
    {
        label: '证件号码',
        value: 'ZJHM',
    },
    {
        label: '联系电话',
        value: 'LXDH',
    },
    {
        label: '地址',
        value: 'DZ',
    },
]


