<template>
    <div class="batch-item">
        <div class="title">
            <div class="name-path" @click="expand = !expand">
                <span class="iconfont iconxiajiantou" :class="{'expand': expand}"></span>
                <span>{{`第${batch.subIndex}批次`}}</span>
                <span>{{`【${batch.fileList.length}个】`}}</span>
                <div class="info">
                    <span class="ml15 mr15">|</span>
                    <span>数据来源：{{source}}</span>
                    <span class="ml15 mr15">|</span>
                    <span class="ml5">上传用户：{{batch.uploadUser && batch.uploadUser.realName}}</span>
                    <span class="ml15 mr15">|</span>
                    <span>上传时间：{{Date.dateFormat(Number(batch.createTime),'YY-mm-dd HH:MM:SS')}}</span>
                </div>
            </div>
        </div>
        <div v-show="expand">
            <div class="axis-item-container" v-for="item in dirList" :key="item.id" >
                <AxisItem 
                    ref="axisItem"
                    :dir="item"
                    @selectChange="selectChange" 
                    @selectFile="$listeners.selectFile"
                    @toImgView="$listeners.toImgView"
                    :orderFun="orderFun"
                ></AxisItem>
            </div>
        </div>
    </div>
</template>

<script>
import AxisItem from '../axisView/AxisItem.vue';

export default {
    name: 'BatchItem',
    components: {
        AxisItem
    },
    props: {
        batch: {
            type: Object,
            default(){
                return null;
            }
        },
        orderFun: Function,
    },
    mounted(){
        if(this === this.$parent.$children[0]){
            this.expand = true;
        }
    },
    inject: ['state','mutations'],
    computed: {
        source(){
            let name = '';
            switch(this.batch.source){
                case 'CLIENT': name = '客户端上传'; break;
                case 'REVIEW': name = '阅卷上传'; break;
                case 'HUAYU': name = '华宇同步'; break;
                case 'HUAXIA': name = '华夏同步'; break;
                case 'UNKNOWN': name = '未知的'; break;
            }
            return name;
        },
        dirList(){
            // let dirs = [];
            // this.batch.fileList.forEach(file => {
            //     let dir = this.state.dirObj[file.pid];
            //     if(!dirs.find(item => item.id === dir.id)){
            //         let newDir = {
            //             id: dir.id,
            //             pid: dir.pid,
            //             name: dir.name,
            //             children: []
            //         }
            //         dirs.push(newDir);
            //     }
            //     let target = dirs.find(item => item.id === dir.id);
            //     target.children.push(file);
            // })
            // console.log(dirs);
            return this.batch.dirs;
        },
    },
    data() {
        return {
            allSelected: false,
            expand: false
        };
    },
    methods: {
        checkboxChange(val){
            this.batch.fileList.forEach(item => item.selected = val);
        },
        selectChange(){
            let flag = this.$refs.axisItem.find(item => !item.allSelected);
            this.allSelected = !flag;
            this.$emit('selectChange');
        },
    },
};
</script>

<style scoped lang='less'>
.batch-item{
    padding: 20px 8px;
    .axis-item-container{
        padding: 20px 13px;
    }
}
.title{
    display: flex;
    align-items: center;
    font-size: 16px;
    .point {
        // width: 8px;
        // height: 8px;
        // background: #1976d2;
        // border-radius: 50%;
        // margin-right: 16px;
        // box-shadow: 0 0 0 2px rgba(25,118,210,.2);
    }
    .name-path{
        font-size: 14px;
        color: #009C25;
        cursor: pointer;
        display: flex;
        .info{
            color: #999;
        }
        > span{
            margin-left: 10px;
        }
        .iconfont{
            display: inline-block;
            transform: rotate(-90deg);
        }
        .expand{
           transform: rotate(0);
        }
    }
}
.file-list{
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    user-select: none;
    position: relative;
    .text{
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .line{
        width: 2px;
        height: 100%;
        background: #E6E6E6;
        position: absolute;
        left: 3px;
        top: 0;
    }
}

</style>