<template>
    <div class="output-pdf">
        <div class="select">
            <div class="title">选择输出的内容：</div>
            <div>
                <!-- <div class="radio-item"><el-radio :value="value" @input="$emit('input',$event)" :label="1">案件PDF文件    一个连续的案件PDF</el-radio></div>
                <div class="radio-item"><el-radio :value="value" @input="$emit('input',$event)" :label="2">案件PDF文件     一个分册一个PDF</el-radio></div>
                <div class="radio-item"><el-radio :value="value" @input="$emit('input',$event)" :label="3">案件PDF文件 和 XML    一页卷宗一个PDF</el-radio></div> -->
                <div class="radio-item"><el-radio :value="value" @input="$emit('input',$event)" :label="4">案件JPG文件 和 XML    一页卷宗一个JPG</el-radio></div>
            </div>
        </div>
        <div class="action">
            <button class="btn btn-achive" @click="download">下载</button>
        </div>
        
    </div>
</template>

<script>

export default {
    props: {
        value: {
            type: Number,
            default: 4
        },
    },
    methods:{
        download(){
            this.$emit('download');
        }
    }
}
</script>

<style scoped lang='less'>
    .output-pdf{
        display: flex;
        justify-content: center;
        > div{
            width: 360px;
            height: 240px;
            border: 1px dashed #bbb;
            margin: 0 2px;
            box-sizing: border-box;
            padding: 24px 30px;

            
            
        }
        .select{
            .title{
                color: #999;
            }
            .radio-item{
                margin-top: 20px;
            }
        }
        .action{
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    
</style>